import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Collapse, Text } from '../../components';
import { InspectionValue } from '../../types/submission';
import InspectionItem from '../shared/InspectionItem';
import {
  AttachmentsFormItem, AddAttachment, AttachmentFieldItem, AttachmentType,
} from '../forms/useAttachments';
import {
  InspectionCategoryFormItem, InspectionFieldState,
} from '../forms/useInspectionForm';
import { removeCategoryNameSuffixByCategoryType } from '../utils/utils';

const StyledCollapse = styled(Collapse)<{ hasError: boolean; isAllFieldsFilled: boolean }>`
  ${({ theme, hasError, isAllFieldsFilled }) => {
    if (hasError) {
      return `
        background-color: ${theme.palette.red.variation95};
        color: ${theme.palette.red.variation60};
      `;
    }

    if (isAllFieldsFilled) {
      return `
        background-color: ${theme.palette.blue.variation50};
        color: ${theme.palette.neutral.variation100};
      `;
    }

    return `
        background-color: ${theme.palette.darkBlue.variation95};
        color: ${theme.palette.darkBlue.variation50};
      `;
  }}
`;

const StyledRepairBadge = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.red.variation40};
  border-radius: 50%;
`;

const StyledCollapseTitle = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-left: 16px;
  padding-right: 8px;
`;

const StyledCollapseContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface CategoryProps {
  categoryName: string;
  category: InspectionCategoryFormItem;
  isOpen?: boolean;
  readOnly?: boolean;
  attachments: AttachmentsFormItem;
  onInspectionFormChange?: (categoryName: string, itemName: string, value: InspectionValue) => void;
  onAddAttachment?: (attachmentOptions: AddAttachment) => void;
  onEditNote?: (categoryName: string, itemName: string, noteText: string) => void;
  onDeleteNote?: (categoryName: string, itemName: string) => void;
  onDeletePhoto?: (categoryName: string, itemName: string, photoId: string) => void;
  isDriverSignOff?: boolean;
}

const Category: FC<CategoryProps> = ({
  categoryName,
  category,
  isOpen = false,
  readOnly = false,
  attachments,
  onInspectionFormChange = () => {},
  onAddAttachment = () => {},
  onEditNote = () => {},
  onDeletePhoto = () => {},
  onDeleteNote = () => { },
  isDriverSignOff = false,
}) => {
  const handleInspectionFormChange = (itemName: string, value: InspectionValue) => {
    onInspectionFormChange(categoryName, itemName, value);
  };

  const {
    numberOfFilledFields, hasError, isAllFieldsFilled, numberOfMechanicActionItems, isMechanicHasActionItem,
  } = useMemo(() => {
    const fields = Object.values(category?.items || {});
    const filledFields = fields.filter((field) => field.value);
    const mechanicActionsItems = fields.filter((field) => field.value === InspectionValue.RepairsMade || field.value === InspectionValue.RepairsNotNecessary);

    return {
      numberOfFilledFields: filledFields.length,
      hasError: fields.some((field) => field.isError),
      isAllFieldsFilled: filledFields.length === fields.length,
      numberOfMechanicActionItems: mechanicActionsItems?.length,
      isMechanicHasActionItem: mechanicActionsItems?.length > 0,
    };
  }, [category?.items]);

  const handleAddAttachment = ({ itemName, toAttach, type }: Omit<AddAttachment, 'categoryName'>) => {
    onAddAttachment({
      categoryName,
      itemName,
      toAttach,
      type,
    });
  };

  const handleEditNote = (itemName: string, noteText: string) => {
    onEditNote(categoryName, itemName, noteText);
  };

  const handleDeleteNote = (itemName: string) => {
    onDeleteNote(categoryName, itemName);
  };

  const handleDeletePhoto = (itemName: string, photoId: string) => {
    onDeletePhoto(categoryName, itemName, photoId);
  };

  const hasNotesOrAttachments = (attachmentFieldItem: AttachmentFieldItem): boolean => {
    if (attachmentFieldItem == null) {
      return false;
    }
    const hasNotes = attachmentFieldItem[AttachmentType.Notes] && attachmentFieldItem[AttachmentType.Notes].length > 0;
    const hasPhotos = attachmentFieldItem[AttachmentType.Photos] && attachmentFieldItem[AttachmentType.Photos].length > 0;

    return hasNotes || hasPhotos;
  };

  const categoryNameToDisplay = removeCategoryNameSuffixByCategoryType(categoryName, category.categoryType);

  const renderDriverReviewTitle = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 2,
        paddingRight: 1,
      }}
    >
      <Text
        sx={{
          textTransform: 'capitalize',
        }}
        fontLevel="title"
        fontSize="medium"
        fontWeight="lightBold"
      >
        {categoryNameToDisplay.toLowerCase()}
      </Text>
      <Text fontLevel="label" fontSize="large" fontWeight="bold">
        {`${numberOfFilledFields}/${Object.keys(category.items).length}`}
      </Text>
    </Box>
  );

  const isValid = (itemName: string, item: InspectionFieldState) => {
    const noCommentsOrPhoto = !readOnly && !hasNotesOrAttachments(attachments[itemName]) && item.value === InspectionValue.NeedsRepair;

    /** TODO: fix me, I broke reactivity */
    // eslint-disable-next-line
    item.isError = noCommentsOrPhoto;
    if (noCommentsOrPhoto) {
      return { noCommentsOrPhoto };
    }

    return {};
  };

  const renderDriverSignOffTitle = () => (
    <StyledCollapseTitle>
      {isMechanicHasActionItem && (
      <StyledRepairBadge>
        <Text fontLevel="title" fontSize="medium" fontWeight="bold">
          {numberOfMechanicActionItems}
        </Text>
      </StyledRepairBadge>
      )}
      <Text sx={{ textTransform: 'capitalize' }} fontLevel="title" fontSize="medium" fontWeight="bold">
        {categoryNameToDisplay.toLowerCase()}
      </Text>
    </StyledCollapseTitle>
  );

  return (
    <StyledCollapse
      isInitiallyOpen={isOpen}
      hasError={hasError}
      isAllFieldsFilled={isDriverSignOff ? isMechanicHasActionItem : isAllFieldsFilled}
      title={isDriverSignOff ? renderDriverSignOffTitle() : renderDriverReviewTitle()}
      content={(
        <StyledCollapseContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {Object.entries(category.items || {}).map(([itemName, item]) => (
              <InspectionItem
                field={item}
                withoutAttachments={readOnly && !hasNotesOrAttachments(attachments[itemName])}
                onStatusSelect={handleInspectionFormChange}
                attachments={attachments[itemName]}
                onAddAttachment={handleAddAttachment}
                isValid={isValid(itemName, item)}
                onEditNote={handleEditNote}
                readOnly={readOnly}
                onDeleteNote={handleDeleteNote}
                onDeletePhoto={handleDeletePhoto}
                title={itemName}
                name={itemName}
                key={itemName}
                isDriverSignOff={isDriverSignOff}
              />
            ))}
          </Box>
        </StyledCollapseContent>
      )}
    />
  );
};

export default Category;
