import React, {
  FC, useContext, useState, useEffect,
} from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Dvir from '../types/dvir';
import { EventsManagerContext } from '../context/EventsManagerContext';
import { TruckIcon } from '../icons/TruckIcon';
import BarItem from './BarItem';
import TrailerNumber from './TrailerNumber';
import { Button } from '../components';
import { StyledHeader } from './styles';
import { DvirHistoryData } from './types';

const StyleButtonDisabled = {
  '&.Mui-disabled': {
    background: '#eaeaea',
    color: '#c0c0c0',
  },
};

interface EquipmentBarProps extends Dvir {
  showTrailer: boolean;
  dvirForReview: DvirHistoryData[];
  onStartTrailerDvir: () => void;
  isPreTripCompleted: boolean;
  isPostTripCompleted: boolean;
}

const StyledTruckIcon = (
  <TruckIcon sx={{
    width: 24, height: 24, verticalAlign: 'middle', color: (theme) => theme.palette.neutral.variation40,
  }}
  />
);

const StyledButton = styled(Button)`
    max-width: 170px;
    width: fit-content;
    height: 56.77px;
    border-radius: 12px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    border: none;
`;

const EquipmentBar: FC<EquipmentBarProps> = ({
  showTrailer,
  isPreTripCompleted,
  isPostTripCompleted,
  dvirForReview,
  onStartTrailerDvir,
}) => {
  const { t } = useTranslation();
  const { vehicleNum } = useContext(EventsManagerContext);
  const { driverData } = useContext(EventsManagerContext);

  const [trailerVin, setTrailerVin] = useState('');

  useEffect(() => {
    if (driverData?.driverId) {
      const storedVin = localStorage.getItem(`trailer_vin_${driverData?.driverId}`);
      setTrailerVin(storedVin);
    }
  }, []);

  useEffect(() => {
    if (trailerVin) {
      localStorage.setItem(`trailer_vin_${driverData?.driverId}`, trailerVin);
    }
  }, [trailerVin]);

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      gap: 1.5,
    }}
    >
      <BarItem title={t('cloud_dvir__truck_number_label')} itemText={vehicleNum} icon={StyledTruckIcon} />
      {showTrailer && <TrailerNumber />}
      {showTrailer && trailerVin && isPreTripCompleted && !isPostTripCompleted && (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        >
          <StyledHeader />
          <StyledButton
            disabled={dvirForReview !== undefined && dvirForReview.length > 0}
            textTransform="uppercase"
            isFilled
            onClick={onStartTrailerDvir}
            sx={StyleButtonDisabled}
          >
            {t('cloud_dvir__trailer_only_dvir')}
          </StyledButton>
        </Box>
      )}
    </Box>
  );
};

export default EquipmentBar;
