import { useState, useEffect, useRef } from 'react';
import { initFieldState } from './constants';
import { FieldState } from '../types';
import { convertToFixed } from '../../utils/numbers';
import { EngineData } from '../../context/EventsManagerContext';
import { InspectionTripType } from '../../types/types';
import { Submission } from '../../types/submission';

export const VehicleFormFields = {
  odometer: { name: 'odometer', optional: false },
  odometerIsoUom: { name: 'odometerIsoUom', optional: false },
  engineHours: { name: 'engineHours', optional: false },
  inspectionTripType: { name: 'inspectionTripType', optional: false },
};

export const VehicleFormLabels = {
  [VehicleFormFields.odometer.name]: 'cloud_dvir__odometer_reading',
  [VehicleFormFields.engineHours.name]: 'cloud_dvir__engine_hours',
  [VehicleFormFields.inspectionTripType.name]: 'cloud_dvir__inspection_trip_type',
};

export const InspectionTripTypeLabels = {
  PRE_TRIP: 'cloud_dvir__pre_trip',
  POST_TRIP: 'cloud_dvir__post_trip',
  OTHER: 'cloud_dvir__ad_hoc',
};

export const OdometerIsoUomLabels = {
  SMI: 'cloud_dvir__units_miles_abbr',
  KMT: 'cloud_dvir__units_kilometers_abbr',
};

export type VehicleFormFieldState = FieldState<string>;

export type VehicleForm = Record<keyof typeof VehicleFormFields, VehicleFormFieldState>;

interface UseVehicleFormProps {
  engineData: EngineData;
  inspectionTripType: InspectionTripType;
  defaultOdometerIsoUom: string;
  dvirData: Submission;
}

interface UseVehicleForm {
  form: VehicleForm;
  validate: () => boolean;
  handleFormChange: (name: string, value: string) => void;
  hasFormChanged: () => boolean,
}

const transformInitForm = ({ inspectionTripType, defaultOdometerIsoUom }: UseVehicleFormProps):VehicleForm => Object.values(VehicleFormFields)
  .reduce((form, field) => {
    let fieldValue = initFieldState.value;

    if (field.name === VehicleFormFields.inspectionTripType.name) {
      fieldValue = inspectionTripType;
    }

    if (field.name === VehicleFormFields.odometerIsoUom.name) {
      fieldValue = defaultOdometerIsoUom;
    }

    return {
      ...form,
      [field.name]: {
        ...initFieldState,
        value: fieldValue,
      },
    };
  }, {} as VehicleForm);

const useVehicleForm = (props: UseVehicleFormProps): UseVehicleForm => {
  const { engineData, dvirData } = props;

  const [form, setForm] = useState<VehicleForm>(() => transformInitForm(props));

  const isFormDirty = useRef<boolean>(false);

  const validate = (): boolean => {
    let isValid = true;

    const validatedForm = Object.entries(form).reduce((res, [name, field]) => {
      const fieldDescription = VehicleFormFields[name];
      if (!fieldDescription.optional && !field.value) {
        isValid = false;

        return {
          ...res,
          [name]: { ...field, isError: true },
        };
      }

      return { ...res, [name]: field };
    }, {} as VehicleForm);

    if (!isValid) {
      setForm(validatedForm);
    }

    return isValid;
  };

  const handleFormChange = (name: string, value: string) => {
    const updatedField: FieldState<string> = { value, isError: false };

    isFormDirty.current = true;

    setForm({ ...form, [name]: updatedField });
  };

  const isEngineDataField = (name: string) => [VehicleFormFields.odometer.name, VehicleFormFields.engineHours.name].includes(name);

  const updateFormFields = (data: Partial<Submission>) => {
    setForm((prevForm) => Object.entries(prevForm).reduce((result, [name, field]) => {
      if (data[name]) {
        const value = isEngineDataField(name) ? convertToFixed(data[name], 2) : data[name];

        return {
          ...result,
          [name]: { ...field, value, isError: false },
        };
      }

      return {
        ...result,
        [name]: field,
      };
    }, {} as VehicleForm));
  };

  useEffect(() => {
    const hasEngineData = engineData?.engineHours || engineData?.odometer;
    const isFormPartiallyFilled = form.engineHours.value !== '' || form.odometer.value !== '';

    if (!isFormPartiallyFilled && hasEngineData) {
      updateFormFields(engineData);
    }
  }, [engineData]);

  useEffect(() => {
    if (dvirData) {
      updateFormFields(dvirData);
    }
  }, [dvirData]);

  return {
    form,
    validate,
    handleFormChange,
    hasFormChanged: () => isFormDirty.current,
  };
};

export default useVehicleForm;
