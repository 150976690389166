import React, { FC, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Category from './Category';
import { InspectionValue } from '../../types/submission';
import { InspectionForm } from '../forms/useInspectionForm';
import { AttachmentsForm, AddAttachment } from '../forms/useAttachments';
import { VehicleForm } from '../forms/useVehicleForm';
import { CategoryType } from '../../types/template';
import { Header } from './Header';

interface InspectionProps {
  attachmentsForm: AttachmentsForm;
  onAddAttachment: (attachmentOptions: AddAttachment) => void;
  onEditNote: (categoryName: string, itemName: string, noteText: string) => void;
  onDeleteNote: (categoryName: string, itemName: string) => void;
  onDeletePhoto: (categoryName: string, itemName: string, photoId: string) => void;
  readOnly: boolean;
  isVehicleDvirSubmit: boolean;
  isTrailerDvirSubmit: boolean;
  vehicleForm: VehicleForm;
  inspectionForm: InspectionForm;
  onInspectionFormChange: (categoryName: string, itemName: string, value: InspectionValue) => void;
  onVehicleFormChange: (fieldName: string, value: string) => void;
  incompleteInspectionCategoryName: string;
  isDriverSignOff: boolean;
  currentDvirSubmitMode: string;
}

const Inspection: FC<InspectionProps> = ({
  incompleteInspectionCategoryName,
  attachmentsForm,
  onAddAttachment,
  onDeletePhoto,
  onEditNote,
  onDeleteNote,
  readOnly,
  isVehicleDvirSubmit,
  isTrailerDvirSubmit,
  vehicleForm,
  onVehicleFormChange,
  inspectionForm,
  onInspectionFormChange,
  isDriverSignOff,
  currentDvirSubmitMode,
}) => {
  const categoryItems = useRef<HTMLDivElement[]>([]);

  let renderCounter = 0;

  useEffect(() => {
    if (incompleteInspectionCategoryName) {
      categoryItems.current?.[incompleteInspectionCategoryName]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [incompleteInspectionCategoryName]);

  const isNewRendered = (categoryName: string) => (categoryName.startsWith(`${CategoryType.Vehicle}-`) && isVehicleDvirSubmit)
    || (categoryName.startsWith(`${CategoryType.Trailer}-`) && isTrailerDvirSubmit)
    || ((categoryName.startsWith(`${CategoryType.Vehicle}-`) || categoryName.startsWith(`${CategoryType.Trailer}-`))
      && isVehicleDvirSubmit
      && isTrailerDvirSubmit);

  const isReviewRendered = (categoryName: string) => !categoryName.startsWith(`${CategoryType.Vehicle}-`)
    && !categoryName.startsWith(`${CategoryType.Trailer}-`)
    && (isVehicleDvirSubmit || isTrailerDvirSubmit);

  const isRendered = (categoryName: string) => {
    const result = isNewRendered(categoryName) || isReviewRendered(categoryName);
    if (result) {
      renderCounter += 1;
    }
    return result;
  };

  return (
    <Box
      sx={{
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <Header readOnly={readOnly} vehicleForm={vehicleForm} onVehicleFormChange={onVehicleFormChange} currentDvirSubmitMode={currentDvirSubmitMode} />

      {Object.entries(inspectionForm).map(
        ([categoryName, category]) => isRendered(categoryName) && (
        <Box
          key={categoryName}
          ref={(ref) => {
            categoryItems.current[categoryName] = ref;
          }}
        >
          <Category
            category={category}
            categoryName={categoryName}
            attachments={attachmentsForm?.[categoryName]?.items}
            isOpen={renderCounter === 1}
            readOnly={readOnly}
            onInspectionFormChange={onInspectionFormChange}
            onAddAttachment={onAddAttachment}
            onEditNote={onEditNote}
            onDeleteNote={onDeleteNote}
            onDeletePhoto={onDeletePhoto}
            isDriverSignOff={isDriverSignOff}
          />
        </Box>
        ),
      )}
    </Box>
  );
};

export default Inspection;
