import { Sign as ISign } from '../submission/forms/useOverallForm';
import { DvirStatus, InspectionTripType } from './types';

export type Photo = { src: string, id: string };

export enum InspectionValue {
    Ok = 'OK',
    NeedsRepair = 'NEEDS_REPAIR',
    NotApplicable = 'NOT_APPLICABLE',
    RepairsMade = 'REPAIRS_MADE',
    RepairsNotNecessary = 'REPAIRS_NOT_NECESSARY',
    InProgress = 'IN_PROGRESS',
}

export enum InspectorType {
    Driver = 'DRIVER',
    Mechanic = 'MECHANIC'
}

export interface SubmissionDriverData {
    id: string;
    name: string;
}

export interface SubmissionLocation {
    latitude: string;
    longitude: string;
    address?: string,
}

export type SubmissionEvent = {
    inspectorId: string;
    safetyStatus: InspectionValue,
    inspectorType: InspectorType,
    updatedTime: string;
    signature?: string | ISign;
    note?: string;
    photos?: string[];
}

export type SubmissionCategoryItem = {
    name: string;
    id: string;
    events: SubmissionEvent[];
}

export type SubmissionCategory = {
    name: string;
    items: SubmissionCategoryItem[];
}

export enum SubmissionType {
    Trailer = 'trailer',
    Vehicle = 'vehicle',
}

export type Submission = {
    companyId: string;
    vin: string;
    submissionDate: string;
    templateName: string;
    inspectionTripType: InspectionTripType;
    location: SubmissionLocation;
    status: DvirStatus;
    initialSafetyStatus:InspectionValue;
    driver: SubmissionDriverData;
    engineHours: string | number;
    odometer: string | number;
    odometerIsoUom: string;
    events: SubmissionEvent[];
    categories: SubmissionCategory[];
    expirationDate: number;
    workOrderNumber?: string;
    submissionType?: SubmissionType;
}
