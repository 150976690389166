const _fc_props = {};
_fc_props.ante_meridiem = 'ER';
_fc_props.post_meridiem = 'OM EFTERMIDDAGEN';
_fc_props.retry = 'Prøve igen';
_fc_props.default = 'Standard';
_fc_props.origin = 'Oprindelse';
_fc_props.loading = 'Indlæser';
_fc_props.list = 'Liste';
_fc_props.send = 'Sende';
_fc_props.error = 'Fejl';
_fc_props.save = 'Gemme';
_fc_props.delete = 'Slet';
_fc_props.message = 'Besked';
_fc_props.logout = 'Log ud';
_fc_props.add = 'Tilføje';
_fc_props.remove = 'Fjerne';
_fc_props.done = 'Færdig';
_fc_props.other = 'Andet';
_fc_props.interval = 'Interval';
_fc_props.time = 'Tid';
_fc_props.speed = 'Fart';
_fc_props.longitude = 'Længde';
_fc_props.latitude = 'Breddegrad';
_fc_props.x_minutes = '{0} min';
_fc_props.general = 'Generel';
_fc_props.messages = 'Beskeder';
_fc_props.gps_fixes = 'GPS rettelser';
_fc_props.device_id = 'Enheds-id';
_fc_props.vehicle = 'Køretøj';
_fc_props.employee = 'Medarbejder';
_fc_props.version = 'Version';
_fc_props.statuses = 'Statusser';
_fc_props.edit = 'Redigere';
_fc_props.cancel = 'Afbestille';
_fc_props.yes = 'Ja';
_fc_props.none = 'Ingen';
_fc_props.never = 'Aldrig';
_fc_props.name = 'Navn';
_fc_props.true = 'Rigtigt';
_fc_props.status = 'Status';
_fc_props.distance = 'Afstand';
_fc_props.ok = 'Okay';
_fc_props.login = 'Log på';
_fc_props.next = 'Næste';
_fc_props.menu = 'Menu';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Tilbage';
_fc_props.select = 'Vælg';
_fc_props.dispatch = 'Forsendelse';
_fc_props.messaging = 'Beskeder';
_fc_props.plants = 'Planter';
_fc_props.last_plants = 'Sidste Planter';
_fc_props.vehicle_types = 'Køretøjstyper';
_fc_props.employee_types = 'Medarbejdertyper';
_fc_props.employee_groups = 'Medarbejdergrupper';
_fc_props.equipment_groups = 'Udstyrsgrupper';
_fc_props.job = 'Job';
_fc_props.order = 'Bestille';
_fc_props.ticket = 'Billet nr.';
_fc_props.small = 'Lille';
_fc_props.medium = 'Medium';
_fc_props.large = 'Stor';
_fc_props.reports = 'Rapporter';
_fc_props.payroll = 'Lønningsliste';
_fc_props.support = 'Support';
_fc_props.username = 'Brugernavn';
_fc_props.all = 'Alle';
_fc_props.question = 'Spørgsmål';
_fc_props.select_employee = 'Vælg Medarbejder';
_fc_props.phone = 'telefon';
_fc_props.january = 'januar';
_fc_props.february = 'februar';
_fc_props.march = 'marts';
_fc_props.april = 'April';
_fc_props.may = 'Kan';
_fc_props.june = 'juni';
_fc_props.july = 'juli';
_fc_props.august = 'august';
_fc_props.september = 'september';
_fc_props.october = 'oktober';
_fc_props.november = 'november';
_fc_props.december = 'december';
_fc_props.are_you_sure_you_want_to_delete = 'Er du sikker på, at du vil slette';
_fc_props.unknown = 'Ukendt';
_fc_props.select_all = 'Vælg alle';
_fc_props.equipment = 'Udstyr';
_fc_props.region = 'Område';
_fc_props.no_data = 'Ingen data';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adresse';
_fc_props.retrieving_address = 'Henter adresse...';
_fc_props.address_not_found = 'Adressen blev ikke fundet';
_fc_props.active_time = 'Aktiv tid';
_fc_props.destination_time = 'Destinationstid';
_fc_props.complete_time = 'Fuldstændig tid';
_fc_props.status_report = 'Status rapport';
_fc_props.export_report = 'Eksporter rapport';
_fc_props.download_report = 'Download rapport';
_fc_props.view_report = 'Se rapport';
_fc_props.duration = 'Varighed';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Startdato/tidspunkt';
_fc_props.select_start_date = 'Vælg Startdato';
_fc_props.end_date_time = 'Slutdato/tidspunkt';
_fc_props.select_end_date = 'Vælg Slutdato';
_fc_props.trip_time = 'Rejsetid';
_fc_props.travel_time = 'Rejsetid';
_fc_props.description = 'Beskrivelse';
_fc_props.map_controls = 'Kort kontrol';
_fc_props.road = 'Vej';
_fc_props.aerial = 'Antenne';
_fc_props.hybrid = 'Hybrid';
_fc_props.trip = 'Rejse';
_fc_props.map = 'Kort';
_fc_props.plant = 'Plante';
_fc_props.select_date = 'Vælg dato';
_fc_props.apply = 'ansøge';
_fc_props.save_settings = 'Gem indstillinger';
_fc_props.false = 'Falsk';
_fc_props.delete_confirmation = 'Slet bekræftelse';
_fc_props.last_name = 'Efternavn';
_fc_props.none_selected = 'Ingen valgt';
_fc_props.start_date = 'Start dato';
_fc_props.group_by = 'Grupper efter';
_fc_props.equipment_employee = 'Udstyr / Medarbejder';
_fc_props.inactive = 'Inaktiv';
_fc_props.active = 'Aktiv';
_fc_props.note_checking_may_affect_performance = 'Bemærk: Kontrol kan påvirke ydeevnen';
_fc_props.show_distancefuel_used = 'Vis afstand/forbrugt brændstof';
_fc_props.mins = 'min';
_fc_props.threshold_time = 'Tærskeltid';
_fc_props.include_voided_tickets = 'Inkluder annullerede billetter';
_fc_props.note_all_times_listed_are_in_minutes = 'Bemærk: Alle angivne tider er i minutter';
_fc_props.please_select_a_job_order_or_equipment = 'Vælg et job, en ordre, et anlæg, et udstyr eller en medarbejder';
_fc_props.device = 'Enhed';
_fc_props.select_equipment = 'Vælg Udstyr';
_fc_props.all_equipment_types = 'Alle udstyrstyper';
_fc_props.summary = 'Resumé';
_fc_props.units = {};
_fc_props.units.liters = 'Liter';
_fc_props.type = 'Type';
_fc_props.no_data_available = 'Ingen tilgængelig data';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Afstand og brændstof efter region';
_fc_props.menu_settings_title_settings = 'Indstillinger';
_fc_props.menu_settings_title_alerts = 'Advarsler';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Udstyr';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots og regioner';
_fc_props.days = '{0} dag(e)';
_fc_props.home = 'Hjem';
_fc_props.find = 'Find';
_fc_props.close_this_panel = 'luk dette panel';
_fc_props.filters = 'Filtre';
_fc_props.start = 'Start';
_fc_props.end = 'Ende';
_fc_props.location_details = 'Placeringsdetaljer';
_fc_props.first_name = 'Fornavn';
_fc_props.material_per_hour = 'Materiale pr. time';
_fc_props.equipment_pound = 'Udstyr #';
_fc_props.equipment_type = 'Udstyrstype';
_fc_props.options = 'Muligheder';
_fc_props.zoom_on_cursor_position = 'Zoom på markørposition';
_fc_props.display_equipment_identifier = 'Vis udstyrsidentifikator';
_fc_props.view_equipment = 'Se udstyr';
_fc_props.view_hotspots = 'Se hotspots';
_fc_props.view_regions = 'Se regioner';
_fc_props.use_standard_filters = 'Brug standardfiltre';
_fc_props.home_plant = 'Hjemmeplante';
_fc_props.last_plant = 'Sidste Plante';
_fc_props.equip_types = 'Udstyre. Typer';
_fc_props.equip_groups = 'Udstyre. Grupper';
_fc_props.orders = 'Ordre:% s';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Brug udstyrsfilter';
_fc_props.all_selected = 'Alle valgt';
_fc_props.of = 'Af';
_fc_props.your_map_session_has_expired = 'Din kortsession er udløbet. Opdater venligst siden.';
_fc_props.equipment_short = 'Udstyre.';
_fc_props.job_info_for = 'Jobinfo til';
_fc_props.order_info_for = 'Bestillingsinfo vedr';
_fc_props.ticket_info_for_vehicle = 'Billetinfo for køretøj';
_fc_props.inbox = 'Indbakke';
_fc_props.outbox = 'Udbakke';
_fc_props.alerts = 'Advarsler';
_fc_props.critical = 'Kritisk';
_fc_props.important = 'Vigtig';
_fc_props.informational = 'Oplysende';
_fc_props.view_routes = 'Se ruter';
_fc_props.hours_size = 'Timestørrelse';
_fc_props.used_count = 'Brugt Count';
_fc_props.last_login = 'Sidste login';
_fc_props.last_vehicle = 'Sidste køretøj';
_fc_props.regions = 'Regioner';
_fc_props.driver = 'Chauffør';
_fc_props.js_delete = 'Slet';
_fc_props.move = 'Bevæge sig';
_fc_props.edit_hotspot = 'Rediger Hotspot';
_fc_props.adjust_shape = 'Juster form';
_fc_props.edit_region = 'Rediger region';
_fc_props.enter_a_start_address = 'Indtast venligst en startadresse';
_fc_props.select_a_start_hotspot = 'Vælg venligst et starthotspot';
_fc_props.failed_to_find_start_hotspot = 'Kunne ikke finde start-hotspot.';
_fc_props.invalid_latitudelongitude = 'Ugyldig bredde-/længdegrad';
_fc_props.enter_an_end_address = 'Indtast venligst en slutadresse';
_fc_props.select_an_end_hotspot = 'Vælg venligst et slut-hotspot';
_fc_props.failed_to_find_end_hotspot = 'Kunne ikke finde slutningen af hotspot.';
_fc_props.print = 'Print';
_fc_props.these_directions_are_for_informational_purposes = 'Disse anvisninger er kun til informationsformål. Der gives ingen garanti for deres fuldstændighed eller nøjagtighed. Byggeprojekter, trafik eller andre begivenheder kan forårsage, at de faktiske forhold afviger fra disse resultater.';
_fc_props.enter_an_address = 'Indtast venligst en adresse';
_fc_props.select_a_hotspot = 'Vælg venligst et hotspot';
_fc_props.failed_to_find_hotspot = 'Det lykkedes ikke at finde Hotspot.';
_fc_props.select_a_vehicle = 'Vælg venligst et køretøj';
_fc_props.failed_to_find_vehicle = 'Kunne ikke finde køretøjet.';
_fc_props.failed_to_find_address = 'Adressen kunne ikke findes';
_fc_props.failed_to_find_vehicles = 'Kunne ikke finde køretøjer i søgekriterierne.';
_fc_props.get_address = 'Få adresse';
_fc_props.results_for = 'Resultater for';
_fc_props.error_retrieving_results = 'Fejl ved hentning af resultater';
_fc_props.no_results_found = 'Ingen resultater fundet';
_fc_props.address_is_required = 'Adresse er påkrævet';
_fc_props.city_is_required = 'By er påkrævet';
_fc_props.set_location = 'Indstil placering';
_fc_props.set_area_of_interest = 'Indstil interesseområde';
_fc_props.center_map_here = 'Centrer kort her';
_fc_props.zoom_to_street_level = 'Zoom til gadeniveau';
_fc_props.set_start = 'Indstil Start';
_fc_props.set_end = 'Sæt Slut';
_fc_props.delete_node = 'Slet node';
_fc_props.create_a_hotspot = 'Opret et hotspot';
_fc_props.create_a_region = 'Opret en region';
_fc_props.toggle_shapes = 'Skift mellem former';
_fc_props.refresh_map = 'Opdater kort';
_fc_props.more_options = 'Flere muligheder';
_fc_props.recenter_map = 'Gencentrer kort';
_fc_props.zoom_to_vehicle = 'Zoom til køretøj';
_fc_props.apply_filters = 'Anvend filtre';
_fc_props.grid_view = 'Gittervisning';
_fc_props.list_view = 'Listevisning';
_fc_props.click_to_sort = 'Klik for at sortere';
_fc_props.message_history = 'Meddelelseshistorik';
_fc_props.equipment_types = 'Udstyrstyper';
_fc_props.send_message = 'Send besked';
_fc_props.last_load = 'Sidste belastning';
_fc_props.last_load_on = 'Sidste belastning på';
_fc_props.manual_logout = 'Manuel log ud';
_fc_props.manual_login = 'Manuelt login';
_fc_props.no_employees_logged_in = 'Ingen medarbejdere logget ind';
_fc_props.select_phone = 'Vælg Telefon';
_fc_props.you_must_select_a_phone = 'Du skal vælge en telefon, udstyr og medarbejder';
_fc_props.are_you_sure_you_want_to_logout = 'Er du sikker på, at du vil logge ud';
_fc_props.fix_time = 'Fix tid';
_fc_props.to_next_fix = 'til næste rettelse';
_fc_props.minutes_to_next_fix = 'Minutter til næste rettelse';
_fc_props.liters = 'Liter';
_fc_props.destination = 'Bestemmelsessted';
_fc_props.return = 'Vend tilbage';
_fc_props.product = 'Produkt';
_fc_props.no_records_found = 'Ingen registreringer fundet.';
_fc_props.vehicle_type = 'Køretøjstype';
_fc_props.view_in_map = 'Se alt på kort';
_fc_props.uploads = 'Uploads';
_fc_props.upload_csv_file = 'Upload CSV-fil';
_fc_props.permanent = 'Permanent';
_fc_props.expire_time = 'Udløbstid';
_fc_props.please_select_x_for_tickets_marked_active = 'Vælg venligst {0} for billetter markeret med AKTIVE';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Flere billetter markeret AKTIVE for samme {0}';
_fc_props.failure_connecting_to_servlet = 'Fejl ved forbindelse til servlet.';
_fc_props.status_time_summary = 'Status Tidsoversigt';
_fc_props.view_in_map_one = 'Se på kort';
_fc_props.question_report = 'Spørgsmålsrapport';
_fc_props.end_date = 'Slutdato';
_fc_props.trailer_number = 'Trailer nummer';
_fc_props.region_panel = 'Regionspanel';
_fc_props.hotspot_panel = 'Hotspot panel';
_fc_props.shape = 'Form';
_fc_props.circle = 'Cirkel';
_fc_props.polygon = 'Polygon';
_fc_props.click_the_map_to_set_the_location = 'Klik på kortet for at indstille den ønskede placering.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Klik på kortet for at indstille hotspot-centret.';
_fc_props.click_the_map_to_set_the_region_center = 'Klik på kortet for at indstille regionscenteret.';
_fc_props.click_and_drag_points = 'Klik og træk punkter for at redigere formen. Klik på GEM for at gemme ændringer.';
_fc_props.size = 'Størrelse';
_fc_props.are_you_sure_you_want_to_move_this = 'Er du sikker på, at du vil flytte dette';
_fc_props.are_you_sure_you_want_to_delete_this = 'Er du sikker på, at du vil slette dette';
_fc_props.please_enter_a_valid_size = 'Indtast venligst en gyldig størrelse';
_fc_props.failed_to_save = 'Kunne ikke gemme.';
_fc_props.failed_to_move = 'Kunne ikke flytte.';
_fc_props.failed_to_delete = 'Kunne ikke slette.';
_fc_props.x_hours = '{0} timer';
_fc_props.hide_hotspots = 'Skjul hotspots';
_fc_props.show_regions = 'Vis regioner';
_fc_props.show_hotspots = 'Vis hotspots';
_fc_props.hide_regions = 'Skjul regioner';
_fc_props.numeric = 'Numerisk';
_fc_props.message_type = 'Meddelelsestype';
_fc_props.text = 'Tekst';
_fc_props.yes_no = 'Ja Nej';
_fc_props.responses = 'Svar';
_fc_props.disabled = 'handicappet';
_fc_props.group_name = 'Gruppe navn';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Er du sikker på, at du vil slette \'{0}\'-formatet?';
_fc_props.phone_number = 'Telefonnummer';
_fc_props.interface_pound = 'Interface #';
_fc_props.field = 'Mark';
_fc_props.default_label = 'Standard etiket';
_fc_props.custom_label = 'Brugerdefineret etiket';
_fc_props.you_must_enter_a_label = 'Du skal indtaste en etiket.';
_fc_props.enabled = 'Aktiveret';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Billetintegration';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off åbne Hotspots';
_fc_props.settings.associate_open_hotspots_wticket = 'Tilknyt åbne hotspots m/billet';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Tillad geofencing af åbne hotspots eller kun hotspots forbundet med Driver\'s Ticket';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Hvorvidt åbne Hotspots skal knyttes til billetter, når et køretøj kommer ind i dem';
_fc_props.settings.void_ticket_without_destination = 'Ugyldig billet uden destination';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'hvis en billet modtages, mens en anden er aktiv, hvis den aktive billet ikke har en destination, annulleres billetten.';
_fc_props.settings.autocreate_hotspots = 'Opret automatisk hotspots';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Om der automatisk skal oprettes hotspots fra destinationsadressen, når en billet modtages';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Autoaktiver billet ved modtagelse';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Er billetten aktiv, når den modtages af TrackIt (standard \'sand\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Autoluk aktiv billet ved modtagelse af minutter';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Hvor længe skal den aktuelle aktive billet have været aktiv, før den automatisk lukkes (standard \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Billet Max Aktiver Alder minutter';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Hvor gammel i minutter kan en billet være, når den aktiveres i TrackIt (Standard \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Max Hotspot ubrugte dage';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Indstiller det maksimale antal dage, et hotspot kan være ubrugt og stadig genoplives';
_fc_props.settings.radius_multiplier = 'Radius multiplikator';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Hvad skal radius ganges med, når en billet modtages fra ekspeditionssystemet';
_fc_props.settings.ticket_message_date_format = 'Billetmeddelelse Datoformat';
_fc_props.am = 'er';
_fc_props.pm = 'om eftermiddagen';
_fc_props.settings.ticket_message = 'Billet besked';
_fc_props.settings.add_ticket_element = 'Tilføj billetelement';
_fc_props.driver_performance = 'Driver ydeevne';
_fc_props.update_failed_can_not_connect = 'Opdatering mislykkedes: Kan ikke oprette forbindelse til servlet.';
_fc_props.unknown_error = 'Ukendt fejl';
_fc_props.selected = 'Valgte';
_fc_props.web_service = 'Webservice';
_fc_props.error_processing_request = 'Fejl ved behandling af anmodning.';
_fc_props.email_address = 'Email adresse';
_fc_props.id = 'ID';
_fc_props.last_used = 'Sidst brugt';
_fc_props.error_invalid_vehiclenum = 'Fejl Ugyldigt køretøjsnummer';
_fc_props.error_vehicle_is_not_logged_in = 'Fejl: Køretøjet er ikke logget ind.';
_fc_props.users = 'Brugere';
_fc_props.you_dont_have_access_to_this_page = 'Du har ikke adgang til at se denne side. Kontakt venligst din systemadministrator for at få adgang.';
_fc_props.previous_week = 'Forrige uge';
_fc_props.status_breakdown = 'Statusopdeling';
_fc_props.select_plant_or_employee_group = 'Du skal vælge enten et anlæg eller en medarbejdergruppe.';
_fc_props.cannot_select_plant_and_employee_group = 'Du kan ikke vælge både et anlæg og en medarbejdergruppe.';
_fc_props.odometer_must_be_a_number = 'Startkilometertæller skal være et tal';
_fc_props.ending_odometer_must_be_a_number = 'Afsluttende kilometertæller skal være et tal';
_fc_props.beginning_hour_meter_must_be_a_number = 'Starttimetæller skal være et tal';
_fc_props.ending_hour_meter_must_be_a_number = 'Sluttimetæller skal være et tal';
_fc_props.error_loading_status_change_page = 'Fejl ved indlæsning af statusændringsside.';
_fc_props.x_is_an_invalid_time_format = '{0} er et ugyldigt tidsformat, det skal være ÅÅÅÅ-MM-DD TT:MM:SS';
_fc_props.x_for_schedule_login = '{0} til planlægningslogin';
_fc_props.x_for_schedule_logout = '{0} til tidsplanlogout';
_fc_props.x_for_actual_login = '{0} for faktisk login';
_fc_props.x_for_actual_logout = '{0} for faktisk logout';
_fc_props.by_refreshing_the_time_card_detail = 'Ved at opdatere Time Card Detail vil du miste alle ændringer. Fortsæt?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Er du sikker på, at du vil slette {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Command Mobile Ticket URL';
_fc_props.year = 'År';
_fc_props.make = 'Lave';
_fc_props.model = 'Model';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket-adgangskode';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Mål';
_fc_props.number_of_digits = 'Antal cifre';
_fc_props.number_of_integer_digits = 'Antal heltalscifre';
_fc_props.number_of_fractional_digits = 'Antal brøkcifre';
_fc_props.search = 'Søg';
_fc_props.settings.command_mobile_ticket_date_order = 'Command Mobile Billet Dato Bestilling';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Dato Længde';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Dato Separator';
_fc_props.settings.command_mobile_ticket_slash = '/ (skråstreg)';
_fc_props.settings.command_mobile_ticket_dash = '- (bindestreg)';
_fc_props.settings.command_mobile_ticket_period = '. (periode)';
_fc_props.settings.command_mobile_ticket_space = '(plads)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Tidslængde';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (kolon)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket Time Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Brug grænsefladenummer';
_fc_props.mechanic = 'Mekaniker';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Afstemningsinterval (sekunder)';
_fc_props.driving_time = 'Køretid';
_fc_props.find_near_plants = 'Find nær planter';
_fc_props.please_create_hot_spot_first = 'Opret venligst hotspot først';
_fc_props.please_enter_hot_spot_description = 'Indtast beskrivelse af hotspot';
_fc_props.please_select_a_hot_spot_type = 'Vælg venligst en hotspot-type';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Send driver-e-mail';
_fc_props.directions_not_found = 'Ugyldig {0}-adresse. Rutevejledning ikke fundet.';
_fc_props.ws_user = 'Bruger';
_fc_props.ws_service = 'Service';
_fc_props.ws_method = 'Metode';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Oprindelse og destination';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Hjælp';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Brug Alt medarbejdernummer';
_fc_props.settings.command_mobile_ticket_android_url = 'Android-mobilbillet-URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android Mobile Ticket Secret';
_fc_props.employee_x_has_no_logout_on_y = 'Medarbejder {0} har ikke logget ud for et skift, der starter den {1}.';
_fc_props.phone_number_has_duplicates = 'Telefonnumre har dobbelte indtastninger';
_fc_props.ounce = 'Unse';
_fc_props.water_extra = 'Vand Ekstra';
_fc_props.always = 'Altid';
_fc_props.work_types_must_be_unique = 'Arbejdstyper skal være unikke';
_fc_props.manufacturer = 'Fabrikant';
_fc_props.need_to_enter_seconds = 'Skal indtaste sekunder.';
_fc_props.copy = 'Kopi';
_fc_props.create_in_existing_required = 'Opret i eksisterende skal vælges for at oprette roaming-hotspot.';
_fc_props.break = 'Pause';
_fc_props.add_export_data_type = 'Tilføj eksportdatatype';
_fc_props.edit_export_data_type = 'Rediger eksportdatatype';
_fc_props.delete_export_data_type = 'Slet eksportdatatype';
_fc_props.export_data_type = 'Eksporter datatype';
_fc_props.error_updating_export_data_type = 'Fejl under opdatering af eksportdatatype';
_fc_props.settings.address_key = 'Adresse nøgle';
_fc_props.settings.address_key_hover = 'Indstillinger for adressenøgle';
_fc_props.settings.address_key_options = 'Indstillinger for adressenøgle';
_fc_props.more_filter_options = 'Flere filtermuligheder';
_fc_props.unauthorized_mapit_app = 'Denne version af MapIt er ikke godkendt. Opdater venligst MapIt så hurtigt som muligt.';
_fc_props.zello_timeout = 'Timeout opstod ved kontakt til Zello.';
_fc_props.user_agreement_text = 'Ved at logge ind accepterer du';
_fc_props.user_agreement = 'Brugeraftale';
_fc_props.upload = 'Upload';
_fc_props.x_mins_left = '{0} min(er) tilbage';
_fc_props.continuous_location_tracking_colon = 'Kontinuerlig placeringssporing:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler kan ikke registrere ny enhed.';
_fc_props.timecard_does_not_contain_shift = 'Employee Time Card indeholder ikke den angivne vagt (Time Card Info).';
_fc_props.timecard_not_found = 'Medarbejdertidskort blev ikke fundet';
_fc_props.vehicle_must_be_assigned_for_clt = 'Køretøjet skal tildeles sporsteder.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Køretøjet blev ikke tildelt kontinuerlig sporing vil blive deaktiveret.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Mistet forbindelsen til EDC. Tjek venligst, at din WiFi og/eller Bluetooth er aktiveret.';
_fc_props.acknowledge_all = 'Anerkend alle';
_fc_props.expand = 'Udvide';
_fc_props.collapse = 'Bryder sammen';
_fc_props.expand_all = 'Udvid alle';
_fc_props.collapse_all = 'Skjul alle';
_fc_props.last_update = 'Sidste ændring';
_fc_props.acknowledge_alert = 'Bekræft alarm';
_fc_props.acknowledge_message = 'Bekræft besked';
_fc_props.acknowledge_all_alerts = 'Bekræft alle advarsler';
_fc_props.acknowledge_all_messages = 'Bekræft alle meddelelser';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Tolerance skal være et gyldigt heltal mellem -99 og 99 (begge inkluderet)';
_fc_props.gps_permission_required = 'GPS-tilladelse påkrævet';
_fc_props.permissions_required = 'Tilladelser påkrævet';
_fc_props.change_user = 'Skift bruger';
_fc_props.account_not_registered_with_carrier = 'Denne konto er ikke registreret hos et mobilselskab';
_fc_props.slump_loss = 'Nedturstab';
_fc_props.slump_loss_help = 'Slump Loss Hjælp';
_fc_props.did_not_receive_any_edc_devices = 'Modtog ingen EDC-enheder.';
_fc_props.edc_record_not_found = 'EDC-registrering blev ikke fundet';
_fc_props.disable_hotspot_prompt = 'Tryk på "OK", når du bliver bedt om at deaktivere dit hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} er i brug.';
_fc_props.an_unknown_exception_has_occurred = 'Der er opstået en ukendt undtagelse';
_fc_props.login_has_timed_out = 'Login har timeout';
_fc_props.use_paper_logs = 'Brug papirlogfiler';
_fc_props.eld_login_failed = 'Timer af service-login mislykkedes';
_fc_props.setup_time_off_type = 'Indstil venligst en afspadseringstype.';
_fc_props.view_load_properties = 'Se belastningsegenskaber';
_fc_props.couldnt_download_edc_firmware = 'Kunne ikke downloade EDC-firmware.';
_fc_props.password_incorrect = 'Adgangskoden er forkert';
_fc_props.edc_configuration_password_prompt = 'På grund af denne enheds tilstand skal du angive installationsadgangskoden for at konfigurere.';
_fc_props.edc_configuration_check_failed = 'Validering af EDC-serverposter mislykkedes. Se venligst fejlmeddelelser for detaljer.';
_fc_props.configuration_issues_prompt = 'Følgende problemer blev opdaget under konfiguration af den valgte EDC for den specificerede lastbil';
_fc_props.checking_password = 'Tjek adgangskode';
_fc_props.reset_password_instructions_sent = 'Instruktioner til nulstilling af din adgangskode sendt til e-mailadresse {0}.';
_fc_props.reset_password_failed = 'Nulstilling af adgangskode mislykkedes. Prøv igen.';
_fc_props.forgot_password = 'Glemt kodeord?';
_fc_props.enter_valid_email = 'Ugyldig e-mailadresse. Indtast venligst en gyldig e-mailadresse og prøv igen.';
_fc_props.issues_while_updating_edc_record = 'EDC\'en blev konfigureret med succes. Der er dog problemer med registreringerne for EDC\'en på TrackIt-serveren, som kan forårsage, at den ikke fungerer. Ring venligst til support for at rette oplysningerne.';
_fc_props.sending = 'Sender...';
_fc_props.minimum_ratio = 'Minimumsforhold';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Tromle RPM ikke inden for rækkevidde';
_fc_props.probe_failure = 'Sondefejl';
_fc_props.unknown_probe_data_failure = 'Ukendt sondedatafejl';
_fc_props.no_loaded_status_meaning = 'Ingen indlæst status';
_fc_props.no_depart_status_meaning = 'Ingen afgangsstatus';
_fc_props.no_arrive_status_meaning = 'Ingen ankomststatus';
_fc_props.no_begin_work_status_meaning = 'Ingen start arbejde status';
_fc_props.no_fully_mixed_status_meaning = 'Ingen fuldstændig blandet status';
_fc_props.no_begin_work_or_at_job_status = 'Statusser påbegyndt arbejde eller ved job er ikke til stede';
_fc_props.driver_didnt_follow_procedure = 'Chaufføren fulgte ikke proceduren (sænker ikke tromlen)';
_fc_props.probe_malfunction = 'Probefejl (intern fejl osv.)';
_fc_props.no_data_from_probe = 'Ingen data fra sonden (datatab)';
_fc_props.missing_probe_calibration = 'Ingen probekalibreringsdata';
_fc_props.probe_pressure_not_in_range = 'Probetryk ikke inden for rækkevidde';
_fc_props.arrival = 'Ankomst';
_fc_props.small_load = 'LILLE LÆSNING';
_fc_props.dry_slump = 'TØR SLUMP';
_fc_props.probe_remixing_turns = 'Remixing';
_fc_props.turns_needed_to_remix = 'Vand tilsat - Yderligere blanding nødvendig';
_fc_props.probe_remixing_finished = 'Remixing færdig';
_fc_props.probe_battery_low_alert = 'Advarsel om lavt probebatteri';
_fc_props.probe_battery_low_message = 'Probe batteri lavt';
_fc_props.probe_battery_low = 'Probe batteri lavt';
_fc_props.alert_when_probe_battery_low = 'Advarsel, når sondens batteri er lavt';
_fc_props.probe_sensor_fatal_failure = 'Føler fatal fejl';
_fc_props.probe_thermistor_failure = 'Termistorfejl';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor sidder fast i beton';
_fc_props.probe_sensor_over_weight = 'Sensor over vægt';
_fc_props.probe_batteries_low = 'Lavt batteri';
_fc_props.no_probe_data = 'Ingen sondedata:';
_fc_props.check_probe = 'Tjek sonde';
_fc_props.service_probe = 'Servicesonde';
_fc_props.below_x = 'Under {0}';
_fc_props.above_x = 'Over {0}';
_fc_props.probe_serial_number_invalid_format = 'Probens serienummer er ikke i gyldigt format som vist i pladsholderen for feltet';
_fc_props.talk_dnd_permission_prompt = 'Talk kræver FORORD IKKE-tilladelse.';
_fc_props.max = 'Maks';
_fc_props.status_changed_quickly = 'Status ændret for hurtigt';
_fc_props.is_empty = 'Er tom';
_fc_props.slump_increased_plasticizer_question = 'Blev der tilsat blødgører?';
_fc_props.slump_increased_water_added_question = 'Hvor meget umålt vand blev tilsat?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Probe- og leveringscyklusovervågning understøttes ikke sammen på den samme enhed.';
_fc_props.no_data_from_sensor = 'Ingen data fra sensor (datatab)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'Systemtype';
_fc_props.magnet_count = 'Magnettælling';
_fc_props.sensors_setup = 'Opsætning af sensorer';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM kræver, at I/O-boks MAC-adresse, tromletype, DCM-type og magnetantal er indstillet.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Billetbind';
_fc_props.drs_current_volume = 'Aktuel lydstyrke';
_fc_props.drs_current_water_cement_ratio = 'Nuværende vand/cement-forhold';
_fc_props.drs_target_water_cement_ratio = 'Mål vand/cement-forhold';
_fc_props.drs_total_revolutions = 'i alt';
_fc_props.drs_current_volume_lp_small = 'Nuværende';
_fc_props.drum_revolutions = 'Trommerevolutioner';
_fc_props.drs_total_revolutions_lp_small = 'i alt';
_fc_props.drs_charging_revolutions_lp_small = 'Opladning';
_fc_props.drs_discharging_revolutions_lp_small = 'Afladning';
_fc_props.drs_target_wc_lp_small = 'Mål W/C';
_fc_props.drs_current_wc_lp_small = 'Nuværende W/C';
_fc_props.drs_ticketed_volume_lp_small = 'Billetter';
_fc_props.android_location_disclosure = 'TrackIt bruger fin og grov placering til at indsamle placeringsdata i baggrunden, selv når den ikke er i brug, for at aktivere automatisk status og kortsynlighed i realtid til afsendelse.';
_fc_props.android_location_usage = 'Oplysning om placering';
_fc_props.end_pour_trigger_threshold = 'Slut hældningstærskel';
_fc_props.drs_wiring_error = 'Ledningsfejl';
_fc_props.drs_wiring_pulse_error = 'Ledningspulsfejl';
_fc_props.drs_wiring_magnet_error = 'Ledningsmagnetfejl';
_fc_props.drs_magnet_missing_error = 'Magnet mangler fejl';
_fc_props.android_location_disclosure_3p = 'TrackIt indsamler lokationsdata i baggrunden for at aktivere automatisk status og kortsynlighed i realtid til afsendelse. Når den er klokket ud fra afsender, deles placeringen ikke.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Vælg venligst enten Fuld MDM eller Trackit Remote Support';
_fc_props.move_up = 'Flyt op';
_fc_props.move_down = 'Flyt ned';
_fc_props.driver_not_found = 'Driver ikke fundet.';
_fc_props.multiple = 'Mange';
_fc_props.water_meter_installed = 'Vandmåler installeret';
_fc_props.delete_waypoint = 'Slet waypoint';
_fc_props.wash_sensor_installed = 'Vaskesensor installeret';
_fc_props.washing_start_time = 'Vaskestarttid';
_fc_props.end_washing = 'Afslut vask';
_fc_props.variable_rpms = 'Var RPM\'er';
_fc_props.no_slump = 'Ingen nedtur';
_fc_props.end_washing_duration = 'Slut vasketid';
_fc_props.privacy_policy = 'Fortrolighedspolitik';
_fc_props.privacy_policy_text = 'Jeg har læst og accepterer';
_fc_props.login_fail_accept_privacy_policy = 'Fortrolighedspolitik skal accepteres for at fortsætte.';
_fc_props.rotation_sensor = 'Rotationssensor';
_fc_props.pto_sensor = 'PTO sensor';
_fc_props.pto_sensor_installed = 'PTO-sensor installeret';
_fc_props.polarity_reversed = 'Omvendt polaritet';
_fc_props.pto_sensor_type = 'PTO sensor type';
_fc_props.sensor_type = 'Sensortype';
_fc_props.prompt_select_pto_sensor_type = 'Du skal vælge en "PTO-sensortype", hvis du har valgt en "PTO-sensor" som din hovedsensortype.';
_fc_props.priming_turns_at_full_load = 'Spædevendinger ved fuld belastning';
_fc_props.priming_turns_at_ten_percent_load = 'Spædevendinger ved 10 % belastning';
_fc_props.weight_units = 'Vægt enheder';
_fc_props.settings.send_status = 'Send status/placering til begivenhedsbroen';
_fc_props.volume_difference = 'Volumen forskel';
_fc_props.mobile_ticket_iframe_url = 'Mobile Ticket IFrame URL';
_fc_props.mobile_ticket_user = 'Mobilbilletbruger';
_fc_props.mobile_ticket_password = 'Mobilbilletadgangskode';
_fc_props.current_truck_status = 'Nuværende lastbilstatus';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Udløst af max omdrejninger';
_fc_props.alerts_by_type = 'Advarsler efter type';
_fc_props.water_flow_meter = 'Vandflowmåler';
_fc_props.flow_meter_no_pulses_warning = 'Ingen pulser - Advarsel';
_fc_props.flow_meter_no_pulses_critical = 'Ingen pulser - Kritisk';
_fc_props.flow_meter_excessive_pulses = 'Overdreven pulsering';
_fc_props.flow_meter_constantly_flowing = 'Konstant flydende';
_fc_props.flow_meter_good = 'godt';
_fc_props.whats_new = 'Hvad er nyt';
_fc_props.whats_new_widget_text = 'Vidste du, at vi opdaterer TrackIt mindst en gang om måneden? Få de seneste opdateringer og tips.';
_fc_props.get_the_scoop = 'Få Scoop';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Forretningsanalyse, der forbedrer din bundlinje, er nu inkluderet i TrackIt';
_fc_props.find_out_more = 'Find ud af mere';
_fc_props.daily_breakdown = 'Daglig opdeling';
_fc_props.question_summary = 'Opsummering af spørgsmål';
_fc_props.trip_summary = 'Rejseoversigt';
_fc_props.manage_employees = 'Administrer medarbejdere';
_fc_props.manage_hotspots = 'Administrer Hotspots';
_fc_props.manage_devices = 'Administrer enheder';
_fc_props.manage_equipment = 'Administrer udstyr';
_fc_props.manage_users = 'Administrer brugere';
_fc_props.manage_statuses = 'Administrer statusser';
_fc_props.manage_alerts = 'Administrer advarsler';
_fc_props.popular_report_links = 'Populære rapportlinks';
_fc_props.popular_settings_links = 'Populære indstillinger Links';
_fc_props.sidemenu_settings = 'Indstillinger';
_fc_props.loads_per_driver = 'Belastninger pr. driver';
_fc_props.please_select_application = 'Vælg venligst mindst én applikation';
_fc_props.external_app_processing = 'Behandling på ekstern app';
_fc_props.external_app_sent = 'Sendt til ekstern app';
_fc_props.external_app_received = 'Modtaget af ekstern app';
_fc_props.wait_time_at_job = 'Ventetid på job';
_fc_props.wait_time_at_plant = 'Ventetid på fabrikken';
_fc_props.loading_time = 'Indlæsningstid';
_fc_props.unloading_time = 'Aflæsningstid';
_fc_props.pre_post_trip = 'Før/efter tur';
_fc_props.update_thresholds = 'Opdater tærskler';
_fc_props.green = 'Grøn';
_fc_props.yellow = 'Gul';
_fc_props.red = 'Rød';
_fc_props.between = 'Mellem';
_fc_props.num_of_loads = '# belastninger';
_fc_props.num_of_drivers = '# af drivere';
_fc_props.status_meaning_not_setup = 'Status Betydning ikke opsat';
_fc_props.or_less = 'Eller mindre';
_fc_props.or_greater = 'Eller større';
_fc_props.probe_self_diagnostic_state = 'Probe selvdiagnosetilstand';
_fc_props.update_current_truck_status_settings = 'Opdater aktuelle Truck Status Settings';
_fc_props.default_behavior = 'Standard (Top 10 statusser)';
_fc_props.combine_similar_statuses = 'Kombiner de samme statusser fra alle typer (løkker)';
_fc_props.use_other_category = 'Brug kategorien \'Andet\'';
_fc_props.selected_statuses = 'Udvalgte statusser';
_fc_props.ideas = 'Ideer';
_fc_props.air_content = 'Luftindhold';
_fc_props.air_measured_time = 'Luft målt tid';
_fc_props.ten_max = '10 Maks';
_fc_props.loads_per_plant = 'Belastninger pr. anlæg';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Ugyldig flowmålerpuls pr. volumen.';
_fc_props.coming_soon_title = 'Flere widgets kommer snart';
_fc_props.submit_your_ideas = 'Send dine ideer til vores Idéportal ved hjælp af pæren i din venstre navigationsmenu.';
_fc_props.selected_plants = 'Udvalgte planter';
_fc_props.update_loads_per_plant_settings = 'Opdater belastninger pr. anlægsindstillinger';
_fc_props.default_top_10_plants = 'Standard (Top 10 planter)';
_fc_props.phone_number_will_be_uploaded = 'Enheds-id vil blive uploadet til registreringsformål.';
_fc_props.include_deleted = 'Medtag slettet';
_fc_props.primary_status_type = 'Primær statustype';
_fc_props.additional_status_trigger = 'Yderligere statusudløsere';
_fc_props.update_tickets_destination = 'Opdater destination på alle tilknyttede billetter';
_fc_props.settings.send_destination_changes_to_connex = 'Send destinationsændringer til Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Er billetten aktiv, når den modtages af TrackIt (standard \'altid\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Automatisk aktiver næste billet ved forrige billetafslutning';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Bør automatisk aktivere næste billet ved færdiggørelse af billet (standard \'sand\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status til inaktiv status, når ingen aktiv billet';
_fc_props.break_down = 'Sammenbrud';
_fc_props.idle = 'Ledig';
_fc_props.pto_work_maximum_speed_limit = 'Arbejd maksimal hastighedsgrænse';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Tilknyt statusændring, der fuldender en billet med den billet';
_fc_props.approaching = 'Nærmer sig';
_fc_props.performing_task = 'Udførelse af opgave';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Er du sikker på, at du vil slette Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Opdater billet {0}. Destination ændret.';
_fc_props.trackit_return_destination_changed_to_0 = 'Opdater billet {0}. Retur ændret.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Tilføj Geogate';
_fc_props.driver_enabled = 'Driver aktiveret';
_fc_props.dispatcher_enabled = 'Dispatcher aktiveret';
_fc_props.runsheet_number = 'Runsheet nummer';
_fc_props.manual_load = 'Manuel belastning';
_fc_props.edit_geogate = 'Rediger Geogate';
_fc_props.handling = 'Håndtering';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate panel';
_fc_props.vehicle_is_not_logged_in = 'Beskeden er ikke sendt (køretøjet er ikke logget ind)';
_fc_props.air = 'Luft';
_fc_props.create_a_geogate = 'Opret en Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Klik på kortet for at indstille geogate-punkterne.';
_fc_props.clock_out = 'Uret ude';
_fc_props.handling_operation = 'Håndtering Drift';
_fc_props.view_geogates = 'Se Geogates';
_fc_props.select_a_geogate = 'Vælg venligst en Geogate';
_fc_props.failed_to_find_geogate = 'Det lykkedes ikke at finde Geogate';
_fc_props.adjust_geogate = 'Juster Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Udløbsdato Tid Standard Ekstra dage';
_fc_props.menu_settings_title_retention = 'Dataopbevaring';
_fc_props.category = 'Kategori';
_fc_props.telemetry = 'Telemetri';
_fc_props.ticket_data = 'Billetdata';
_fc_props._30_days = '30 dage';
_fc_props._6_months = '6 måneder';
_fc_props._1_year = '1 år';
_fc_props._3_years = '3 år';
_fc_props.new_group = 'Ny gruppe';
_fc_props.selected_vehicles = 'Udvalgte køretøjer';
_fc_props.group_name_is_required_field = 'Gruppenavn er obligatorisk felt';
_fc_props.at_least_vehicle_should_be_selected = 'Der skal vælges mindst 1 køretøj';
_fc_props.groups = 'Grupper';
_fc_props.trucks = 'Lastbiler';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate er blevet fjernet. Opdater venligst siden.';
_fc_props.air_timestamp = 'Luft tidsstempel';
_fc_props.fail = 'SVIGTE';
_fc_props.stuck = 'SIDDE FAST';
_fc_props.dirty = 'SNAVSET';
_fc_props.seasonal = 'Sæson';
_fc_props.category_details = 'Kategori detaljer';
_fc_props.alert_events = 'Alarmhændelser';
_fc_props.driver_performance_infractions = 'Overtrædelser af driverens ydeevne';
_fc_props.telemetry_details = 'Placeringsdata, hotspots, tid i hotspots, tid i regioner, DCM-data, probedata, motordata';
_fc_props.ticket_data_details = 'Billetdata';
_fc_props.general_details = 'Statusændringer, Tidskort, Tidsplaner';
_fc_props.wash = 'Vask';
_fc_props.filter_options = 'Filterindstillinger';
_fc_props.home_plants = 'Hjemmeplanter';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO kræver, at PTO Sensor Type indstilles.';
_fc_props.invalid_io_box_mac_address = 'Ugyldig I/O Box Mac-adresse';
_fc_props.access_denied = 'Adgang nægtet';
_fc_props.wash_events = 'Vask begivenheder';
_fc_props.min_speed = 'Min hastighed';
_fc_props.temperature_change = 'Temperaturændring';
_fc_props.degrees_cap = 'grader';
_fc_props.washout_tracking = 'Sporing af udvaskning';
_fc_props.approaching_wash = 'Nærmer sig vask';
_fc_props.arrival_wash = 'Ankomst Vask';
_fc_props.performing_wash = 'Udfører vask';
_fc_props.wash_complete = 'Vask færdig';
_fc_props.must_be_positive_or_zero = 'Skal være positiv eller nul!';
_fc_props.min_speed_greater_than_max = 'Min. hastighed skal være mindre end maks. hastighed!';
_fc_props.clock_in = 'Stemple ind';
_fc_props.mix_water_at_x_to_y_for_t = 'Bland vand ved {0} til {1} omdr./min. i {2} omdrejninger.';
_fc_props.discharge_water_for_x_revolutions = 'Tøm vand i {0} omdrejninger.';
_fc_props.eod_drum_wash_complete = 'Trommevask færdig! <br> Stop tromme med sonde øverst.';
_fc_props.washing_complete = 'Guidet udvaskning gennemført';
_fc_props.eod_washout_no_data_available = 'Sensordata er ikke tilgængelige. Gennemfør venligst vaskeprocessen.';
_fc_props.eod_washout_no_data_available_title = 'Guidet udvaskning ikke tilgængelig';
_fc_props.menu_reports_title_ticket_time_summary = 'Billettidsoversigt';
_fc_props.menu_reports_description_ticket_time_summary = 'Se billettidsoplysninger for en ordre eller et køretøj';
_fc_props.menu_reports_title_summary = 'Resumé';
_fc_props.menu_reports_title_ticket_summary = 'Billetoversigt';
_fc_props.menu_reports_description_summary1 = 'Se en oversigt over tid i hotspots';
_fc_props.menu_dispatch_title_main = 'Hoved';
_fc_props.menu_dispatch_title_login_registry = 'Login registreringsdatabasen';
_fc_props.menu_reports_title_main = 'Hoved';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Er du sikker på, at du vil slette denne eksportdatatype?';
_fc_props.dvir_status_paper_logs = 'Papirlogs';
_fc_props.menu_reports_title_billing_report = 'Faktureringsrapport';
_fc_props.menu_reports_description_billing_report = 'Faktureringsrapport';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status til billetstatus ved aktivering af arbejde';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status til billetstatus ved aktivering af arbejde (standard \'False\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Pund';
_fc_props.units_weight_kilogram_label = 'Kilogram';
_fc_props.settings_weight = 'Vægt';
_fc_props.units_pounds = 'Pund';
_fc_props.units_kilograms = 'Kilogram';
_fc_props.geo_gate_edit_modal_description = 'Beskrivelse';
_fc_props.geo_gate_edit_modal_start_point = 'Startpunkt';
_fc_props.geo_gate_edit_modal_end_point = 'Slutpunkt';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Udstyrstype';
_fc_props.geo_gate_edit_modal_heading_south = 'Syd til Nord';
_fc_props.geo_gate_edit_modal_heading_north = 'Nord til Syd';
_fc_props.geo_gate_edit_modal_driver_message = 'Driver besked';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Send besked';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Udløbsdato Tid';
_fc_props.geo_gate_edit_modal_permanent = 'Permanent';
_fc_props.geo_gate_edit_modal_critical = 'Kritisk';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Fejl ved lagring af Geogate: Beskrivelsesfeltet skal udfyldes.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Fejl ved lagring af Geogate: Startpunkt-feltet skal udfyldes.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Fejl ved lagring af Geogate: Startpunkt-feltet indeholder ugyldigt dataformat.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Fejl ved lagring af Geogate: End Point-feltet indeholder ugyldigt dataformat.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Fejl ved lagring af Geogate: Udløbsdato Tidsfelt skal udfyldes.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Fejl ved lagring af Geogate: Udløbsdato Tidsfelt indeholder ugyldigt dataformat.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Fejl ved lagring af Geogate: Nord til Syd - Forsendelsesmeddelelsesfeltet skal udfyldes';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Fejl ved lagring af Geogate: Ingen ændringer registreret';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Fejl ved lagring af Geogate: Udløbsdato Tidsfelt indeholder udløbet dato-klokkeslæt';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Fejl ved lagring af Geogate: Beskrivelsesfeltet må kun indeholde bogstaver og tal';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Fejl ved lagring af Geogate: Feltet Udstyrstype skal udfyldes';
_fc_props.geo_gate_table_message_label_north_to_south = 'Nord til Syd';
_fc_props.geo_gate_table_message_label_south_to_north = 'Syd til Nord';
_fc_props.geo_gate_table_message_label_both = 'Begge';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Breddegrad, Længdegrad';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Hvis du afkrydser dette, tilsidesættes opsætningen for begrænsning af enhedsbevægelsesmeddelelser';
_fc_props.geo_gate_edit_modal_seasonal = 'Sæson';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Fejl ved lagring af Geogate: Sæsonbestemte datoer skal udfyldes';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Fejl ved lagring af Geogate: Sæsonbestemt dato-felter indeholder ugyldigt dataformat';
_fc_props.test_js_test_javascript = 'Test Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometer';
_fc_props.units_miles = 'Miles';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Fejl ved lagring af Geogate: Syd til nord - Førermeddelelsesfeltet skal udfyldes';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Fejl ved lagring af Geogate: Syd til Nord - Afsendelsesmeddelelsesfeltet skal udfyldes';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Fejl ved lagring af Geogate: Nord til Syd - Førermeddelelsesfeltet skal udfyldes';
_fc_props.geo_gate_heading = 'Overskrift';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Fejl ved lagring af Geogate: End Point-feltet skal udfyldes';
_fc_props.units_mile = 'Mil';
_fc_props.units_kilometer = 'Kilometer';
_fc_props.cloud_dvir__exit = 'Afslut';
_fc_props.cloud_dvir__signature = 'Underskrift';
_fc_props.cloud_dvir__inspection = 'Inspektion';
_fc_props.cloud_dvir__add_photos_notes = 'Tilføj billeder/noter';
_fc_props.cloud_dvir__odometer_reading = 'Kilometertælleraflæsning';
_fc_props.cloud_dvir__engine_hours = 'Motortimer';
_fc_props.cloud_dvir__truck_details = 'Lastbil detaljer';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Bestem køretøjets generelle tilstand';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Chaufførens fulde navn og underskrift';
_fc_props.cloud_dvir__sign = 'Skilt';
_fc_props.cloud_dvir__driver_note_from = 'Drivernotat fra';
_fc_props.cloud_dvir__add_notes = 'Tilføj noter';
_fc_props.cloud_dvir__driver_notes = 'Driver noter';
_fc_props.cloud_dvir__done = 'Færdig';
_fc_props.cloud_dvir__needs_repair = 'Trænger til reparation';
_fc_props.cloud_dvir__not_applicable = 'Ikke anvendelig';
_fc_props.cloud_dvir__review_and_sign = 'Gennemgå og underskriv';
_fc_props.cloud_dvir__add_photos = 'Tilføj billede';
_fc_props.cloud_dvir__upload_photo_limit = 'Du kan uploade op til 3 billeder';
_fc_props.cloud_dvir__mismatch_title = 'Mismatch Alert';
_fc_props.cloud_dvir__ok = 'Okay';
_fc_props.cloud_dvir_template = 'Cloud DVIR skabelon';
_fc_props.cloud_dvir__needs_review = 'DVIR skal gennemgås';
_fc_props.cloud_dvir__submitted = 'Indsendt';
_fc_props.cloud_dvir__completed_by = 'Færdiggjort af';
_fc_props.cloud_dvir__review_btn = 'Anmeldelse';
_fc_props.cloud_dvir__truck_number = 'Lastbil {0}';
_fc_props.cloud_dvir__pending_review = 'Afventer gennemgang';
_fc_props.cloud_dvir__start_dvir = 'Start DVIR';
_fc_props.cloud_dvir__dvir_history = 'DVIR historie';
_fc_props.cloud_dvir__view_all = 'Se alt';
_fc_props.load_zone = 'Indlæsningszone';
_fc_props.view_load_zones = 'Se belastningszoner';
_fc_props.edit_load_zone = 'Rediger Load Zone';
_fc_props.create_a_load_zone = 'Opret en belastningszone';
_fc_props.load_zone_panel = 'Indlæs zonepanel';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Klik på kortet for at indstille belastningszonens centrum.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Denne inspektion har defekter angivet, og du har valgt, at dette køretøj er sikkert at betjene. Bekræft venligst, før du fortsætter';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Denne inspektion har ingen defekter angivet, og du har valgt, at dette køretøj er usikkert at betjene. Bekræft venligst, før du fortsætter.';
_fc_props._90_days = '90 dage';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR-indsendelser';
_fc_props.show_load_zones = 'vis belastningszoner';
_fc_props.hide_load_zones = 'skjule belastningszoner';
_fc_props.no = 'Nej';
_fc_props.cloud_dvir__reset = 'Nulstil';
_fc_props.cloud_dvir__clear = 'Klar';
_fc_props.mark_read = 'Marker læst';
_fc_props.mark_unread = 'Marker som ulæst';
_fc_props.read = 'Læs';
_fc_props.unread = 'Ulæst';
_fc_props.air_sensor_serial_number = 'Luftsensor serienummer';
_fc_props.air_sensor_mac_address = 'Luftsensor MAC-adresse';
_fc_props.air_sensor_serial_number_invalid_format = 'Luftsensorens serienummer er ikke i gyldigt format: (alfanumerisk og højst 32 tegn langt med bindestreger)';
_fc_props.air_sensor_mac_address_invalid_format = 'Air Sensor MAC-adresse er ikke i gyldigt hex-format som vist i pladsholderen for feltet';
_fc_props.air_sensor_license = 'Luftsensor';
_fc_props.adjust_route_point = 'Juster rutepunkt';
_fc_props.route_point_panel = 'Rutepunktpanel';
_fc_props.route_point = 'Rutepunkt';
_fc_props.cloud_dvir__pre_trip = 'Før tur';
_fc_props.cloud_dvir__post_trip = 'Post tur';
_fc_props.cloud_dvir__other_insction_type = 'Andet';
_fc_props.cloud_dvir__dvir_completed = 'Afsluttet';
_fc_props.cloud_dvir__awaiting_mechanic = 'Afventende mekaniker';
_fc_props.cloud_dvir__awaiting_driver = 'Afventer Driver Sign-off';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Kan ikke kontrollere luftsensorens serienummer. Kontakt support for yderligere hjælp.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Dette luftsensorserienummer findes allerede, brug venligst et andet serienummer';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Kan ikke kontrollere luftsensorens MAC-adresse, kontakt venligst support for at få hjælp';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Fejl ved indsendelse af DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Prøv igen';
_fc_props.ticket_integration_descr = 'Billetintegration gør det muligt at indstille indstillinger for billetintegration samt startskærm / indlæsningsskærm / hårdmonteringsskærm';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR blev indsendt';
_fc_props.cloud_dvir__inspection_trip_type = 'Inspektionsrejsetype';
_fc_props.menu_dvir_title_mechanic = 'Mekaniker';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Luftsensor firmware';
_fc_props.when_no_active_ticket = 'Når ingen aktiv billet';
_fc_props.used_by = 'Brugt af';
_fc_props.fields = 'Felter';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Er du sikker på, at du vil slette billetlayout "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Billetlayout';
_fc_props.add_ticket_layout = 'Tilføj billetlayout';
_fc_props.edit_ticket_layout = 'Rediger billetlayout';
_fc_props.ticket_layout_edit_modal_name = 'Navn';
_fc_props.ticket_layout_edit_modal_default = 'Standard';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Fejl ved lagring af billetlayout: Navnefeltet skal udfyldes.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Fejl ved lagring af billetlayout: Navnefelt må kun indeholde bogstaver og tal';
_fc_props.ticket_layout_edit_modal_fields = 'Felter';
_fc_props.ticket_layout_edit_modal_usage = 'Brug';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Billetlayout er blevet fjernet. Opdater venligst siden.';
_fc_props.more_lower_case = 'mere';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Fejl ved lagring af billetlayout: Et eller flere felter skal vælges.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Fejl ved lagring af billetlayout: En eller flere statusser skal vælges.';
_fc_props.cloud_dvir__ad_hoc = 'Ad Hoc';
_fc_props.radius_multiplier_should_be = 'Radiusmultiplikator skal være et flydende tal';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutter skal være et heltal.';
_fc_props.max_hotspot_unused_days_errs = 'Max Hotspot Unused Days skal være et heltal.';
_fc_props.begin_pour_air_content = 'Begynd Hæld luftindhold';
_fc_props.arrival_air_content = 'Ankomst Luftindhold';
_fc_props.fully_mixed_air_content = 'Fuldt blandet luftindhold';
_fc_props.departure_air_content = 'Afgang Luftindhold';
_fc_props.popup_enabled = 'Popup aktiveret';
_fc_props.popup_disabled = 'Popup deaktiveret';
_fc_props.cloud_dvir__leave_confirmation_title = 'Advarsel';
_fc_props.cloud_dvir__leave_confirmation_description = 'Hvis du forlader siden nu, vil alle ændringer gå tabt.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Bekræfte';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Afbestille';
_fc_props.air_bt_ratio = 'Luftsensormåling';
_fc_props.cloud_dvir__view_photos_notes = 'Se billeder/noter';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Tilføjelse af status vil oprette en tom brug i et andet billetlayout. Vil du fortsætte med tilføjelsen?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Køretøjet har ikke luftsensorlicens.';
_fc_props.air_sensor_installed = 'Luftsensor installeret';
_fc_props.load_card = 'Indlæs kort';
_fc_props.cloud_dvir__inspection_profile = 'Inspektionsprofil';
_fc_props.cloud_dvir__view_details = 'Se detaljer';
_fc_props.cloud_dvir__certify_repairs = 'Certificere reparationer';
_fc_props.cloud_dvir__repairs_made = 'Reparationer udført';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparationer ikke nødvendige';
_fc_props.cloud_dvir__mechanic_note_from = 'Mekanikernotat fra';
_fc_props.cloud_dvir__mechanic_assignment = 'Mekaniker opgave';
_fc_props.cloud_dvir__mechanic_signature_description = 'Marker alle relevante punkter nedenfor og skilt, der angiver, at køretøjet er blevet korrekt inspiceret.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Tilstanden af dette køretøj er tilfredsstillende';
_fc_props.cloud_dvir__defects_corrected_label = 'Mangler er rettet';
_fc_props.cloud_dvir__repair_not_required_label = 'Reparation er ikke nødvendig for sikker drift af køretøjet';
_fc_props.safety = 'Sikkerhed';
_fc_props.cloud_dvir__awaiting_sign = 'Afventer tegn';
_fc_props.cloud_dvir__dvir_review = 'DVIR anmeldelse';
_fc_props.settings.mobile_auto_start_navigation = 'Start automatisk navigation på mobil';
_fc_props.cloud_dvir__edit = 'Redigere';
_fc_props.delete_custom_label = 'Slet tilpasset etiket';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Din version af TrackIt er forældet, og nogle DVIR-funktioner fungerer muligvis ikke korrekt. Opdater venligst TrackIt, før du fortsætter.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Noget gik galt under upload af signatur. Prøv igen';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot Unused Days har et maksimum på 1000 dage.';
_fc_props.menu_dvir_title_admin_templates = 'Skabeloner';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Admin';
_fc_props.cloud_dvir__admin_templates = 'Skabeloner';
_fc_props.form_name = 'Formularnavn';
_fc_props.form_status = 'Formularstatus';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'Hent';
_fc_props.cloud_dvir__option_view = 'Udsigt';
_fc_props.cloud_dvir__inspectortype_driver = 'Chauffør';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Se/Rediger/Opret tilpassede DVIR-formularer og indsendelser';
_fc_props.menu.dvir.description.mechanic = 'Se/rediger handlingsrettede DVIR-indsendelser';
_fc_props.not_in_use = 'Ikke i brug';
_fc_props.logged_in = 'logget ind';
_fc_props.reviewing_driver = 'Gennemgang af driver';
_fc_props.cloud_dvir__report_title = 'Førerens bilsynsrapport';
_fc_props.cloud_dvir__report_signed = 'underskrevet';
_fc_props.cloud_dvir__report_page_counter = 'Side {0} af {1}';
_fc_props.cloud_dvir__report_defects = 'Defekter';
_fc_props.cloud_dvir__report_comments = 'Kommentarer';
_fc_props.cloud_dvir__report_inspection_table_title = 'Bilsyn';
_fc_props.cloud_dvir__report_driver_table_title = 'Driver oplysninger';
_fc_props.cloud_dvir__report_carrier = 'Transportør';
_fc_props.cloud_dvir__report_truck = 'Lastbil';
_fc_props.cloud_dvir__report_location = 'Beliggenhed';
_fc_props.cloud_dvir__report_odometer = 'Kilometertæller';
_fc_props.cloud_dvir__report_hours = 'Timer';
_fc_props.cloud_dvir__report_no_comments = 'Ingen kommentarer';
_fc_props.include_unlicensed_vehicles = 'Inkluder ikke-licenserede køretøjer';
_fc_props.cloud_dvir__search_by_form_name = 'Søg efter formularnavn';
_fc_props.cloud_dvir__create_dvir = 'Opret en DVIR';
_fc_props.cloud_dvir__update_dvir = 'Opdater DVIR';
_fc_props.cloud_dvir__form_name = 'Formularnavn';
_fc_props.cloud_dvir__form_description = 'Formularbeskrivelse';
_fc_props.cloud_dvir__form_status = 'Formularstatus';
_fc_props.information = 'Information';
_fc_props.cloud_dvir__search_categories = 'Søg kategorier';
_fc_props.cloud_dvir__create_new_category = 'Opret ny kategori';
_fc_props.category_name = 'Kategorinavn';
_fc_props.sub_category = 'Underkategori';
_fc_props.sub_category_name = 'Navn på underkategori';
_fc_props.delete_category = 'Slet kategori';
_fc_props.cloud_dvir__dvir_information = 'DVIR Information';
_fc_props.cloud_dvir__dvir_choose_categories = 'Vælg kategorier';
_fc_props.cloud_dvir__create_category = 'Opret kategori';
_fc_props.cloud_dvir__update_category = 'Opdater kategori';
_fc_props.delete_route_point = 'Slet rutepunkt';
_fc_props.view_route_points = 'Se rutepunkter';
_fc_props.ignore_cancel_preload = 'Ignorer annulleringen, du er forudindlæst. Dit hjemløb vil snart blive vist';
_fc_props.cloud_dvir__assigned_mechanic = 'Udnævnt mekaniker';
_fc_props.cloud_dvir__add_mechanic = 'Tilføj en mekaniker';
_fc_props.contact_type = 'Kontakttype';
_fc_props.cloud_dvir__send_email_notifications = 'Send e-mail notifikationer';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Fejl ved hentning af DVIR-historikoptegnelser.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Fejl ved hentning af DVIR-post.';
_fc_props.cloud_dvir__error_saving_submission = 'Fejl under lagring af indsendelse. Prøv igen.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Fejl ved hentning af liste over mekanikere.';
_fc_props.cloud_dvir__template_saved = 'Skabelonen er gemt';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Fejl ved hentning af liste over skabeloner.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Fejl ved lagring af skabelon. Prøv igen.';
_fc_props.dispatch_group = 'Forsendelsesgruppe';
_fc_props.save_adjust = 'Gem og juster rutepunkt';
_fc_props.reset_route_point = 'Nulstil rutepunkt';
_fc_props.no_records_stub_adjust_filters = 'Prøv at justere dine søge- eller filterindstillinger for at øge antallet af resultater.';
_fc_props.no_dvir_records = 'Alle fanget DVIR\'er!';
_fc_props.no_dvir_records_sub_title = 'Du har nået meget';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-nøgle';
_fc_props.slump_not_obtained_for_x = 'Ingen slumplæsning opnået for: {0}';
_fc_props.please_select_a_sensor_status_type = 'Vælg venligst sensorstatustyperne for at udløse denne advarsel';
_fc_props.settings.master_data_connex_token = 'Master Data Connex Token';
_fc_props.error_validating_master_data_connex_credentials = 'Fejl under validering af Master Data Connex-legitimationsoplysninger';
_fc_props.settings.master_data_connex_base_url = 'Master Data Connex Base URL';
_fc_props.settings.master_data_connex_exp_time = 'Master Data Connex Udløbstid';
_fc_props.download_template = 'Download skabelon';
_fc_props.menu_settings_title_dispatch_group = 'Forsendelsesgrupper';
_fc_props.settings.dispatch_groups = 'Forsendelsesgrupper';
_fc_props.dispatch_group_name = 'Forsendelsesgruppenavn';
_fc_props.dispatch_group_plants = 'Send gruppeanlæg';
_fc_props.dispatch_group_plants_mapping_message = 'Angiv hvilke anlæg der vil være en del af denne forsendelsesgruppe.';
_fc_props.dispatch_group_users_mapping_message = 'Angiv, hvilke brugere der vil være en del af denne forsendelsesgruppe.';
_fc_props.dispatch_group_plant_disassociation_message = 'Anlægget bør være under én afsendelsesgruppe ad gangen';
_fc_props.dispatch_group_search_by_plant = 'Søg efter plantenavn';
_fc_props.dispatch_group_search_by_username = 'Søg efter brugernavn';
_fc_props.search_by_dispatch_group_name = 'Søg efter afsendelsesgruppenavn';
_fc_props.create_dispatch_group = 'Opret en afsendelsesgruppe';
_fc_props.update_dispatch_group = 'Opdater afsendelsesgruppe';
_fc_props.dispatch_group_saved = 'Afsendelsesgruppen er gemt';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Sletning af denne afsendelsesgruppe vil oprette standardafsendelsesgrupper for de tilknyttede anlæg.';
_fc_props.error_saving_dispatch_group = 'Fejl ved lagring af afsendelsesgruppe. Prøv igen.';
_fc_props.please_enter_a_valid_plant_num = 'Indtast venligst et gyldigt anlægsnummer.';
_fc_props.cloud_dvir__report_no_defects = 'Ingen defekter';
_fc_props.shoud_select_dispatch_group = 'Du skal vælge mindst én afsendelsesgruppe';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Kan ikke slettes, fordi denne afsendelsesgruppe indeholder brugere, der kun er knyttet til denne afsendelsesgruppe.';
_fc_props.cloud_dvir__username = 'Brugernavn';
_fc_props.cloud_dvir__password = 'Adgangskode';
_fc_props.cloud_dvir__login = 'Log på';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mekaniker Log ind';
_fc_props.delete_dispatch_group = 'Slet afsendelsesgruppe';
_fc_props.cloud_dvir__driver = 'Chauffør';
_fc_props.cloud_dvir__equipment_pound = 'Udstyr #';
_fc_props.cloud_dvir__mechanic = 'Mekaniker';
_fc_props.dispatch_group_user_only_unassociate_message = 'Kan ikke slette bruger, fordi brugere skal være tilknyttet mindst én afsendelsesgruppe.';
_fc_props.cloud_dvir__time_period_filter_today = 'I dag';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'I går';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Sidste 7 dage';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Sidste 30 dage';
_fc_props.cloud_dvir__date_range_filter = 'Filtrer efter dato';
_fc_props.cloud_dvir__download = 'Hent';
_fc_props.dispatch_group_is_empty = 'Forsendelsesgruppen må ikke være tom.';
_fc_props.dispatch_group_with_empty_plants = 'Mindst ét anlæg skal være tilknyttet afsendelsesgruppen.';
_fc_props.dispatch_group_with_empty_users = 'Mindst én bruger skal være tilknyttet afsendelsesgruppen.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Kan ikke slette afsendelsesgruppen, da den indeholder brugere, der kun er tilknyttet denne afsendelsesgruppe.';
_fc_props.dispatch_group_not_exists = 'Forsendelsesgruppe findes ikke.';
_fc_props.dispatch_group_contains_one_plant = 'Kan ikke slettes, fordi denne forsendelsesgruppe kun indeholder én plante.';
_fc_props.dispatch_group_users = 'Send gruppebrugere';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Fejl ved hentning af DVIR-post';
_fc_props.cloud_dvir__assign_mechanic = 'Tildel mekaniker';
_fc_props.cloud_dvir__submit_review = 'Send anmeldelse';
_fc_props.error_deleting_dispatch_group = 'Fejl ved sletning af afsendelsesgruppe.';
_fc_props.error_retrieving_dispatch_groups = 'Fejl ved hentning af liste over afsendelsesgrupper.';
_fc_props.cloud_dvir__undo_selection = 'Fortryd valg';
_fc_props.enable_master_data = 'Aktiver stamdata';
_fc_props.manually_sync = 'Synkroniser manuelt';
_fc_props.master_data_connex_token_expiration = 'Master Data Connex Token Udløb';
_fc_props.cloud_dvir__time_period_filter_custom = 'Brugerdefinerede';
_fc_props.settings.zello_session_timeout_minutes = 'Zello Session Timeout (minutter)';
_fc_props.menu_settings_title_master_data = 'Stamdata';
_fc_props.settings.master_data = 'Stamdata';
_fc_props.error_retrieving_master_data = 'Fejl ved hentning af stamdata';
_fc_props.master_data_sync_completed = 'Synkronisering af masterdata fuldført';
_fc_props.master_data_successfully_updated = 'Data blev opdateret';
_fc_props.master_data_failed_to_update = 'Kunne ikke gemme.';
_fc_props.master_data_sync_initiated = 'Synkronisering af masterdata er startet';
_fc_props.master_data_sync_failed = 'Synkronisering af masterdata mislykkedes';
_fc_props.enabled_excluding_origin_on_deliveries = 'Sand (ekskl. oprindelse på leveringsbilletter)';
_fc_props.enabled_including_origin_on_deliveries = 'Sandt (inklusive oprindelse på leveringsbilletter)';
_fc_props.cloud_dvir__i_will_decide_later = 'Jeg beslutter mig senere';
_fc_props.returning = 'Vender tilbage';
_fc_props.leftover_concrete = 'Rester af beton';
_fc_props.leftover_concrete_on_vehicle_x = 'Betonrester på køretøjet {0}';
_fc_props.leftover_concrete_alert = 'Resterende betonadvarsel';
_fc_props.wc_ratio_exceeded = 'W/C-forhold overskredet';
_fc_props.search_by_site_name = 'Søg efter webstedsnavn';
_fc_props.sites = 'Websteder';
_fc_props.token_expiration = 'Tokens udløb';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API Key Management';
_fc_props.settings.connex_api_key_management = 'Connex API Key Management';
_fc_props.error_retrieving_connex_api_key_data = 'Fejl ved hentning af connex api-nøgledata';
_fc_props.qr_code = 'QR kode';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informerer driver og grænseflade ved indtastning af forkert anlæg';
_fc_props.enable_driver_message = 'Aktiver drivermeddelelse';
_fc_props.enable_dispatch_message = 'Aktiver afsendelsesmeddelelse';
_fc_props.enable_sending_message_to_interface = 'Aktiver afsendelse af besked til Interface';
_fc_props.ticket_qr_code = 'Billet QR-kode';
_fc_props.leftover_concrete_alert_description = 'Advarsel, når der findes betonrester efter påfyldning.';
_fc_props.minimum_leftover_concrete_amount = 'Min. resterende betonmængde';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Fejl ved validering af Master Data Connex-legitimationsoplysninger ikke autoriseret';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Fejl ved validering af Master Data Connex-legitimationstilladelser';
_fc_props.error_validating_master_data_connex_url_not_found = 'Fejl ved validering af Master Data Connex URL ikke fundet';
_fc_props.error_validating_master_data_connex_unknown_error = 'Fejl ved validering af Master Data Connex Ukendt fejl';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Synkronisering af masterdata mislykkedes Synkronisering af køretøjstype.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Synkronisering af masterdata mislykkedes. Synkroniser køretøj.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Synkronisering af masterdata mislykkedes Synkronisering af anlæg.';
_fc_props.master_data_sync_failed_data_base_connection = 'Synkronisering af masterdata mislykkedes i forbindelse med databasen.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Fejl under validering af Master Data Connex Credentials Bærer Token-tilladelse er utilstrækkelig';
_fc_props.settings.back_date_arrive_job = 'Tilbage dato ankommer job';
_fc_props.device_notifications = 'Meddelelser';
_fc_props.loading_number = 'Indlæsningsnummer';
_fc_props.leftover_concrete_volume_temp_age = 'Resterende volumen={0}, Temp={1}, Alder={2}';
_fc_props.total_ordered = 'Samlet bestilt mængde';
_fc_props.total_ticketed = 'Leveres Bestilt';
_fc_props.load_number = 'Indlæsningsnummer';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Minimum restbeton skal være udfyldt og større end {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Fejl ved validering af stamdata. Legitimationsoplysninger kan ikke være de samme.';
_fc_props.use_aws_for_talk = 'Brug AWS til Talk';
_fc_props.air_readings_not_supported = 'Luftaflæsninger understøttes ikke';
_fc_props.air_not_supported = 'Luft ikke understøttet';
_fc_props.mix_not_supported = 'Mix ikke understøttet';
_fc_props.air_default_and_none_not_supported = 'Kan ikke vælge standard og understøttes ikke på samme tid!';
_fc_props.notifications_enabled = 'Notifikationer aktiveret';
_fc_props.prevent_status_change = 'Forhindre statusændring';
_fc_props.message_to_interface = 'Besked til Interface';
_fc_props.prevent_wrong_plant = 'Forebyg forkert plante';
_fc_props.previous_tickets = 'Tidligere billetter';
_fc_props.audit_ticket_number = 'Billetnummer';
_fc_props.audit_date_created = 'Dato oprettet';
_fc_props.audit_vehicle_number = 'Køretøjsnummer';
_fc_props.cloud_dvir__table_ok = 'Sikker at betjene';
_fc_props.cloud_dvir__table_needs_repair = 'Usikker at betjene';
_fc_props.missing_air_calibration = 'Ingen luftkalibreringsdata';
_fc_props.reported_defects = 'Indberettede mangler';
_fc_props.address_latitude = 'Adresse Latitude';
_fc_props.address_longitude = 'Adresse Længdegrad';
_fc_props.routing_latitude = 'Rutebredde';
_fc_props.routing_longitude = 'Rute Længdegrad';
_fc_props.category_name_must_be_unique = 'Kategorinavn skal være unikt';
_fc_props.subcategory_name_must_be_unique = 'Navnet på underkategorien skal være unikt';
_fc_props.menu_settings_tracking_device = 'Sporingsenhed';
_fc_props.menu_settings_title_tracking_device = 'Sporingsenhed';
_fc_props.menu_settings_title_personal_device_registration = 'Personlig enhedsregistrering';
_fc_props.registration_code = 'Registreringskode';
_fc_props.creation_date = 'Skabelse';
_fc_props.expiration_date = 'Udløb';
_fc_props.trackitadmin_personal_device_registration = 'Personlig enhedsregistrering';
_fc_props.create_registration_code = 'Tilføj registreringskode';
_fc_props.remove_registration_code = 'Fjern registreringskoden';
_fc_props.add_a_registration_code = 'Tilføj en registreringskode';
_fc_props.select_number_of_registration_codes_to_generate = 'Vælg det antal registreringskoder, du vil generere.';
_fc_props.registration_code_count = 'Registreringskodeantal';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Er du sikker på, at du vil slette den valgte registrerede kode?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Er du sikker på, at du vil slette de valgte registrerede koder?';
_fc_props.add_personal_device = 'Tilføj personlig enhed';
_fc_props.menu_settings_title_personal_device = 'Personlig enhed';
_fc_props.cloud_dvir__approval_critical_alert = 'Køretøjet har en kritisk defekt. Kan ikke markeres som sikker at betjene.';
_fc_props.export_registration_codes = 'Eksporter registreringskoder';
_fc_props.download_format = 'Download format';
_fc_props.specify_download_format_x = 'Angiv downloadformatet for at eksportere {0} registreringskoder';
_fc_props.personal_device_download = 'Hent';
_fc_props.add_personal_device_input_message = 'Indtast et personligt enheds-id nedenfor. Udløb vil automatisk blive anvendt efter lagring';
_fc_props.error_creating_personal_device = 'Fejl ved oprettelse af en personlig enhed';
_fc_props.device_already_exists = 'Den personlige enhed findes allerede';
_fc_props.export = 'Eksport';
_fc_props.error_can_not_change_connex_eid = 'Kan ikke ændre connex eid, da det i øjeblikket bruges til stamdata';
_fc_props.remove_personal_device = 'Fjern personlig enhed';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Er du sikker på, at du vil slette den valgte personlige enhed?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Er du sikker på, at du vil slette de valgte personlige enheder?';
_fc_props.error_deleting_personal_device = 'Fejl ved sletning af personlig enhed';
_fc_props.customerid = 'Kunde ID';
_fc_props.customername = 'Kundenavn';
_fc_props.ordernumber = 'Ordrenummer';
_fc_props.orderdescription = 'Ordrebeskrivelse';
_fc_props.locationid = 'Lokations-id';
_fc_props.locationdescription = 'Placeringsbeskrivelse';
_fc_props.productid = 'Produkt ID';
_fc_props.productdescription = 'Produkt beskrivelse';
_fc_props.vehicleid = 'Køretøjs-ID';
_fc_props.vehicledescription = 'Køretøjsbeskrivelse';
_fc_props.ticketnumber = 'Billet nr.';
_fc_props.ticketdate = 'Billetdato/tidspunkt';
_fc_props.drivername = 'Driver navn';
_fc_props.arriveregion = 'Ankomstområde (TIME)';
_fc_props.leaveregion = 'Forlad region (TIME)';
_fc_props.regionduration = 'Regionens varighed';
_fc_props.menu_reports_title_prevailing_wage = 'Gældende løn';
_fc_props.slump_calibration_x_not_found_for_y = 'Slumpkalibrering "{0}" blev ikke fundet for billetten {1}';
_fc_props.slump_calibration_not_found = 'Kalibrering af fald blev ikke fundet';
_fc_props.slump_calibration_not_found_description = 'Advarsel, når Slump-kalibrering ikke blev fundet';
_fc_props.error_getting_personal_device_list = 'Fejl ved hentning af personlig enhedsliste';
_fc_props.batch_summary_date_range_note = '*BEMÆRK: Denne rapport er begrænset til 3 dage, hvis der ikke anvendes filtre. Datointervallet kan gå op til 31 dage, når det filtreres efter udstyr (maksimalt 10), anlæg (maksimalt 1), produkt (maksimalt 1) eller job (maksimalt 1)';
_fc_props.select_registration_code_expiration_time_days = 'Vælg antallet af dage, indtil registreringskoderne udløber, og klik derefter på Gem.';
_fc_props.add_personal_device_expiration = 'Udløb (i dage)';
_fc_props.cloud_dvir__vehicle_no = 'Køretøj nr';
_fc_props.cloud_dvir__trailer_no = 'Trailer nr';
_fc_props.cloud_dvir__vehicle = 'Køretøj';
_fc_props.cloud_dvir__trailer = 'Anhænger';
_fc_props.cloud_dvir__truck_number_label = 'Lastbil nummer';
_fc_props.cloud_dvir__trailer_number = 'Trailer nummer';
_fc_props.cloud_dvir__has_trailer = 'Kører ikke med trailer';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Indtast dit trailernummer her';
_fc_props.work_order = 'Arbejdsordre';
_fc_props.unable_to_determine_slump = 'Kan ikke bestemme Slump';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Bestem den overordnede tilstand af traileren';
_fc_props.defaultTrailer = 'Standard trailer';
_fc_props.trailer_type_column_billable = 'Fakturerbar';
_fc_props.trailer_type_column_notes = 'Noter';
_fc_props.trailer_type_column_date = 'Dato';
_fc_props.trailer_type_column_order = 'Bestille';
_fc_props.trailer_type_column_ticket = 'Billet';
_fc_props.trailer_type_column_project = 'Projekt';
_fc_props.trailer_type_column_customer = 'Kunde';
_fc_props.trailer_type_column_location = 'Beliggenhed';
_fc_props.trailer_type_column_vehicle_type = 'Køretøjstype';
_fc_props.trailer_type_column_product = 'Produkt';
_fc_props.trailer_type_action_rebill = 'Rebill';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Synkronisering af masterdata mislykkedes, ingen standardudstyrstype er indstillet';
_fc_props.trailer_type = 'Trailer type';
_fc_props.trailer_type_is_required = 'Trailertype er påkrævet';
_fc_props.trailer_number_already_exists = 'Trailernummer findes allerede';
_fc_props.plate_number_already_exists = 'Pladenummeret findes allerede';
_fc_props.trailer_vin_already_exists = 'Trailer Vin findes allerede';
_fc_props.trailer_does_not_exist = 'Trailer findes ikke';
_fc_props.trailer_number_is_required = 'Trailernummer er påkrævet';
_fc_props.plate_number_is_required = 'Pladenummer er påkrævet';
_fc_props.plate_number = 'Pladenummer';
_fc_props.menu_settings_title_trailer = 'Anhænger';
_fc_props.add_trailer = 'Tilføj trailer';
_fc_props.edit_trailer = 'Rediger trailer';
_fc_props.menu_settings_title_equipment = 'Udstyr';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorer nye billetter fra Command Cloud, der er ældre end X minutter';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorer nye billetter fra Command Cloud med afsendelsesdato-tid ældre end X minutter (standard er tom og deaktiveret)';
_fc_props.edit_load_card = 'Rediger Indlæs kort';
_fc_props.default_layout = 'Standardlayout';
_fc_props.load_card_empty_fields = 'Fejl ved lagring af Indlæs kort: Et eller flere felter skal vælges.';
_fc_props.trailers = 'Trailere';
_fc_props.error_failed_to_save_data = 'Fejl: Kunne ikke gemme data';
_fc_props.slump_increased_plasticizer_added_question = 'Hvor meget blødgører blev der tilsat?';
_fc_props.add_trailer_type = 'Tilføj Trailer Type';
_fc_props.menu_settings_title_trailer_type = 'Trailer type';
_fc_props.edit_trailer_type = 'Rediger trailertype';
_fc_props.single_use_codes = 'Engangskoder';
_fc_props.multiple_use_codes = 'Koder til flere brug';
_fc_props.trailer_type_description_is_required = 'Trailertypebeskrivelse er påkrævet';
_fc_props.trailer_saved = 'Trailer gemt';
_fc_props.cloud_dvir__trailer_only_dvir = 'Kun trailer DVIR';
_fc_props.choose_file = 'Vælg Fil';
_fc_props.no_file_chosen = 'Ingen fil valgt';
_fc_props.please_choose_file = 'Vælg venligst Fil';
_fc_props.no_filters = 'Ingen filtre valgt';
_fc_props.remaining_defects = 'Resterende mangler';
_fc_props.load_more = 'Indlæs mere';
_fc_props.pagination_label = '{0}-{1} af {2}';
_fc_props.extend = 'Udvide';
_fc_props.cloud_dvir__notes_or_photo_required = 'Enten noter eller et billede er påkrævet.';
_fc_props.no_default_plant_specified_failed_to_save = 'Intet standardanlæg angivet.';
_fc_props.no_default_employee_type_specified_failed_to_save = 'Ingen standard medarbejdertype angivet.';
_fc_props.personal_device_registration_code_saved = 'Den personlige enheds registreringskode er gemt';
_fc_props.error_saving_personal_device_registration_code = 'Fejl ved lagring af personlig enhedsregistreringskode. Prøv igen.';
_fc_props.settings.master_data_sub_title = 'Opsæt en masterdataintegration mellem TrackIt og Connex';
_fc_props.connex_authentication = '{0} Godkendelse';
_fc_props.select_company = 'Vælg Firma';
_fc_props.requirements = 'Krav';
_fc_props.default_plant_type = 'Standardanlæg';
_fc_props.default_employee_type = 'Standard medarbejdertype';
_fc_props.default_equipment_type = 'Standardudstyrstype';
_fc_props.support_alphanumeric_trucks = 'Support alfanumeriske lastbiler';
_fc_props.no_default_equipment_type_specified_failed_to_save = 'Ingen standardudstyrstype angivet.';
_fc_props.air_pau_battery_low_message = 'Air PAU Batteri lavt alarm';
_fc_props.air_pau_battery_low = 'Air PAU batteri lavt';
_fc_props.alert_when_air_pau_battery_low = 'Advarsel, når Air PAU-batteriet er lavt';
_fc_props.air_pau_battery_low_alert = 'Advarsel om lavt luftbatteri';
_fc_props.sensor_unable_to_determine_slump = 'Sensor kan ikke bestemme fald';
_fc_props.menu_settings_title_export = 'Eksportere';
_fc_props.compare_company = 'Sammenlign med et andet firma';
_fc_props.site_settings = 'Indstillinger for websted';
_fc_props.permission_type = 'Tilladelsestype';
_fc_props.ticket_integration = 'Billetintegration';
_fc_props.select_one_or_more_settings = 'Vælg en eller flere indstillinger';
_fc_props.export_tooltip = 'Denne mulighed er kun tilgængelig, hvis du tilhører mere end én virksomhed.';
_fc_props.select_company_error = 'Vælg venligst en virksomhed';
_fc_props.audible = 'Hørbar';
_fc_props.deleted_master_data_successfully = 'Stamdata blev slettet';
_fc_props.cloud_dvir__units_miles_abbr = 'MI';
_fc_props.cloud_dvir__units_kilometers_abbr = 'KM';

export default _fc_props;
