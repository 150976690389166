import React, { FC, useEffect } from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useTranslation } from 'react-i18next';
import { Input, Text } from '../../components';
import {
  InspectionTripTypeLabels,
  OdometerIsoUomLabels, VehicleForm,
  VehicleFormFields,
  VehicleFormLabels,
} from '../forms/useVehicleForm';
import { DvirSubmitMode } from '../../constants';

interface HeaderProps {
    readOnly: boolean;
    vehicleForm: VehicleForm;
    onVehicleFormChange: (fieldName: string, value: string) => void;
    currentDvirSubmitMode: string;
}

export const Header:FC<HeaderProps> = ({
  currentDvirSubmitMode, vehicleForm, readOnly, onVehicleFormChange,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (currentDvirSubmitMode === DvirSubmitMode.Trailer) {
      VehicleFormFields.engineHours.optional = true;
      VehicleFormFields.odometer.optional = true;
    }
  }, []);

  const handleValueChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    onVehicleFormChange(name, value);
  };

  return (
    <Box
      sx={{
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          marginBottom: 4,
          color: (theme) => theme.palette.neutral.variation40,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            gap: 3,
            alignItems: 'center',
          }}
        >
          <LocalShippingOutlinedIcon sx={{ fontSize: 30 }} />
          <Text fontLevel="title" fontSize="large" fontWeight="bold">
            {t('cloud_dvir__truck_details')}
          </Text>
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Select
            name={VehicleFormFields.inspectionTripType.name}
            value={vehicleForm.inspectionTripType.value}
            error={readOnly ? false : vehicleForm.inspectionTripType.isError}
            fullWidth
            readOnly={readOnly}
            onChange={handleValueChange}
          >
            {Object.entries(InspectionTripTypeLabels).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {t(value)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      {currentDvirSubmitMode !== DvirSubmitMode.Trailer && (
        <Box display="flex" gap={3} marginBottom={4}>
          <Input
            sx={{ width: '30%' }}
            key={VehicleFormFields.odometer.name}
            type="number"
            InputProps={{ readOnly }}
            label={t(VehicleFormLabels[VehicleFormFields.odometer.name])}
            value={vehicleForm[VehicleFormFields.odometer.name].value}
            error={readOnly ? false : vehicleForm[VehicleFormFields.odometer.name].isError}
            name={VehicleFormFields.odometer.name}
            onChange={handleValueChange}
          />
          <Select
            sx={{ width: '30%', height: '48px' }}
            name={VehicleFormFields.odometerIsoUom.name}
            value={vehicleForm.odometerIsoUom.value}
            error={readOnly ? false : vehicleForm.odometerIsoUom.isError}
            fullWidth
            readOnly={readOnly}
            onChange={handleValueChange}
          >
            {Object.entries(OdometerIsoUomLabels).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {t(value)}
              </MenuItem>
            ))}
          </Select>
          <Input
            sx={{ width: '30%' }}
            key={VehicleFormFields.engineHours.name}
            type="number"
            fullWidth
            InputProps={{ readOnly }}
            label={t(VehicleFormLabels[VehicleFormFields.engineHours.name])}
            value={vehicleForm[VehicleFormFields.engineHours.name].value}
            error={readOnly ? false : vehicleForm[VehicleFormFields.engineHours.name].isError}
            name={VehicleFormFields.engineHours.name}
            onChange={handleValueChange}
          />
        </Box>
      )}
    </Box>
  );
};
