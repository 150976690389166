const _fc_props = {};
_fc_props.ante_meridiem = 'ER';
_fc_props.post_meridiem = 'PM';
_fc_props.retry = 'Prøv på nytt';
_fc_props.default = 'Misligholde';
_fc_props.origin = 'Opprinnelse';
_fc_props.loading = 'Laster';
_fc_props.list = 'Liste';
_fc_props.send = 'Sende';
_fc_props.error = 'Feil';
_fc_props.save = 'Lagre';
_fc_props.delete = 'Slett';
_fc_props.message = 'Beskjed';
_fc_props.logout = 'Logg ut';
_fc_props.add = 'Legge til';
_fc_props.remove = 'Ta vekk';
_fc_props.done = 'Ferdig';
_fc_props.other = 'Annen';
_fc_props.interval = 'Intervall';
_fc_props.time = 'Tid';
_fc_props.speed = 'Hastighet';
_fc_props.longitude = 'Lengdegrad';
_fc_props.latitude = 'Breddegrad';
_fc_props.x_minutes = '{0} min';
_fc_props.general = 'Generell';
_fc_props.messages = 'Meldinger';
_fc_props.gps_fixes = 'GPS-fikser';
_fc_props.device_id = 'Enhets-ID';
_fc_props.vehicle = 'Kjøretøy';
_fc_props.employee = 'Ansatt';
_fc_props.version = 'Versjon';
_fc_props.statuses = 'Statuser';
_fc_props.edit = 'Redigere';
_fc_props.cancel = 'Avbryt';
_fc_props.yes = 'Ja';
_fc_props.none = 'Ingen';
_fc_props.never = 'Aldri';
_fc_props.name = 'Navn';
_fc_props.true = 'ekte';
_fc_props.status = 'Status';
_fc_props.distance = 'Avstand';
_fc_props.ok = 'Ok';
_fc_props.login = 'Logg Inn';
_fc_props.next = 'Neste';
_fc_props.menu = 'Meny';
_fc_props.not_available = 'N/A';
_fc_props.back = 'Tilbake';
_fc_props.select = 'Plukke ut';
_fc_props.dispatch = 'Utsendelse';
_fc_props.messaging = 'Meldinger';
_fc_props.plants = 'Planter';
_fc_props.last_plants = 'Siste planter';
_fc_props.vehicle_types = 'Kjøretøytyper';
_fc_props.employee_types = 'Ansatttyper';
_fc_props.employee_groups = 'Ansattgrupper';
_fc_props.equipment_groups = 'Utstyrsgrupper';
_fc_props.job = 'Jobb';
_fc_props.order = 'Rekkefølge';
_fc_props.ticket = 'Billett #';
_fc_props.small = 'Liten';
_fc_props.medium = 'Medium';
_fc_props.large = 'Stor';
_fc_props.reports = 'Rapporter';
_fc_props.payroll = 'Lønn';
_fc_props.support = 'Brukerstøtte';
_fc_props.username = 'Brukernavn';
_fc_props.all = 'Alle';
_fc_props.question = 'Spørsmål';
_fc_props.select_employee = 'Velg Ansatt';
_fc_props.phone = 'Telefon';
_fc_props.january = 'januar';
_fc_props.february = 'februar';
_fc_props.march = 'mars';
_fc_props.april = 'april';
_fc_props.may = 'Kan';
_fc_props.june = 'juni';
_fc_props.july = 'juli';
_fc_props.august = 'august';
_fc_props.september = 'september';
_fc_props.october = 'oktober';
_fc_props.november = 'november';
_fc_props.december = 'desember';
_fc_props.are_you_sure_you_want_to_delete = 'Er du sikker på at du vil slette';
_fc_props.unknown = 'Ukjent';
_fc_props.select_all = 'Velg alle';
_fc_props.equipment = 'Utstyr';
_fc_props.region = 'Region';
_fc_props.no_data = 'Ingen data';
_fc_props.hotspot = 'Hotspot';
_fc_props.address = 'Adresse';
_fc_props.retrieving_address = 'Henter adresse ...';
_fc_props.address_not_found = 'Adressen ble ikke funnet';
_fc_props.active_time = 'Aktiv tid';
_fc_props.destination_time = 'Destinasjonstid';
_fc_props.complete_time = 'Fullstendig tid';
_fc_props.status_report = 'Statusrapport';
_fc_props.export_report = 'Eksporter rapport';
_fc_props.download_report = 'Last ned rapport';
_fc_props.view_report = 'Se rapport';
_fc_props.duration = 'Varighet';
_fc_props.min = 'Min';
_fc_props.start_date_time = 'Startdato/klokkeslett';
_fc_props.select_start_date = 'Velg Startdato';
_fc_props.end_date_time = 'Sluttdato/tid';
_fc_props.select_end_date = 'Velg Sluttdato';
_fc_props.trip_time = 'Reisetid';
_fc_props.travel_time = 'Reisetid';
_fc_props.description = 'Beskrivelse';
_fc_props.map_controls = 'Kartkontroller';
_fc_props.road = 'Vei';
_fc_props.aerial = 'Antenne';
_fc_props.hybrid = 'Hybrid';
_fc_props.trip = 'Tur';
_fc_props.map = 'Kart';
_fc_props.plant = 'Anlegg';
_fc_props.select_date = 'Velg Dato';
_fc_props.apply = 'Søke om';
_fc_props.save_settings = 'Lagre innstillinger';
_fc_props.false = 'Falsk';
_fc_props.delete_confirmation = 'Slett bekreftelse';
_fc_props.last_name = 'Etternavn';
_fc_props.none_selected = 'Ingen valgt';
_fc_props.start_date = 'Startdato';
_fc_props.group_by = 'Gruppe av';
_fc_props.equipment_employee = 'Utstyr / Ansatt';
_fc_props.inactive = 'Inaktiv';
_fc_props.active = 'Aktiv';
_fc_props.note_checking_may_affect_performance = 'Merk: Kontroll kan påvirke ytelsen';
_fc_props.show_distancefuel_used = 'Vis avstand/brukt drivstoff';
_fc_props.mins = 'Min';
_fc_props.threshold_time = 'Terskeltid';
_fc_props.include_voided_tickets = 'Inkluder annullerte billetter';
_fc_props.note_all_times_listed_are_in_minutes = 'Merk: Alle oppførte tider er i minutter';
_fc_props.please_select_a_job_order_or_equipment = 'Velg en jobb, ordre, anlegg, utstyr eller ansatt';
_fc_props.device = 'Enhet';
_fc_props.select_equipment = 'Velg Utstyr';
_fc_props.all_equipment_types = 'Alle utstyrstyper';
_fc_props.summary = 'Sammendrag';
_fc_props.units = {};
_fc_props.units.liters = 'Liter';
_fc_props.type = 'Type';
_fc_props.no_data_available = 'Ingen data tilgjengelig';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'Avstand og drivstoff etter region';
_fc_props.menu_settings_title_settings = 'Innstillinger';
_fc_props.menu_settings_title_alerts = 'Varsler';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'Utstyr';
_fc_props.menu_settings_title_hotspots_regions = 'Hotspots og regioner';
_fc_props.days = '{0} dag(er)';
_fc_props.home = 'Hjem';
_fc_props.find = 'Finne';
_fc_props.close_this_panel = 'lukk dette panelet';
_fc_props.filters = 'Filtre';
_fc_props.start = 'Start';
_fc_props.end = 'Slutt';
_fc_props.location_details = 'Stedsdetaljer';
_fc_props.first_name = 'Fornavn';
_fc_props.material_per_hour = 'Materiale per time';
_fc_props.equipment_pound = 'Utstyr #';
_fc_props.equipment_type = 'Utstyrstype';
_fc_props.options = 'Alternativer';
_fc_props.zoom_on_cursor_position = 'Zoom på markørposisjon';
_fc_props.display_equipment_identifier = 'Vis utstyrsidentifikasjon';
_fc_props.view_equipment = 'Se utstyr';
_fc_props.view_hotspots = 'Se hotspots';
_fc_props.view_regions = 'Vis regioner';
_fc_props.use_standard_filters = 'Bruk standardfiltre';
_fc_props.home_plant = 'Hjemmeplante';
_fc_props.last_plant = 'Siste plante';
_fc_props.equip_types = 'Utruste. Typer';
_fc_props.equip_groups = 'Utruste. Grupper';
_fc_props.orders = 'Ordrene';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'Bruk utstyrsfilter';
_fc_props.all_selected = 'Alle valgt';
_fc_props.of = 'Av';
_fc_props.your_map_session_has_expired = 'Kartøkten din er utløpt. Oppdater siden.';
_fc_props.equipment_short = 'Utruste.';
_fc_props.job_info_for = 'Jobbinfo for';
_fc_props.order_info_for = 'Bestillingsinfo for';
_fc_props.ticket_info_for_vehicle = 'Billettinformasjon for kjøretøy';
_fc_props.inbox = 'Innboks';
_fc_props.outbox = 'Utboks';
_fc_props.alerts = 'Varsler';
_fc_props.critical = 'Kritisk';
_fc_props.important = 'Viktig';
_fc_props.informational = 'Informasjonsmessig';
_fc_props.view_routes = 'Se ruter';
_fc_props.hours_size = 'Timer Størrelse';
_fc_props.used_count = 'Brukt Count';
_fc_props.last_login = 'Siste innlogging';
_fc_props.last_vehicle = 'Siste kjøretøy';
_fc_props.regions = 'Regioner';
_fc_props.driver = 'Sjåfør';
_fc_props.js_delete = 'Slett';
_fc_props.move = 'Bevege seg';
_fc_props.edit_hotspot = 'Rediger Hotspot';
_fc_props.adjust_shape = 'Juster form';
_fc_props.edit_region = 'Rediger region';
_fc_props.enter_a_start_address = 'Vennligst skriv inn en startadresse';
_fc_props.select_a_start_hotspot = 'Velg et starthotspot';
_fc_props.failed_to_find_start_hotspot = 'Kunne ikke finne starthotspot.';
_fc_props.invalid_latitudelongitude = 'Ugyldig breddegrad/lengdegrad';
_fc_props.enter_an_end_address = 'Vennligst skriv inn en sluttadresse';
_fc_props.select_an_end_hotspot = 'Velg et slutt-hotspot';
_fc_props.failed_to_find_end_hotspot = 'Kunne ikke finne slutten av hotspot.';
_fc_props.print = 'Skrive ut';
_fc_props.these_directions_are_for_informational_purposes = 'Disse instruksjonene er kun for informasjonsformål. Det gis ingen garanti for deres fullstendighet eller nøyaktighet. Byggeprosjekter, trafikk eller andre hendelser kan føre til at faktiske forhold avviker fra disse resultatene.';
_fc_props.enter_an_address = 'Vennligst skriv inn en adresse';
_fc_props.select_a_hotspot = 'Velg et hotspot';
_fc_props.failed_to_find_hotspot = 'Kunne ikke finne Hotspot.';
_fc_props.select_a_vehicle = 'Vennligst velg et kjøretøy';
_fc_props.failed_to_find_vehicle = 'Kunne ikke finne kjøretøyet.';
_fc_props.failed_to_find_address = 'Kunne ikke finne adressen';
_fc_props.failed_to_find_vehicles = 'Kunne ikke finne kjøretøy i søkekriteriene.';
_fc_props.get_address = 'Få adresse';
_fc_props.results_for = 'Resultater for';
_fc_props.error_retrieving_results = 'Feil ved henting av resultater';
_fc_props.no_results_found = 'Ingen resultater';
_fc_props.address_is_required = 'Adresse er påkrevd';
_fc_props.city_is_required = 'By er påkrevd';
_fc_props.set_location = 'Angi plassering';
_fc_props.set_area_of_interest = 'Angi interesseområde';
_fc_props.center_map_here = 'Senter kart her';
_fc_props.zoom_to_street_level = 'Zoom til gatenivå';
_fc_props.set_start = 'Sett Start';
_fc_props.set_end = 'Sett slutt';
_fc_props.delete_node = 'Slett node';
_fc_props.create_a_hotspot = 'Opprett et hotspot';
_fc_props.create_a_region = 'Opprett en region';
_fc_props.toggle_shapes = 'Veksle mellom former';
_fc_props.refresh_map = 'Oppdater kart';
_fc_props.more_options = 'Flere valg';
_fc_props.recenter_map = 'Sentrer kart på nytt';
_fc_props.zoom_to_vehicle = 'Zoom til kjøretøy';
_fc_props.apply_filters = 'Bruk filtre';
_fc_props.grid_view = 'Rutenett visning';
_fc_props.list_view = 'Listevisning';
_fc_props.click_to_sort = 'Klikk for å sortere';
_fc_props.message_history = 'Meldingshistorikk';
_fc_props.equipment_types = 'Utstyrstyper';
_fc_props.send_message = 'Sende melding';
_fc_props.last_load = 'Siste last';
_fc_props.last_load_on = 'Siste last på';
_fc_props.manual_logout = 'Manuell utlogging';
_fc_props.manual_login = 'Manuell pålogging';
_fc_props.no_employees_logged_in = 'Ingen ansatte logget på';
_fc_props.select_phone = 'Velg Telefon';
_fc_props.you_must_select_a_phone = 'Du må velge en telefon, utstyr og ansatt';
_fc_props.are_you_sure_you_want_to_logout = 'Er du sikker på at du vil logge ut';
_fc_props.fix_time = 'Fix tid';
_fc_props.to_next_fix = 'til neste fiks';
_fc_props.minutes_to_next_fix = 'Minutter til neste fiks';
_fc_props.liters = 'Liter';
_fc_props.destination = 'Mål';
_fc_props.return = 'Komme tilbake';
_fc_props.product = 'Produkt';
_fc_props.no_records_found = 'Ingen opptak funnet.';
_fc_props.vehicle_type = 'Bil type';
_fc_props.view_in_map = 'Se alt på kartet';
_fc_props.uploads = 'Opplastinger';
_fc_props.upload_csv_file = 'Last opp CSV-fil';
_fc_props.permanent = 'Fast';
_fc_props.expire_time = 'Utløpstid';
_fc_props.please_select_x_for_tickets_marked_active = 'Velg {0} for billetter merket med AKTIVE';
_fc_props.multiple_tickets_marked_active_for_same_x = 'Flere billetter merket med AKTIVE for samme {0}';
_fc_props.failure_connecting_to_servlet = 'Feil ved tilkobling til servlet.';
_fc_props.status_time_summary = 'Status Tidssammendrag';
_fc_props.view_in_map_one = 'Vis i kart';
_fc_props.question_report = 'Spørsmålsrapport';
_fc_props.end_date = 'Sluttdato';
_fc_props.trailer_number = 'Trailernummer';
_fc_props.region_panel = 'Regionpanel';
_fc_props.hotspot_panel = 'Hotspot-panel';
_fc_props.shape = 'Form';
_fc_props.circle = 'Sirkel';
_fc_props.polygon = 'Polygon';
_fc_props.click_the_map_to_set_the_location = 'Klikk på kartet for å angi ønsket plassering.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'Klikk på kartet for å angi hotspot-senteret.';
_fc_props.click_the_map_to_set_the_region_center = 'Klikk på kartet for å angi regionsenteret.';
_fc_props.click_and_drag_points = 'Klikk og dra punkter for å redigere formen. Klikk på LAGRE for å lagre endringene.';
_fc_props.size = 'Størrelse';
_fc_props.are_you_sure_you_want_to_move_this = 'Er du sikker på at du vil flytte denne';
_fc_props.are_you_sure_you_want_to_delete_this = 'Er du sikker på at du vil slette dette';
_fc_props.please_enter_a_valid_size = 'Vennligst skriv inn en gyldig størrelse';
_fc_props.failed_to_save = 'Kunne ikke lagre.';
_fc_props.failed_to_move = 'Kunne ikke flytte.';
_fc_props.failed_to_delete = 'Kunne ikke slette.';
_fc_props.x_hours = '{0} timer';
_fc_props.hide_hotspots = 'Skjul hotspots';
_fc_props.show_regions = 'Vis regioner';
_fc_props.show_hotspots = 'Vis hotspots';
_fc_props.hide_regions = 'Skjul regioner';
_fc_props.numeric = 'Numerisk';
_fc_props.message_type = 'Meldingstype';
_fc_props.text = 'Tekst';
_fc_props.yes_no = 'Ja Nei';
_fc_props.responses = 'Svar';
_fc_props.disabled = 'Funksjonshemmet';
_fc_props.group_name = 'Gruppenavn';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'Er du sikker på at du vil slette "{0}"-formatet?';
_fc_props.phone_number = 'Telefonnummer';
_fc_props.interface_pound = 'Grensesnitt #';
_fc_props.field = 'Felt';
_fc_props.default_label = 'Standard etikett';
_fc_props.custom_label = 'Egendefinert etikett';
_fc_props.you_must_enter_a_label = 'Du må angi en etikett.';
_fc_props.enabled = 'Aktivert';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'Billettintegrering';
_fc_props.settings.geofence_off_open_hotspots = 'Geofence off åpne Hotspots';
_fc_props.settings.associate_open_hotspots_wticket = 'Tilknytt åpne hotspots m/billett';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'Tillat geofencing av åpne hotspots eller bare hotspots knyttet til Driver\'s Ticket';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'Hvorvidt åpne Hotspots skal knyttes til billetter når et kjøretøy kommer inn i dem';
_fc_props.settings.void_ticket_without_destination = 'Ugyldig billett uten destinasjon';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'hvis en billett mottas mens en annen er aktiv, hvis den aktive billetten ikke har en destinasjon vil billetten bli annullert.';
_fc_props.settings.autocreate_hotspots = 'Opprett hotspots automatisk';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'Hvorvidt du skal opprette hotspots automatisk fra destinasjonsadressen når en billett mottas';
_fc_props.settings.autoactivate_ticket_upon_receive = 'Automatisk aktiver billett ved mottak';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'Er billetten aktiv når den mottas av TrackIt (Standard \'true\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'Autolukk aktiv billett ved mottak av minutter';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'Hvor lenge må gjeldende aktive billett ha vært aktiv før den automatisk lukkes (standard \'\')';
_fc_props.settings.ticket_max_activate_age_minutes = 'Ticket Max Aktiver Alder minutter';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'Hvor gammel i minutter kan en billett være når du aktiverer den i TrackIt (Standard \'\')';
_fc_props.settings.max_hotspot_unused_days = 'Maks. Hotspot ubrukte dager';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'Angir maksimalt antall dager et hotspot kan være ubrukt og fortsatt gjenopplives';
_fc_props.settings.radius_multiplier = 'Radius multiplikator';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'Hva skal radius ganges med når en billett mottas fra ekspedisjonssystemet';
_fc_props.settings.ticket_message_date_format = 'Datoformat for billettmelding';
_fc_props.am = 'er';
_fc_props.pm = 'pm';
_fc_props.settings.ticket_message = 'Billettmelding';
_fc_props.settings.add_ticket_element = 'Legg til billettelement';
_fc_props.driver_performance = 'Driver ytelse';
_fc_props.update_failed_can_not_connect = 'Oppdatering mislyktes: Kan ikke koble til servlet.';
_fc_props.unknown_error = 'Ukjent feil';
_fc_props.selected = 'Valgt';
_fc_props.web_service = 'Nettjeneste';
_fc_props.error_processing_request = 'Feil under behandling av forespørselen.';
_fc_props.email_address = 'Epostadresse';
_fc_props.id = 'ID';
_fc_props.last_used = 'Sist brukt';
_fc_props.error_invalid_vehiclenum = 'Feil Ugyldig kjøretøynummer';
_fc_props.error_vehicle_is_not_logged_in = 'Feil: Kjøretøyet er ikke pålogget.';
_fc_props.users = 'Brukere';
_fc_props.you_dont_have_access_to_this_page = 'Du har ikke tilgang til å se denne siden. Kontakt systemadministratoren din for tilgang.';
_fc_props.previous_week = 'Forrige uke';
_fc_props.status_breakdown = 'Statusoversikt';
_fc_props.select_plant_or_employee_group = 'Du må velge enten et anlegg eller en ansattgruppe.';
_fc_props.cannot_select_plant_and_employee_group = 'Du kan ikke velge både et anlegg og en ansattgruppe.';
_fc_props.odometer_must_be_a_number = 'Start kilometerteller må være et tall';
_fc_props.ending_odometer_must_be_a_number = 'Avsluttende kilometerteller må være et tall';
_fc_props.beginning_hour_meter_must_be_a_number = 'Starttimeteller må være et tall';
_fc_props.ending_hour_meter_must_be_a_number = 'Slutttimeteller må være et tall';
_fc_props.error_loading_status_change_page = 'Feil ved innlasting av statusendringsside.';
_fc_props.x_is_an_invalid_time_format = '{0} er et ugyldig tidsformat, det må være ÅÅÅÅ-MM-DD TT:MM:SS';
_fc_props.x_for_schedule_login = '{0} for tidsplanpålogging';
_fc_props.x_for_schedule_logout = '{0} for tidsplanlogging';
_fc_props.x_for_actual_login = '{0} for faktisk pålogging';
_fc_props.x_for_actual_logout = '{0} for faktisk utlogging';
_fc_props.by_refreshing_the_time_card_detail = 'Ved å oppdatere tidskortdetaljene vil du miste alle endringer, fortsette?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'Er du sikker på at du vil slette {0}?';
_fc_props.settings.command_mobile_ticket_url = 'Command Mobile Ticket URL';
_fc_props.year = 'År';
_fc_props.make = 'Gjøre';
_fc_props.model = 'Modell';
_fc_props.settings.command_mobile_ticket_password = 'Command Mobile Ticket Passord';
_fc_props.settings.command_mobile_ticket_auth_token = 'Command Mobile Ticket Auth Token';
_fc_props.target = 'Mål';
_fc_props.number_of_digits = 'Antall siffer';
_fc_props.number_of_integer_digits = 'Antall heltallssiffer';
_fc_props.number_of_fractional_digits = 'Antall brøksiffer';
_fc_props.search = 'Søk';
_fc_props.settings.command_mobile_ticket_date_order = 'Kommando mobilbillettdatobestilling';
_fc_props.settings.command_mobile_ticket_date_length = 'Command Mobile Ticket Dato Lengde';
_fc_props.settings.command_mobile_ticket_date_separator = 'Command Mobile Ticket Datoskiller';
_fc_props.settings.command_mobile_ticket_slash = '/ (skråstrek)';
_fc_props.settings.command_mobile_ticket_dash = '- (bindestrek)';
_fc_props.settings.command_mobile_ticket_period = '. (periode)';
_fc_props.settings.command_mobile_ticket_space = '(rom)';
_fc_props.settings.command_mobile_ticket_time_length = 'Command Mobile Ticket Tidslengde';
_fc_props.settings.command_mobile_ticket_time_separator = 'Command Mobile Ticket Time Separator';
_fc_props.settings.command_mobile_ticket_colon = ': (kolon)';
_fc_props.settings.command_mobile_ticket_time_format = 'Command Mobile Ticket Time Format';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'Bruk grensesnittnummer';
_fc_props.mechanic = 'Mekaniker';
_fc_props.settings.command_mobile_ticket_poll_interval = 'Avstemningsintervall (sekunder)';
_fc_props.driving_time = 'Kjøretid';
_fc_props.find_near_plants = 'Finn nær planter';
_fc_props.please_create_hot_spot_first = 'Opprett hotspot først';
_fc_props.please_enter_hot_spot_description = 'Vennligst skriv inn beskrivelse av hot spot';
_fc_props.please_select_a_hot_spot_type = 'Velg en type hot spot';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'Send sjåfør-e-post';
_fc_props.directions_not_found = 'Ugyldig {0}-adresse. Veibeskrivelse ikke funnet.';
_fc_props.ws_user = 'Bruker';
_fc_props.ws_service = 'Service';
_fc_props.ws_method = 'Metode';
_fc_props.ws_params = 'Params';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'Opprinnelse og destinasjon';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'Hjelp';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android Bruk Alt ansattnummer';
_fc_props.settings.command_mobile_ticket_android_url = 'Android mobilbillett-URL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Android-mobilbilletthemmelighet';
_fc_props.employee_x_has_no_logout_on_y = 'Ansatt {0} har ingen utlogging for et skift som starter {1}.';
_fc_props.phone_number_has_duplicates = 'Telefonnumre har dupliserte oppføringer';
_fc_props.ounce = 'Unse';
_fc_props.water_extra = 'Vann Ekstra';
_fc_props.always = 'Bestandig';
_fc_props.work_types_must_be_unique = 'Arbeidstyper må være unike';
_fc_props.manufacturer = 'Produsent';
_fc_props.need_to_enter_seconds = 'Må angi sekunder.';
_fc_props.copy = 'Kopiere';
_fc_props.create_in_existing_required = 'Opprett i eksisterende må velges for å opprette roaming-hotspot.';
_fc_props.break = 'Gå i stykker';
_fc_props.add_export_data_type = 'Legg til eksportdatatype';
_fc_props.edit_export_data_type = 'Rediger eksportdatatype';
_fc_props.delete_export_data_type = 'Slett eksportdatatype';
_fc_props.export_data_type = 'Eksporter datatype';
_fc_props.error_updating_export_data_type = 'Feil under oppdatering av eksportdatatype';
_fc_props.settings.address_key = 'Adressenøkkel';
_fc_props.settings.address_key_hover = 'Alternativer for adressenøkkel';
_fc_props.settings.address_key_options = 'Alternativer for adressenøkkel';
_fc_props.more_filter_options = 'Flere filteralternativer';
_fc_props.unauthorized_mapit_app = 'Denne versjonen av MapIt er ikke autorisert, vennligst oppdater MapIt så snart som mulig.';
_fc_props.zello_timeout = 'Tidsavbrudd oppsto ved kontakt med Zello.';
_fc_props.user_agreement_text = 'Ved å logge inn godtar du';
_fc_props.user_agreement = 'Brukeravtale';
_fc_props.upload = 'Laste opp';
_fc_props.x_mins_left = '{0} min(er) igjen';
_fc_props.continuous_location_tracking_colon = 'Kontinuerlig posisjonssporing:';
_fc_props.hired_hauler_cannot_register_device = 'Hired Hauler kan ikke registrere ny enhet.';
_fc_props.timecard_does_not_contain_shift = 'Ansatttidskort inneholder ikke skiftet (tidskortinfo) som er oppgitt';
_fc_props.timecard_not_found = 'Ansatttidskort ble ikke funnet';
_fc_props.vehicle_must_be_assigned_for_clt = 'Kjøretøyet må tilordnes sporplasseringer.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'Kjøretøyet ble ikke tilordnet kontinuerlig sporing vil bli deaktivert.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'Mistet forbindelsen med EDC. Sjekk at WiFi og/eller Bluetooth er aktivert.';
_fc_props.acknowledge_all = 'Bekreft alle';
_fc_props.expand = 'Utvide';
_fc_props.collapse = 'Kollapse';
_fc_props.expand_all = 'Utvid alle';
_fc_props.collapse_all = 'Skjul alle';
_fc_props.last_update = 'Siste oppdatering';
_fc_props.acknowledge_alert = 'Bekreft varsel';
_fc_props.acknowledge_message = 'Bekreft melding';
_fc_props.acknowledge_all_alerts = 'Godkjenne alle varsler';
_fc_props.acknowledge_all_messages = 'Bekreft alle meldinger';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = 'Toleranse må være et gyldig heltall mellom -99 og 99 (begge inkludert)';
_fc_props.gps_permission_required = 'GPS-tillatelse kreves';
_fc_props.permissions_required = 'Tillatelser kreves';
_fc_props.change_user = 'Bytt bruker';
_fc_props.account_not_registered_with_carrier = 'Denne kontoen er ikke registrert hos en operatør';
_fc_props.slump_loss = 'Nedgangstap';
_fc_props.slump_loss_help = 'Slump Loss Help';
_fc_props.did_not_receive_any_edc_devices = 'Fikk ingen EDC-enheter.';
_fc_props.edc_record_not_found = 'EDC-posten ble ikke funnet';
_fc_props.disable_hotspot_prompt = 'Trykk på "OK" når du blir bedt om å deaktivere din hotspot.';
_fc_props.edc_device_is_in_use = 'EDC {0} er i bruk.';
_fc_props.an_unknown_exception_has_occurred = 'Et ukjent unntak har skjedd';
_fc_props.login_has_timed_out = 'Påloggingen har gått ut';
_fc_props.use_paper_logs = 'Bruk papirlogger';
_fc_props.eld_login_failed = 'Innlogging på timer mislyktes';
_fc_props.setup_time_off_type = 'Vennligst konfigurer en avspaseringstype.';
_fc_props.view_load_properties = 'Vis lasteegenskaper';
_fc_props.couldnt_download_edc_firmware = 'Kunne ikke laste ned EDC-fastvare.';
_fc_props.password_incorrect = 'Feil passord';
_fc_props.edc_configuration_password_prompt = 'På grunn av tilstanden til denne enheten må du oppgi installatørpassordet for å konfigurere.';
_fc_props.edc_configuration_check_failed = 'Validering av EDC-serverposter mislyktes. Vennligst sjekk feilmeldingene for detaljer.';
_fc_props.configuration_issues_prompt = 'Følgende problemer ble oppdaget under konfigurering av valgt EDC for den angitte lastebilen';
_fc_props.checking_password = 'Sjekker passord';
_fc_props.reset_password_instructions_sent = 'Instruksjoner for å tilbakestille passordet sendes til e-postadressen {0}.';
_fc_props.reset_password_failed = 'Tilbakestilling av passord mislyktes. Prøv på nytt.';
_fc_props.forgot_password = 'Glemt passord?';
_fc_props.enter_valid_email = 'Ugyldig e-postadresse, skriv inn en gyldig e-postadresse og prøv igjen.';
_fc_props.issues_while_updating_edc_record = 'EDC ble konfigurert. Det er imidlertid problemer med postene for EDC på TrackIt-serveren som kan føre til at den ikke fungerer. Ring support for å korrigere informasjonen.';
_fc_props.sending = 'Sender...';
_fc_props.minimum_ratio = 'Minimumsforhold';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'Drum RPM ikke innenfor rekkevidde';
_fc_props.probe_failure = 'Sondefeil';
_fc_props.unknown_probe_data_failure = 'Ukjent sondedatafeil';
_fc_props.no_loaded_status_meaning = 'Ingen lastet status';
_fc_props.no_depart_status_meaning = 'Ingen avreisestatus';
_fc_props.no_arrive_status_meaning = 'Ingen ankomststatus';
_fc_props.no_begin_work_status_meaning = 'Ingen Start Work Status';
_fc_props.no_fully_mixed_status_meaning = 'Ingen fullstendig blandet status';
_fc_props.no_begin_work_or_at_job_status = 'Statusene Start Work eller At Job er ikke til stede';
_fc_props.driver_didnt_follow_procedure = 'Sjåføren fulgte ikke prosedyren (ikke bremset trommelen)';
_fc_props.probe_malfunction = 'Probefeil (intern feil osv.)';
_fc_props.no_data_from_probe = 'Ingen data fra sonden (datatap)';
_fc_props.missing_probe_calibration = 'Ingen sondekalibreringsdata';
_fc_props.probe_pressure_not_in_range = 'Probetrykket er ikke innenfor rekkevidde';
_fc_props.arrival = 'Ankomst';
_fc_props.small_load = 'LITEN LAST';
_fc_props.dry_slump = 'TØRRSLUMP';
_fc_props.probe_remixing_turns = 'Remiksing';
_fc_props.turns_needed_to_remix = 'Vann tilsatt - Ytterligere blanding nødvendig';
_fc_props.probe_remixing_finished = 'Remiksingen er ferdig';
_fc_props.probe_battery_low_alert = 'Varsel om lavt probebatteri';
_fc_props.probe_battery_low_message = 'Probe batteri lavt';
_fc_props.probe_battery_low = 'Probe batteri lavt';
_fc_props.alert_when_probe_battery_low = 'Varsle når probebatteriet er lavt';
_fc_props.probe_sensor_fatal_failure = 'Fatal feil på sensoren';
_fc_props.probe_thermistor_failure = 'Termistorfeil';
_fc_props.probe_sensor_stuck_in_concrete = 'Sensor sitter fast i betong';
_fc_props.probe_sensor_over_weight = 'Sensor over vekt';
_fc_props.probe_batteries_low = 'Lavt batteri';
_fc_props.no_probe_data = 'Ingen sondedata:';
_fc_props.check_probe = 'Sjekk sonde';
_fc_props.service_probe = 'Tjenestesonde';
_fc_props.below_x = 'Under {0}';
_fc_props.above_x = 'Over {0}';
_fc_props.probe_serial_number_invalid_format = 'Probens serienummer er ikke i gyldig format som vist i plassholderen for feltet';
_fc_props.talk_dnd_permission_prompt = 'Talk krever IKKE FORTYR-tillatelse.';
_fc_props.max = 'Maks';
_fc_props.status_changed_quickly = 'Status endret for raskt';
_fc_props.is_empty = 'Er tom';
_fc_props.slump_increased_plasticizer_question = 'Ble mykner tilsatt?';
_fc_props.slump_increased_water_added_question = 'Hvor mye umålt vann ble tilsatt?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'Probe- og leveringssyklusovervåking støttes ikke sammen på samme enhet.';
_fc_props.no_data_from_sensor = 'Ingen data fra sensor (datatap)';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'System type';
_fc_props.magnet_count = 'Magnetantall';
_fc_props.sensors_setup = 'Oppsett av sensorer';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCM krever at I/O-boks MAC-adresse, trommeltype, DCM-type og magnetantall angis.';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = 'Billettvolum';
_fc_props.drs_current_volume = 'Nåværende volum';
_fc_props.drs_current_water_cement_ratio = 'Nåværende vann/sementforhold';
_fc_props.drs_target_water_cement_ratio = 'Mål vann/sement-forhold';
_fc_props.drs_total_revolutions = 'Total';
_fc_props.drs_current_volume_lp_small = 'Nåværende';
_fc_props.drum_revolutions = 'Trommerevolusjoner';
_fc_props.drs_total_revolutions_lp_small = 'Total';
_fc_props.drs_charging_revolutions_lp_small = 'Lader';
_fc_props.drs_discharging_revolutions_lp_small = 'Utladning';
_fc_props.drs_target_wc_lp_small = 'Mål W/C';
_fc_props.drs_current_wc_lp_small = 'Nåværende W/C';
_fc_props.drs_ticketed_volume_lp_small = 'Billetter';
_fc_props.android_location_disclosure = 'TrackIt bruker fin og grov plassering for å samle plasseringsdata i bakgrunnen selv når den ikke er i bruk, for å aktivere automatisk status og sanntidskartsynlighet for sending.';
_fc_props.android_location_usage = 'Plassering';
_fc_props.end_pour_trigger_threshold = 'Slutt helle utløserterskel';
_fc_props.drs_wiring_error = 'Kablingsfeil';
_fc_props.drs_wiring_pulse_error = 'Ledningspulsfeil';
_fc_props.drs_wiring_magnet_error = 'Kablingsmagnetfeil';
_fc_props.drs_magnet_missing_error = 'Magnet mangler feil';
_fc_props.android_location_disclosure_3p = 'TrackIt samler plasseringsdata i bakgrunnen, for å aktivere automatisk status og sanntids kartsynlighet for sending. Når den er klokket ut fra avsender, deles ikke plasseringen.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'Velg enten Full MDM eller Trackit Remote Support';
_fc_props.move_up = 'Flytte opp';
_fc_props.move_down = 'Flytt ned';
_fc_props.driver_not_found = 'Driver ikke funnet.';
_fc_props.multiple = 'Flere';
_fc_props.water_meter_installed = 'Vannmåler installert';
_fc_props.delete_waypoint = 'Slett veipunkt';
_fc_props.wash_sensor_installed = 'Vaskesensor installert';
_fc_props.washing_start_time = 'Vaskestarttid';
_fc_props.end_washing = 'Slutt vask';
_fc_props.variable_rpms = 'Var RPM';
_fc_props.no_slump = 'Ingen nedgang';
_fc_props.end_washing_duration = 'Slutt vasketid';
_fc_props.privacy_policy = 'Personvernerklæring';
_fc_props.privacy_policy_text = 'Jeg har lest og godtar';
_fc_props.login_fail_accept_privacy_policy = 'Retningslinjer for personvern må godtas for å fortsette.';
_fc_props.rotation_sensor = 'Rotasjonssensor';
_fc_props.pto_sensor = 'PTO sensor';
_fc_props.pto_sensor_installed = 'PTO-sensor installert';
_fc_props.polarity_reversed = 'Revers polaritet';
_fc_props.pto_sensor_type = 'PTO Sensor Type';
_fc_props.sensor_type = 'Sensortype';
_fc_props.prompt_select_pto_sensor_type = 'Du må velge en "PTO-sensortype" hvis du har valgt en "PTO-sensor" som hovedsensortype.';
_fc_props.priming_turns_at_full_load = 'Priming svinger ved full belastning';
_fc_props.priming_turns_at_ten_percent_load = 'Priming svinger ved 10 % belastning';
_fc_props.weight_units = 'Vektenheter';
_fc_props.settings.send_status = 'Send status/sted til Event Bridge';
_fc_props.volume_difference = 'Volumforskjell';
_fc_props.mobile_ticket_iframe_url = 'Mobile Ticket IFrame URL';
_fc_props.mobile_ticket_user = 'Mobilbillettbruker';
_fc_props.mobile_ticket_password = 'Passord for mobilbillett';
_fc_props.current_truck_status = 'Gjeldende lastebilstatus';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'Utløses av maks omdreininger';
_fc_props.alerts_by_type = 'Varsler etter type';
_fc_props.water_flow_meter = 'Vannmengdemåler';
_fc_props.flow_meter_no_pulses_warning = 'Ingen pulser - Advarsel';
_fc_props.flow_meter_no_pulses_critical = 'Ingen pulser - Kritisk';
_fc_props.flow_meter_excessive_pulses = 'Overdreven pulser';
_fc_props.flow_meter_constantly_flowing = 'Stadig flytende';
_fc_props.flow_meter_good = 'God';
_fc_props.whats_new = 'Hva er nytt';
_fc_props.whats_new_widget_text = 'Visste du at vi oppdaterer TrackIt minst en gang i måneden? Få de siste oppdateringene og tipsene.';
_fc_props.get_the_scoop = 'Få Scoop';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = 'Forretningsanalyse som forbedrer bunnlinjen din, er nå inkludert i TrackIt';
_fc_props.find_out_more = 'Finne ut mer';
_fc_props.daily_breakdown = 'Daglig sammenbrudd';
_fc_props.question_summary = 'Oppsummering av spørsmål';
_fc_props.trip_summary = 'Tursammendrag';
_fc_props.manage_employees = 'Administrer ansatte';
_fc_props.manage_hotspots = 'Administrer Hotspots';
_fc_props.manage_devices = 'Administrer enheter';
_fc_props.manage_equipment = 'Administrer utstyr';
_fc_props.manage_users = 'Administrer brukere';
_fc_props.manage_statuses = 'Administrer statuser';
_fc_props.manage_alerts = 'Administrer varsler';
_fc_props.popular_report_links = 'Populære rapportlenker';
_fc_props.popular_settings_links = 'Populære innstillinger-lenker';
_fc_props.sidemenu_settings = 'Innstillinger';
_fc_props.loads_per_driver = 'Laster per driver';
_fc_props.please_select_application = 'Velg minst én applikasjon';
_fc_props.external_app_processing = 'Behandling på ekstern app';
_fc_props.external_app_sent = 'Sendt til ekstern app';
_fc_props.external_app_received = 'Mottatt av ekstern app';
_fc_props.wait_time_at_job = 'Ventetid på jobb';
_fc_props.wait_time_at_plant = 'Ventetid på anlegget';
_fc_props.loading_time = 'Lastetid';
_fc_props.unloading_time = 'Losetid';
_fc_props.pre_post_trip = 'Før/etter tur';
_fc_props.update_thresholds = 'Oppdater terskler';
_fc_props.green = 'Grønn';
_fc_props.yellow = 'Gul';
_fc_props.red = 'rød';
_fc_props.between = 'Mellom';
_fc_props.num_of_loads = 'Antall belastninger';
_fc_props.num_of_drivers = 'Antall sjåfører';
_fc_props.status_meaning_not_setup = 'Status Betydning ikke oppsett';
_fc_props.or_less = 'Eller mindre';
_fc_props.or_greater = 'Eller større';
_fc_props.probe_self_diagnostic_state = 'Probe selvdiagnostisk tilstand';
_fc_props.update_current_truck_status_settings = 'Oppdater gjeldende lastebilstatusinnstillinger';
_fc_props.default_behavior = 'Standard (topp 10 statuser)';
_fc_props.combine_similar_statuses = 'Kombiner samme statuser fra alle typer (løkker)';
_fc_props.use_other_category = 'Bruk \'Annet\' kategori';
_fc_props.selected_statuses = 'Valgte statuser';
_fc_props.ideas = 'Ideer';
_fc_props.air_content = 'Luftinnhold';
_fc_props.air_measured_time = 'Luftmålt tid';
_fc_props.ten_max = '10 Maks';
_fc_props.loads_per_plant = 'Laster per anlegg';
_fc_props.invalid_flow_meter_pulse_per_volume = 'Ugyldig strømningsmålerpuls per volum.';
_fc_props.coming_soon_title = 'Flere widgets kommer snart';
_fc_props.submit_your_ideas = 'Send inn ideene dine til idéportalen vår ved å bruke lyspæren på venstre navigasjonsmeny.';
_fc_props.selected_plants = 'Utvalgte planter';
_fc_props.update_loads_per_plant_settings = 'Oppdater belastninger per anleggsinnstillinger';
_fc_props.default_top_10_plants = 'Standard (topp 10 planter)';
_fc_props.phone_number_will_be_uploaded = 'Enhets-ID vil bli lastet opp for registreringsformål.';
_fc_props.include_deleted = 'Inkluder slettet';
_fc_props.primary_status_type = 'Primær statustype';
_fc_props.additional_status_trigger = 'Ytterligere statusutløsere';
_fc_props.update_tickets_destination = 'Oppdater destinasjon på alle tilknyttede billetter';
_fc_props.settings.send_destination_changes_to_connex = 'Send destinasjonsendringer til Connex';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'Er billetten aktiv når den mottas av TrackIt (Standard \'alltid\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'Automatisk aktiver neste billett ved forrige billettgjennomføring';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'Bør automatisk aktivere neste billett når billetten er fullført (standard "true")';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'Status til inaktiv status når ingen aktiv billett';
_fc_props.break_down = 'Sammenbrudd';
_fc_props.idle = 'Tomgang';
_fc_props.pto_work_maximum_speed_limit = 'Arbeid maksimal fartsgrense';
_fc_props.settings.associate_ticket_to_status_on_complete = 'Tilknytt statusendring som fullfører en billett med den billetten';
_fc_props.approaching = 'Nærmer seg';
_fc_props.performing_task = 'Utføre oppgave';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Er du sikker på at du vil slette Geogate {0}?';
_fc_props.trackit_destination_changed_to_0 = 'Oppdater billett {0}. Destinasjon endret.';
_fc_props.trackit_return_destination_changed_to_0 = 'Oppdater billett {0}. Retur endret.';
_fc_props.menu_settings_title_geogate = 'Geogate';
_fc_props.add_geogate = 'Legg til Geogate';
_fc_props.driver_enabled = 'Driver aktivert';
_fc_props.dispatcher_enabled = 'Sender aktivert';
_fc_props.runsheet_number = 'Runearknummer';
_fc_props.manual_load = 'Manuell lasting';
_fc_props.edit_geogate = 'Rediger Geogate';
_fc_props.handling = 'Håndtering';
_fc_props.geogate_name = 'Geogate';
_fc_props.geogate_panel = 'Geogate panel';
_fc_props.vehicle_is_not_logged_in = 'Melding ikke sendt (kjøretøyet er ikke pålogget)';
_fc_props.air = 'Luft';
_fc_props.create_a_geogate = 'Lag en Geogate';
_fc_props.click_the_map_to_set_the_geogate_points = 'Klikk på kartet for å angi geogatepunkter.';
_fc_props.clock_out = 'Stemple ut';
_fc_props.handling_operation = 'Håndtering Drift';
_fc_props.view_geogates = 'Se Geogates';
_fc_props.select_a_geogate = 'Vennligst velg en Geogate';
_fc_props.failed_to_find_geogate = 'Kunne ikke finne Geogate';
_fc_props.adjust_geogate = 'Juster Geogate';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate Utløpsdato Tid Standard Ekstra dager';
_fc_props.menu_settings_title_retention = 'Oppbevaring av data';
_fc_props.category = 'Kategori';
_fc_props.telemetry = 'Telemetri';
_fc_props.ticket_data = 'Billettdata';
_fc_props._30_days = '30 dager';
_fc_props._6_months = '6 måneder';
_fc_props._1_year = '1 år';
_fc_props._3_years = '3 år';
_fc_props.new_group = 'Ny gruppe';
_fc_props.selected_vehicles = 'Utvalgte kjøretøy';
_fc_props.group_name_is_required_field = 'Gruppenavn er obligatorisk felt';
_fc_props.at_least_vehicle_should_be_selected = 'Minst 1 kjøretøy bør velges';
_fc_props.groups = 'Grupper';
_fc_props.trucks = 'Lastebiler';
_fc_props.geogate_has_been_removed_please_refresh_page = 'Geogate er fjernet. Vennligst oppdater siden.';
_fc_props.air_timestamp = 'Luft tidsstempel';
_fc_props.fail = 'FAIL';
_fc_props.stuck = 'SITTENDE FAST';
_fc_props.dirty = 'SKITTEN';
_fc_props.seasonal = 'Sesongbestemt';
_fc_props.category_details = 'Kategoridetaljer';
_fc_props.alert_events = 'Varsle hendelser';
_fc_props.driver_performance_infractions = 'Brudd på sjåførytelse';
_fc_props.telemetry_details = 'Plasseringsdata, hotspots, tid i hotspots, tid i regioner, DCM-data, probedata, motordata';
_fc_props.ticket_data_details = 'Billettdata';
_fc_props.general_details = 'Statusendringer, timekort, tidsplaner';
_fc_props.wash = 'Vask';
_fc_props.filter_options = 'Filteralternativer';
_fc_props.home_plants = 'Hjemmeplanter';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM kraftuttak krever at kraftuttakets sensortype stilles inn.';
_fc_props.invalid_io_box_mac_address = 'Ugyldig I/O-boks Mac-adresse';
_fc_props.access_denied = 'Ingen tilgang';
_fc_props.wash_events = 'Vask hendelser';
_fc_props.min_speed = 'Min hastighet';
_fc_props.temperature_change = 'Temperaturendring';
_fc_props.degrees_cap = 'grader';
_fc_props.washout_tracking = 'Sporing av utvasking';
_fc_props.approaching_wash = 'Nærmer seg vask';
_fc_props.arrival_wash = 'Ankomst Vask';
_fc_props.performing_wash = 'Utfører vask';
_fc_props.wash_complete = 'Vask ferdig';
_fc_props.must_be_positive_or_zero = 'Må være positivt eller null!';
_fc_props.min_speed_greater_than_max = 'Min hastighet må være mindre enn maks hastighet!';
_fc_props.clock_in = 'Sjekk inn';
_fc_props.mix_water_at_x_to_y_for_t = 'Bland vann ved {0} til {1} RPM i {2} omdreininger.';
_fc_props.discharge_water_for_x_revolutions = 'Slipp ut vann i {0} omdreininger.';
_fc_props.eod_drum_wash_complete = 'Trommevask komplett! <br> Stopp tromme med sonde på toppen.';
_fc_props.washing_complete = 'Guidet utvasking fullført';
_fc_props.eod_washout_no_data_available = 'Sensordata er utilgjengelig. Fullfør vaskeprosessen.';
_fc_props.eod_washout_no_data_available_title = 'Guidet utvasking utilgjengelig';
_fc_props.menu_reports_title_ticket_time_summary = 'Billetttidssammendrag';
_fc_props.menu_reports_description_ticket_time_summary = 'Se billetttidsinformasjon for en bestilling eller kjøretøy';
_fc_props.menu_reports_title_summary = 'Sammendrag';
_fc_props.menu_reports_title_ticket_summary = 'Billettsammendrag';
_fc_props.menu_reports_description_summary1 = 'Se et sammendrag av tid i hotspots';
_fc_props.menu_dispatch_title_main = 'Hoved';
_fc_props.menu_dispatch_title_login_registry = 'Logg inn register';
_fc_props.menu_reports_title_main = 'Hoved';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'Er du sikker på at du vil slette denne eksportdatatypen?';
_fc_props.dvir_status_paper_logs = 'Papirlogger';
_fc_props.menu_reports_title_billing_report = 'Faktureringsrapport';
_fc_props.menu_reports_description_billing_report = 'Faktureringsrapport';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'Status til billettstatus ved aktivering av arbeid';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'Status til billettstatus ved aktivering av arbeid (Standard \'False\')';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'Connex EID';
_fc_props.units_weight_pound_label = 'Pounds';
_fc_props.units_weight_kilogram_label = 'Kilogram';
_fc_props.settings_weight = 'Vekt';
_fc_props.units_pounds = 'Pounds';
_fc_props.units_kilograms = 'Kilogram';
_fc_props.geo_gate_edit_modal_description = 'Beskrivelse';
_fc_props.geo_gate_edit_modal_start_point = 'Startpunkt';
_fc_props.geo_gate_edit_modal_end_point = 'Sluttpunkt';
_fc_props.geo_gate_edit_modal_vehicle_types = 'Utstyrstype';
_fc_props.geo_gate_edit_modal_heading_south = 'Sør til nord';
_fc_props.geo_gate_edit_modal_heading_north = 'Nord til Sør';
_fc_props.geo_gate_edit_modal_driver_message = 'Drivermelding';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'Send melding';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'Utløpsdato Tid';
_fc_props.geo_gate_edit_modal_permanent = 'Fast';
_fc_props.geo_gate_edit_modal_critical = 'Kritisk';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Feil ved lagring av Geogate: Beskrivelsesfeltet må fylles ut.';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'Feil ved lagring av Geogate: Startpunkt-feltet må fylles ut.';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'Feil ved lagring av Geogate: Startpunkt-feltet inneholder ugyldig dataformat.';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'Feil ved lagring av Geogate: End Point-feltet inneholder ugyldig dataformat.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Feil ved lagring av Geogate: Utløpsdato Tidsfelt må fylles ut.';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Feil ved lagring av Geogate: Utløpsdato Tidsfelt inneholder ugyldig dataformat.';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'Feil ved lagring av Geogate: Nord til Sør - Sendingsmeldingsfeltet må fylles ut';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Feil ved lagring av Geogate: Ingen endringer oppdaget';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Feil ved lagring av Geogate: Utløpsdato Tid-feltet inneholder utløpt dato-klokkeslett';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Feil ved lagring av Geogate: Beskrivelsesfeltet må kun inneholde bokstaver og tall';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Feil ved lagring av Geogate: Utstyrstype-feltet må fylles ut';
_fc_props.geo_gate_table_message_label_north_to_south = 'Nord til Sør';
_fc_props.geo_gate_table_message_label_south_to_north = 'Sør til nord';
_fc_props.geo_gate_table_message_label_both = 'Både';
_fc_props.geo_gate_edit_modal_field_point_tip = 'Breddegrad lengdegrad';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'Hvis du merker av for dette, overstyres oppsettet for meldingsbegrensning for enhetsbevegelse';
_fc_props.geo_gate_edit_modal_seasonal = 'Sesongbestemt';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Feil ved lagring av Geogate: Sesongdatoer må fylles ut';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Feil ved lagring av Geogate: Sesongdato-felt inneholder ugyldig dataformat';
_fc_props.test_js_test_javascript = 'Test Javascript';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'Kilometer';
_fc_props.units_miles = 'Miles';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'Feil ved lagring av Geogate: Sør til nord - Førermeldingsfeltet må fylles ut';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'Feil ved lagring av Geogate: Sør til Nord - Sendingsmeldingsfeltet må fylles ut';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'Feil ved lagring av Geogate: Nord til Sør - Førermeldingsfeltet må fylles ut';
_fc_props.geo_gate_heading = 'Overskrift';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'Feil ved lagring av Geogate: End Point-feltet må fylles ut';
_fc_props.units_mile = 'Mile';
_fc_props.units_kilometer = 'Kilometer';
_fc_props.cloud_dvir__exit = 'Exit';
_fc_props.cloud_dvir__signature = 'Signatur';
_fc_props.cloud_dvir__inspection = 'Undersøkelse';
_fc_props.cloud_dvir__add_photos_notes = 'Legg til bilder/notater';
_fc_props.cloud_dvir__odometer_reading = 'Kilometerstand';
_fc_props.cloud_dvir__engine_hours = 'Motortimer';
_fc_props.cloud_dvir__truck_details = 'Lastebildetaljer';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'Bestem den generelle tilstanden til kjøretøyet';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'Fullt navn og signatur til sjåføren';
_fc_props.cloud_dvir__sign = 'Skilt';
_fc_props.cloud_dvir__driver_note_from = 'Førernotat fra';
_fc_props.cloud_dvir__add_notes = 'Legg til notater';
_fc_props.cloud_dvir__driver_notes = 'Drivernotater';
_fc_props.cloud_dvir__done = 'Ferdig';
_fc_props.cloud_dvir__needs_repair = 'Trenger reparasjon';
_fc_props.cloud_dvir__not_applicable = 'Ikke aktuelt';
_fc_props.cloud_dvir__review_and_sign = 'Gjennomgå og signer';
_fc_props.cloud_dvir__add_photos = 'Legg til bildet';
_fc_props.cloud_dvir__upload_photo_limit = 'Du kan laste opp opptil 3 bilder';
_fc_props.cloud_dvir__mismatch_title = 'Mismatch Alert';
_fc_props.cloud_dvir__ok = 'Ok';
_fc_props.cloud_dvir_template = 'Cloud DVIR-mal';
_fc_props.cloud_dvir__needs_review = 'DVIR trenger gjennomgang';
_fc_props.cloud_dvir__submitted = 'Sendt inn';
_fc_props.cloud_dvir__completed_by = 'Fullført av';
_fc_props.cloud_dvir__review_btn = 'Anmeldelse';
_fc_props.cloud_dvir__truck_number = 'Lastebil {0}';
_fc_props.cloud_dvir__pending_review = 'Avventer vurdering';
_fc_props.cloud_dvir__start_dvir = 'Start DVIR';
_fc_props.cloud_dvir__dvir_history = 'DVIR historie';
_fc_props.cloud_dvir__view_all = 'Se alt';
_fc_props.load_zone = 'Lastesone';
_fc_props.view_load_zones = 'Vis lastesoner';
_fc_props.edit_load_zone = 'Rediger lastesone';
_fc_props.create_a_load_zone = 'Opprett en lastesone';
_fc_props.load_zone_panel = 'Last sonepanel';
_fc_props.click_the_map_to_set_the_load_zone_center = 'Klikk på kartet for å angi lastesonesenter.';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'Denne inspeksjonen har defekter indikert, og du har valgt at dette kjøretøyet er trygt å bruke. Vennligst valider før du fortsetter';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'Denne inspeksjonen har ingen defekter indikert, og du har valgt at dette kjøretøyet er usikkert å bruke. Vennligst valider før du fortsetter.';
_fc_props._90_days = '90 dager';
_fc_props.cloud_dvir = 'Cloud DVIR';
_fc_props.cloud_dvir_details = 'Cloud DVIR-innleveringer';
_fc_props.show_load_zones = 'vis lastsoner';
_fc_props.hide_load_zones = 'skjule belastningssoner';
_fc_props.no = 'Nei';
_fc_props.cloud_dvir__reset = 'Nullstille';
_fc_props.cloud_dvir__clear = 'Klar';
_fc_props.mark_read = 'Merk Les';
_fc_props.mark_unread = 'Marker ulest';
_fc_props.read = 'Lese';
_fc_props.unread = 'Ulest';
_fc_props.air_sensor_serial_number = 'Luftsensor serienummer';
_fc_props.air_sensor_mac_address = 'Luftsensor MAC-adresse';
_fc_props.air_sensor_serial_number_invalid_format = 'Luftsensorens serienummer er ikke i gyldig format: (alfanumerisk og maksimalt 32 tegn med bindestreker)';
_fc_props.air_sensor_mac_address_invalid_format = 'Air Sensor MAC-adresse er ikke i gyldig hex-format som vist i plassholderen for feltet';
_fc_props.air_sensor_license = 'Luftsensor';
_fc_props.adjust_route_point = 'Juster rutepunkt';
_fc_props.route_point_panel = 'Rutepunktpanel';
_fc_props.route_point = 'Rutepunkt';
_fc_props.cloud_dvir__pre_trip = 'Før tur';
_fc_props.cloud_dvir__post_trip = 'Post tur';
_fc_props.cloud_dvir__other_insction_type = 'Annen';
_fc_props.cloud_dvir__dvir_completed = 'Fullført';
_fc_props.cloud_dvir__awaiting_mechanic = 'Avventende mekaniker';
_fc_props.cloud_dvir__awaiting_driver = 'Venter på påmelding av sjåfør';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'Kan ikke sjekke serienummeret til luftsensoren, kontakt support for ytterligere hjelp.';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'Dette luftsensorens serienummer finnes allerede, vennligst bruk et annet serienummer';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'Kan ikke sjekke luftsensorens MAC-adresse, vennligst kontakt kundestøtte for å få hjelp';
_fc_props.cloud_dvir__error_submit_dvir_title = 'Feil ved innsending av DVIR';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'Vær så snill, prøv på nytt';
_fc_props.ticket_integration_descr = 'Billettintegrering lar deg angi innstillinger for billettintegrering samt startskjerm / lasteskjerm / hardmontert skjerm';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR er sendt inn';
_fc_props.cloud_dvir__inspection_trip_type = 'Type inspeksjonsreise';
_fc_props.menu_dvir_title_mechanic = 'Mekaniker';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = 'Luftsensorfastvare';
_fc_props.when_no_active_ticket = 'Når ingen aktiv billett';
_fc_props.used_by = 'Brukt av';
_fc_props.fields = 'Enger';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'Er du sikker på at du vil slette billettoppsett "{0}"?';
_fc_props.menu_settings_title_ticket_layout = 'Billettoppsett';
_fc_props.add_ticket_layout = 'Legg til billettoppsett';
_fc_props.edit_ticket_layout = 'Rediger billettoppsett';
_fc_props.ticket_layout_edit_modal_name = 'Navn';
_fc_props.ticket_layout_edit_modal_default = 'Misligholde';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'Feil ved lagring av billettoppsett: Navnefeltet må fylles ut.';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'Feil ved lagring av billettoppsett: Navnefeltet må kun inneholde bokstaver og tall';
_fc_props.ticket_layout_edit_modal_fields = 'Enger';
_fc_props.ticket_layout_edit_modal_usage = 'Bruk';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'Billettoppsett er fjernet. Vennligst oppdater siden.';
_fc_props.more_lower_case = 'mer';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'Feil ved lagring av billettoppsett: Ett eller flere felt må velges.';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'Feil ved lagring av billettoppsett: En eller flere statuser må velges.';
_fc_props.cloud_dvir__ad_hoc = 'Ad Hoc';
_fc_props.radius_multiplier_should_be = 'Radiusmultiplikator skal være et flytende tall';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutter skal være et heltall.';
_fc_props.max_hotspot_unused_days_errs = 'Max Hotspot Unused Days bør være et heltall.';
_fc_props.begin_pour_air_content = 'Begynn med å helle luftinnhold';
_fc_props.arrival_air_content = 'Luftinnhold ved ankomst';
_fc_props.fully_mixed_air_content = 'Fullt blandet luftinnhold';
_fc_props.departure_air_content = 'Avgang Air Content';
_fc_props.popup_enabled = 'Popup aktivert';
_fc_props.popup_disabled = 'Popup deaktivert';
_fc_props.cloud_dvir__leave_confirmation_title = 'Advarsel';
_fc_props.cloud_dvir__leave_confirmation_description = 'Hvis du går ut av siden nå, vil alle endringer gå tapt.';
_fc_props.cloud_dvir__leave_confirmation_approve = 'Bekrefte';
_fc_props.cloud_dvir__leave_confirmation_abort = 'Avbryt';
_fc_props.air_bt_ratio = 'Luftsensormåling';
_fc_props.cloud_dvir__view_photos_notes = 'Se bilder/notater';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'Hvis du legger til statusen, opprettes en tom bruk i et annet billettoppsett. Vil du fortsette med tillegget?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'Kjøretøyet har ikke luftsensorlisens.';
_fc_props.air_sensor_installed = 'Luftsensor installert';
_fc_props.load_card = 'Last inn kort';
_fc_props.cloud_dvir__inspection_profile = 'Inspeksjonsprofil';
_fc_props.cloud_dvir__view_details = 'Vis detaljer';
_fc_props.cloud_dvir__certify_repairs = 'Sertifiser reparasjoner';
_fc_props.cloud_dvir__repairs_made = 'Reparasjoner utført';
_fc_props.cloud_dvir__repairs_not_necessary = 'Reparasjoner ikke nødvendig';
_fc_props.cloud_dvir__mechanic_note_from = 'Mekanikernotat fra';
_fc_props.cloud_dvir__mechanic_assignment = 'Mekanikeroppdrag';
_fc_props.cloud_dvir__mechanic_signature_description = 'Merk alle gjeldende elementer nedenfor og skilt som indikerer at kjøretøyet har blitt inspisert på riktig måte.';
_fc_props.cloud_dvir__condition_satisfactory_label = 'Tilstanden til dette kjøretøyet er tilfredsstillende';
_fc_props.cloud_dvir__defects_corrected_label = 'Mangler er rettet';
_fc_props.cloud_dvir__repair_not_required_label = 'Reparasjon er ikke nødvendig for sikker drift av kjøretøyet';
_fc_props.safety = 'Sikkerhet';
_fc_props.cloud_dvir__awaiting_sign = 'Venter på tegn';
_fc_props.cloud_dvir__dvir_review = 'DVIR anmeldelse';
_fc_props.settings.mobile_auto_start_navigation = 'Autostart navigasjon på mobil';
_fc_props.cloud_dvir__edit = 'Redigere';
_fc_props.delete_custom_label = 'Slett egendefinert etikett';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'Din versjon av TrackIt er utdatert og enkelte DVIR-funksjoner fungerer kanskje ikke som de skal. Oppdater TrackIt før du fortsetter.';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'Noe gikk galt under signaturopplasting. Vær så snill, prøv på nytt';
_fc_props.max_hotspot_unused_days_error = 'Max Hotspot Unused Days har et maksimum på 1000 dager.';
_fc_props.menu_dvir_title_admin_templates = 'Maler';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'Admin';
_fc_props.cloud_dvir__admin_templates = 'Maler';
_fc_props.form_name = 'Skjemanavn';
_fc_props.form_status = 'Skjemastatus';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'nedlasting';
_fc_props.cloud_dvir__option_view = 'Utsikt';
_fc_props.cloud_dvir__inspectortype_driver = 'Sjåfør';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'Se/rediger/opprett egendefinerte DVIR-skjemaer og innsendinger';
_fc_props.menu.dvir.description.mechanic = 'Se/rediger handlingsverdige DVIR-innleveringer';
_fc_props.not_in_use = 'Ikke i bruk';
_fc_props.logged_in = 'logget inn';
_fc_props.reviewing_driver = 'Gjennomgang av driver';
_fc_props.cloud_dvir__report_title = 'Inspeksjonsrapport for føreren';
_fc_props.cloud_dvir__report_signed = 'signert';
_fc_props.cloud_dvir__report_page_counter = 'Side {0} av {1}';
_fc_props.cloud_dvir__report_defects = 'Defekter';
_fc_props.cloud_dvir__report_comments = 'Kommentarer';
_fc_props.cloud_dvir__report_inspection_table_title = 'Kjøretøykontroll';
_fc_props.cloud_dvir__report_driver_table_title = 'Førerinformasjon';
_fc_props.cloud_dvir__report_carrier = 'Transportør';
_fc_props.cloud_dvir__report_truck = 'Lastebil';
_fc_props.cloud_dvir__report_location = 'plassering';
_fc_props.cloud_dvir__report_odometer = 'Kilometerteller';
_fc_props.cloud_dvir__report_hours = 'Timer';
_fc_props.cloud_dvir__report_no_comments = 'Ingen kommentarer';
_fc_props.include_unlicensed_vehicles = 'Inkluder ulisensierte kjøretøy';
_fc_props.cloud_dvir__search_by_form_name = 'Søk etter skjemanavn';
_fc_props.cloud_dvir__create_dvir = 'Lag en DVIR';
_fc_props.cloud_dvir__update_dvir = 'Oppdater DVIR';
_fc_props.cloud_dvir__form_name = 'Skjemanavn';
_fc_props.cloud_dvir__form_description = 'Skjemabeskrivelse';
_fc_props.cloud_dvir__form_status = 'Skjemastatus';
_fc_props.information = 'Informasjon';
_fc_props.cloud_dvir__search_categories = 'Søk kategorier';
_fc_props.cloud_dvir__create_new_category = 'Opprett ny kategori';
_fc_props.category_name = 'kategori navn';
_fc_props.sub_category = 'Underkategori';
_fc_props.sub_category_name = 'Navn på underkategori';
_fc_props.delete_category = 'Slett kategori';
_fc_props.cloud_dvir__dvir_information = 'DVIR-informasjon';
_fc_props.cloud_dvir__dvir_choose_categories = 'Velg kategorier';
_fc_props.cloud_dvir__create_category = 'Opprett kategori';
_fc_props.cloud_dvir__update_category = 'Oppdater kategori';
_fc_props.delete_route_point = 'Slett rutepunkt';
_fc_props.view_route_points = 'Se rutepunkter';
_fc_props.ignore_cancel_preload = 'Ignorer kanselleringen, du er forhåndslastet. Hjemmeløpet ditt vises snart';
_fc_props.cloud_dvir__assigned_mechanic = 'Tildelt mekaniker';
_fc_props.cloud_dvir__add_mechanic = 'Legg til en mekaniker';
_fc_props.contact_type = 'Kontakttype';
_fc_props.cloud_dvir__send_email_notifications = 'Send e-postvarsler';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'Feil ved henting av DVIR-historikk.';
_fc_props.cloud_dvir__error_processing_dvir_record = 'Feil ved henting av DVIR-post.';
_fc_props.cloud_dvir__error_saving_submission = 'Feil under lagring av innsending. Prøv igjen.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'Feil ved henting av liste over mekanikk.';
_fc_props.cloud_dvir__template_saved = 'Malen er lagret';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'Feil ved henting av liste over maler.';
_fc_props.cloud_dvir__error_saving_dvir_template = 'Feil under lagring av mal. Prøv igjen.';
_fc_props.dispatch_group = 'Sendegruppe';
_fc_props.save_adjust = 'Lagre og juster rutepunkt';
_fc_props.reset_route_point = 'Tilbakestill rutepunkt';
_fc_props.no_records_stub_adjust_filters = 'Prøv å justere søke- eller filterinnstillingene for å øke antallet resultater.';
_fc_props.no_dvir_records = 'Alle fanget opp på DVIRs!';
_fc_props.no_dvir_records_sub_title = 'Du har fått til mye';
_fc_props.settings.master_data_connex_x_api_key = 'Master Data Connex x-api-key';
_fc_props.slump_not_obtained_for_x = 'Ingen nedgangslesing oppnådd for: {0}';
_fc_props.please_select_a_sensor_status_type = 'Velg sensorstatustyper for å utløse dette varselet';
_fc_props.settings.master_data_connex_token = 'Master Data Connex Token';
_fc_props.error_validating_master_data_connex_credentials = 'Feil under validering av Master Data Connex-legitimasjon';
_fc_props.settings.master_data_connex_base_url = 'Master Data Connex Base URL';
_fc_props.settings.master_data_connex_exp_time = 'Master Data Connex Utløpstid';
_fc_props.download_template = 'Last ned mal';
_fc_props.menu_settings_title_dispatch_group = 'Sendegrupper';
_fc_props.settings.dispatch_groups = 'Sendegrupper';
_fc_props.dispatch_group_name = 'Navn på ekspedisjonsgruppe';
_fc_props.dispatch_group_plants = 'Send gruppeanlegg';
_fc_props.dispatch_group_plants_mapping_message = 'Angi hvilke anlegg som vil være en del av denne forsendelsesgruppen.';
_fc_props.dispatch_group_users_mapping_message = 'Angi hvilke brukere som vil være en del av denne utsendelsesgruppen.';
_fc_props.dispatch_group_plant_disassociation_message = 'Anlegget bør være under én ekspedisjonsgruppe om gangen';
_fc_props.dispatch_group_search_by_plant = 'Søk etter plantenavn';
_fc_props.dispatch_group_search_by_username = 'Søk etter brukernavn';
_fc_props.search_by_dispatch_group_name = 'Søk etter ekspedisjonsgruppenavn';
_fc_props.create_dispatch_group = 'Opprett en ekspedisjonsgruppe';
_fc_props.update_dispatch_group = 'Oppdater utsendelsesgruppe';
_fc_props.dispatch_group_saved = 'Sendegruppen er lagret';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'Sletting av denne ekspedisjonsgruppen vil opprette standard ekspedisjonsgrupper for de tilknyttede anleggene.';
_fc_props.error_saving_dispatch_group = 'Feil under lagring av ekspedisjonsgruppe. Prøv igjen.';
_fc_props.please_enter_a_valid_plant_num = 'Vennligst skriv inn et gyldig anleggsnummer.';
_fc_props.cloud_dvir__report_no_defects = 'Ingen defekter';
_fc_props.shoud_select_dispatch_group = 'Du må velge minst én ekspedisjonsgruppe';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'Kan ikke slette fordi denne sendingsgruppen inneholder brukere som kun er tilknyttet denne sendingsgruppen.';
_fc_props.cloud_dvir__username = 'Brukernavn';
_fc_props.cloud_dvir__password = 'Passord';
_fc_props.cloud_dvir__login = 'Logg Inn';
_fc_props.cloud_dvir__mechanic_sign_in = 'Mekaniker Logg på';
_fc_props.delete_dispatch_group = 'Slett ekspedisjonsgruppe';
_fc_props.cloud_dvir__driver = 'Sjåfør';
_fc_props.cloud_dvir__equipment_pound = 'Utstyr #';
_fc_props.cloud_dvir__mechanic = 'Mekaniker';
_fc_props.dispatch_group_user_only_unassociate_message = 'Kan ikke slette bruker fordi brukere må være tilknyttet minst én ekspedisjonsgruppe.';
_fc_props.cloud_dvir__time_period_filter_today = 'I dag';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'I går';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'Siste 7 dager';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'Siste 30 dager';
_fc_props.cloud_dvir__date_range_filter = 'Filtrer etter dato';
_fc_props.cloud_dvir__download = 'nedlasting';
_fc_props.dispatch_group_is_empty = 'Ekspedisjonsgruppen kan ikke være tom.';
_fc_props.dispatch_group_with_empty_plants = 'Minst ett anlegg må være tilknyttet ekspedisjonsgruppen.';
_fc_props.dispatch_group_with_empty_users = 'Minst én bruker må være tilknyttet ekspedisjonsgruppen.';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'Kan ikke slette ekspedisjonsgruppen da den inneholder brukere som kun er tilknyttet denne ekspedisjonsgruppen.';
_fc_props.dispatch_group_not_exists = 'Sendegruppe eksisterer ikke.';
_fc_props.dispatch_group_contains_one_plant = 'Kan ikke slette fordi denne forsendelsesgruppen bare inneholder én plante.';
_fc_props.dispatch_group_users = 'Send gruppebrukere';
_fc_props.cloud_dvir__error_retrieving_dvir = 'Feil ved henting av DVIR-post';
_fc_props.cloud_dvir__assign_mechanic = 'Tilordne mekaniker';
_fc_props.cloud_dvir__submit_review = 'Send inn anmeldelse';
_fc_props.error_deleting_dispatch_group = 'Feil ved sletting av ekspedisjonsgruppe.';
_fc_props.error_retrieving_dispatch_groups = 'Feil ved henting av liste over ekspedisjonsgrupper.';
_fc_props.cloud_dvir__undo_selection = 'Angre valg';
_fc_props.enable_master_data = 'Aktiver stamdata';
_fc_props.manually_sync = 'Synkroniser manuelt';
_fc_props.master_data_connex_token_expiration = 'Master Data Connex Token Expiration';
_fc_props.cloud_dvir__time_period_filter_custom = 'Tilpasset';
_fc_props.settings.zello_session_timeout_minutes = 'Zello-økttidsavbrudd (minutter)';
_fc_props.menu_settings_title_master_data = 'Stamdata';
_fc_props.settings.master_data = 'Stamdata';
_fc_props.error_retrieving_master_data = 'Feil ved henting av stamdata';
_fc_props.master_data_sync_completed = 'Synkronisering av masterdata er fullført';
_fc_props.master_data_successfully_updated = 'Data ble oppdatert';
_fc_props.master_data_failed_to_update = 'Kunne ikke lagre.';
_fc_props.master_data_sync_initiated = 'Synkronisering av masterdata startet';
_fc_props.master_data_sync_failed = 'Synkronisering av masterdata mislyktes';
_fc_props.enabled_excluding_origin_on_deliveries = 'Sant (unntatt opprinnelse på leveringsbilletter)';
_fc_props.enabled_including_origin_on_deliveries = 'Sant (inkludert opprinnelse på leveringsbilletter)';
_fc_props.cloud_dvir__i_will_decide_later = 'Jeg bestemmer meg senere';
_fc_props.returning = 'Returnerer';
_fc_props.leftover_concrete = 'Betongrester';
_fc_props.leftover_concrete_on_vehicle_x = 'Betongrester på kjøretøy {0}';
_fc_props.leftover_concrete_alert = 'Resterende betongvarsel';
_fc_props.wc_ratio_exceeded = 'W/C-forhold overskredet';
_fc_props.search_by_site_name = 'Søk etter nettstedsnavn';
_fc_props.sites = 'Nettsteder';
_fc_props.token_expiration = 'Token utløper';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API Key Management';
_fc_props.settings.connex_api_key_management = 'Connex API Key Management';
_fc_props.error_retrieving_connex_api_key_data = 'Feil ved henting av connex api-nøkkeldata';
_fc_props.qr_code = 'QR kode';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'Informere driver og grensesnitt når du går inn i feil anlegg';
_fc_props.enable_driver_message = 'Aktiver drivermelding';
_fc_props.enable_dispatch_message = 'Aktiver forsendelsesmelding';
_fc_props.enable_sending_message_to_interface = 'Aktiver sending av melding til grensesnitt';
_fc_props.ticket_qr_code = 'QR-kode for billett';
_fc_props.leftover_concrete_alert_description = 'Varsle når betongrester blir funnet etter påstøping.';
_fc_props.minimum_leftover_concrete_amount = 'Min. rest betongmengde';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'Feil ved validering av Master Data Connex-legitimasjon ikke autorisert';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'Feil under validering av Master Data Connex Credentials tillatelser';
_fc_props.error_validating_master_data_connex_url_not_found = 'Feil ved validering av hoveddataforbindelsesurl ikke funnet';
_fc_props.error_validating_master_data_connex_unknown_error = 'Feil ved validering av Master Data Connex Ukjent feil';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'Synkronisering av masterdata mislyktes Synkroniser kjøretøytype.';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'Synkronisering av masterdata mislyktes Synkroniser kjøretøy.';
_fc_props.master_data_sync_failed_sync_up_plant = 'Synkronisering av masterdata mislyktes Synkroniser anlegget.';
_fc_props.master_data_sync_failed_data_base_connection = 'Synkronisering av masterdata mislyktes i forbindelse med databasen.';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'Feil ved validering av hoveddata Connex Credentials Bærer Token-tillatelse er utilstrekkelig';
_fc_props.settings.back_date_arrive_job = 'Tilbake dato ankommer jobb';
_fc_props.device_notifications = 'Varsler';
_fc_props.loading_number = 'Laster nummer';
_fc_props.leftover_concrete_volume_temp_age = 'Resterende volum={0}, Temp={1}, Alder={2}';
_fc_props.total_ordered = 'Totalt bestilt antall';
_fc_props.total_ticketed = 'Levert Bestilt';
_fc_props.load_number = 'Last nummer';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'Minste betongrester må fylles ut og være større enn {0}';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'Feil ved validering av stamdata. Legitimasjonen kan ikke være den samme.';
_fc_props.use_aws_for_talk = 'Bruk AWS for Talk';
_fc_props.air_readings_not_supported = 'Luftavlesninger støttes ikke';
_fc_props.air_not_supported = 'Luft støttes ikke';
_fc_props.mix_not_supported = 'Blanding støttes ikke';
_fc_props.air_default_and_none_not_supported = 'Kan ikke velge standard og støttes ikke samtidig!';
_fc_props.notifications_enabled = 'Varsler aktivert';
_fc_props.prevent_status_change = 'Forhindre statusendring';
_fc_props.message_to_interface = 'Melding til grensesnitt';
_fc_props.prevent_wrong_plant = 'Forhindre feil plante';
_fc_props.previous_tickets = 'Tidligere billetter';
_fc_props.audit_ticket_number = 'Billettnummer';
_fc_props.audit_date_created = 'Date laget';
_fc_props.audit_vehicle_number = 'Kjøretøy nummer';
_fc_props.cloud_dvir__table_ok = 'Trygg å betjene';
_fc_props.cloud_dvir__table_needs_repair = 'Utrygt å betjene';
_fc_props.missing_air_calibration = 'Ingen luftkalibreringsdata';
_fc_props.reported_defects = 'Rapporterte mangler';
_fc_props.address_latitude = 'Adresse Latitude';
_fc_props.address_longitude = 'Adresse Lengdegrad';
_fc_props.routing_latitude = 'Ruting av breddegrad';
_fc_props.routing_longitude = 'Ruting Lengdegrad';
_fc_props.category_name_must_be_unique = 'Kategorinavnet må være unikt';
_fc_props.subcategory_name_must_be_unique = 'Navnet på underkategorien må være unikt';
_fc_props.menu_settings_tracking_device = 'Sporingsenhet';
_fc_props.menu_settings_title_tracking_device = 'Sporingsenhet';
_fc_props.menu_settings_title_personal_device_registration = 'Personlig enhetsregistrering';
_fc_props.registration_code = 'Registreringskode';
_fc_props.creation_date = 'Opprettelse';
_fc_props.expiration_date = 'Utløp';
_fc_props.trackitadmin_personal_device_registration = 'Personlig enhetsregistrering';
_fc_props.create_registration_code = 'Legg til registreringskode';
_fc_props.remove_registration_code = 'Fjern registreringskoden';
_fc_props.add_a_registration_code = 'Legg til en registreringskode';
_fc_props.select_number_of_registration_codes_to_generate = 'Velg antall registreringskoder du vil generere.';
_fc_props.registration_code_count = 'Antall registreringskoder';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'Er du sikker på at du vil slette den valgte registrerte koden?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'Er du sikker på at du vil slette de valgte registrerte kodene?';
_fc_props.add_personal_device = 'Legg til personlig enhet';
_fc_props.menu_settings_title_personal_device = 'Personlig enhet';
_fc_props.cloud_dvir__approval_critical_alert = 'Kjøretøyet har en kritisk defekt. Kan ikke merkes som sikker å betjene.';
_fc_props.export_registration_codes = 'Eksporter registreringskoder';
_fc_props.download_format = 'Last ned format';
_fc_props.specify_download_format_x = 'Angi nedlastingsformatet for å eksportere {0} registreringskoder';
_fc_props.personal_device_download = 'nedlasting';
_fc_props.add_personal_device_input_message = 'Skriv inn en personlig enhets-ID nedenfor. Utløp vil automatisk bli brukt etter lagring';
_fc_props.error_creating_personal_device = 'Feil ved opprettelse av en personlig enhet';
_fc_props.device_already_exists = 'Personlig enhet finnes allerede';
_fc_props.export = 'Eksport';
_fc_props.error_can_not_change_connex_eid = 'Kan ikke endre connex eid da den brukes for masterdata';
_fc_props.remove_personal_device = 'Fjern personlig enhet';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'Er du sikker på at du vil slette den valgte personlige enheten?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'Er du sikker på at du vil slette de valgte personlige enhetene?';
_fc_props.error_deleting_personal_device = 'Feil ved sletting av personlig enhet';
_fc_props.customerid = 'Kunde ID';
_fc_props.customername = 'Kundenavn';
_fc_props.ordernumber = 'Ordrenummer';
_fc_props.orderdescription = 'Bestillingsbeskrivelse';
_fc_props.locationid = 'Steds-ID';
_fc_props.locationdescription = 'Stedsbeskrivelse';
_fc_props.productid = 'Produkt ID';
_fc_props.productdescription = 'produktbeskrivelse';
_fc_props.vehicleid = 'Kjøretøy-ID';
_fc_props.vehicledescription = 'Kjøretøybeskrivelse';
_fc_props.ticketnumber = 'Billett #';
_fc_props.ticketdate = 'Billettdato/klokkeslett';
_fc_props.drivername = 'Drivernavn';
_fc_props.arriveregion = 'Ankomstregion (TIME)';
_fc_props.leaveregion = 'Forlate regionen (TIME)';
_fc_props.regionduration = 'Regionens varighet';
_fc_props.menu_reports_title_prevailing_wage = 'Rådende lønn';
_fc_props.slump_calibration_x_not_found_for_y = 'Slump Calibration "{0}" ikke funnet for billett {1}';
_fc_props.slump_calibration_not_found = 'Slumpkalibrering ikke funnet';
_fc_props.slump_calibration_not_found_description = 'Varsle når slumpkalibrering ikke ble funnet';
_fc_props.error_getting_personal_device_list = 'Feil ved henting av personlig enhetsliste';
_fc_props.batch_summary_date_range_note = '*MERK: Denne rapporten er begrenset til 3 dager hvis ingen filtre brukes. Datointervallet kan gå opptil 31 dager når det filtreres etter utstyr (maksimalt 10), anlegg (maksimalt 1), produkt (maksimalt 1) eller jobb (maksimalt 1)';
_fc_props.select_registration_code_expiration_time_days = 'Velg antall dager før registreringskodene skal utløpe, og klikk deretter på Lagre.';
_fc_props.add_personal_device_expiration = 'Utløp (i dager)';
_fc_props.cloud_dvir__vehicle_no = 'Kjøretøy nr';
_fc_props.cloud_dvir__trailer_no = 'Trailer nr';
_fc_props.cloud_dvir__vehicle = 'Kjøretøy';
_fc_props.cloud_dvir__trailer = 'Tilhenger';
_fc_props.cloud_dvir__truck_number_label = 'Lastebilnummer';
_fc_props.cloud_dvir__trailer_number = 'Trailernummer';
_fc_props.cloud_dvir__has_trailer = 'Kjører ikke med tilhenger';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'Skriv inn trailernummeret ditt her';
_fc_props.work_order = 'Arbeidsordre';
_fc_props.unable_to_determine_slump = 'Kan ikke bestemme Slump';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'Bestem den generelle tilstanden til tilhengeren';
_fc_props.defaultTrailer = 'Standard trailer';
_fc_props.trailer_type_column_billable = 'Fakturerbar';
_fc_props.trailer_type_column_notes = 'Notater';
_fc_props.trailer_type_column_date = 'Dato';
_fc_props.trailer_type_column_order = 'Bestille';
_fc_props.trailer_type_column_ticket = 'Billett';
_fc_props.trailer_type_column_project = 'Prosjekt';
_fc_props.trailer_type_column_customer = 'Kunde';
_fc_props.trailer_type_column_location = 'Sted';
_fc_props.trailer_type_column_vehicle_type = 'Type kjøretøy';
_fc_props.trailer_type_column_product = 'Produkt';
_fc_props.trailer_type_action_rebill = 'Rebill';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'Synkronisering av masterdata mislyktes, ingen standard utstyrstype er angitt';
_fc_props.trailer_type = 'Tilhengertype';
_fc_props.trailer_type_is_required = 'Tilhengertype er påkrevd';
_fc_props.trailer_number_already_exists = 'Trailernummeret finnes allerede';
_fc_props.plate_number_already_exists = 'Platenummeret finnes allerede';
_fc_props.trailer_vin_already_exists = 'Trailer Vin eksisterer allerede';
_fc_props.trailer_does_not_exist = 'Trailer finnes ikke';
_fc_props.trailer_number_is_required = 'Tilhengernummer er påkrevd';
_fc_props.plate_number_is_required = 'Platenummer er påkrevd';
_fc_props.plate_number = 'Platenummer';
_fc_props.menu_settings_title_trailer = 'Tilhenger';
_fc_props.add_trailer = 'Legg til trailer';
_fc_props.edit_trailer = 'Rediger trailer';
_fc_props.menu_settings_title_equipment = 'Utstyr';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Ignorer nye billetter fra Command Cloud eldre enn X minutter';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'Ignorer nye billetter fra Command Cloud med utsendelsesdato-tid eldre enn X minutter (standard er tom og av)';
_fc_props.edit_load_card = 'Rediger Last inn kort';
_fc_props.default_layout = 'Standard oppsett';
_fc_props.load_card_empty_fields = 'Feil ved lagring av Last kort: Ett eller flere felt må velges.';
_fc_props.trailers = 'Tilhengere';
_fc_props.error_failed_to_save_data = 'Feil: Kunne ikke lagre data';
_fc_props.slump_increased_plasticizer_added_question = 'Hvor mye mykner ble tilsatt?';
_fc_props.add_trailer_type = 'Legg til trailertype';
_fc_props.menu_settings_title_trailer_type = 'Tilhengertype';
_fc_props.edit_trailer_type = 'Rediger trailertype';
_fc_props.single_use_codes = 'Engangskoder';
_fc_props.multiple_use_codes = 'Flerbrukskoder';
_fc_props.trailer_type_description_is_required = 'Tilhengertypebeskrivelse er nødvendig';
_fc_props.trailer_saved = 'Trailer lagret';
_fc_props.cloud_dvir__trailer_only_dvir = 'Kun tilhenger DVIR';
_fc_props.choose_file = 'Velg Fil';
_fc_props.no_file_chosen = 'Ingen fil valgt';
_fc_props.please_choose_file = 'Vennligst velg fil';
_fc_props.no_filters = 'Ingen filtre er valgt';
_fc_props.remaining_defects = 'Gjenværende defekter';
_fc_props.load_more = 'Last inn mer';
_fc_props.pagination_label = '{0}-{1} av {2}';
_fc_props.extend = 'Forlenge';
_fc_props.cloud_dvir__notes_or_photo_required = 'Enten notater eller et bilde kreves.';
_fc_props.no_default_plant_specified_failed_to_save = 'Ingen standardanlegg spesifisert.';
_fc_props.no_default_employee_type_specified_failed_to_save = 'Ingen standard ansatttype spesifisert.';
_fc_props.personal_device_registration_code_saved = 'Personlig enhetsregistreringskode lagret';
_fc_props.error_saving_personal_device_registration_code = 'Feil ved lagring av personlig enhetsregistreringskode. Prøv igjen.';
_fc_props.settings.master_data_sub_title = 'Sett opp en masterdataintegrasjon mellom TrackIt og Connex';
_fc_props.connex_authentication = '{0} Autentisering';
_fc_props.select_company = 'Velg Firma';
_fc_props.requirements = 'Krav';
_fc_props.default_plant_type = 'Standard anlegg';
_fc_props.default_employee_type = 'Standard ansatttype';
_fc_props.default_equipment_type = 'Standard utstyrstype';
_fc_props.support_alphanumeric_trucks = 'Støtte alfanumeriske lastebiler';
_fc_props.no_default_equipment_type_specified_failed_to_save = 'Ingen standard utstyrstype spesifisert.';
_fc_props.air_pau_battery_low_message = 'Air PAU Batteri lavt varsel';
_fc_props.air_pau_battery_low = 'Luft PAU-batteri lavt';
_fc_props.alert_when_air_pau_battery_low = 'Varsle når Air PAU-batteriet er lavt';
_fc_props.air_pau_battery_low_alert = 'Varsel om lavt luftbatteri';
_fc_props.sensor_unable_to_determine_slump = 'Sensor kan ikke bestemme slump';
_fc_props.menu_settings_title_export = 'Eksport';
_fc_props.compare_company = 'Sammenlign med et annet selskap';
_fc_props.site_settings = 'Nettstedinnstillinger';
_fc_props.permission_type = 'Tillatelsestype';
_fc_props.ticket_integration = 'Billettintegrering';
_fc_props.select_one_or_more_settings = 'Velg én eller flere innstillinger';
_fc_props.export_tooltip = 'Dette alternativet er kun tilgjengelig hvis du tilhører mer enn ett selskap.';
_fc_props.select_company_error = 'Velg et selskap';
_fc_props.audible = 'Hørbar';
_fc_props.deleted_master_data_successfully = 'Slettet masterdata vellykket';
_fc_props.cloud_dvir__units_miles_abbr = 'MI';
_fc_props.cloud_dvir__units_kilometers_abbr = 'KM';

export default _fc_props;
