const _fc_props = {};
_fc_props.ante_meridiem = '午前';
_fc_props.post_meridiem = '午後';
_fc_props.retry = 'リトライ';
_fc_props.default = '規定値';
_fc_props.origin = '出発地点';
_fc_props.loading = '読み込み中';
_fc_props.list = 'リスト';
_fc_props.send = '送信';
_fc_props.error = 'エラー';
_fc_props.save = '保存';
_fc_props.delete = '削除';
_fc_props.message = 'メッセージ';
_fc_props.logout = 'ログアウト';
_fc_props.add = '追加';
_fc_props.remove = '削除';
_fc_props.done = '完了';
_fc_props.other = 'その他';
_fc_props.interval = '間隔';
_fc_props.time = '時間';
_fc_props.speed = '速度';
_fc_props.longitude = '経度';
_fc_props.latitude = '緯度';
_fc_props.x_minutes = '{0}分';
_fc_props.general = '一般';
_fc_props.messages = 'メッセージ';
_fc_props.gps_fixes = 'GPS履歴';
_fc_props.device_id = 'デバイスID';
_fc_props.vehicle = '車両';
_fc_props.employee = '従業員';
_fc_props.version = 'バージョン';
_fc_props.statuses = 'ステータス';
_fc_props.edit = '編集';
_fc_props.cancel = 'キャンセル';
_fc_props.yes = 'はい';
_fc_props.none = 'なし';
_fc_props.never = '絶対に';
_fc_props.name = '名前';
_fc_props.true = 'はい';
_fc_props.status = 'ステータス';
_fc_props.distance = '距離';
_fc_props.ok = 'OK';
_fc_props.login = 'ログイン';
_fc_props.next = '次';
_fc_props.menu = 'メニュー';
_fc_props.not_available = 'N / A';
_fc_props.back = '戻る';
_fc_props.select = '選択';
_fc_props.dispatch = '配送・出荷';
_fc_props.messaging = 'メッセージを送信中';
_fc_props.plants = '工場';
_fc_props.last_plants = '前回の出荷工場';
_fc_props.vehicle_types = '車両タイプ';
_fc_props.employee_types = '従業員タイプ';
_fc_props.employee_groups = '従業員グループ';
_fc_props.equipment_groups = '車両タイプ';
_fc_props.job = 'ジョブ';
_fc_props.order = '指示';
_fc_props.ticket = '伝票#';
_fc_props.small = '小';
_fc_props.medium = '中';
_fc_props.large = '大';
_fc_props.reports = 'レポート';
_fc_props.payroll = '従業員管理';
_fc_props.support = 'サポート';
_fc_props.username = 'ユーザー名';
_fc_props.all = 'すべて';
_fc_props.question = '質問';
_fc_props.select_employee = '従業員を選択';
_fc_props.phone = '電話';
_fc_props.january = '1月';
_fc_props.february = '2月';
_fc_props.march = '3月';
_fc_props.april = '4月';
_fc_props.may = '5月';
_fc_props.june = '6月';
_fc_props.july = '7月';
_fc_props.august = '8月';
_fc_props.september = '9月';
_fc_props.october = '10月';
_fc_props.november = '11月';
_fc_props.december = '12月';
_fc_props.are_you_sure_you_want_to_delete = '本当に消去しますか';
_fc_props.unknown = '不明';
_fc_props.select_all = 'すべて選択';
_fc_props.equipment = '機器・車両';
_fc_props.region = '指定領域';
_fc_props.no_data = 'データなし';
_fc_props.hotspot = 'ホットスポット';
_fc_props.address = '住所';
_fc_props.retrieving_address = '住所の取得中...';
_fc_props.address_not_found = 'アドレスが見つかりません';
_fc_props.active_time = '稼働時間';
_fc_props.destination_time = '目的地までの所要時間';
_fc_props.complete_time = '経過時間';
_fc_props.status_report = 'ステータスレポート';
_fc_props.export_report = 'エクスポートレポート';
_fc_props.download_report = 'ダウンロードレポート';
_fc_props.view_report = 'レポート確認';
_fc_props.duration = '所要';
_fc_props.min = '分';
_fc_props.start_date_time = '開始日時';
_fc_props.select_start_date = '開始日を選択';
_fc_props.end_date_time = '終了日時';
_fc_props.select_end_date = '終了日を選択';
_fc_props.trip_time = '移動時間';
_fc_props.travel_time = '移動時間';
_fc_props.description = '説明';
_fc_props.map_controls = '地図設定';
_fc_props.road = '地図';
_fc_props.aerial = '航空写真';
_fc_props.hybrid = 'ラベルを表示';
_fc_props.trip = '運行';
_fc_props.map = '地図';
_fc_props.plant = '工場';
_fc_props.select_date = '日付を選択';
_fc_props.apply = '適用';
_fc_props.save_settings = '設定を保存する';
_fc_props.false = 'エラー';
_fc_props.delete_confirmation = '削除の確認';
_fc_props.last_name = '苗字';
_fc_props.none_selected = '未選択';
_fc_props.start_date = '開始日';
_fc_props.group_by = 'グループ化する';
_fc_props.equipment_employee = '機器・車両/従業員';
_fc_props.inactive = '休止';
_fc_props.active = '稼働';
_fc_props.note_checking_may_affect_performance = '注：チェックはパフォーマンスに影響する可能性があります';
_fc_props.show_distancefuel_used = '使用距離/燃料を表示する';
_fc_props.mins = '分';
_fc_props.threshold_time = 'しきい値（時間）';
_fc_props.include_voided_tickets = '無効な伝票を含める';
_fc_props.note_all_times_listed_are_in_minutes = '注：記載されているすべての時間は分単位です';
_fc_props.please_select_a_job_order_or_equipment = 'ジョブ、注文、工場、設備、または従業員を選択してください';
_fc_props.device = 'デバイス';
_fc_props.select_equipment = '機器・車両の選択';
_fc_props.all_equipment_types = 'すべての機器・車両タイプ';
_fc_props.summary = 'サマリー';
_fc_props.units = {};
_fc_props.units.liters = 'リットル';
_fc_props.type = 'タイプ';
_fc_props.no_data_available = 'データなし';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = '指定領域による距離と燃料消費量';
_fc_props.menu_settings_title_settings = '設定';
_fc_props.menu_settings_title_alerts = 'アラート';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = '装置';
_fc_props.menu_settings_title_hotspots_regions = 'ホットスポットと指定領域';
_fc_props.days = '{0}日';
_fc_props.home = 'ホーム';
_fc_props.find = '検索';
_fc_props.close_this_panel = 'このパネルを閉じる';
_fc_props.filters = 'フィルター';
_fc_props.start = '開始';
_fc_props.end = '終了';
_fc_props.location_details = '所在地の詳細';
_fc_props.first_name = '名前（名）';
_fc_props.material_per_hour = '輸送品/時間';
_fc_props.equipment_pound = '機器・車両#';
_fc_props.equipment_type = '機器・車両のタイプ';
_fc_props.options = 'オプション';
_fc_props.zoom_on_cursor_position = 'カーソル位置を拡大';
_fc_props.display_equipment_identifier = '車両番号を表示する';
_fc_props.view_equipment = '機器・車両を表示する';
_fc_props.view_hotspots = 'ホットスポットを表示する';
_fc_props.view_regions = '指定地域を表示する';
_fc_props.use_standard_filters = '標準フィルタを使用する';
_fc_props.home_plant = '所属工場';
_fc_props.last_plant = '前回の出荷工場';
_fc_props.equip_types = '車両タイプ';
_fc_props.equip_groups = '車両グループ';
_fc_props.orders = '指示';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = '車両フィルターを使用する';
_fc_props.all_selected = 'すべて選択';
_fc_props.of = 'の';
_fc_props.your_map_session_has_expired = 'マップセッションの有効期限が切れました。ページを更新してください';
_fc_props.equipment_short = '機器/車両';
_fc_props.job_info_for = 'Job情報　';
_fc_props.order_info_for = '指示情報';
_fc_props.ticket_info_for_vehicle = '車両の伝票情報';
_fc_props.inbox = '受信ボックス';
_fc_props.outbox = '送信ボックス';
_fc_props.alerts = '警告';
_fc_props.critical = '重大な';
_fc_props.important = '重要な';
_fc_props.informational = '情報表示';
_fc_props.view_routes = 'ルートを表示します';
_fc_props.hours_size = '時間のサイズ';
_fc_props.used_count = '使用回数';
_fc_props.last_login = '前回のログイン';
_fc_props.last_vehicle = '前回の車両';
_fc_props.regions = '指定領域';
_fc_props.driver = 'ドライバー';
_fc_props.js_delete = '削除';
_fc_props.move = '移動';
_fc_props.edit_hotspot = 'ホットスポットの編集';
_fc_props.adjust_shape = '形状の調整';
_fc_props.edit_region = '指定領域の編集';
_fc_props.enter_a_start_address = '出発地点（住所）を入力してください';
_fc_props.select_a_start_hotspot = '出発地点（ホットスポット）を入力してください';
_fc_props.failed_to_find_start_hotspot = '出発地点（ホットスポット）が見つかりませんでした';
_fc_props.invalid_latitudelongitude = '緯度/経度が無効です';
_fc_props.enter_an_end_address = '到着地点（住所）を入力してください';
_fc_props.select_an_end_hotspot = '到着地点（ホットスポット）を入力してください';
_fc_props.failed_to_find_end_hotspot = '到着地点（ホットスポット）が見つかりませんでした';
_fc_props.print = '印刷';
_fc_props.these_directions_are_for_informational_purposes = 'これらの結果は交通状況やその他の理由により、実際の状況と異なる場合があります';
_fc_props.enter_an_address = '住所を入力してください';
_fc_props.select_a_hotspot = 'ホットスポットを選択してください';
_fc_props.failed_to_find_hotspot = 'ホットスポットが見つかりませんでした';
_fc_props.select_a_vehicle = '車両を選択してください';
_fc_props.failed_to_find_vehicle = '車両が見つかりませんでした';
_fc_props.failed_to_find_address = '住所が見つかりませんでした';
_fc_props.failed_to_find_vehicles = '検索条件では車両がみつかりませんでした';
_fc_props.get_address = '住所を取得';
_fc_props.results_for = 'の結果';
_fc_props.error_retrieving_results = '結果取得エラー';
_fc_props.no_results_found = '結果が見つかりませんでした';
_fc_props.address_is_required = '住所は必須です';
_fc_props.city_is_required = '市町村は必須です';
_fc_props.set_location = '場所を設定する';
_fc_props.set_area_of_interest = '関心領域を設定する';
_fc_props.center_map_here = 'マップ中心点にする';
_fc_props.zoom_to_street_level = '詳細表示';
_fc_props.set_start = '開始設定';
_fc_props.set_end = '終了設定';
_fc_props.delete_node = 'ノードの削除';
_fc_props.create_a_hotspot = 'ホットスポットを作成する';
_fc_props.create_a_region = '指定領域を作成する';
_fc_props.toggle_shapes = 'ホットスポット/指定領域の表示有無';
_fc_props.refresh_map = '位置情報の更新';
_fc_props.more_options = 'その他の設定';
_fc_props.recenter_map = '地図の中心点を戻す';
_fc_props.zoom_to_vehicle = '車両にズーム';
_fc_props.apply_filters = 'フィルタを適用する';
_fc_props.grid_view = 'グリッド表示';
_fc_props.list_view = 'リスト表示';
_fc_props.click_to_sort = 'クリックして並べ替える';
_fc_props.message_history = 'メッセージ履歴';
_fc_props.equipment_types = '機器・車両タイプ';
_fc_props.send_message = 'メッセージを送る';
_fc_props.last_load = '前回の積載';
_fc_props.last_load_on = '前回の積載';
_fc_props.manual_logout = '手動ログアウト';
_fc_props.manual_login = '手動ログイン';
_fc_props.no_employees_logged_in = '従業員が誰もログインしていません';
_fc_props.select_phone = '携帯端末を選択';
_fc_props.you_must_select_a_phone = '携帯端末、機器・車両と従業員を選択してください';
_fc_props.are_you_sure_you_want_to_logout = '本当にログアウトしますか？';
_fc_props.fix_time = '時刻の修正';
_fc_props.to_next_fix = '次の修正まで';
_fc_props.minutes_to_next_fix = '次の修正までの時間';
_fc_props.liters = 'リットル';
_fc_props.destination = '目的地';
_fc_props.return = '帰路';
_fc_props.product = '製品';
_fc_props.no_records_found = '記録がみつかりませんでした';
_fc_props.vehicle_type = '車両タイプ';
_fc_props.view_in_map = '地図上にすべてを表示します';
_fc_props.uploads = 'アップロード';
_fc_props.upload_csv_file = 'CSVファイルをアップロード';
_fc_props.permanent = '設定を記憶する';
_fc_props.expire_time = '有効期限';
_fc_props.please_select_x_for_tickets_marked_active = '有効と表示された伝票は{0}を選択してください';
_fc_props.multiple_tickets_marked_active_for_same_x = '{0}と同じ有効と表示された複数の伝票';
_fc_props.failure_connecting_to_servlet = 'サーブレットへ接続できませんでした';
_fc_props.status_time_summary = 'ステータス経過時間のサマリー';
_fc_props.view_in_map_one = '地図を表示';
_fc_props.question_report = '質問レポート';
_fc_props.end_date = '終了日';
_fc_props.trailer_number = 'トレーラー番号';
_fc_props.region_panel = '指定領域設定パネル';
_fc_props.hotspot_panel = 'ホットスポット設定パネル';
_fc_props.shape = '形状';
_fc_props.circle = '円';
_fc_props.polygon = '自由設定';
_fc_props.click_the_map_to_set_the_location = '地図をクリックして目的の場所を設定します。';
_fc_props.click_the_map_to_set_the_hotspot_center = '地図をクリックして、ホットスポットの中心を設定します';
_fc_props.click_the_map_to_set_the_region_center = '地図をクリックして、指定領域の中心を設定します';
_fc_props.click_and_drag_points = 'ポイントをドラッグし、領域の形状を編集します。変更を保存するには「保存」をクリックします';
_fc_props.size = 'サイズ';
_fc_props.are_you_sure_you_want_to_move_this = '移動しますか？';
_fc_props.are_you_sure_you_want_to_delete_this = '削除しますか？';
_fc_props.please_enter_a_valid_size = '有効なサイズを入力してください';
_fc_props.failed_to_save = '保存できませんでした';
_fc_props.failed_to_move = '移動できませんでした';
_fc_props.failed_to_delete = '削除できませんでした';
_fc_props.x_hours = '{0}時間';
_fc_props.hide_hotspots = 'ホットスポットを隠す';
_fc_props.show_regions = '指定領域を表示する';
_fc_props.show_hotspots = 'ホットスポットを表示する';
_fc_props.hide_regions = '指定領域を隠す';
_fc_props.numeric = '数字選択';
_fc_props.message_type = 'メッセージタイプ';
_fc_props.text = 'テキスト';
_fc_props.yes_no = 'はい/いいえ';
_fc_props.responses = '応答';
_fc_props.disabled = '無効';
_fc_props.group_name = 'グループ名';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = '\'{0}\'形式を削除してもよろしいですか？';
_fc_props.phone_number = '電話番号';
_fc_props.interface_pound = 'インターフェイス＃';
_fc_props.field = '入力欄';
_fc_props.default_label = 'デフォルトのラベル';
_fc_props.custom_label = '任意設定ラベル';
_fc_props.you_must_enter_a_label = 'ラベルを入力してください';
_fc_props.enabled = '有効';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = '伝票の統合';
_fc_props.settings.geofence_off_open_hotspots = 'オープンホットスポットのジオフェンスをオフにする';
_fc_props.settings.associate_open_hotspots_wticket = 'オープンホットスポットと伝票の関連付け';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'ドライバーの伝票に関連付けられているホットスポット、またはオープンホットスポットのジオフェンシングを許可する';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = '車両が領域に入場した際にオープンホットスポットと伝票を関連付けるかどうか';
_fc_props.settings.void_ticket_without_destination = '目的地のない伝票の無効化';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = '別の伝票が有効な間に新たに伝票が受信された場合や、有効な伝票であっても、目的地の記載がない場合、その伝票は無効になります';
_fc_props.settings.autocreate_hotspots = 'ホットスポットの自動作成';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = '伝票が受信されたときに目的地住所からホットスポットを自動的に作成するかどうか';
_fc_props.settings.autoactivate_ticket_upon_receive = '受信時に伝票を自動有効化する';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'TrackItが受信した際に伝票は有効になっていますか？（デフォルトは \'はい\'）';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = '受信時に有効な伝票を自動的に閉じる';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = '現在有効な伝票が、有効になってから自動クローズするまでの時間（デフォルトは \'\'）';
_fc_props.settings.ticket_max_activate_age_minutes = '伝票の最大有効時間（分）';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'TrackItで伝票を有効化する際の伝票有効時間（分）（デフォルトは"）';
_fc_props.settings.max_hotspot_unused_days = '最大ホットスポット未使用日数';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = '使用されていないホットスポットが再使用できる最大日数を設定します';
_fc_props.settings.radius_multiplier = '半径の大きさ';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = '配送・出荷システムから伝票を受け取った際に、半径を何倍に設定しますか';
_fc_props.settings.ticket_message_date_format = '伝票メッセージの日付形式';
_fc_props.am = '午前';
_fc_props.pm = '午後';
_fc_props.settings.ticket_message = '伝票メッセージ';
_fc_props.settings.add_ticket_element = '伝票の構成を追加する';
_fc_props.driver_performance = 'ドライバーのパフォーマンス';
_fc_props.update_failed_can_not_connect = '更新に失敗しました：サーブレットに接続できません';
_fc_props.unknown_error = '不明なエラー';
_fc_props.selected = '選択済';
_fc_props.web_service = 'ウェブサービス';
_fc_props.error_processing_request = 'リクエスト処理エラー';
_fc_props.email_address = 'Eメールアドレス';
_fc_props.id = 'ID';
_fc_props.last_used = '前回（最後）に使用された';
_fc_props.error_invalid_vehiclenum = 'エラー無効車両番号';
_fc_props.error_vehicle_is_not_logged_in = 'エラー：車両がログインしていません';
_fc_props.users = 'ユーザー';
_fc_props.you_dont_have_access_to_this_page = 'このページを表示するためのアクセス権がありません。アクセスについては、システム管理者に連絡してください。';
_fc_props.previous_week = '前週';
_fc_props.status_breakdown = 'ステータスの内訳';
_fc_props.select_plant_or_employee_group = '工場、または従業員グループのいずれかを選択してください';
_fc_props.cannot_select_plant_and_employee_group = '工場と従業員グループの両方を選択する事はできません';
_fc_props.odometer_must_be_a_number = '運行開始時の走行距離計は数字を入力してください';
_fc_props.ending_odometer_must_be_a_number = '運行終了時の走行距離計は数字を入力してください';
_fc_props.beginning_hour_meter_must_be_a_number = '運行開始時のアワーメーターは数字を入力してください';
_fc_props.ending_hour_meter_must_be_a_number = '運行終了時のアワーメーターは数字を入力してください';
_fc_props.error_loading_status_change_page = 'ステ―タス変更ページの読み込みエラーです';
_fc_props.x_is_an_invalid_time_format = '{0}は無効な時刻形式です.YYYY-MM-DD HH：MM：SS';
_fc_props.x_for_schedule_login = '{0}の予定ログイン時間';
_fc_props.x_for_schedule_logout = '{0}の予定ログアウト時間';
_fc_props.x_for_actual_login = '{0}の実際のログイン時間';
_fc_props.x_for_actual_logout = '{0}の実際のログアウト時間';
_fc_props.by_refreshing_the_time_card_detail = 'タイムカードの詳細を更新すると、すべての変更が失われます。続行しますか？';
_fc_props.are_you_sure_you_want_to_delete_0 = '{0}を削除しますか？';
_fc_props.settings.command_mobile_ticket_url = 'コマンドモバイルチケットのURL';
_fc_props.year = '年式';
_fc_props.make = 'メーカー';
_fc_props.model = 'モデル型式';
_fc_props.settings.command_mobile_ticket_password = 'コマンドモバイルチケットパスワード';
_fc_props.settings.command_mobile_ticket_auth_token = 'コマンドモバイルチケットの認証トークン';
_fc_props.target = 'ターゲット';
_fc_props.number_of_digits = '数字の桁数';
_fc_props.number_of_integer_digits = '整数桁数';
_fc_props.number_of_fractional_digits = '小数点以下の桁数';
_fc_props.search = '検索';
_fc_props.settings.command_mobile_ticket_date_order = 'コマンドモバイルチケット日付オーダー';
_fc_props.settings.command_mobile_ticket_date_length = 'コマンドモバイルチケットの日付の長さ';
_fc_props.settings.command_mobile_ticket_date_separator = 'コマンドモバイルチケットの日付セパレータ';
_fc_props.settings.command_mobile_ticket_slash = '/（スラッシュ）';
_fc_props.settings.command_mobile_ticket_dash = ' - （ダッシュ）';
_fc_props.settings.command_mobile_ticket_period = '. (ピリオド)';
_fc_props.settings.command_mobile_ticket_space = '（スペース）';
_fc_props.settings.command_mobile_ticket_time_length = 'コマンドモバイルチケットの時間長';
_fc_props.settings.command_mobile_ticket_time_separator = 'コマンドモバイルチケットのタイムセパレータ';
_fc_props.settings.command_mobile_ticket_colon = ':(コロン）';
_fc_props.settings.command_mobile_ticket_time_format = 'コマンドモバイルチケットの時刻表示形式';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'インターフェイス番号を使用する';
_fc_props.mechanic = 'メカニック';
_fc_props.settings.command_mobile_ticket_poll_interval = 'ポーリング間隔（秒）';
_fc_props.driving_time = '運転時間';
_fc_props.find_near_plants = '近くの工場を検索';
_fc_props.please_create_hot_spot_first = '最初にホットスポットを作成してください';
_fc_props.please_enter_hot_spot_description = 'ホットスポットの詳細を入力してください';
_fc_props.please_select_a_hot_spot_type = 'ホットスポットタイプを選択してください';
_fc_props.settings.command_mobile_ticket_send_driver_email = '電子メールをドライバーに送信';
_fc_props.directions_not_found = '住所：{0}が無効です。方角が見つかりません';
_fc_props.ws_user = 'ユーザー';
_fc_props.ws_service = '勤務時間';
_fc_props.ws_method = '方法';
_fc_props.ws_params = 'パラメーター';
_fc_props.units_kilometers_abbr = 'Km';
_fc_props.units_miles_abbr = 'Mile';
_fc_props.origin_and_destination = '出発地と目的地';
_fc_props.liter_abbr = 'L';
_fc_props.help = 'ヘルプ';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'Android他の従業員番号を使用';
_fc_props.settings.command_mobile_ticket_android_url = 'AndroidモバイルチケットのURL';
_fc_props.settings.command_mobile_ticket_android_secret = 'Androidモバイルチケットのシークレット';
_fc_props.employee_x_has_no_logout_on_y = '従業員{0}は、{1}に開始した業務シフトから退勤（ログアウト）していません';
_fc_props.phone_number_has_duplicates = '電話番号が重複しています';
_fc_props.ounce = 'オンス';
_fc_props.water_extra = '追加された水量';
_fc_props.always = '常に';
_fc_props.work_types_must_be_unique = '仕事の種類は１つだけ選択してください';
_fc_props.manufacturer = 'メーカー';
_fc_props.need_to_enter_seconds = '秒を入力してください';
_fc_props.copy = 'コピー';
_fc_props.create_in_existing_required = '既存の中に作成する場合は移行ホットスポット作成を選択してください';
_fc_props.break = '休憩';
_fc_props.add_export_data_type = 'データ出力タイプを追加';
_fc_props.edit_export_data_type = 'データ出力タイプを編集';
_fc_props.delete_export_data_type = 'データ出力タイプを削除';
_fc_props.export_data_type = 'データ出力タイプ';
_fc_props.error_updating_export_data_type = 'データ出力タイプの更新中にエラーが発生しました';
_fc_props.settings.address_key = '住所キー';
_fc_props.settings.address_key_hover = '住所キーオプション';
_fc_props.settings.address_key_options = '住所キーオプション';
_fc_props.more_filter_options = 'その他のフィルタオプション';
_fc_props.unauthorized_mapit_app = 'このMapItは承認されていません。今すぐアップデートしてください';
_fc_props.zello_timeout = 'Zello接続中にタイムアウト発生';
_fc_props.user_agreement_text = 'ログインすると、に同意する事になります。';
_fc_props.user_agreement = 'ユーザー規約';
_fc_props.upload = 'アップロード';
_fc_props.x_mins_left = '{0}分（秒）';
_fc_props.continuous_location_tracking_colon = '継続的な位置追跡：';
_fc_props.hired_hauler_cannot_register_device = 'Hired Haulerは新しいデバイスを登録できません';
_fc_props.timecard_does_not_contain_shift = '従業員のタイムカードには、指定されたシフト（タイムカード情報）が含まれていません';
_fc_props.timecard_not_found = '従業員のタイムカードが見つかりません';
_fc_props.vehicle_must_be_assigned_for_clt = '車両は場所を追跡するために割り当てられなければなりません。';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = '車両が割り当てられていないため、継続的な追跡は無効になります。';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'M';
_fc_props.error_lost_edc_connection = 'EDCとの接続が切断されました。お使いのWiFiおよび/またはBluetoothが有効になっているか確認してください';
_fc_props.acknowledge_all = 'すべてを肯定する';
_fc_props.expand = '展開する';
_fc_props.collapse = '崩壊';
_fc_props.expand_all = 'すべて展開';
_fc_props.collapse_all = 'すべてを折りたたむ';
_fc_props.last_update = '最後の更新';
_fc_props.acknowledge_alert = 'アラートを確認する';
_fc_props.acknowledge_message = 'メッセージを確認する';
_fc_props.acknowledge_all_alerts = 'すべてのアラートを確認する';
_fc_props.acknowledge_all_messages = 'すべてのメッセージを確認する';
_fc_props.eta = 'ETA';
_fc_props.tolerance_must_be_a_valid_integer = '許容値は-99と99の間の有効な整数でなければなりません（両方とも含まれます）';
_fc_props.gps_permission_required = '必要なGPS権限';
_fc_props.permissions_required = '必要な権限';
_fc_props.change_user = 'ユーザーを変更';
_fc_props.account_not_registered_with_carrier = 'このアカウントは携帯電話会社に登録されていません';
_fc_props.slump_loss = 'スランプロス';
_fc_props.slump_loss_help = 'スランプロスヘルプ';
_fc_props.did_not_receive_any_edc_devices = 'EDCデバイスを受信しませんでした。';
_fc_props.edc_record_not_found = 'EDCレコードが見つかりませんでした';
_fc_props.disable_hotspot_prompt = 'ホットスポットを無効にするように求められたら、[OK]をタップしてください。';
_fc_props.edc_device_is_in_use = 'EDC {0}は使用中です。';
_fc_props.an_unknown_exception_has_occurred = '未知の例外が発生しました';
_fc_props.login_has_timed_out = 'ログインがタイムアウトしました';
_fc_props.use_paper_logs = '紙のログを使用する';
_fc_props.eld_login_failed = '営業時間ログインに失敗しました';
_fc_props.setup_time_off_type = 'タイムオフタイプを設定してください。';
_fc_props.view_load_properties = '荷重プロパティを表示する';
_fc_props.couldnt_download_edc_firmware = 'EDCファームウェアをダウンロードできませんでした。';
_fc_props.password_incorrect = '正しくないパスワード';
_fc_props.edc_configuration_password_prompt = 'このデバイスの状態により、設定するインストーラのパスワードを入力する必要があります。';
_fc_props.edc_configuration_check_failed = 'EDCサーバーレコードの検証に失敗しました。詳細については、エラーメッセージを確認してください。';
_fc_props.configuration_issues_prompt = '指定されたトラックの選択されたEDCを構成中に、次の問題が検出されました';
_fc_props.checking_password = 'パスワードを確認しています';
_fc_props.reset_password_instructions_sent = 'メールアドレス{0}に送信されたパスワードをリセットする手順。';
_fc_props.reset_password_failed = 'パスワードのリセットに失敗しました。再試行してください。';
_fc_props.forgot_password = 'パスワードをお忘れですか？';
_fc_props.enter_valid_email = 'メールアドレスが無効です。有効なメールアドレスを入力して、もう一度やり直してください。';
_fc_props.issues_while_updating_edc_record = 'EDCは正常に構成されました。ただし、TrackItサーバー上のEDCのレコードには、機能しない原因となる問題があります。サポートに連絡して情報を修正してください。';
_fc_props.sending = '送信...';
_fc_props.minimum_ratio = '最小比率';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'ドラムRPMが範囲外';
_fc_props.probe_failure = 'プローブ障害';
_fc_props.unknown_probe_data_failure = '不明なプローブデータの障害';
_fc_props.no_loaded_status_meaning = 'ロード済みステータスなし';
_fc_props.no_depart_status_meaning = '出発ステータスなし';
_fc_props.no_arrive_status_meaning = '到着ステータスなし';
_fc_props.no_begin_work_status_meaning = '作業開始ステータスなし';
_fc_props.no_fully_mixed_status_meaning = '完全な混合ステータスなし';
_fc_props.no_begin_work_or_at_job_status = '作業開始またはジョブ開始ステータスが存在しない';
_fc_props.driver_didnt_follow_procedure = 'ドライバーが手順に従わなかった（ドラムを減速させない）';
_fc_props.probe_malfunction = 'プローブの故障（内部故障など）';
_fc_props.no_data_from_probe = 'プローブからのデータなし（データ損失）';
_fc_props.missing_probe_calibration = 'プローブ校正データなし';
_fc_props.probe_pressure_not_in_range = 'プローブ圧力が範囲外';
_fc_props.arrival = '到着';
_fc_props.small_load = '小負荷';
_fc_props.dry_slump = 'ドライスランプ';
_fc_props.probe_remixing_turns = 'リミックス';
_fc_props.turns_needed_to_remix = '追加された水-追加の混合が必要';
_fc_props.probe_remixing_finished = 'リミックス完了';
_fc_props.probe_battery_low_alert = 'プローブバッテリー低下アラート';
_fc_props.probe_battery_low_message = 'プローブのバッテリー残量が少ない';
_fc_props.probe_battery_low = 'プローブのバッテリー残量が少ない';
_fc_props.alert_when_probe_battery_low = 'プローブのバッテリー残量が少なくなったときに警告する';
_fc_props.probe_sensor_fatal_failure = 'センサーの致命的な障害';
_fc_props.probe_thermistor_failure = 'サーミスタの故障';
_fc_props.probe_sensor_stuck_in_concrete = 'センサーがコンクリートに詰まっている';
_fc_props.probe_sensor_over_weight = '重量超過センサー';
_fc_props.probe_batteries_low = 'バッテリー残量が少ない';
_fc_props.no_probe_data = 'プローブデータなし：';
_fc_props.check_probe = 'プローブを確認';
_fc_props.service_probe = 'サービスプローブ';
_fc_props.below_x = '{0}以下';
_fc_props.above_x = '上記{0}';
_fc_props.probe_serial_number_invalid_format = 'プローブのシリアル番号は、フィールドのプレースホルダーに表示される有効な形式ではありません';
_fc_props.talk_dnd_permission_prompt = 'トークには許可しないでください。';
_fc_props.max = 'マックス';
_fc_props.status_changed_quickly = 'ステータスの変更が速すぎます';
_fc_props.is_empty = '空です';
_fc_props.slump_increased_plasticizer_question = '可塑剤は添加されましたか？';
_fc_props.slump_increased_water_added_question = '計量されていない水はどれくらい追加されましたか?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'プローブと配信サイクルの監視は、同じデバイスで一緒にサポートされていません。';
_fc_props.no_data_from_sensor = 'センサーからのデータなし（データ損失）';
_fc_props.dvir = 'DVIR';
_fc_props.system_type = 'システムタイプ';
_fc_props.magnet_count = '磁石数';
_fc_props.sensors_setup = 'センサーのセットアップ';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'DCMでは、I / OボックスのMACアドレス、ドラムタイプ、DCMタイプ、およびマグネットカウントを設定する必要があります。';
_fc_props.drs = 'DCM';
_fc_props.drs_ticketed_volume = '発券済みボリューム';
_fc_props.drs_current_volume = '現在のボリューム';
_fc_props.drs_current_water_cement_ratio = '現在の水/セメント比';
_fc_props.drs_target_water_cement_ratio = '目標水/セメント比';
_fc_props.drs_total_revolutions = '合計';
_fc_props.drs_current_volume_lp_small = '電流';
_fc_props.drum_revolutions = 'ドラム革命';
_fc_props.drs_total_revolutions_lp_small = '合計';
_fc_props.drs_charging_revolutions_lp_small = '充電';
_fc_props.drs_discharging_revolutions_lp_small = '放電中';
_fc_props.drs_target_wc_lp_small = 'ターゲットW / C';
_fc_props.drs_current_wc_lp_small = '現在のW / C';
_fc_props.drs_ticketed_volume_lp_small = '発券済み';
_fc_props.android_location_disclosure = 'TrackItは、細かい位置と粗い位置を使用して、使用されていないときでもバックグラウンドで位置データを収集し、自動ステータス設定とディスパッチのリアルタイムマップの可視性を有効にします。';
_fc_props.android_location_usage = '場所の開示';
_fc_props.end_pour_trigger_threshold = 'エンドポアトリガーしきい値';
_fc_props.drs_wiring_error = '配線エラー';
_fc_props.drs_wiring_pulse_error = '配線パルスエラー';
_fc_props.drs_wiring_magnet_error = '配線マグネットエラー';
_fc_props.drs_magnet_missing_error = 'マグネットミッシングエラー';
_fc_props.android_location_disclosure_3p = 'TrackItはバックグラウンドで位置データを収集し、ディスパッチの自動ステータス設定とリアルタイムの地図の可視性を有効にします。配送業者から出勤する場合、場所は共有されません。';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'フルMDMまたはTrackitリモートサポートのいずれかを選択してください';
_fc_props.move_up = '上に移動';
_fc_props.move_down = '下に移動';
_fc_props.driver_not_found = 'ドライバーが見つかりません。';
_fc_props.multiple = '複数';
_fc_props.water_meter_installed = '水道メーター設置';
_fc_props.delete_waypoint = 'ウェイポイントを削除する';
_fc_props.wash_sensor_installed = 'ウォッシュセンサーが取り付けられています';
_fc_props.washing_start_time = '洗濯開始時間';
_fc_props.end_washing = '洗浄終了';
_fc_props.variable_rpms = 'VarRPM';
_fc_props.no_slump = 'スランプなし';
_fc_props.end_washing_duration = '洗濯終了時間';
_fc_props.privacy_policy = '個人情報保護方針';
_fc_props.privacy_policy_text = '私は読んで受け入れました';
_fc_props.login_fail_accept_privacy_policy = '続行するには、プライバシーポリシーに同意する必要があります。';
_fc_props.rotation_sensor = '回転センサー';
_fc_props.pto_sensor = 'PTOセンサー';
_fc_props.pto_sensor_installed = 'PTOセンサー搭載';
_fc_props.polarity_reversed = '逆極性';
_fc_props.pto_sensor_type = 'PTOセンサータイプ';
_fc_props.sensor_type = 'センサータイプ';
_fc_props.prompt_select_pto_sensor_type = 'メインセンサータイプとして「PTOセンサー」を選択した場合は、「PTOセンサータイプ」を選択する必要があります。';
_fc_props.priming_turns_at_full_load = 'プライミングは全負荷で回転します';
_fc_props.priming_turns_at_ten_percent_load = 'プライミングは10％の負荷で回転します';
_fc_props.weight_units = '重量単位';
_fc_props.settings.send_status = 'ステータス/場所をイベントブリッジに送信';
_fc_props.volume_difference = '音量差';
_fc_props.mobile_ticket_iframe_url = 'モバイルチケットIFrameのURL';
_fc_props.mobile_ticket_user = 'モバイルチケットユーザー';
_fc_props.mobile_ticket_password = 'モバイルチケットパスワード';
_fc_props.current_truck_status = '現在のトラックの状況';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = '最大回転数によってトリガーされます';
_fc_props.alerts_by_type = 'タイプ別のアラート';
_fc_props.water_flow_meter = '水流量計';
_fc_props.flow_meter_no_pulses_warning = 'パルスなし-警告';
_fc_props.flow_meter_no_pulses_critical = 'パルスなし-クリティカル';
_fc_props.flow_meter_excessive_pulses = '過剰なパルス';
_fc_props.flow_meter_constantly_flowing = '常に流れる';
_fc_props.flow_meter_good = '良い';
_fc_props.whats_new = '新着情報';
_fc_props.whats_new_widget_text = '少なくとも月に1回はTrackItを更新することをご存知ですか？最新のアップデートとヒントを入手してください。';
_fc_props.get_the_scoop = 'スクープを入手';
_fc_props.trackit_insights = 'Trackit Insights';
_fc_props.business_analytics_blurb = '収益を改善するビジネス分析がTrackItに含まれるようになりました';
_fc_props.find_out_more = '詳細はこちら';
_fc_props.daily_breakdown = '毎日の内訳';
_fc_props.question_summary = '質問の概要';
_fc_props.trip_summary = '旅行の概要';
_fc_props.manage_employees = '従業員の管理';
_fc_props.manage_hotspots = 'ホットスポットの管理';
_fc_props.manage_devices = 'デバイスの管理';
_fc_props.manage_equipment = '機器の管理';
_fc_props.manage_users = 'ユーザーの管理';
_fc_props.manage_statuses = 'ステータスの管理';
_fc_props.manage_alerts = 'アラートを管理する';
_fc_props.popular_report_links = '人気のレポートリンク';
_fc_props.popular_settings_links = '人気の設定リンク';
_fc_props.sidemenu_settings = '設定';
_fc_props.loads_per_driver = 'ドライバーあたりの負荷';
_fc_props.please_select_application = '少なくとも1つのアプリケーションを選択してください';
_fc_props.external_app_processing = '外部アプリでの処理';
_fc_props.external_app_sent = '外部アプリに送信';
_fc_props.external_app_received = '外部アプリが受信';
_fc_props.wait_time_at_job = '仕事での待ち時間';
_fc_props.wait_time_at_plant = '工場での待ち時間';
_fc_props.loading_time = '読み込み時間';
_fc_props.unloading_time = '荷降ろし時間';
_fc_props.pre_post_trip = '移動距離　前/後';
_fc_props.update_thresholds = 'しきい値の更新';
_fc_props.green = '緑';
_fc_props.yellow = '黄';
_fc_props.red = '赤';
_fc_props.between = '間';
_fc_props.num_of_loads = '負荷数';
_fc_props.num_of_drivers = 'ドライバーの数';
_fc_props.status_meaning_not_setup = 'ステータス意味が設定されていません';
_fc_props.or_less = '以下';
_fc_props.or_greater = '以上';
_fc_props.probe_self_diagnostic_state = 'プローブの自己診断状態';
_fc_props.update_current_truck_status_settings = '現在のトラックステータス設定を更新する';
_fc_props.default_behavior = 'デフォルト（トップ10ステータス）';
_fc_props.combine_similar_statuses = 'すべてのタイプの同じステータスを組み合わせる（ループ）';
_fc_props.use_other_category = '「その他」のカテゴリを使用';
_fc_props.selected_statuses = '選択されたステータス';
_fc_props.ideas = 'アイデア';
_fc_props.air_content = '空気の内容';
_fc_props.air_measured_time = '空気測定時間';
_fc_props.ten_max = '最大10';
_fc_props.loads_per_plant = 'プラントあたりの負荷';
_fc_props.invalid_flow_meter_pulse_per_volume = 'ボリュームあたりの流量計パルスが無効です。';
_fc_props.coming_soon_title = '間もなく登場するウィジェット';
_fc_props.submit_your_ideas = '左側のナビゲーションメニューの電球を使用して、アイデアをアイデアポータルに送信します。';
_fc_props.selected_plants = '選択された植物';
_fc_props.update_loads_per_plant_settings = 'プラント設定ごとの負荷の更新';
_fc_props.default_top_10_plants = 'デフォルト（上位10植物）';
_fc_props.phone_number_will_be_uploaded = 'デバイスIDは登録のためにアップロードされます。';
_fc_props.include_deleted = '削除を含める';
_fc_props.primary_status_type = 'プライマリステータスタイプ';
_fc_props.additional_status_trigger = '追加のステータストリガー';
_fc_props.update_tickets_destination = '関連するすべてのチケットの宛先を更新する';
_fc_props.settings.send_destination_changes_to_connex = '宛先の変更をConnexに送信する';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'TrackItがチケットを受信したときにチケットはアクティブですか（デフォルトは「常に」）';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = '前のチケット完了時に次のチケットを自動アクティブ化';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'チケットの完了時に次のチケットを自動アクティブ化する必要があります（デフォルトは「true」）';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'アクティブなチケットがない場合のステータスからアイドルステータス';
_fc_props.break_down = '壊す';
_fc_props.idle = 'アイドル';
_fc_props.pto_work_maximum_speed_limit = '最高速度制限';
_fc_props.settings.associate_ticket_to_status_on_complete = 'チケットを完了するステータス変更をそのチケットに関連付ける';
_fc_props.approaching = '近づいています';
_fc_props.performing_task = 'タスクの実行';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'Geogate {0}を削除してもよろしいですか？';
_fc_props.trackit_destination_changed_to_0 = 'チケット{0}を更新します。宛先が変更されました。';
_fc_props.trackit_return_destination_changed_to_0 = 'チケット{0}を更新します。返品が変更されました。';
_fc_props.menu_settings_title_geogate = 'ジオゲート';
_fc_props.add_geogate = 'Geogateを追加する';
_fc_props.driver_enabled = 'ドライバーが有効';
_fc_props.dispatcher_enabled = 'コーディネーターが有効';
_fc_props.runsheet_number = 'ランシート番号';
_fc_props.manual_load = '手動ロード';
_fc_props.edit_geogate = 'Geogateを編集する';
_fc_props.handling = '取り扱い';
_fc_props.geogate_name = 'ジオゲート';
_fc_props.geogate_panel = 'ジオゲートパネル';
_fc_props.vehicle_is_not_logged_in = 'メッセージが送信されていません (車両がログインしていません)';
_fc_props.air = '空気';
_fc_props.create_a_geogate = 'ジオゲートを作成する';
_fc_props.click_the_map_to_set_the_geogate_points = 'マップをクリックして、ジオゲート ポイントを設定します。';
_fc_props.clock_out = 'クロックアウト';
_fc_props.handling_operation = '取扱い業務';
_fc_props.view_geogates = 'ジオゲートを見る';
_fc_props.select_a_geogate = 'ジオゲートを選択してください';
_fc_props.failed_to_find_geogate = 'ジオゲートが見つかりませんでした';
_fc_props.adjust_geogate = 'ジオゲートの調整';
_fc_props.geogate_expiration_date_time_extra_days = 'Geogate 有効期限 日付 時間 デフォルト 追加日数';
_fc_props.menu_settings_title_retention = 'データ保持';
_fc_props.category = 'カテゴリー';
_fc_props.telemetry = 'テレメトリー';
_fc_props.ticket_data = 'チケットデータ';
_fc_props._30_days = '30日';
_fc_props._6_months = '6ヵ月';
_fc_props._1_year = '1年';
_fc_props._3_years = '3年';
_fc_props.new_group = '新グループ';
_fc_props.selected_vehicles = '選択された車両';
_fc_props.group_name_is_required_field = 'グループ名は必須フィールドです';
_fc_props.at_least_vehicle_should_be_selected = '少なくとも 1 台の車両を選択する必要があります';
_fc_props.groups = 'グループ';
_fc_props.trucks = 'トラック';
_fc_props.geogate_has_been_removed_please_refresh_page = 'ジオゲートが削除されました。ページを更新してください。';
_fc_props.air_timestamp = 'エアタイムスタンプ';
_fc_props.fail = '不合格';
_fc_props.stuck = '立ち往生';
_fc_props.dirty = '汚れた';
_fc_props.seasonal = '季節の';
_fc_props.category_details = 'カテゴリーの詳細';
_fc_props.alert_events = 'アラート イベント';
_fc_props.driver_performance_infractions = 'ドライバーのパフォーマンス違反';
_fc_props.telemetry_details = '位置データ、ホットスポット、ホットスポットでの時間、地域での時間、DCM データ、プローブ データ、エンジン データ';
_fc_props.ticket_data_details = 'チケットデータ';
_fc_props.general_details = 'ステータス変更、タイムカード、スケジュール';
_fc_props.wash = '洗う';
_fc_props.filter_options = 'フィルター オプション';
_fc_props.home_plants = 'ホームプラント';
_fc_props.dcm = 'DCM';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO では、PTO センサー タイプを設定する必要があります。';
_fc_props.invalid_io_box_mac_address = 'I/O ボックスの MAC アドレスが無効です';
_fc_props.access_denied = 'アクセス拒否';
_fc_props.wash_events = '洗浄イベント';
_fc_props.min_speed = '最低速度';
_fc_props.temperature_change = '温度変化';
_fc_props.degrees_cap = '度';
_fc_props.washout_tracking = 'ウォッシュアウト追跡';
_fc_props.approaching_wash = '近づくウォッシュ';
_fc_props.arrival_wash = 'アライバルウォッシュ';
_fc_props.performing_wash = '洗浄を行う';
_fc_props.wash_complete = '洗浄完了';
_fc_props.must_be_positive_or_zero = '正またはゼロでなければなりません!';
_fc_props.min_speed_greater_than_max = '最小速度は最大速度より小さくなければなりません!';
_fc_props.clock_in = '出勤';
_fc_props.mix_water_at_x_to_y_for_t = '{2} 回転の {0} から {1} RPM で水を混合します。';
_fc_props.discharge_water_for_x_revolutions = '{0} 回転で水を排出します。';
_fc_props.eod_drum_wash_complete = 'ドラム洗浄完了！ <br> プローブを上にしてドラムを止めます。';
_fc_props.washing_complete = 'ガイド付きウォッシュアウト完了';
_fc_props.eod_washout_no_data_available = 'センサー データを利用できません。洗浄プロセスを完了してください。';
_fc_props.eod_washout_no_data_available_title = 'ガイド付きウォッシュアウトは利用できません';
_fc_props.menu_reports_title_ticket_time_summary = 'チケット時間の概要';
_fc_props.menu_reports_description_ticket_time_summary = '注文または車両のチケット時間情報を表示する';
_fc_props.menu_reports_title_summary = 'まとめ';
_fc_props.menu_reports_title_ticket_summary = 'チケット概要';
_fc_props.menu_reports_description_summary1 = 'ホットスポットでの時間の概要を表示する';
_fc_props.menu_dispatch_title_main = '主要';
_fc_props.menu_dispatch_title_login_registry = 'ログインレジストリ';
_fc_props.menu_reports_title_main = '主要';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'このエクスポート データ タイプを削除してもよろしいですか?';
_fc_props.dvir_status_paper_logs = '紙のログ';
_fc_props.menu_reports_title_billing_report = '請求レポート';
_fc_props.menu_reports_description_billing_report = '請求レポート';
_fc_props.settings_status_to_ticketed_status_on_activate_work = '作業をアクティブ化すると、ステータスがチケット発行済みステータスに変わります';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = '作業のアクティブ化時のステータスからチケット発行済みステータスへ (デフォルトは「False」)';
_fc_props.status_change_type_drum_rotation_sensor = 'DCM';
_fc_props.settings_connex_eid = 'コネックスEID';
_fc_props.units_weight_pound_label = 'ポンド';
_fc_props.units_weight_kilogram_label = 'キログラム';
_fc_props.settings_weight = '重さ';
_fc_props.units_pounds = 'ポンド';
_fc_props.units_kilograms = 'キログラム';
_fc_props.geo_gate_edit_modal_description = '説明';
_fc_props.geo_gate_edit_modal_start_point = '出発地点';
_fc_props.geo_gate_edit_modal_end_point = '終点';
_fc_props.geo_gate_edit_modal_vehicle_types = '機器の種類';
_fc_props.geo_gate_edit_modal_heading_south = '南から北へ';
_fc_props.geo_gate_edit_modal_heading_north = '北から南へ';
_fc_props.geo_gate_edit_modal_driver_message = 'ドライバーのメッセージ';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'メッセージを送信する';
_fc_props.geo_gate_edit_modal_expiration_date_time = '有効期限日時';
_fc_props.geo_gate_edit_modal_permanent = '永続';
_fc_props.geo_gate_edit_modal_critical = '致命的';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'Geogate の保存中にエラーが発生しました: 説明フィールドに値を入力する必要があります。';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'ジオゲートの保存中にエラーが発生しました: [開始点] フィールドに値を入力する必要があります。';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'ジオゲートの保存中にエラーが発生しました: [開始点] フィールドに無効なデータ形式が含まれています。';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'ジオゲートの保存中にエラーが発生しました: [エンドポイント] フィールドに無効なデータ形式が含まれています。';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'Geogate の保存中にエラーが発生しました: 有効期限の日付時刻フィールドに値を入力する必要があります。';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'Geogate の保存中にエラーが発生しました: 有効期限日付時刻フィールドに無効なデータ形式が含まれています。';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'ジオゲートの保存中にエラーが発生しました: 北から南 - ディスパッチ メッセージ フィールドに値を入力する必要があります';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'Geogate の保存中にエラーが発生しました: 変更は検出されませんでした';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'Geogate の保存中にエラーが発生しました: [有効期限日時] フィールドに有効期限が切れた日時が含まれています';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'Geogate の保存中にエラーが発生しました: 説明フィールドには文字と数字のみを含める必要があります';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'Geogate の保存中にエラーが発生しました: [機器タイプ] フィールドに値を入力する必要があります';
_fc_props.geo_gate_table_message_label_north_to_south = '北から南へ';
_fc_props.geo_gate_table_message_label_south_to_north = '南から北へ';
_fc_props.geo_gate_table_message_label_both = '両方';
_fc_props.geo_gate_edit_modal_field_point_tip = '緯度、経度';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'これをチェックすると、デバイス移動メッセージ制限の設定が上書きされます。';
_fc_props.geo_gate_edit_modal_seasonal = '季節限定';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'Geogate の保存中にエラーが発生しました: 季節の日付を入力する必要があります';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'Geogate の保存エラー: 季節の日付フィールドに無効なデータ形式が含まれています';
_fc_props.test_js_test_javascript = 'JavaScript をテストする';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'キロメートル';
_fc_props.units_miles = 'マイル';
_fc_props.units_gallons_abbr = 'ギャル';
_fc_props.units_liters_abbr = 'L';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'ジオゲートの保存中にエラーが発生しました: 南から北へ - ドライバー メッセージ フィールドに値を入力する必要があります';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'ジオゲートの保存中にエラーが発生しました: 南から北へ - ディスパッチ メッセージ フィールドに値を入力する必要があります';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'ジオゲートの保存中にエラーが発生しました: 北から南 - ドライバー メッセージ フィールドに値を入力する必要があります';
_fc_props.geo_gate_heading = '見出し';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'ジオゲートの保存中にエラーが発生しました: [エンド ポイント] フィールドに値を入力する必要があります';
_fc_props.units_mile = 'マイル';
_fc_props.units_kilometer = 'キロメートル';
_fc_props.cloud_dvir__exit = '出口';
_fc_props.cloud_dvir__signature = 'サイン';
_fc_props.cloud_dvir__inspection = '検査';
_fc_props.cloud_dvir__add_photos_notes = '写真/メモを追加する';
_fc_props.cloud_dvir__odometer_reading = '走行距離計の読書';
_fc_props.cloud_dvir__engine_hours = 'エンジン時間';
_fc_props.cloud_dvir__truck_details = 'トラックの詳細';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = '車両の全体的な状態を判断する';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'ドライバーのフルネームと署名';
_fc_props.cloud_dvir__sign = 'サイン';
_fc_props.cloud_dvir__driver_note_from = 'ドライバーノートから';
_fc_props.cloud_dvir__add_notes = 'メモを追加する';
_fc_props.cloud_dvir__driver_notes = 'ドライバーのメモ';
_fc_props.cloud_dvir__done = '終わり';
_fc_props.cloud_dvir__needs_repair = '修理が必要です';
_fc_props.cloud_dvir__not_applicable = '適用できない';
_fc_props.cloud_dvir__review_and_sign = 'レビューと署名';
_fc_props.cloud_dvir__add_photos = '写真を追加';
_fc_props.cloud_dvir__upload_photo_limit = '写真は3枚までアップロードできます';
_fc_props.cloud_dvir__mismatch_title = '不一致アラート';
_fc_props.cloud_dvir__ok = 'Ok';
_fc_props.cloud_dvir_template = 'クラウドDVIRテンプレート';
_fc_props.cloud_dvir__needs_review = 'DVIR は検討が必要です';
_fc_props.cloud_dvir__submitted = '提出済み';
_fc_props.cloud_dvir__completed_by = '完了者';
_fc_props.cloud_dvir__review_btn = 'レビュー';
_fc_props.cloud_dvir__truck_number = 'トラック {0}';
_fc_props.cloud_dvir__pending_review = '審査待ち';
_fc_props.cloud_dvir__start_dvir = 'DVIRを開始する';
_fc_props.cloud_dvir__dvir_history = 'DVIRの歴史';
_fc_props.cloud_dvir__view_all = 'すべて見る';
_fc_props.load_zone = '負荷ゾーン';
_fc_props.view_load_zones = 'ロードゾーンの表示';
_fc_props.edit_load_zone = 'ロードゾーンの編集';
_fc_props.create_a_load_zone = 'ロードゾーンを作成する';
_fc_props.load_zone_panel = 'ロードゾーンパネル';
_fc_props.click_the_map_to_set_the_load_zone_center = 'マップをクリックして荷重ゾーンの中心を設定します。';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'この検査には欠陥が指摘されており、この車両は安全に運転できると選択されました。続行する前に検証してください';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'この検査では欠陥は指摘されておらず、この車両を運転するには安全ではないと選択されました。続行する前に検証してください。';
_fc_props._90_days = '90日';
_fc_props.cloud_dvir = 'クラウドDVIR';
_fc_props.cloud_dvir_details = 'クラウドDVIRの提出';
_fc_props.show_load_zones = 'ロードゾーンを表示';
_fc_props.hide_load_zones = 'ロードゾーンを非表示にする';
_fc_props.no = 'いいえ';
_fc_props.cloud_dvir__reset = 'リセット';
_fc_props.cloud_dvir__clear = 'クリア';
_fc_props.mark_read = '既読マークを付ける';
_fc_props.mark_unread = '未読としてマークする';
_fc_props.read = '読む';
_fc_props.unread = '未読';
_fc_props.air_sensor_serial_number = 'エアセンサーのシリアル番号';
_fc_props.air_sensor_mac_address = '空気センサーのMACアドレス';
_fc_props.air_sensor_serial_number_invalid_format = 'エア センサーのシリアル番号が有効な形式ではありません: (英数字、ダッシュを含む最大 32 文字)';
_fc_props.air_sensor_mac_address_invalid_format = 'エア センサーの MAC アドレスが、フィールドのプレースホルダーに表示される有効な 16 進形式ではありません';
_fc_props.air_sensor_license = 'エアセンサー';
_fc_props.adjust_route_point = 'ルートポイントの調整';
_fc_props.route_point_panel = 'ルートポイントパネル';
_fc_props.route_point = 'ルートポイント';
_fc_props.cloud_dvir__pre_trip = '旅行前';
_fc_props.cloud_dvir__post_trip = '旅行後';
_fc_props.cloud_dvir__other_insction_type = '他の';
_fc_props.cloud_dvir__dvir_completed = '完了';
_fc_props.cloud_dvir__awaiting_mechanic = 'メカニックを待っています';
_fc_props.cloud_dvir__awaiting_driver = 'ドライバーのサインオフを待っています';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'エア センサーのシリアル番号を確認できません。追加の支援が必要な場合はサポートにお問い合わせください。';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'このエア センサーのシリアル番号はすでに存在します。別のシリアル番号を使用してください';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = '空気センサーの MAC アドレスを確認できません。サポートにお問い合わせください。';
_fc_props.cloud_dvir__error_submit_dvir_title = 'DVIR 送信エラー';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'もう一度試してください';
_fc_props.ticket_integration_descr = 'チケット統合により、ホーム画面/ロード画面/ハードマウントディスプレイだけでなくチケット統合設定を行うことができます';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR が正常に送信されました';
_fc_props.cloud_dvir__inspection_trip_type = '検査旅行の種類';
_fc_props.menu_dvir_title_mechanic = 'メカニック';
_fc_props.sidemenu_dvir = 'DVIR';
_fc_props.air_sensor_firmware = '空気センサーファームウェア';
_fc_props.when_no_active_ticket = '有効なチケットがない場合';
_fc_props.used_by = 'によって使われた';
_fc_props.fields = '田畑';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'チケット レイアウト「{0}」を削除してもよろしいですか?';
_fc_props.menu_settings_title_ticket_layout = 'チケットのレイアウト';
_fc_props.add_ticket_layout = 'チケットレイアウトの追加';
_fc_props.edit_ticket_layout = 'チケットレイアウトの編集';
_fc_props.ticket_layout_edit_modal_name = '名前';
_fc_props.ticket_layout_edit_modal_default = 'デフォルト';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'チケット レイアウトの保存中にエラーが発生しました: [名前] フィールドに値を入力する必要があります。';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'チケットレイアウトの保存中にエラーが発生しました: 名前フィールドには文字と数字のみを含める必要があります';
_fc_props.ticket_layout_edit_modal_fields = '田畑';
_fc_props.ticket_layout_edit_modal_usage = '使用法';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'チケットのレイアウトは削除されました。ページを更新してください。';
_fc_props.more_lower_case = 'もっと';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'チケット レイアウトの保存中にエラーが発生しました: 1 つ以上のフィールドを選択する必要があります。';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'チケット レイアウトの保存中にエラーが発生しました: 1 つ以上のステータスを選択する必要があります。';
_fc_props.cloud_dvir__ad_hoc = 'このために';
_fc_props.radius_multiplier_should_be = '半径乗数は浮動小数点である必要があります';
_fc_props.ticket_max_activate_age_err = 'Ticket Max Activate Age Minutes は整数である必要があります。';
_fc_props.max_hotspot_unused_days_errs = 'ホットスポットの最大未使用日数は整数である必要があります。';
_fc_props.begin_pour_air_content = '空気含有量の注入を開始します';
_fc_props.arrival_air_content = '到着空気含有量';
_fc_props.fully_mixed_air_content = '完全に混合された空気含有量';
_fc_props.departure_air_content = '出発航空内容';
_fc_props.popup_enabled = 'ポップアップを有効にする';
_fc_props.popup_disabled = 'ポップアップが無効になっています';
_fc_props.cloud_dvir__leave_confirmation_title = '警告';
_fc_props.cloud_dvir__leave_confirmation_description = 'ここでページを終了すると、すべての変更が失われます。';
_fc_props.cloud_dvir__leave_confirmation_approve = '確認する';
_fc_props.cloud_dvir__leave_confirmation_abort = 'キャンセル';
_fc_props.air_bt_ratio = '空気センサー測定';
_fc_props.cloud_dvir__view_photos_notes = '写真/メモを見る';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'ステータスを追加すると、別のチケット レイアウトに空白の使用法が作成されます。追加を続行しますか?';
_fc_props.vehicle_does_not_have_air_sensor_license = '車両にはエアセンサーライセンスがありません。';
_fc_props.air_sensor_installed = 'エアセンサー搭載';
_fc_props.load_card = 'ロードカード';
_fc_props.cloud_dvir__inspection_profile = '検査プロファイル';
_fc_props.cloud_dvir__view_details = '詳細を見る';
_fc_props.cloud_dvir__certify_repairs = '修理の証明';
_fc_props.cloud_dvir__repairs_made = '修理が行われました';
_fc_props.cloud_dvir__repairs_not_necessary = '修理は必要ありません';
_fc_props.cloud_dvir__mechanic_note_from = 'メカニックノートより';
_fc_props.cloud_dvir__mechanic_assignment = 'メカニックの割り当て';
_fc_props.cloud_dvir__mechanic_signature_description = '以下の該当するすべての項目にマークを付け、車両が適切に検査されていることを示す標識を付けます。';
_fc_props.cloud_dvir__condition_satisfactory_label = 'この車両の状態は良好です';
_fc_props.cloud_dvir__defects_corrected_label = '不具合は修正されました';
_fc_props.cloud_dvir__repair_not_required_label = '車両を安全に運転するために修理は必要ありません';
_fc_props.safety = '安全性';
_fc_props.cloud_dvir__awaiting_sign = 'サイン待ち';
_fc_props.cloud_dvir__dvir_review = 'DVIR レビュー';
_fc_props.settings.mobile_auto_start_navigation = 'モバイルでのナビゲーションの自動開始';
_fc_props.cloud_dvir__edit = '編集';
_fc_props.delete_custom_label = 'カスタムラベルの削除';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'TrackIt のバージョンが古いため、一部の DVIR 機能が正しく動作しない可能性があります。続行する前に TrackIt を更新してください。';
_fc_props.cloud_dvir__error_submit_dvir_signature = '署名のアップロード中に問題が発生しました。もう一度試してください';
_fc_props.max_hotspot_unused_days_error = 'ホットスポットの最大未使用日数は最大 1000 日です。';
_fc_props.menu_dvir_title_admin_templates = 'テンプレート';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = '管理者';
_fc_props.cloud_dvir__admin_templates = 'テンプレート';
_fc_props.form_name = 'フォーム名';
_fc_props.form_status = 'フォームステータス';
_fc_props.menu.dvir.title = 'DVIR';
_fc_props.cloud_dvir__option_download = 'ダウンロード';
_fc_props.cloud_dvir__option_view = 'ビュー';
_fc_props.cloud_dvir__inspectortype_driver = '運転者';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'カスタム DVIR フォームおよび提出物の表示/編集/作成';
_fc_props.menu.dvir.description.mechanic = '実用的な DVIR 提出の表示/編集';
_fc_props.not_in_use = '使用されていません';
_fc_props.logged_in = 'ログインしました';
_fc_props.reviewing_driver = 'ドライバーのレビュー';
_fc_props.cloud_dvir__report_title = '運転者の車両検査報告書';
_fc_props.cloud_dvir__report_signed = '署名済み';
_fc_props.cloud_dvir__report_page_counter = 'ページ {0}/{1}';
_fc_props.cloud_dvir__report_defects = '欠陥';
_fc_props.cloud_dvir__report_comments = 'コメント';
_fc_props.cloud_dvir__report_inspection_table_title = '車検';
_fc_props.cloud_dvir__report_driver_table_title = 'ドライバー情報';
_fc_props.cloud_dvir__report_carrier = 'キャリア';
_fc_props.cloud_dvir__report_truck = 'トラック';
_fc_props.cloud_dvir__report_location = '位置';
_fc_props.cloud_dvir__report_odometer = 'オドメーター';
_fc_props.cloud_dvir__report_hours = '時間';
_fc_props.cloud_dvir__report_no_comments = 'コメントはありません';
_fc_props.include_unlicensed_vehicles = '無免許車両を含む';
_fc_props.cloud_dvir__search_by_form_name = 'フォーム名で検索';
_fc_props.cloud_dvir__create_dvir = 'DVIR を作成する';
_fc_props.cloud_dvir__update_dvir = 'DVIRを更新する';
_fc_props.cloud_dvir__form_name = 'フォーム名';
_fc_props.cloud_dvir__form_description = 'フォームの説明';
_fc_props.cloud_dvir__form_status = 'フォームステータス';
_fc_props.information = '情報';
_fc_props.cloud_dvir__search_categories = '検索カテゴリ';
_fc_props.cloud_dvir__create_new_category = '新しいカテゴリーを作成する';
_fc_props.category_name = '種別名';
_fc_props.sub_category = 'サブカテゴリ';
_fc_props.sub_category_name = 'サブカテゴリ名';
_fc_props.delete_category = 'カテゴリの削除';
_fc_props.cloud_dvir__dvir_information = 'DVIR 情報';
_fc_props.cloud_dvir__dvir_choose_categories = 'カテゴリの選択';
_fc_props.cloud_dvir__create_category = 'カテゴリの作成';
_fc_props.cloud_dvir__update_category = 'カテゴリを更新する';
_fc_props.delete_route_point = 'ルートポイントの削除';
_fc_props.view_route_points = 'ルートポイントの表示';
_fc_props.ignore_cancel_preload = 'キャンセルは無視してください。プリロードされています。あなたのホームランはすぐに表示されます';
_fc_props.cloud_dvir__assigned_mechanic = '担当メカニック';
_fc_props.cloud_dvir__add_mechanic = 'メカニックを追加する';
_fc_props.contact_type = '接点の種類';
_fc_props.cloud_dvir__send_email_notifications = '電子メール通知を送信する';
_fc_props.menu_dvir_title_admin_dvir = 'DVIR';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'DVIR 履歴レコードの取得中にエラーが発生しました。';
_fc_props.cloud_dvir__error_processing_dvir_record = 'DVIR レコードの取得中にエラーが発生しました。';
_fc_props.cloud_dvir__error_saving_submission = '提出物を保存中にエラーが発生しました。もう一度やり直してください。';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'メカニズムのリストを取得中にエラーが発生しました。';
_fc_props.cloud_dvir__template_saved = 'テンプレートが保存されました';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'テンプレートのリストを取得中にエラーが発生しました。';
_fc_props.cloud_dvir__error_saving_dvir_template = 'テンプレートの保存中にエラーが発生しました。もう一度やり直してください。';
_fc_props.dispatch_group = '派遣グループ';
_fc_props.save_adjust = 'ルートポイントの保存と調整';
_fc_props.reset_route_point = 'ルートポイントをリセット';
_fc_props.no_records_stub_adjust_filters = '検索またはフィルターの設定を調整して、結果の数を増やしてみてください。';
_fc_props.no_dvir_records = 'DVIR にすべて追いつきました!';
_fc_props.no_dvir_records_sub_title = 'あなたは多くのことを成し遂げてきました';
_fc_props.settings.master_data_connex_x_api_key = 'マスター データ コネックス x-api-key';
_fc_props.slump_not_obtained_for_x = 'スランプ測定値は取得されませんでした: {0}';
_fc_props.please_select_a_sensor_status_type = 'このアラートをトリガーするにはセンサー ステータス タイプを選択してください';
_fc_props.settings.master_data_connex_token = 'マスターデータコネックストークン';
_fc_props.error_validating_master_data_connex_credentials = 'マスター データ Connex 認証情報の検証中にエラーが発生しました';
_fc_props.settings.master_data_connex_base_url = 'マスター データ コネックス ベース URL';
_fc_props.settings.master_data_connex_exp_time = 'マスターデータ接続の有効期限';
_fc_props.download_template = 'テンプレートをダウンロード';
_fc_props.menu_settings_title_dispatch_group = '派遣グループ';
_fc_props.settings.dispatch_groups = '派遣グループ';
_fc_props.dispatch_group_name = '派遣グループ名';
_fc_props.dispatch_group_plants = '派遣グループ工場';
_fc_props.dispatch_group_plants_mapping_message = 'どのプラントがこの派遣グループの一部となるかを示します。';
_fc_props.dispatch_group_users_mapping_message = 'どのユーザーがこのディスパッチ グループに属するかを指定します。';
_fc_props.dispatch_group_plant_disassociation_message = 'プラントは一度に 1 つのディスパッチ グループの下にある必要があります';
_fc_props.dispatch_group_search_by_plant = '植物名から探す';
_fc_props.dispatch_group_search_by_username = 'ユーザー名で検索';
_fc_props.search_by_dispatch_group_name = '派遣グループ名で検索';
_fc_props.create_dispatch_group = 'ディスパッチグループを作成する';
_fc_props.update_dispatch_group = 'ディスパッチグループの更新';
_fc_props.dispatch_group_saved = 'ディスパッチグループが保存されました';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'このディスパッチ グループを削除すると、関連するプラントのデフォルトのディスパッチ グループが作成されます。';
_fc_props.error_saving_dispatch_group = 'ディスパッチグループの保存中にエラーが発生しました。もう一度やり直してください。';
_fc_props.please_enter_a_valid_plant_num = '有効なプラント番号を入力してください。';
_fc_props.cloud_dvir__report_no_defects = '欠陥なし';
_fc_props.shoud_select_dispatch_group = '少なくとも 1 つのディスパッチ グループを選択する必要があります';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'このディスパッチ グループには、このディスパッチ グループにのみ関連付けられているユーザーが含まれているため、削除できません。';
_fc_props.cloud_dvir__username = 'ユーザー名';
_fc_props.cloud_dvir__password = 'パスワード';
_fc_props.cloud_dvir__login = 'ログイン';
_fc_props.cloud_dvir__mechanic_sign_in = '整備士サインイン';
_fc_props.delete_dispatch_group = 'ディスパッチグループの削除';
_fc_props.cloud_dvir__driver = '運転者';
_fc_props.cloud_dvir__equipment_pound = '装置 ＃';
_fc_props.cloud_dvir__mechanic = 'メカニック';
_fc_props.dispatch_group_user_only_unassociate_message = 'ユーザーは少なくとも 1 つのディスパッチ グループに関連付ける必要があるため、ユーザーを削除できません。';
_fc_props.cloud_dvir__time_period_filter_today = '今日';
_fc_props.cloud_dvir__time_period_filter_yesterday = '昨日';
_fc_props.cloud_dvir__time_period_filter_last_7_days = '過去 7 日間';
_fc_props.cloud_dvir__time_period_filter_last_30_days = '過去 30 日間';
_fc_props.cloud_dvir__date_range_filter = '日付でフィルターする';
_fc_props.cloud_dvir__download = 'ダウンロード';
_fc_props.dispatch_group_is_empty = 'ディスパッチグループを空にすることはできません。';
_fc_props.dispatch_group_with_empty_plants = '少なくとも 1 つのプラントをディスパッチ グループに関連付ける必要があります。';
_fc_props.dispatch_group_with_empty_users = '少なくとも 1 人のユーザーをディスパッチ グループに関連付ける必要があります。';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'このディスパッチ グループにのみ関連付けられているユーザーが含まれているため、ディスパッチ グループを削除できません。';
_fc_props.dispatch_group_not_exists = '派遣グループが存在しません。';
_fc_props.dispatch_group_contains_one_plant = 'このディスパッチ グループにはプラントが 1 つしか含まれていないため、削除できません。';
_fc_props.dispatch_group_users = '派遣グループユーザー';
_fc_props.cloud_dvir__error_retrieving_dvir = 'DVIR レコードの取得中にエラーが発生しました';
_fc_props.cloud_dvir__assign_mechanic = 'メカニックの割り当て';
_fc_props.cloud_dvir__submit_review = 'レビュー送信';
_fc_props.error_deleting_dispatch_group = 'ディスパッチグループの削除中にエラーが発生しました。';
_fc_props.error_retrieving_dispatch_groups = 'ディスパッチグループのリストを取得中にエラーが発生しました。';
_fc_props.cloud_dvir__undo_selection = '選択を元に戻す';
_fc_props.enable_master_data = 'マスターデータを有効にする';
_fc_props.manually_sync = '手動で同期する';
_fc_props.master_data_connex_token_expiration = 'マスターデータ Connex トークンの有効期限';
_fc_props.cloud_dvir__time_period_filter_custom = 'カスタム';
_fc_props.settings.zello_session_timeout_minutes = 'Zello セッションのタイムアウト (分)';
_fc_props.menu_settings_title_master_data = 'マスターデータ';
_fc_props.settings.master_data = 'マスターデータ';
_fc_props.error_retrieving_master_data = 'マスターデータ取得エラー';
_fc_props.master_data_sync_completed = 'マスターデータの同期が完了しました';
_fc_props.master_data_successfully_updated = 'データが正常に更新されました';
_fc_props.master_data_failed_to_update = '保存に失敗しました。';
_fc_props.master_data_sync_initiated = 'マスターデータの同期が開始されました';
_fc_props.master_data_sync_failed = 'マスターデータの同期に失敗しました';
_fc_props.enabled_excluding_origin_on_deliveries = 'True (配信元のチケットを除く)';
_fc_props.enabled_including_origin_on_deliveries = 'True (配信元のチケットを含む)';
_fc_props.cloud_dvir__i_will_decide_later = '後で決めます';
_fc_props.returning = '戻る';
_fc_props.leftover_concrete = '残コンクリート';
_fc_props.leftover_concrete_on_vehicle_x = '車両 {0} に残ったコンクリート';
_fc_props.leftover_concrete_alert = 'コンクリート残渣警報';
_fc_props.wc_ratio_exceeded = 'W/C比率超過';
_fc_props.search_by_site_name = 'サイト名で検索';
_fc_props.sites = 'サイト';
_fc_props.token_expiration = 'トークンの有効期限';
_fc_props.menu_settings_title_connex_api_key_management = 'Connex API キー管理';
_fc_props.settings.connex_api_key_management = 'Connex APIキー管理';
_fc_props.error_retrieving_connex_api_key_data = 'connex API キーデータの取得エラー';
_fc_props.qr_code = 'QRコード';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = '間違ったプラントを入力した場合、ドライバーとインターフェースに通知する';
_fc_props.enable_driver_message = 'ドライバーメッセージを有効にする';
_fc_props.enable_dispatch_message = 'ディスパッチメッセージを有効にする';
_fc_props.enable_sending_message_to_interface = 'インターフェースへのメッセージ送信を有効にする';
_fc_props.ticket_qr_code = 'チケットQRコード';
_fc_props.leftover_concrete_alert_description = 'コンクリートを打設した後に残ったコンクリートが見つかった場合に警告します。';
_fc_props.minimum_leftover_concrete_amount = '最小残りコンクリート量';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'マスターデータ検証エラー Connex 資格情報が許可されていません';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'マスターデータ Connex 資格情報の権限の検証中にエラーが発生しました';
_fc_props.error_validating_master_data_connex_url_not_found = 'マスターデータ検証エラー Connex URL が見つかりません';
_fc_props.error_validating_master_data_connex_unknown_error = 'マスターデータConnexの検証中にエラーが発生しました。不明なエラーです。';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'マスターデータの同期に失敗しました。車両タイプを同期してください。';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'マスターデータの同期に失敗しました。車両を同期してください。';
_fc_props.master_data_sync_failed_sync_up_plant = 'マスター データの同期に失敗しました。プラントを同期します。';
_fc_props.master_data_sync_failed_data_base_connection = 'マスター データの同期でデータベースへの接続に失敗しました。';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'マスターデータ Connex 資格情報のベアラートークン権限が不十分なため、エラーが発生しています';
_fc_props.settings.back_date_arrive_job = '仕事到着日を遡る';
_fc_props.device_notifications = '通知';
_fc_props.loading_number = '積載数';
_fc_props.leftover_concrete_volume_temp_age = '残り容量={0}、温度={1}、経過時間={2}';
_fc_props.total_ordered = '合計注文数量';
_fc_props.total_ticketed = '配達済み 注文済み';
_fc_props.load_number = '積載数';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = '残りのコンクリートの最小量は埋められ、{0} より大きくなければなりません';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'マスター データの検証中にエラーが発生しました。資格情報を同じにすることはできません。';
_fc_props.use_aws_for_talk = 'AWS をトークに活用';
_fc_props.air_readings_not_supported = '空気の読み取りはサポートされていません';
_fc_props.air_not_supported = '空気はサポートされていません';
_fc_props.mix_not_supported = 'ミックスはサポートされていません';
_fc_props.air_default_and_none_not_supported = 'デフォルトとサポートされていないものを同時に選択することはできません。';
_fc_props.notifications_enabled = '通知が有効';
_fc_props.prevent_status_change = 'ステータスの変更を防ぐ';
_fc_props.message_to_interface = 'インターフェースへのメッセージ';
_fc_props.prevent_wrong_plant = '間違った植物を防ぐ';
_fc_props.previous_tickets = '以前のチケット';
_fc_props.audit_ticket_number = 'チケット番号';
_fc_props.audit_date_created = '作成日';
_fc_props.audit_vehicle_number = '車両番号';
_fc_props.cloud_dvir__table_ok = '安全に操作可能';
_fc_props.cloud_dvir__table_needs_repair = '操作が安全でない';
_fc_props.missing_air_calibration = '空気校正データなし';
_fc_props.reported_defects = '報告された欠陥';
_fc_props.address_latitude = '住所の緯度';
_fc_props.address_longitude = '住所経度';
_fc_props.routing_latitude = 'ルーティング緯度';
_fc_props.routing_longitude = 'ルーティング経度';
_fc_props.category_name_must_be_unique = 'カテゴリ名は一意である必要があります';
_fc_props.subcategory_name_must_be_unique = 'サブカテゴリ名は一意である必要があります';
_fc_props.menu_settings_tracking_device = '追跡装置';
_fc_props.menu_settings_title_tracking_device = '追跡装置';
_fc_props.menu_settings_title_personal_device_registration = '個人用デバイスの登録';
_fc_props.registration_code = '登録コード';
_fc_props.creation_date = '創造';
_fc_props.expiration_date = '有効期限';
_fc_props.trackitadmin_personal_device_registration = '個人用デバイスの登録';
_fc_props.create_registration_code = '登録コードを追加';
_fc_props.remove_registration_code = '登録コードを削除する';
_fc_props.add_a_registration_code = '登録コードを追加する';
_fc_props.select_number_of_registration_codes_to_generate = '生成する登録コードの数を選択します。';
_fc_props.registration_code_count = '登録コード数';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = '選択した登録済みコードを削除してもよろしいですか?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = '選択した登録済みコードを削除してもよろしいですか?';
_fc_props.add_personal_device = '個人用デバイスを追加';
_fc_props.menu_settings_title_personal_device = '個人用デバイス';
_fc_props.cloud_dvir__approval_critical_alert = '車両に重大な欠陥があります。安全に運転できるとマークできません。';
_fc_props.export_registration_codes = '輸出登録コード';
_fc_props.download_format = 'ダウンロード形式';
_fc_props.specify_download_format_x = '{0} 登録コードをエクスポートするためのダウンロード形式を指定します';
_fc_props.personal_device_download = 'ダウンロード';
_fc_props.add_personal_device_input_message = '以下に個人用デバイスIDを入力してください。保存すると有効期限が自動的に適用されます。';
_fc_props.error_creating_personal_device = '個人用デバイスの作成中にエラーが発生しました';
_fc_props.device_already_exists = '個人用デバイスがすでに存在する';
_fc_props.export = '輸出';
_fc_props.error_can_not_change_connex_eid = '現在マスターデータに使用されているため、connex eid を変更できません';
_fc_props.remove_personal_device = '個人用デバイスを削除する';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = '選択した個人用デバイスを削除してもよろしいですか?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = '選択した個人用デバイスを削除してもよろしいですか?';
_fc_props.error_deleting_personal_device = '個人用デバイスの削除中にエラーが発生しました';
_fc_props.customerid = '顧客ID';
_fc_props.customername = '顧客名';
_fc_props.ordernumber = '注文番号';
_fc_props.orderdescription = '注文内容の説明';
_fc_props.locationid = '場所ID';
_fc_props.locationdescription = '位置/説明';
_fc_props.productid = '製品番号';
_fc_props.productdescription = '製品説明';
_fc_props.vehicleid = '車両ID';
_fc_props.vehicledescription = '車両の説明';
_fc_props.ticketnumber = 'チケット番号';
_fc_props.ticketdate = 'チケットの日付/時刻';
_fc_props.drivername = 'ドライバー名';
_fc_props.arriveregion = '到着地域（時間）';
_fc_props.leaveregion = '地域を離れる (時間)';
_fc_props.regionduration = '地域期間';
_fc_props.menu_reports_title_prevailing_wage = '現行賃金';
_fc_props.slump_calibration_x_not_found_for_y = 'チケット {1} のスランプ キャリブレーション "{0}" が見つかりません';
_fc_props.slump_calibration_not_found = 'スランプキャリブレーションが見つかりません';
_fc_props.slump_calibration_not_found_description = 'スランプキャリブレーションが見つからない場合に警告する';
_fc_props.error_getting_personal_device_list = '個人デバイスリストの取得中にエラーが発生しました';
_fc_props.batch_summary_date_range_note = '*注意: このレポートは、フィルターが適用されていない場合は 3 日間に制限されます。設備 (最大 10 件)、工場 (最大 1 件)、製品 (最大 1 件)、またはジョブ (最大 1 件) でフィルターすると、日付範囲は最大 31 日間になります。';
_fc_props.select_registration_code_expiration_time_days = '登録コードの有効期限が切れるまでの日数を選択し、「保存」をクリックします。';
_fc_props.add_personal_device_expiration = '有効期限（日数）';
_fc_props.cloud_dvir__vehicle_no = '車両番号';
_fc_props.cloud_dvir__trailer_no = 'トレーラー番号';
_fc_props.cloud_dvir__vehicle = '車両';
_fc_props.cloud_dvir__trailer = 'トレーラー';
_fc_props.cloud_dvir__truck_number_label = 'トラック番号';
_fc_props.cloud_dvir__trailer_number = 'トレーラー番号';
_fc_props.cloud_dvir__has_trailer = 'トレーラーを牽引して運転しない';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'トレーラー番号をここに入力してください';
_fc_props.work_order = '作業指示書';
_fc_props.unable_to_determine_slump = 'スランプを判定できない';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'トレーラーの全体的な状態を判断する';
_fc_props.defaultTrailer = 'デフォルトのトレーラー';
_fc_props.trailer_type_column_billable = '請求可能';
_fc_props.trailer_type_column_notes = '注記';
_fc_props.trailer_type_column_date = '日付';
_fc_props.trailer_type_column_order = '注文';
_fc_props.trailer_type_column_ticket = 'チケット';
_fc_props.trailer_type_column_project = 'プロジェクト';
_fc_props.trailer_type_column_customer = 'お客様';
_fc_props.trailer_type_column_location = '位置';
_fc_props.trailer_type_column_vehicle_type = '車両タイプ';
_fc_props.trailer_type_column_product = '製品';
_fc_props.trailer_type_action_rebill = '再請求';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'マスターデータの同期に失敗しました。デフォルトの機器タイプが設定されていません。';
_fc_props.trailer_type = 'トレーラータイプ';
_fc_props.trailer_type_is_required = 'トレーラータイプは必須です';
_fc_props.trailer_number_already_exists = 'トレーラー番号はすでに存在します';
_fc_props.plate_number_already_exists = 'プレート番号はすでに存在します';
_fc_props.trailer_vin_already_exists = 'トレーラーVinはすでに存在します';
_fc_props.trailer_does_not_exist = 'トレーラーが存在しません';
_fc_props.trailer_number_is_required = 'トレーラー番号は必須です';
_fc_props.plate_number_is_required = 'ナンバープレートは必須です';
_fc_props.plate_number = 'ナンバープレート';
_fc_props.menu_settings_title_trailer = 'トレーラー';
_fc_props.add_trailer = 'トレーラーを追加';
_fc_props.edit_trailer = 'トレーラー編集';
_fc_props.menu_settings_title_equipment = '装置';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'Command Cloud からの X 分以上前の新しいチケットを無視する';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'ディスパッチ日時が X 分以上前の Command Cloud からの新しいチケットを無視します (デフォルトは空でオフ)';
_fc_props.edit_load_card = 'ロードカードを編集';
_fc_props.default_layout = 'デフォルトレイアウト';
_fc_props.load_card_empty_fields = 'ロード カードの保存中にエラーが発生しました: 1 つ以上のフィールドを選択する必要があります。';
_fc_props.trailers = 'トレーラー';
_fc_props.error_failed_to_save_data = 'エラー: データの保存に失敗しました';
_fc_props.slump_increased_plasticizer_added_question = '可塑剤はどれくらい加えられましたか？';
_fc_props.add_trailer_type = 'トレーラータイプの追加';
_fc_props.menu_settings_title_trailer_type = 'トレーラータイプ';
_fc_props.edit_trailer_type = 'トレーラータイプの編集';
_fc_props.single_use_codes = '使い捨てコード';
_fc_props.multiple_use_codes = '複数使用コード';
_fc_props.trailer_type_description_is_required = 'トレーラータイプの説明が必要です';
_fc_props.trailer_saved = 'トレーラーを保存しました';
_fc_props.cloud_dvir__trailer_only_dvir = 'トレーラーのみのDVIR';
_fc_props.choose_file = 'ファイルを選択';
_fc_props.no_file_chosen = 'ファイルが選択されていません';
_fc_props.please_choose_file = 'ファイルを選択してください';
_fc_props.no_filters = 'フィルターが選択されていません';
_fc_props.remaining_defects = '残る欠陥';
_fc_props.load_more = 'さらに読み込む';
_fc_props.pagination_label = '{0}-{1} / {2}';
_fc_props.extend = '伸ばす';
_fc_props.cloud_dvir__notes_or_photo_required = 'メモまたは写真のいずれかが必要です。';
_fc_props.no_default_plant_specified_failed_to_save = 'デフォルトのプラントが指定されていません。';
_fc_props.no_default_employee_type_specified_failed_to_save = 'デフォルトの従業員タイプが指定されていません。';
_fc_props.personal_device_registration_code_saved = '個人用デバイス登録コードが保存されました';
_fc_props.error_saving_personal_device_registration_code = '個人用デバイス登録コードの保存中にエラーが発生しました。もう一度お試しください。';
_fc_props.settings.master_data_sub_title = 'TrackItとConnex間のマスターデータ統合を設定する';
_fc_props.connex_authentication = '{0} 認証';
_fc_props.select_company = '会社を選択';
_fc_props.requirements = '要件';
_fc_props.default_plant_type = 'デフォルトプラント';
_fc_props.default_employee_type = 'デフォルトの従業員タイプ';
_fc_props.default_equipment_type = 'デフォルトの装備タイプ';
_fc_props.support_alphanumeric_trucks = '英数字トラックをサポート';
_fc_props.no_default_equipment_type_specified_failed_to_save = 'デフォルトの機器タイプが指定されていません。';
_fc_props.air_pau_battery_low_message = 'Air PAU バッテリー低下警告';
_fc_props.air_pau_battery_low = 'Air PAU バッテリー残量低下';
_fc_props.alert_when_air_pau_battery_low = 'Air PAUのバッテリー残量が少なくなると警告';
_fc_props.air_pau_battery_low_alert = '空気電池残量低下警告';
_fc_props.sensor_unable_to_determine_slump = 'センサーがスランプを判定できない';
_fc_props.menu_settings_title_export = '輸出';
_fc_props.compare_company = '他の会社と比較する';
_fc_props.site_settings = 'サイト設定';
_fc_props.permission_type = '権限タイプ';
_fc_props.ticket_integration = 'チケット統合';
_fc_props.select_one_or_more_settings = '1つ以上の設定を選択してください';
_fc_props.export_tooltip = 'このオプションは、複数の会社に所属している場合にのみ利用できます。';
_fc_props.select_company_error = '会社を選択してください';
_fc_props.audible = '可聴';
_fc_props.deleted_master_data_successfully = 'マスターデータが正常に削除されました';
_fc_props.cloud_dvir__units_miles_abbr = 'ミシガン州';
_fc_props.cloud_dvir__units_kilometers_abbr = 'ケイエム';

export default _fc_props;
