export const EVENT_TYPE = {
  JWT_GENERATION_INIT: 'JWT_GENERATION_INIT',
  JWT_GENERATION_COMPLETED: 'JWT_GENERATION_COMPLETED',
  JWT_READY: 'JWT_READY',
  LOCATION_CHANGED: 'LOCATION_CHANGED',
  LOCATION_UPDATED: 'LOCATION_UPDATED',
  START_UP_DATA: 'START_UP_DATA',
  LOADED: 'LOADED_DVIR_UI',
  REFRESH_HOME_PAGE_DATA: 'REFRESH_HOME_PAGE_DATA',
  ENGINE_DATA: 'ENGINE_DATA',
  DRIVER_DATA: 'DRIVER_DATA',
  CLOSE_DVIR_UI: 'CLOSE_DVIR_UI',
  CLOSE_APPROVE: 'CLOSE_APPROVE',
  SAVED: 'SAVED',
  ADDRESS_REQUEST: 'ADDRESS_REQUEST',
  ADDRESS_UPDATED: 'ADDRESS_UPDATED',
  SUBMISSION: 'SUBMISSION',
  LOG: 'LOG',
  TRAILER_LIST: 'TRAILER_LIST',
};

export const DEFAULT_ODOMETER_ISO_UOM = 'SMI';
