import { useState, useEffect } from 'react';
import Template, { CategoryType, TemplateCategory } from '../../types/template';
import {
  InspectionValue, InspectorType, Photo, Submission,
} from '../../types/submission';
import { addCategoryNameSuffixByCategoryType } from '../utils/utils';

export enum AttachmentType { Notes = 'note', Photos = 'photos' }

export enum S3Type { JPEG = 'image/jpeg' }

export type Note = { text: string, isDriverNote: boolean, timestamp: number };

export type AttachmentFieldItem = { [AttachmentType.Notes]: Note[], [AttachmentType.Photos]: Photo[], name: string, id: string }

export type AttachmentsFormItem = Record<'name' | 'id', AttachmentFieldItem>

export type AttachmentsForm = Record<TemplateCategory['name'], { items: AttachmentsFormItem, categoryType?: CategoryType }>;

export type CategoriesEvents = { name: string, items: AttachmentsFormItem[], files: Photo[] };

export type ItemAttachments = { attachmentItems: AttachmentsFormItem[], attachmentFiles: Photo[] };

const transformTemplateToInitForm = (template: Template): AttachmentsForm => template.categories.reduce((res, category) => {
  const categoryName = addCategoryNameSuffixByCategoryType(category.name, category.categoryType);

  return {
    ...res,
    [categoryName]: {
      items: category.items.reduce((acc, item) => ({
        ...acc,
        [item.name]: {
          [AttachmentType.Notes]: [], [AttachmentType.Photos]: [], id: item.id, name: item.name,
        },
      }), {} as AttachmentsFormItem),
      categoryType: category.categoryType,
    },
  };
}, {} as AttachmentsForm);

export const transformSubmissionToForm = (submission: Submission): AttachmentsForm => submission.categories.reduce((res, category) => ({
  ...res,
  [category.name]: {
    items: category.items.reduce((acc, item) => {
      const notes = item.events
        .filter((event) => !!event.note)
        .filter((ev) => ev.safetyStatus !== InspectionValue.InProgress)
        .map((ev) => ({
          text: ev.note,
          timestamp: ev.updatedTime,
          isDriverNote: ev.inspectorType === InspectorType.Driver,
        }));
      const photos = item.events
        .filter((ev) => (ev.photos !== undefined))
        .flatMap((ev) => ev.photos);
      return {
        ...acc,
        [item.name]: {
          [AttachmentType.Notes]: notes, [AttachmentType.Photos]: photos, id: item.id, name: item.name,
        },
      };
    }, {}as AttachmentsFormItem),
  },
}), {} as AttachmentsForm);

export type AddAttachment = {
  categoryName: string,
  itemName: string,
  toAttach: string | Photo,
  type: AttachmentType
}

interface UseAttachmentsReturnType {
  form: AttachmentsForm;
  addAttachment: (attachmentOptions: AddAttachment) => void;
  editNote: (categoryName: string, itemName: string, noteText: string) => void;
  deleteNote: (categoryName: string, itemName: string) => void;
  deletePhoto: (categoryName: string, itemName: string, photoId: string) => void;
  hasFormChanged: () => boolean;
}

const useAttachments = (template: Template, submission: Submission): UseAttachmentsReturnType => {
  const [form, setForm] = useState<AttachmentsForm>(null);

  useEffect(() => {
    if (template) {
      const initForm = transformTemplateToInitForm(template);
      setForm(initForm);
    }
  }, [template]);

  useEffect(() => {
    if (submission) {
      const initForm = transformSubmissionToForm(submission);
      setForm(initForm);
    }
  }, [submission]);

  const addAttachment = ({
    categoryName,
    itemName,
    toAttach,
    type,
  }: AddAttachment) => {
    const { [categoryName]: category } = form;
    const attachment = type === AttachmentType.Notes ? { text: toAttach, timestamp: Date.now() } : toAttach;
    const attachments = {
      ...category.items[itemName],
      [type]: [...category.items[itemName][type], attachment],
    };
    const updatedCategory = {
      ...category,
      items: {
        ...category.items,
        [itemName]: attachments,
      },
    };

    setForm({ ...form, [categoryName]: updatedCategory });
  };

  const deletePhoto = (categoryName: string, itemName: string, photoId: string) => {
    const { [categoryName]: category } = form;
    const updatedPhotos = category.items[itemName][AttachmentType.Photos].filter(({ id }) => id !== photoId);

    const attachments = {
      ...category.items[itemName],
      [AttachmentType.Photos]: updatedPhotos,
    };
    const updatedCategory = {
      ...category,
      items: {
        ...category.items,
        [itemName]: attachments,
      },
    };

    setForm({ ...form, [categoryName]: updatedCategory });
  };

  const editNote = (categoryName: string, itemName: string, noteText: string) => {
    const { [categoryName]: category } = form;
    const note = { text: noteText, timestamp: Date.now() };

    const attachments = {
      ...category.items[itemName],
      [AttachmentType.Notes]: [note],
    };
    const updatedCategory = {
      ...category,
      items: {
        ...category.items,
        [itemName]: attachments,
      },
    };

    setForm({ ...form, [categoryName]: updatedCategory });
  };

  const deleteNote = (categoryName: string, itemName: string) => {
    const { [categoryName]: category } = form;

    const attachments = {
      ...category.items[itemName],
      [AttachmentType.Notes]: [],
    };
    const updatedCategory = {
      ...category,
      items: {
        ...category.items,
        [itemName]: attachments,
      },
    };

    setForm({ ...form, [categoryName]: updatedCategory });
  };

  const hasFormChanged = (): boolean => form && Object.values(form).some((category) => Object.values(category.items).some((item) => item[AttachmentType.Notes].length || item[AttachmentType.Photos].length));

  return {
    addAttachment, editNote, deleteNote, deletePhoto, form, hasFormChanged,
  };
};

export default useAttachments;
