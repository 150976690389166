const _fc_props = {};
_fc_props.ante_meridiem = 'AM';
_fc_props.post_meridiem = 'ਪ੍ਰਧਾਨ ਮੰਤਰੀ';
_fc_props.retry = 'ਮੁੜ ਕੋਸ਼ਿਸ';
_fc_props.default = 'ਮੂਲ';
_fc_props.origin = 'ਮੂਲ';
_fc_props.loading = 'ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ';
_fc_props.list = 'ਦੀ ਸੂਚੀ';
_fc_props.send = 'ਭੇਜੋ';
_fc_props.error = 'ਗਲਤੀ ਹੈ';
_fc_props.save = 'ਸੰਭਾਲੋ';
_fc_props.delete = 'ਹਟਾਓ';
_fc_props.message = 'ਸੁਨੇਹਾ';
_fc_props.logout = 'ਲਾਗਆਉਟ';
_fc_props.add = 'ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.remove = 'ਹਟਾਓ';
_fc_props.done = 'ਸਮਾਪਤ';
_fc_props.other = 'ਹੋਰ';
_fc_props.interval = 'ਅੰਤਰਾਲ';
_fc_props.time = 'ਟਾਈਮ';
_fc_props.speed = 'ਸਪੀਡ';
_fc_props.longitude = 'ਲੰਬਕਾਰ';
_fc_props.latitude = 'Latitude';
_fc_props.x_minutes = '{0} ਘੱਟੋ';
_fc_props.general = 'ਜਨਰਲ';
_fc_props.messages = 'ਸੁਨੇਹੇ';
_fc_props.gps_fixes = 'GPS ਫਿਕਸ';
_fc_props.device_id = 'ਜੰਤਰ ID';
_fc_props.vehicle = 'ਵਾਹਨ';
_fc_props.employee = 'ਕਰਮਚਾਰੀ';
_fc_props.version = 'ਵਰਜਨ';
_fc_props.statuses = 'ਹਾਲਤ';
_fc_props.edit = 'ਸੋਧ';
_fc_props.cancel = 'ਰੱਦ ਕਰੋ';
_fc_props.yes = 'ਜੀ';
_fc_props.none = 'ਕੋਈ';
_fc_props.never = 'ਕਦੇ ਵੀ';
_fc_props.name = 'ਨਾਮ';
_fc_props.true = 'ਇਹ ਸੱਚ ਹੈ';
_fc_props.status = 'ਸਥਿਤੀ';
_fc_props.distance = 'ਦੂਰੀ';
_fc_props.ok = 'ਠੀਕ ਹੈ';
_fc_props.login = 'ਲਾਗਿਨ';
_fc_props.next = 'ਅਗਲਾ';
_fc_props.menu = 'ਮੇਨੂ';
_fc_props.not_available = 'N / A';
_fc_props.back = 'ਵਾਪਸ';
_fc_props.select = 'ਦੀ ਚੋਣ ਕਰੋ';
_fc_props.dispatch = 'ਭੇਜਣ ਸਬੰਧੀ';
_fc_props.messaging = 'ਸੁਨੇਹਾ';
_fc_props.plants = 'ਪੌਦੇ';
_fc_props.last_plants = 'ਪਿਛਲੇ ਪੌਦੇ';
_fc_props.vehicle_types = 'ਵਾਹਨ ਕਿਸਮ';
_fc_props.employee_types = 'ਕਰਮਚਾਰੀ ਕਿਸਮ';
_fc_props.employee_groups = 'ਕਰਮਚਾਰੀ ਗਰੁੱਪ';
_fc_props.equipment_groups = 'ਉਪਕਰਣ ਗਰੁੱਪ';
_fc_props.job = 'ਅੱਯੂਬ';
_fc_props.order = 'ਆਰਡਰ';
_fc_props.ticket = 'ਟਿਕਟ #';
_fc_props.small = 'ਸਮਾਲ';
_fc_props.medium = 'ਦਰਮਿਆਨੇ';
_fc_props.large = 'ਵੱਡੇ';
_fc_props.reports = 'ਰਿਪੋਰਟ';
_fc_props.payroll = 'ਤਨਖਾਹ';
_fc_props.support = 'ਸਹਿਯੋਗ';
_fc_props.username = 'ਯੂਜ਼ਰ';
_fc_props.all = 'ਸਾਰੇ';
_fc_props.question = 'ਸਵਾਲ';
_fc_props.select_employee = 'ਚੁਣੋ ਕਰਮਚਾਰੀ';
_fc_props.phone = 'ਫੋਨ';
_fc_props.january = 'ਜਨਵਰੀ';
_fc_props.february = 'ਫਰਵਰੀ';
_fc_props.march = 'ਮਾਰਚ';
_fc_props.april = 'ਅਪ੍ਰੈਲ';
_fc_props.may = 'ਮਈ';
_fc_props.june = 'ਜੂਨ';
_fc_props.july = 'ਜੁਲਾਈ';
_fc_props.august = 'ਅਗਸਤ';
_fc_props.september = 'ਸਤੰਬਰ';
_fc_props.october = 'ਅਕਤੂਬਰ';
_fc_props.november = 'ਨਵੰਬਰ';
_fc_props.december = 'ਦਸੰਬਰ';
_fc_props.are_you_sure_you_want_to_delete = 'ਤੁਹਾਨੂੰ ਤੁਹਾਡੇ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ';
_fc_props.unknown = 'ਅਣਜਾਣ';
_fc_props.select_all = 'ਸਾਰੇ ਦੀ ਚੋਣ ਕਰੋ';
_fc_props.equipment = 'ਉਪਕਰਣ';
_fc_props.region = 'Region';
_fc_props.no_data = 'ਕੋਈ ਡਾਟਾ';
_fc_props.hotspot = 'ਬਿੰਦੂ';
_fc_props.address = 'ਪਤਾ';
_fc_props.retrieving_address = 'ਐਡਰੈੱਸ ਪ੍ਰਾਪਤ ...';
_fc_props.address_not_found = 'ਪਤਾ ਨਾ ਲੱਭੀ';
_fc_props.active_time = 'ਸਰਗਰਮ ਟਾਈਮ';
_fc_props.destination_time = 'ਟਿਕਾਣਾ ਟਾਈਮ';
_fc_props.complete_time = 'ਮੁਕੰਮਲ ਟਾਈਮ';
_fc_props.status_report = 'ਸਥਿਤੀ ਰਿਪੋਰਟ';
_fc_props.export_report = 'ਨਿਰਯਾਤ ਰਿਪੋਰਟ';
_fc_props.download_report = 'ਡਾਊਨਲੋਡ ਰਿਪੋਰਟ';
_fc_props.view_report = 'View ਰਿਪੋਰਟ';
_fc_props.duration = 'ਅੰਤਰਾਲ';
_fc_props.min = 'Min';
_fc_props.start_date_time = '/ ਟਾਈਮ ਤਾਰੀਖ ਸ਼ੁਰੂ ਕਰੋ';
_fc_props.select_start_date = 'ਚੁਣੋ ਸ਼ੁਰੂਕਰਨ ਦੀ ਿਮਤੀ';
_fc_props.end_date_time = 'ਅੰਤ ਮਿਤੀ / ਟਾਈਮ';
_fc_props.select_end_date = 'ਚੁਣੋ ਅੰਤ ਮਿਤੀ';
_fc_props.trip_time = 'ਟਰਿੱਪ ਦੀ ਟਾਈਮ';
_fc_props.travel_time = 'ਯਾਤਰਾ ਟਾਈਮ';
_fc_props.description = 'ਵੇਰਵਾ';
_fc_props.map_controls = 'Map ਕੰਟਰੋਲ';
_fc_props.road = 'ਰੋਡ';
_fc_props.aerial = 'ਏਰੀਅਲ';
_fc_props.hybrid = 'ਹਾਈਬ੍ਰਿਡ';
_fc_props.trip = 'ਟਰਿੱਪ ਦੀ';
_fc_props.map = 'ਨਕਸ਼ਾ';
_fc_props.plant = 'ਪੌਦਾ';
_fc_props.select_date = 'ਚੁਣੋ ਤਾਰੀਖ';
_fc_props.apply = 'ਲਾਗੂ ਕਰੋ';
_fc_props.save_settings = 'ਸੈਟਿੰਗ ਸੰਭਾਲੋ';
_fc_props.false = 'ਝੂਠੇ';
_fc_props.delete_confirmation = 'ਪੁਸ਼ਟੀ ਹਟਾਓ';
_fc_props.last_name = 'ਆਖਰੀ ਨਾਂਮ';
_fc_props.none_selected = 'ਕੋਈ ਚੁਣੇ';
_fc_props.start_date = 'ਤਾਰੀਖ ਸ਼ੁਰੂ';
_fc_props.group_by = 'ਗਰੁੱਪ ਦੁਆਰਾ';
_fc_props.equipment_employee = 'ਉਪਕਰਣ / ਕਰਮਚਾਰੀ';
_fc_props.inactive = 'ਸਰਗਰਮ';
_fc_props.active = 'ਸਰਗਰਮ';
_fc_props.note_checking_may_affect_performance = 'ਨੋਟ: ਚੈਿਕੰਗ ਪ੍ਰਦਰਸ਼ਨ ਨੂੰ ਪ੍ਰਭਾਵਿਤ ਕਰ ਸਕਦੀ ਹੈ';
_fc_props.show_distancefuel_used = 'ਵੇਖਾਓ ਦੂਰੀ / ਬਾਲਣ ਇਸਤੇਮਾਲ ਕੀਤਾ ਜਾਦਾ ਹੈ';
_fc_props.mins = 'Mins';
_fc_props.threshold_time = 'ਥਰੈਸ਼ਹੋਲਡ ਟਾਈਮ';
_fc_props.include_voided_tickets = 'ਰੱਦ ਟਿਕਟ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.note_all_times_listed_are_in_minutes = 'ਨੋਟ: ਸੂਚੀਬੱਧ ਸਾਰੇ ਵਾਰ ਮਿੰਟ ਵਿੱਚ ਹਨ';
_fc_props.please_select_a_job_order_or_equipment = 'ਕਿਰਪਾ ਕਰਕੇ ਕੋਈ ਨੌਕਰੀ, ਆਰਡਰ, ਪਲਾਂਟ, ਉਪਕਰਣ ਜਾਂ ਕਰਮਚਾਰੀ ਚੁਣੋ';
_fc_props.device = 'ਜੰਤਰ';
_fc_props.select_equipment = 'ਚੁਣੋ ਉਪਕਰਣ';
_fc_props.all_equipment_types = 'ਸਾਰੇ ਉਪਕਰਣ ਕਿਸਮ';
_fc_props.summary = 'ਸੰਖੇਪ';
_fc_props.units = {};
_fc_props.units.liters = 'ਲੀਟਰ';
_fc_props.type = 'ਦੀ ਕਿਸਮ';
_fc_props.no_data_available = 'ਕੋਈ ਡਾਟਾ';
_fc_props.menu = {};
_fc_props.menu.reports = {};
_fc_props.menu.reports.title = {};
_fc_props.menu.reports.title.distance_and_fuel_by_region = 'ਖੇਤਰ ਕੇ ਦੂਰੀ ਅਤੇ ਤੇਲ';
_fc_props.menu_settings_title_settings = 'ਸੈਟਿੰਗ';
_fc_props.menu_settings_title_alerts = 'ਚੇਤਾਵਨੀ';
_fc_props.menu.settings = {};
_fc_props.menu.settings.title = {};
_fc_props.menu.settings.title.equipment = 'ਉਪਕਰਨ';
_fc_props.menu_settings_title_hotspots_regions = 'ਸਪਾਟਸ & ਖੇਤਰ';
_fc_props.days = '{0} ਦਿਨ (s)';
_fc_props.home = 'घर';
_fc_props.find = 'ਲੱਭੋ';
_fc_props.close_this_panel = 'ਇਸ ਪੈਨਲ ਨੂੰ ਬੰਦ';
_fc_props.filters = 'ਫਿਲਟਰ';
_fc_props.start = 'ਸ਼ੁਰੂ ਕਰੋ';
_fc_props.end = 'ਅੰਤ';
_fc_props.location_details = 'ਸਥਿਤੀ ਵੇਰਵਾ';
_fc_props.first_name = 'ਪਹਿਲਾ ਨਾਂ';
_fc_props.material_per_hour = 'ਪ੍ਰਤੀ ਘੰਟਾ ਪਦਾਰਥ';
_fc_props.equipment_pound = 'ਉਪਕਰਣ #';
_fc_props.equipment_type = 'ਉਪਕਰਣ ਦੀ ਕਿਸਮ';
_fc_props.options = 'ਚੋਣ';
_fc_props.zoom_on_cursor_position = 'ਕਰਸਰ ਸਥਿਤੀ \'ਤੇ ਜ਼ੂਮ';
_fc_props.display_equipment_identifier = 'ਉਪਕਰਣ ਪਛਾਣਕਰਤਾ ਵੇਖਾਓ';
_fc_props.view_equipment = 'View ਉਪਕਰਣ';
_fc_props.view_hotspots = 'View ਸਪਾਟਸ';
_fc_props.view_regions = 'View ਖੇਤਰ';
_fc_props.use_standard_filters = 'ਮਿਆਰੀ ਫਿਲਟਰ ਵਰਤੋ';
_fc_props.home_plant = 'Home ਪੌਦਾ';
_fc_props.last_plant = 'ਪਿਛਲੇ ਪੌਦਾ';
_fc_props.equip_types = 'ਤਿਆਰ. ਕਿਸਮ';
_fc_props.equip_groups = 'ਤਿਆਰ. ਗਰੁੱਪ';
_fc_props.orders = 'ਦੇ ਆਦੇਸ਼';
_fc_props.trakitall = 'TrakitALL';
_fc_props.use_equipment_filter = 'ਵਰਤੋ ਉਪਕਰਣ ਫਿਲਟਰ';
_fc_props.all_selected = 'ਸਭ ਚੁਣੇ';
_fc_props.of = 'ਦੀ';
_fc_props.your_map_session_has_expired = 'ਤੁਹਾਡੇ ਨਕਸ਼ੇ ਸੈਸ਼ਨ ਖਤਮ ਹੋ ਗਿਆ ਹੈ, ਸਫਾ ਤਾਜ਼ਾ ਕਰੋ ਜੀ.';
_fc_props.equipment_short = 'ਤਿਆਰ.';
_fc_props.job_info_for = 'ਲਈ ਅੱਯੂਬ ਜਾਣਕਾਰੀ';
_fc_props.order_info_for = 'ਲਈ ਆਰਡਰ ਜਾਣਕਾਰੀ';
_fc_props.ticket_info_for_vehicle = 'ਵਾਹਨ ਲਈ ਟਿਕਟ ਜਾਣਕਾਰੀ';
_fc_props.inbox = 'ਇਨਬਾਕਸ';
_fc_props.outbox = 'ਆਉਟ';
_fc_props.alerts = 'ਚੇਤਾਵਨੀ';
_fc_props.critical = 'ਨਾਜ਼ੁਕ';
_fc_props.important = 'ਖਾਸ';
_fc_props.informational = 'ਜਾਣਕਾਰੀ';
_fc_props.view_routes = 'View ਰੂਟ';
_fc_props.hours_size = 'ਘੰਟੇ ਆਕਾਰ';
_fc_props.used_count = 'ਵਰਤੀ ਗਿਣਤੀ';
_fc_props.last_login = 'ਪਿਛਲੇ ਲਾਗਇਨ';
_fc_props.last_vehicle = 'ਪਿਛਲੇ ਵਾਹਨ';
_fc_props.regions = 'ਖੇਤਰ';
_fc_props.driver = 'ਡਰਾਈਵਰ';
_fc_props.js_delete = 'ਹਟਾਓ';
_fc_props.move = 'ਭੇਜੋ';
_fc_props.edit_hotspot = 'ਸੋਧ ਹੌਟਸਪੌਟ';
_fc_props.adjust_shape = 'ਸ਼ੇਪ ਅਡਜੱਸਟ';
_fc_props.edit_region = 'ਸੋਧ Region';
_fc_props.enter_a_start_address = 'ਇੱਕ ਸ਼ੁਰੂ ਐਡਰੈੱਸ ਦਿਓ ਜੀ';
_fc_props.select_a_start_hotspot = 'ਇੱਕ ਸ਼ੁਰੂ ਹੌਟਸਪੌਟ ਦੀ ਚੋਣ ਕਰੋ ਜੀ';
_fc_props.failed_to_find_start_hotspot = 'ਹੌਟਸਪੌਟ ਸ਼ੁਰੂ ਦਾ ਪਤਾ ਕਰਨ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.invalid_latitudelongitude = 'ਗਲਤ Latitude / ਲੰਬਕਾਰ';
_fc_props.enter_an_end_address = 'ਇੱਕ ਅੰਤ ਐਡਰੈੱਸ ਦਿਓ ਜੀ';
_fc_props.select_an_end_hotspot = 'ਇੱਕ ਅੰਤ ਹੌਟਸਪੌਟ ਦੀ ਚੋਣ ਕਰੋ ਜੀ';
_fc_props.failed_to_find_end_hotspot = 'ਹੌਟਸਪੌਟ ਨੂੰ ਖਤਮ ਦਾ ਪਤਾ ਕਰਨ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.print = 'ਪ੍ਰਿੰਟ ਕਰੋ';
_fc_props.these_directions_are_for_informational_purposes = 'ਇਹ ਨਿਰਦੇਸ਼ ਸਿਰਫ ਜਾਣਕਾਰੀ ਲਈ ਹਨ. ਕੋਈ ਗਰੰਟੀ ਆਪਣੇ ਪੂਰਾ ਜ ਸ਼ੁੱਧਤਾ ਦੇ ਸੰਬੰਧ ਬਣਾਇਆ ਗਿਆ ਹੈ. ਉਸਾਰੀ ਪ੍ਰਾਜੈਕਟ, ਟਰੈਫਿਕ, ਜ ਹੋਰ ਘਟਨਾ ਅਸਲ ਹਾਲਾਤ ਇਹ ਨਤੀਜੇ ਤੱਕ ਵੱਖਰੇ ਕਰਨ ਦਾ ਕਾਰਨ ਬਣ ਸਕਦਾ ਹੈ.';
_fc_props.enter_an_address = 'ਇੱਕ ਐਡਰੈੱਸ ਦਿਓ ਜੀ';
_fc_props.select_a_hotspot = 'ਨੂੰ ਇੱਕ ਹੌਟਸਪੌਟ ਚੁਣੋ ਜੀ';
_fc_props.failed_to_find_hotspot = 'ਹੌਟਸਪੌਟ ਨੂੰ ਲੱਭਣ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.select_a_vehicle = 'ਨੂੰ ਇੱਕ ਵਾਹਨ ਦੀ ਚੋਣ ਕਰੋ ਜੀ';
_fc_props.failed_to_find_vehicle = 'ਵਾਹਨ ਦਾ ਪਤਾ ਕਰਨ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.failed_to_find_address = 'ਐਡਰੈੱਸ ਨੂੰ ਲੱਭਣ ਲਈ ਫੇਲ੍ਹ ਹੈ';
_fc_props.failed_to_find_vehicles = 'ਖੋਜ ਵਿਧੀ ਦੇ ਵਿੱਚ ਵਾਹਨ ਦਾ ਪਤਾ ਕਰਨ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.get_address = 'ਪਤਾ ਲਵੋ';
_fc_props.results_for = 'ਲਈ ਨਤੀਜੇ';
_fc_props.error_retrieving_results = 'ਲੈਣ ਦੌਰਾਨ ਗਲਤੀ ਨਤੀਜੇ';
_fc_props.no_results_found = 'ਕੋਈ ਨਤੀਜੇ ਲੱਭਿਆ';
_fc_props.address_is_required = 'ਪਤਾ ਕਰਨ ਦੀ ਲੋੜ ਹੈ';
_fc_props.city_is_required = 'ਸਿਟੀ ਦੀ ਲੋੜ ਹੈ';
_fc_props.set_location = 'ਸੈੱਟ ਸਥਿਤੀ';
_fc_props.set_area_of_interest = 'ਦਿਲਚਸਪੀ ਦਾ ਖੇਤਰ ਸੈੱਟ ਕਰੋ';
_fc_props.center_map_here = 'Center ਨਕਸ਼ਾ ਇੱਥੇ';
_fc_props.zoom_to_street_level = 'ਸਟਰੀਟ ਪੱਧਰ ਲਈ ਜ਼ੂਮ';
_fc_props.set_start = 'ਸੈੱਟ ਕਰੋ ਸਟਾਰਟ';
_fc_props.set_end = 'ਸੈੱਟ ਕਰੋ ਅੰਤ';
_fc_props.delete_node = 'ਨੋਡ ਹਟਾਓ';
_fc_props.create_a_hotspot = 'ਨੂੰ ਇੱਕ ਹੌਟਸਪੌਟ ਬਣਾਓ';
_fc_props.create_a_region = 'ਇੱਕ ਖੇਤਰ ਬਣਾਓ';
_fc_props.toggle_shapes = 'ਬਦਲੋ ਸ਼ਕਲ';
_fc_props.refresh_map = 'ਤਾਜ਼ਾ ਕਰੋ Map';
_fc_props.more_options = 'ਹੋਰ ਚੋਣ';
_fc_props.recenter_map = 'Recenter ਨਕਸ਼ਾ';
_fc_props.zoom_to_vehicle = 'ਵਾਹਨ ਨੂੰ ਜ਼ੂਮ';
_fc_props.apply_filters = 'ਫਿਲਟਰ ਲਾਗੂ ਕਰੋ';
_fc_props.grid_view = 'ਗਰਿੱਡ ਵੇਖੋ';
_fc_props.list_view = 'ਲਿਸਟ ਝਲਕ';
_fc_props.click_to_sort = 'ਲੜੀਬੱਧ ਕਰਨ ਲਈ ਕਲਿੱਕ ਕਰੋ';
_fc_props.message_history = 'ਸੁਨੇਹਾ ਇਤਿਹਾਸ';
_fc_props.equipment_types = 'ਉਪਕਰਣ ਕਿਸਮ';
_fc_props.send_message = 'ਸੁਨੇਹਾ ਭੇਜੋ';
_fc_props.last_load = 'ਪਿਛਲੇ ਲੋਡ';
_fc_props.last_load_on = 'ਪਿਛਲੇ ਲੋਡ';
_fc_props.manual_logout = 'ਦਸਤਾਵੇਜ਼ ਲਾਗਆਉਟ';
_fc_props.manual_login = 'ਦਸਤਾਵੇਜ਼ ਲਾਗਇਨ';
_fc_props.no_employees_logged_in = 'ਕੋਈ ਕਰਮਚਾਰੀ ਲਾਗਇਨ ਕੀਤਾ ਵਿੱਚ';
_fc_props.select_phone = 'ਚੁਣੋ ਫੋਨ';
_fc_props.you_must_select_a_phone = 'ਤੁਹਾਨੂੰ ਇੱਕ ਫੋਨ, ਸਾਜ ਸਮਾਨ ਅਤੇ ਕਰਮਚਾਰੀ ਦੀ ਚੋਣ ਕਰਨੀ ਚਾਹੀਦੀ ਹੈ';
_fc_props.are_you_sure_you_want_to_logout = 'ਤੁਹਾਨੂੰ ਲਾਗਆਉਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ';
_fc_props.fix_time = 'ਵਾਰ ਲਈ ਹੱਲ਼';
_fc_props.to_next_fix = 'ਅੱਗੇ ਨੂੰ ਠੀਕ ਕਰਨ ਲਈ';
_fc_props.minutes_to_next_fix = 'ਅੱਗੇ ਨੂੰ ਹੱਲ ਕਰਨ ਲਈ ਮਿੰਟ';
_fc_props.liters = 'ਲੀਟਰ';
_fc_props.destination = 'ਟਿਕਾਣਾ';
_fc_props.return = 'ਵਾਪਸੀ';
_fc_props.product = 'ਉਤਪਾਦ';
_fc_props.no_records_found = 'ਕੋਈ ਰਿਕਾਰਡ ਦਾ ਪਾਇਆ.';
_fc_props.vehicle_type = 'ਵਾਹਨ ਦੀ ਕਿਸਮ';
_fc_props.view_in_map = 'Map ਵਿੱਚ ਸਭ ਵੇਖੋ';
_fc_props.uploads = 'ਅੱਪਲੋਡ';
_fc_props.upload_csv_file = 'CSV ਫਾਇਲ ਅੱਪਲੋਡ ਕਰੋ';
_fc_props.permanent = 'ਸਥਾਈ';
_fc_props.expire_time = 'ਟਾਈਮ ਮਿਆਦ';
_fc_props.please_select_x_for_tickets_marked_active = 'ਦੀ ਚੋਣ ਕਰੋ ਜੀ {0} ਟਿਕਟ ਸਰਗਰਮ ਕਰਨ ਲਈ';
_fc_props.multiple_tickets_marked_active_for_same_x = 'ਬਹੁ ਟਿਕਟ ਵੀ ਉਸੇ ਦੇ ਲਈ ਸਰਗਰਮ {0}';
_fc_props.failure_connecting_to_servlet = 'Servlet ਨਾਲ ਜੁੜ ਫੇਲ੍ਹ.';
_fc_props.status_time_summary = 'ਸਥਿਤੀ ਟਾਈਮ ਸੰਖੇਪ';
_fc_props.view_in_map_one = 'ਮੈਪ ਵਿੱਚ ਵੇਖੋ';
_fc_props.question_report = 'ਸਵਾਲ ਰਿਪੋਰਟ';
_fc_props.end_date = 'ਅੰਤ ਮਿਤੀ';
_fc_props.trailer_number = 'ਟ੍ਰੇਲਰ ਨੰਬਰ';
_fc_props.region_panel = 'ਖੇਤਰੀ ਪੈਨਲ';
_fc_props.hotspot_panel = 'ਬਿੰਦੂ ਪੈਨਲ';
_fc_props.shape = 'ਸ਼ੇਪ';
_fc_props.circle = 'ਸਰਕਲ';
_fc_props.polygon = 'ਬਹੁਭੁਜ';
_fc_props.click_the_map_to_set_the_location = 'ਲੋੜੀਦੀ ਸਥਿਤੀ ਦੇ ਸੈੱਟ ਕਰਨ ਲਈ ਨਕਸ਼ੇ ਨੂੰ ਦਬਾਓ.';
_fc_props.click_the_map_to_set_the_hotspot_center = 'ਬਿੰਦੂ Center ਸੈੱਟ ਕਰਨ ਲਈ ਨਕਸ਼ੇ ਨੂੰ ਦਬਾਓ.';
_fc_props.click_the_map_to_set_the_region_center = 'ਇਸ ਖੇਤਰ Center ਸੈੱਟ ਕਰਨ ਲਈ ਨਕਸ਼ੇ ਨੂੰ ਦਬਾਓ.';
_fc_props.click_and_drag_points = 'ਤੇ ਕਲਿੱਕ ਕਰੋ ਅਤੇ ਸ਼ਕਲ ਵਿੱਚ ਸੋਧ ਕਰਨ ਲਈ ਅੰਕ ਡਰੈਗ. ਤਬਦੀਲੀ ਨੂੰ ਸੰਭਾਲਣ ਲਈ ਸੰਭਾਲੋ ਨੂੰ ਦਬਾਉ.';
_fc_props.size = 'ਆਕਾਰ';
_fc_props.are_you_sure_you_want_to_move_this = 'ਤੁਹਾਨੂੰ ਇਸ ਨੂੰ ਜਾਣ ਲਈ ਚਾਹੁੰਦੇ ਹੋ';
_fc_props.are_you_sure_you_want_to_delete_this = 'ਤੁਹਾਨੂੰ ਇਸ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ';
_fc_props.please_enter_a_valid_size = 'ਇੱਕ ਠੀਕ ਦਾ ਆਕਾਰ ਦਿਓ ਜੀ';
_fc_props.failed_to_save = 'ਨੂੰ ਬਚਾਉਣ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.failed_to_move = 'ਜਾਣ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.failed_to_delete = 'ਨੂੰ ਹਟਾਉਣ ਲਈ ਫੇਲ੍ਹ ਹੈ.';
_fc_props.x_hours = '{0} ਘੰਟੇ';
_fc_props.hide_hotspots = 'ਓਹਲੇ ਸਪਾਟਸ';
_fc_props.show_regions = 'ਵੇਖੋ ਖੇਤਰ';
_fc_props.show_hotspots = 'ਵੇਖਾਓ ਸਪਾਟਸ';
_fc_props.hide_regions = 'ਓਹਲੇ ਖੇਤਰ';
_fc_props.numeric = 'ਅੰਕੀ';
_fc_props.message_type = 'ਸੁਨੇਹਾ ਕਿਸਮ';
_fc_props.text = 'ਪਾਠ';
_fc_props.yes_no = 'ਕੋਈ / ਜੀ';
_fc_props.responses = 'ਪ੍ਰਤਿਕਿਰਿਆ';
_fc_props.disabled = 'ਅਯੋਗ';
_fc_props.group_name = 'ਗਰੁੱਪ ਨਾਮ';
_fc_props.are_you_sure_you_want_to_delete_the_x_format = 'ਤੁਹਾਨੂੰ ਤੁਹਾਡੇ \'{0}\' ਫਾਰਮੈਟ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.phone_number = 'ਫੋਨ ਨੰਬਰ';
_fc_props.interface_pound = 'ਇੰਟਰਫੇਸ #';
_fc_props.field = 'ਫੀਲਡ';
_fc_props.default_label = 'ਮੂਲ ਲੇਬਲ';
_fc_props.custom_label = 'ਕਸਟਮ ਲੇਬਲ';
_fc_props.you_must_enter_a_label = 'ਤੁਹਾਨੂੰ ਇੱਕ ਲੇਬਲ ਦੇਣਾ ਚਾਹੀਦਾ ਹੈ.';
_fc_props.enabled = 'ਯੋਗ';
_fc_props.settings = {};
_fc_props.settings.ticket_integration = 'ਟਿਕਟ ਏਕੀਕਰਣ';
_fc_props.settings.geofence_off_open_hotspots = 'ਓਪਨ ਸਪਾਟਸ ਬੰਦ Geofence';
_fc_props.settings.associate_open_hotspots_wticket = 'ਐਸੋਸੀਏਟ ਓਪਨ ਸਪਾਟਸ / ਟਿਕਟ W';
_fc_props.settings.allow_geofencing_off_of_open_hotspots_or_only_hotspots_associated_with_drivers_ticket = 'ਡਰਾਈਵਰ ਦੀ ਟਿਕਟ ਦੇ ਨਾਲ ਸਬੰਧਿਤ ਖੁੱਲ੍ਹੇ ਹਾਟ ਜ ਸਿਰਫ ਹਾਟ ਦੇ ਬੰਦ geofencing ਦੀ ਇਜ਼ਾਜਤ';
_fc_props.settings.whether_or_not_to_associate_open_hotspots_with_tickets_when_a_vehicle_enters_them = 'ਇੱਕ ਵਾਹਨ ਨੂੰ ਪਰਵੇਸ਼ ਜਦ ਟਿਕਟ ਦੇ ਨਾਲ ਖੁੱਲ੍ਹੇ ਸਪਾਟਸ ਸਬੰਧਤ ਕਰਨ ਲਈ ਕੀ ਨਾ';
_fc_props.settings.void_ticket_without_destination = 'ਮੰਜ਼ਿਲ ਬਿਨਾ ਰੱਦ ਟਿਕਟ';
_fc_props.settings.if_a_ticket_is_received_while_another_one_is_active_if_the_active_ticket_does = 'ਟਿਕਟ ਪ੍ਰਾਪਤ ਕੀਤੀ ਹੈ, ਜੇਕਰ ਸਰਗਰਮ ਟਿਕਟ ਟਿਕਟ ਨੂੰ ਖ਼ਤਮ ਕੀਤਾ ਜਾਵੇਗਾ, ਨੂੰ ਇੱਕ ਮੰਜ਼ਿਲ ਹੈ, ਨਾ ਕਰਦਾ, ਜੇ ਇੱਕ ਹੋਰ, ਸਰਗਰਮ ਹੈ, ਜਦਕਿ.';
_fc_props.settings.autocreate_hotspots = 'ਆਟੋ-ਬਣਾਉਣ ਸਪਾਟਸ';
_fc_props.settings.whether_or_not_to_automatically_create_hotspots_from_the_destination = 'ਟਿਕਟ ਪ੍ਰਾਪਤ ਕੀਤੀ ਹੈ, ਜਦ ਆਪਣੇ ਆਪ ਹੀ ਮੰਜ਼ਿਲ ਦਾ ਪਤਾ ਤੱਕ ਹਾਟ ਬਣਾਉਣ ਲਈ ਕਿ ਨਾ';
_fc_props.settings.autoactivate_ticket_upon_receive = 'ਪ੍ਰਾਪਤ ਹੋਣ ਤੇ ਆਟੋ-activate ਟਿਕਟ';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_true = 'ਇਸ ਨੂੰ TrackIt ਕੇ ਪ੍ਰਾਪਤ ਕੀਤੀ ਹੈ, ਜਦ ਕਿ ਟਿਕਟ ਸਰਗਰਮ ਹੈ (ਮੂਲ \'ਇਹ ਸੱਚ ਹੈ,\')';
_fc_props.settings.auto_close_active_ticket_upon_receive_minutes = 'ਮਿੰਟ ਪ੍ਰਾਪਤ ਹੋਣ ਤੇ ਆਟੋ Close ਐਕਟਿਵ ਟਿਕਟ';
_fc_props.settings.how_long_does_the_current_active_ticket_need_to_have_been_active_before = 'ਮੌਜੂਦਾ ਸਰਗਰਮ ਟਿਕਟ (\'\' ਮੂਲ) ਆਟੋ-ਬੰਦ ਕਰਨ ਲਈ ਇਸ ਨੂੰ ਅੱਗੇ ਸਰਗਰਮ ਕੀਤਾ ਗਿਆ ਹੈ, ਦੀ ਲੋੜ ਹੈ ਕਿੰਨਾ ਚਿਰ';
_fc_props.settings.ticket_max_activate_age_minutes = 'ਟਿਕਟ ਮੈਕਸ ਸਰਗਰਮ ਉਮਰ ਮਿੰਟ';
_fc_props.settings.how_old_in_minutes_can_a_ticket_be_when_activating_it_in_trackit_default_ = 'TrackIt ਵਿਚ ਇਸ ਨੂੰ ਸਰਗਰਮ ਹੋਣ ਦਾ ਪੁਰਾਣੇ ਮਿੰਟ ਵਿਚ ਇਕ ਟਿਕਟ (ਮੂਲ \'\') ਹੋ ਸਕਦਾ ਹੈ';
_fc_props.settings.max_hotspot_unused_days = 'ਮੈਕਸ ਹੌਟਸਪੌਟ ਇਸਤੇਮਾਲ ਦਿਨ';
_fc_props.settings.sets_the_max_days_a_hotspot_can_be_unused_and_still_be_revived = 'ਨੂੰ ਇੱਕ ਹੌਟਸਪੌਟ ਵਰਤੇ ਹੋ ਸਕਦਾ ਹੈ ਅਤੇ ਹਾਲੇ ਵੀ ਨੇ ਜੀਵਨ ਜਾ ਮੈਕਸ ਦਿਨ ਸੈੱਟ';
_fc_props.settings.radius_multiplier = 'Radius ਮਲਟੀਪਲਾਇਰ';
_fc_props.settings.what_to_multiply_the_radius_by_when_a_ticket_is_received_from_the = 'ਟਿਕਟ ਡਿਸਪੈਚ ਸਿਸਟਮ ਤੱਕ ਪ੍ਰਾਪਤ ਕੀਤੀ ਹੈ, ਜਦ ਕੇ ਘੇਰੇ ਗੁਣਾ ਕਰਨ ਲਈ ਕੀ';
_fc_props.settings.ticket_message_date_format = 'ਟਿਕਟ ਸੁਨੇਹਾ ਮਿਤੀ ਫਾਰਮੈਟ ਹੈ';
_fc_props.am = 'am';
_fc_props.pm = 'ਪ੍ਰਧਾਨ ਮੰਤਰੀ';
_fc_props.settings.ticket_message = 'ਟਿਕਟ ਸੁਨੇਹਾ';
_fc_props.settings.add_ticket_element = 'ਟਿਕਟ Element ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.driver_performance = 'ਡਰਾਈਵਰ ਦੀ ਕਾਰਜਕੁਸ਼ਲਤਾ ਨੂੰ';
_fc_props.update_failed_can_not_connect = 'ਅੱਪਡੇਟ ਲਈ ਫੇਲ੍ਹ: servlet ਨਾਲ ਜੁੜਨ ਜਾ ਸਕਦੀ ਹੈ.';
_fc_props.unknown_error = 'ਅਣਜਾਣ ਗਲਤੀ ਹੈ';
_fc_props.selected = 'ਚੁਣੇ';
_fc_props.web_service = 'ਵੈੱਬ ਸਰਵਿਸ';
_fc_props.error_processing_request = 'ਗਲਤੀ ਹੈ ਨੂੰ ਕਾਰਵਾਈ ਕਰਨ ਦੀ ਬੇਨਤੀ.';
_fc_props.email_address = 'ਈਮੇਲ ਪਤਾ';
_fc_props.id = 'ID ਨੂੰ';
_fc_props.last_used = 'ਆਖਰੀ ਵਾਰ ਵਰਤਿਆ';
_fc_props.error_invalid_vehiclenum = 'ਗਲਤੀ ਅਯੋਗ ਵਹੀਕਲ ਨੰਬਰ';
_fc_props.error_vehicle_is_not_logged_in = 'ਗਲਤੀ: ਵਾਹਨ ਵਿੱਚ ਲਾਗਇਨ ਨਾ ਗਿਆ ਹੈ.';
_fc_props.users = 'ਉਪਭੋਗੀ';
_fc_props.you_dont_have_access_to_this_page = 'ਤੁਹਾਡੇ ਕੋਲ ਇਸ ਪੰਨੇ ਨੂੰ ਦੇਖਣ ਦੀ ਪਹੁੰਚ ਨਹੀਂ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਪਹੁੰਚ ਲਈ ਆਪਣੇ ਸਿਸਟਮ ਪ੍ਰਬੰਧਕ ਨਾਲ ਸੰਪਰਕ ਕਰੋ.';
_fc_props.previous_week = 'ਪਿਛਲੇ ਹਫ਼ਤੇ';
_fc_props.status_breakdown = 'ਸਥਿਤੀ ਬਰੇਕਡਾਉਨ';
_fc_props.select_plant_or_employee_group = 'ਤੁਹਾਨੂੰ ਇੱਕ ਪੌਦਾ ਜ ਇੱਕ ਕਰਮਚਾਰੀ ਦੇ ਗਰੁੱਪ ਨੂੰ ਕੋਈ ਚੋਣ ਕਰਨੀ ਚਾਹੀਦੀ ਹੈ.';
_fc_props.cannot_select_plant_and_employee_group = 'ਤੁਹਾਨੂੰ ਇੱਕ ਪੌਦਾ ਅਤੇ ਇੱਕ ਕਰਮਚਾਰੀ ਦੇ ਗਰੁੱਪ ਨੂੰ ਦੋਨੋ ਦੀ ਚੋਣ ਨਾ ਕਰ ਸਕਦਾ ਹੈ.';
_fc_props.odometer_must_be_a_number = 'ਓਡੋਮੀਟਰ ਸ਼ੁਰੂ ਇੱਕ ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.ending_odometer_must_be_a_number = 'ਓਡੋਮੀਟਰ Ending ਇੱਕ ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.beginning_hour_meter_must_be_a_number = 'ਘੰਟਾ ਮੀਟਰ ਸ਼ੁਰੂ ਇੱਕ ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.ending_hour_meter_must_be_a_number = 'ਘੰਟਾ ਮੀਟਰ Ending ਇੱਕ ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.error_loading_status_change_page = 'ਲੋਡ ਕਰਨ ਦੌਰਾਨ ਗਲਤੀ ਹਾਲਤ ਤਬਦੀਲੀ ਸਫ਼ਾ.';
_fc_props.x_is_an_invalid_time_format = '{0} ਇਸ ਨੂੰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ ਕਿ ਇੱਕ ਗਲਤ ਵਾਰ ਫਾਰਮੈਟ ਹੈ YYYY-MM-dd HH: MM: SS';
_fc_props.x_for_schedule_login = '{0} ਅਨੁਸੂਚੀ ਲਾਗਇਨ ਲਈ';
_fc_props.x_for_schedule_logout = '{0} ਅਨੁਸੂਚੀ ਲਾਗਆਉਟ ਲਈ';
_fc_props.x_for_actual_login = '{0} ਅਸਲ ਲਾਗਇਨ ਲਈ';
_fc_props.x_for_actual_logout = '{0} ਅਸਲ ਲਾਗਆਉਟ ਲਈ';
_fc_props.by_refreshing_the_time_card_detail = 'ਤੁਹਾਨੂੰ ਸਭ ਬਦਲਾਅ ਖਤਮ ਹੋ ਜਾਵੇਗੀ Time ਕਾਰਡ ਵੇਰਵੇ ਤਾਜ਼ਗੀ ਕੇ, ਜਾਰੀ ਰੱਖਣਾ ਹੈ?';
_fc_props.are_you_sure_you_want_to_delete_0 = 'ਤੁਹਾਨੂੰ {0} ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.settings.command_mobile_ticket_url = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ URL';
_fc_props.year = 'ਸਾਲ';
_fc_props.make = 'ਯਕੀਨੀ ਬਣਾਓ';
_fc_props.model = 'ਮਾਡਲ';
_fc_props.settings.command_mobile_ticket_password = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਪਾਸਵਰਡ';
_fc_props.settings.command_mobile_ticket_auth_token = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਪ੍ਰਮਾਣੀ ਟੋਕਨ';
_fc_props.target = 'Target';
_fc_props.number_of_digits = 'ਅੰਕ ਦੀ ਗਿਣਤੀ ਹੈ';
_fc_props.number_of_integer_digits = 'ਅੰਕ ਅੰਕ ਦੀ ਗਿਣਤੀ ਹੈ';
_fc_props.number_of_fractional_digits = 'ਦਸ਼ਮਲਵ ਅੰਕ ਦੀ ਗਿਣਤੀ ਹੈ';
_fc_props.search = 'ਖੋਜ';
_fc_props.settings.command_mobile_ticket_date_order = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਤਾਰੀਖ ਆਰਡਰ';
_fc_props.settings.command_mobile_ticket_date_length = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਦੀ ਮਿਤੀ ਦੀ ਲੰਬਾਈ';
_fc_props.settings.command_mobile_ticket_date_separator = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਦੀ ਮਿਤੀ ਦੀ ਵੱਖਰੇਵੇ';
_fc_props.settings.command_mobile_ticket_slash = '/ (ਸਲੈਸ਼)';
_fc_props.settings.command_mobile_ticket_dash = '- (ਡੈਸ਼)';
_fc_props.settings.command_mobile_ticket_period = '. (ਦੀ ਮਿਆਦ)';
_fc_props.settings.command_mobile_ticket_space = '(ਸਪੇਸ)';
_fc_props.settings.command_mobile_ticket_time_length = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਟਾਈਮ ਦੀ ਲੰਬਾਈ';
_fc_props.settings.command_mobile_ticket_time_separator = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ Time ਵੱਖਰੇਵੇ';
_fc_props.settings.command_mobile_ticket_colon = ': (ਕੌਲਨ)';
_fc_props.settings.command_mobile_ticket_time_format = 'ਹੁਕਮ ਮੋਬਾਈਲ ਟਿਕਟ ਟਾਈਮ ਫਾਰਮੈਟ';
_fc_props.settings.command_mobile_ticket_use_interface_number = 'ਵਰਤਣ ਵਾਲੇ ਇੰਟਰਫੇਸ ਨੰਬਰ';
_fc_props.mechanic = 'ਮਕੈਨਿਕ';
_fc_props.settings.command_mobile_ticket_poll_interval = 'ਪੋਲ ਅੰਤਰਾਲ (ਸਕਿੰਟ)';
_fc_props.driving_time = 'ਡਰਾਈਵਿੰਗ ਟਾਈਮ';
_fc_props.find_near_plants = 'ਨੇੜੇ ਪੌਦੇ ਲੱਭੋ';
_fc_props.please_create_hot_spot_first = 'ਪਹਿਲੇ ਬਿੰਦੂ ਬਣਾਉਣ ਕਰੋ ਜੀ';
_fc_props.please_enter_hot_spot_description = 'ਤਾਜ਼ਾ ਸਪਾਟ ਵੇਰਵਾ ਦਿਓ ਜੀ';
_fc_props.please_select_a_hot_spot_type = 'ਇੱਕ ਗਰਮ ਸਪਾਟ ਕਿਸਮ ਦੀ ਚੋਣ ਕਰੋ ਜੀ';
_fc_props.settings.command_mobile_ticket_send_driver_email = 'ਡਰਾਈਵਰ ਈਮੇਲ ਭੇਜੋ';
_fc_props.directions_not_found = 'ਗਲਤ {0} ਪਤਾ. ਨਿਰਦੇਸ਼ ਨਾ.';
_fc_props.ws_user = 'ਯੂਜ਼ਰ';
_fc_props.ws_service = 'ਸੇਵਾ';
_fc_props.ws_method = 'ਢੰਗ';
_fc_props.ws_params = 'ਪੈਰਾਮੀਟਰ';
_fc_props.units_kilometers_abbr = 'KM';
_fc_props.units_miles_abbr = 'MI';
_fc_props.origin_and_destination = 'ਮੂਲ ਅਤੇ ਮੰਜ਼ਿਲ';
_fc_props.liter_abbr = 'l';
_fc_props.help = 'ਮਦਦ ਕਰੋ';
_fc_props.settings.command_mobile_ticket_use_alt_emp_number = 'ਛੁਪਾਓ ਵਰਤੋ Alt ਕਰਮਚਾਰੀ ਨੰਬਰ';
_fc_props.settings.command_mobile_ticket_android_url = 'ਛੁਪਾਓ ਮੋਬਾਈਲ ਟਿਕਟ URL ਨੂੰ';
_fc_props.settings.command_mobile_ticket_android_secret = 'ਛੁਪਾਓ ਮੋਬਾਈਲ ਟਿਕਟ ਰਾਜ਼';
_fc_props.employee_x_has_no_logout_on_y = 'ਕਰਮਚਾਰੀ {0} \'ਤੇ ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਇੱਕ ਤਬਦੀਲੀ ਲਈ ਕੋਈ ਲਾਗਆਉਟ ਹਨ {1}.';
_fc_props.phone_number_has_duplicates = 'ਫੋਨ ਨੰਬਰ ਡੁਪਲੀਕੇਟ ਇੰਦਰਾਜ਼ ਹੈ';
_fc_props.ounce = 'ਰੰਚਕ';
_fc_props.water_extra = 'ਪਾਣੀ ਦੀ ਵਾਧੂ';
_fc_props.always = 'ਹਮੇਸ਼ਾ';
_fc_props.work_types_must_be_unique = 'ਕੰਮ ਦੇ ਕਿਸਮ ਵਿਲੱਖਣ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.manufacturer = 'ਨਿਰਮਾਤਾ';
_fc_props.need_to_enter_seconds = 'ਸਕਿੰਟ ਦਿਓ, ਕਰਨ ਦੀ ਲੋੜ ਹੈ.';
_fc_props.copy = 'ਕਾਪੀ ਕਰੋ';
_fc_props.create_in_existing_required = 'ਮੌਜੂਦਾ ਰੋਮਿੰਗ ਹੌਟਸਪੌਟ ਨੂੰ ਬਣਾਉਣ ਲਈ ਚੁਣਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ ਵਿੱਚ ਬਣਾਓ.';
_fc_props.break = 'ਬਰੇਕ';
_fc_props.add_export_data_type = 'ਐਕਸਪੋਰਟ ਡੇਟਾ ਟਾਈਪ';
_fc_props.edit_export_data_type = 'ਐਕਸਪੋਰਟ ਡੇਟਾ ਟਾਈਪ';
_fc_props.delete_export_data_type = 'ਡੇਟਾ ਐਕਸਪੋਰਟ ਭੇਜੋ ਹਟਾਓ';
_fc_props.export_data_type = 'ਐਕਸਪੋਰਟ ਡੇਟਾ ਟਾਈਪ';
_fc_props.error_updating_export_data_type = 'ਐਕਸਪੋਰਟ ਡੇਟਾ ਕਿਸਮ ਅਪਡੇਟ ਕਰਨ ਵਿੱਚ ਅਸ਼ੁੱਧੀ';
_fc_props.settings.address_key = 'ਪਤਾ ਕੁੰਜੀ';
_fc_props.settings.address_key_hover = 'ਐਡਰੈੱਸ ਕੁੰਜੀ ਚੋਣਾਂ';
_fc_props.settings.address_key_options = 'ਐਡਰੈੱਸ ਕੁੰਜੀ ਚੋਣਾਂ';
_fc_props.more_filter_options = 'ਹੋਰ ਫਿਲਟਰ ਵਿਕਲਪ';
_fc_props.unauthorized_mapit_app = 'MapIt ਦਾ ਇਹ ਸੰਸਕਰਣ ਅਧਿਕਾਰਿਤ ਨਹੀਂ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਜਿੰਨੀ ਛੇਤੀ ਸੰਭਵ ਹੋਵੇ MapIt ਨੂੰ ਅਪਡੇਟ ਕਰੋ.';
_fc_props.zello_timeout = 'ਜ਼ੇਲੋ ਨਾਲ ਸੰਪਰਕ ਕੀਤਾ ਗਿਆ ਸਮਾਂ ਸਮਾਪਤ ਹੋਇਆ';
_fc_props.user_agreement_text = 'ਲਾੱਗਇਨ ਕਰਕੇ, ਤੁਸੀਂ ਇਸ ਨਾਲ ਸਹਿਮਤ ਹੋ';
_fc_props.user_agreement = 'ਯੂਜ਼ਰ ਸਮਝੌਤਾ';
_fc_props.upload = 'ਅਪਲੋਡ ਕਰੋ';
_fc_props.x_mins_left = '{0} ਮਿੰਟ (s) ਖੱਬੇ';
_fc_props.continuous_location_tracking_colon = 'ਨਿਰੰਤਰ ਨਿਰਧਾਰਿਤ ਸਥਾਨ ਟਰੈਕਿੰਗ:';
_fc_props.hired_hauler_cannot_register_device = 'ਭਾੜੇਦਾਰ Hauler ਨਵੇਂ ਡਿਵਾਈਸ ਨੂੰ ਰਜਿਸਟਰ ਨਹੀਂ ਕਰ ਸਕਦਾ.';
_fc_props.timecard_does_not_contain_shift = 'ਕਰਮਚਾਰੀ ਦਾ ਟਾਈਮ ਕਾਰਡ ਪ੍ਰਦਾਨ ਕੀਤੀ ਗਈ ਤਬਦੀਲੀ (ਟਾਈਮ ਕਾਰਡ ਜਾਣਕਾਰੀ) ਨਹੀਂ ਰੱਖਦਾ';
_fc_props.timecard_not_found = 'ਕਰਮਚਾਰੀ ਦਾ ਟਾਈਮ ਕਾਰਡ ਨਹੀਂ ਮਿਲਿਆ';
_fc_props.vehicle_must_be_assigned_for_clt = 'ਸਥਾਨਾਂ ਨੂੰ ਟ੍ਰੈਕ ਕਰਨ ਲਈ ਵਾਹਨ ਨੂੰ ਨਿਰਧਾਰਤ ਕੀਤਾ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ.';
_fc_props.vehicle_unassigned_continuous_tracking_disabled = 'ਵਾਹਨ ਦੀ ਨਿਯੁਕਤੀ ਨਾ ਕੀਤੀ ਗਈ ਸੀ ਤਾਂ ਲਗਾਤਾਰ ਟਰੈਕਿੰਗ ਅਯੋਗ ਹੋ ਜਾਵੇਗੀ.';
_fc_props.units_yards_abbr = 'YD';
_fc_props.units_meters_abbr = 'ਐਮ';
_fc_props.error_lost_edc_connection = 'ਈਡੀਸੀ ਨਾਲ ਕੁਨੈਕਸ਼ਨ ਖਤਮ ਹੋਇਆ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ WiFi ਅਤੇ / ਜਾਂ Bluetooth ਦੀ ਜਾਂਚ ਕਰੋ';
_fc_props.acknowledge_all = 'ਸਭ ਨੂੰ ਸਵੀਕਾਰ ਕਰੋ';
_fc_props.expand = 'ਫੈਲਾਓ';
_fc_props.collapse = 'ਸਮੇਟੋ';
_fc_props.expand_all = 'ਸਭ ਫੈਲਾਓ';
_fc_props.collapse_all = 'ਸਾਰੇ ਸੰਕੁਚਿਤ ਕਰੋ';
_fc_props.last_update = 'ਆਖਰੀ ਅਪਡੇਟ';
_fc_props.acknowledge_alert = 'ਸਵੀਕਾਰ ਕਰੋ';
_fc_props.acknowledge_message = 'ਸੰਦੇਸ਼ ਨੂੰ ਸਵੀਕਾਰ ਕਰੋ';
_fc_props.acknowledge_all_alerts = 'ਸਾਰੇ ਅਲਰਟਸ ਸਵੀਕਾਰ ਕਰੋ';
_fc_props.acknowledge_all_messages = 'ਸਾਰੇ ਸੁਨੇਹੇ ਸਵੀਕਾਰ ਕਰੋ';
_fc_props.eta = 'ਈ.ਟੀ.ਏ.';
_fc_props.tolerance_must_be_a_valid_integer = 'ਸਹਿਣਸ਼ੀਲਤਾ -99 ਅਤੇ 99 ਦੇ ਵਿਚਕਾਰ ਇੱਕ ਜਾਇਜ਼ ਪੂਰਨ ਅੰਕ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ (ਦੋਵੇਂ ਸ਼ਾਮਲ ਹਨ)';
_fc_props.gps_permission_required = 'GPS ਅਧਿਕਾਰ ਦੀ ਲੋੜ ਹੈ';
_fc_props.permissions_required = 'ਅਧਿਕਾਰਾਂ ਦੀ ਲੋੜ ਹੈ';
_fc_props.change_user = 'ਯੂਜ਼ਰ ਬਦਲੋ';
_fc_props.account_not_registered_with_carrier = 'ਇਹ ਖਾਤਾ ਇੱਕ ਕੈਰੀਅਰ ਨਾਲ ਰਜਿਸਟਰਡ ਨਹੀਂ ਹੈ';
_fc_props.slump_loss = 'ਡਿੱਗਣ ਦੀ ਘਾਟ';
_fc_props.slump_loss_help = 'ਹਾਦਸੇ ਦਾ ਨੁਕਸਾਨ ਨੁਕਸਾਨ';
_fc_props.did_not_receive_any_edc_devices = 'ਕਿਸੇ ਵੀ EDC ਡਿਵਾਈਸਾਂ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਈਆਂ';
_fc_props.edc_record_not_found = 'EDC ਰਿਕਾਰਡ ਨਹੀਂ ਮਿਲਿਆ ਸੀ';
_fc_props.disable_hotspot_prompt = 'ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਹੌਟਸਪੌਟ ਨੂੰ ਅਸਮਰੱਥ ਕਰਨ ਲਈ ਪ੍ਰੋਂਪਟ ਹੋਣ ਤੇ "ਠੀਕ ਹੈ" ਟੈਪ ਕਰੋ';
_fc_props.edc_device_is_in_use = 'EDC {0} ਵਰਤੋਂ ਵਿੱਚ ਹੈ.';
_fc_props.an_unknown_exception_has_occurred = 'ਇੱਕ ਅਣਜਾਣ ਅਪਵਾਦ ਆਇਆ ਹੈ';
_fc_props.login_has_timed_out = 'ਲੌਗਿਨ ਦਾ ਸਮਾਂ ਸਮਾਪਤ ਹੋ ਗਿਆ ਹੈ';
_fc_props.use_paper_logs = 'ਪੇਪਰ ਲੌਗਜ਼ ਵਰਤੋ';
_fc_props.eld_login_failed = 'ਸੇਵਾ ਦਾ ਲਾਗਿੰਨ ਅਸਫਲ';
_fc_props.setup_time_off_type = 'ਕਿਰਪਾ ਕਰਕੇ ਟਾਈਪ ਆਫ਼ ਟਾਈਪ ਸੈਟ ਅਪ ਕਰੋ.';
_fc_props.view_load_properties = 'ਲੋਡ ਵਿਸ਼ੇਸ਼ਤਾ ਵੇਖੋ';
_fc_props.couldnt_download_edc_firmware = 'ਈਡੀਸੀ ਫਰਮਵੇਅਰ ਨੂੰ ਡਾਊਨਲੋਡ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਿਆ.';
_fc_props.password_incorrect = 'ਪਾਸਵਰਡ ਗਲਤ ਹੈ';
_fc_props.edc_configuration_password_prompt = 'ਇਸ ਡਿਵਾਈਸ ਦੀ ਸਥਿਤੀ ਦੇ ਕਾਰਨ, ਤੁਹਾਨੂੰ ਕੌਂਫਿਗਰ ਕਰਨ ਲਈ ਸਥਾਪਿਤ ਕਰਨ ਵਾਲਾ ਪਾਸਵਰਡ ਦੇਣਾ ਪਵੇਗਾ.';
_fc_props.edc_configuration_check_failed = 'EDC ਸਰਵਰ ਰਿਕਾਰਡ ਪ੍ਰਮਾਣਿਕਤਾ ਅਸਫਲ ਵੇਰਵਿਆਂ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਗਲਤੀ ਸੰਦੇਸ਼ਾਂ ਦੀ ਜਾਂਚ ਕਰੋ.';
_fc_props.configuration_issues_prompt = 'ਨਿਰਧਾਰਤ ਟਰੱਕ ਲਈ ਚੁਣੇ ਗਏ ਈਡੀਸੀ ਨੂੰ ਕੌਂਫਿਗਰ ਕਰਦੇ ਸਮੇਂ ਹੇਠ ਦਿੱਤੇ ਮੁੱਦਿਆਂ ਦਾ ਪਤਾ ਲਗਾਇਆ ਗਿਆ ਸੀ';
_fc_props.checking_password = 'ਪਾਸਵਰਡ ਚੈੱਕ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ';
_fc_props.reset_password_instructions_sent = 'ਈਮੇਲ ਐਡਰੈੱਸ sent 0 to \'ਤੇ ਭੇਜਿਆ ਗਿਆ ਆਪਣਾ ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰਨ ਲਈ ਨਿਰਦੇਸ਼.';
_fc_props.reset_password_failed = 'ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰਨਾ ਅਸਫਲ, ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ.';
_fc_props.forgot_password = 'ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ?';
_fc_props.enter_valid_email = 'ਗਲਤ ਈਮੇਲ ਪਤਾ, ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਈਮੇਲ ਪਤਾ ਦਾਖਲ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ.';
_fc_props.issues_while_updating_edc_record = 'ਈਡੀਸੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੰਰਚਿਤ ਕੀਤਾ ਗਿਆ ਸੀ. ਹਾਲਾਂਕਿ ਟ੍ਰੈਕ ਆਈਟ ਸਰਵਰ ਤੇ ਈਡੀਸੀ ਲਈ ਰਿਕਾਰਡਾਂ ਵਿੱਚ ਮੁੱਦੇ ਹਨ ਜੋ ਇਸ ਦੇ ਕੰਮ ਨਾ ਕਰਨ ਦਾ ਕਾਰਨ ਬਣ ਸਕਦੇ ਹਨ. ਕਿਰਪਾ ਕਰਕੇ ਜਾਣਕਾਰੀ ਨੂੰ ਸਹੀ ਕਰਨ ਲਈ ਸਹਾਇਤਾ ਨੂੰ ਕਾਲ ਕਰੋ.';
_fc_props.sending = 'ਭੇਜ ਰਿਹਾ ਹੈ ...';
_fc_props.minimum_ratio = 'ਘੱਟੋ ਘੱਟ ਅਨੁਪਾਤ';
_fc_props.vin = 'VIN';
_fc_props.probe_rpm_not_in_range = 'ਡਰੱਮ ਆਰਪੀਐਮ ਸੀਮਾ ਵਿੱਚ ਨਹੀਂ ਹੈ';
_fc_props.probe_failure = 'ਪੜਤਾਲ ਅਸਫਲ';
_fc_props.unknown_probe_data_failure = 'ਅਣਜਾਣ ਪੜਤਾਲ ਡਾਟਾ ਅਸਫਲ';
_fc_props.no_loaded_status_meaning = 'ਕੋਈ ਲੋਡ ਸਥਿਤੀ ਨਹੀਂ';
_fc_props.no_depart_status_meaning = 'ਕੋਈ ਵਿਦਾਈ ਸਥਿਤੀ ਨਹੀਂ';
_fc_props.no_arrive_status_meaning = 'ਕੋਈ ਆਗਮਨ ਸਥਿਤੀ ਨਹੀਂ';
_fc_props.no_begin_work_status_meaning = 'ਕੋਈ ਸ਼ੁਰੂਆਤੀ ਕੰਮ ਦੀ ਸਥਿਤੀ ਨਹੀਂ';
_fc_props.no_fully_mixed_status_meaning = 'ਕੋਈ ਪੂਰੀ ਤਰ੍ਹਾਂ ਮਿਸ਼ਰਤ ਸਥਿਤੀ ਨਹੀਂ';
_fc_props.no_begin_work_or_at_job_status = 'ਕੰਮ ਦੀ ਸ਼ੁਰੂਆਤ ਕਰੋ ਜਾਂ ਨੌਕਰੀ ਦੀਆਂ ਸਥਿਤੀਆਂ ਮੌਜੂਦ ਨਹੀਂ ਹਨ';
_fc_props.driver_didnt_follow_procedure = 'ਡਰਾਈਵਰ ਨੇ ਕਾਰਜ ਪ੍ਰਣਾਲੀ ਦੀ ਪਾਲਣਾ ਨਹੀਂ ਕੀਤੀ (drੋਲ ਨੂੰ ਹੌਲੀ ਨਹੀਂ ਕਰ ਰਿਹਾ)';
_fc_props.probe_malfunction = 'ਪੜਤਾਲ ਖਰਾਬੀ (ਅੰਦਰੂਨੀ ਅਸਫਲਤਾ, ਆਦਿ)';
_fc_props.no_data_from_probe = 'ਪੜਤਾਲ ਤੋਂ ਕੋਈ ਡਾਟਾ ਨਹੀਂ (ਡੇਟਾ ਘਾਟਾ)';
_fc_props.missing_probe_calibration = 'ਕੋਈ ਪੜਤਾਲ ਕੈਲੀਬਰੇਸ਼ਨ ਡੇਟਾ ਨਹੀਂ';
_fc_props.probe_pressure_not_in_range = 'ਜਾਂਚ ਦਾ ਦਬਾਅ ਸੀਮਾ ਵਿੱਚ ਨਹੀਂ ਹੈ';
_fc_props.arrival = 'ਪਹੁੰਚਣਾ';
_fc_props.small_load = 'ਛੋਟਾ ਲੋਡ';
_fc_props.dry_slump = 'ਡ੍ਰਾਈ ਸਲੱਮਪ';
_fc_props.probe_remixing_turns = 'ਰੀਮਿਕਸ ਕਰ ਰਿਹਾ ਹੈ';
_fc_props.turns_needed_to_remix = 'ਪਾਣੀ ਜੋੜਿਆ ਗਿਆ - ਵਾਧੂ ਮਿਲਾਉਣ ਦੀ ਜ਼ਰੂਰਤ ਹੈ';
_fc_props.probe_remixing_finished = 'ਰੀਮਿਕਸਿੰਗ ਸਮਾਪਤ';
_fc_props.probe_battery_low_alert = 'ਪੜਤਾਲ ਬੈਟਰੀ ਘੱਟ ਚੇਤਾਵਨੀ';
_fc_props.probe_battery_low_message = 'ਬੈਟਰੀ ਘੱਟ ਹੈ';
_fc_props.probe_battery_low = 'ਬੈਟਰੀ ਘੱਟ ਹੈ';
_fc_props.alert_when_probe_battery_low = 'ਚੇਤਾਵਨੀ ਜਦੋਂ ਜਾਂਚ ਦੀ ਬੈਟਰੀ ਘੱਟ ਹੋਵੇ';
_fc_props.probe_sensor_fatal_failure = 'ਸੈਂਸਰ ਘਾਤਕ ਅਸਫਲਤਾ';
_fc_props.probe_thermistor_failure = 'ਥਰਮਿੰਸਟਰ ਅਸਫਲਤਾ';
_fc_props.probe_sensor_stuck_in_concrete = 'ਸੈਂਸਰ ਕੰਕਰੀਟ ਵਿਚ ਫਸਿਆ';
_fc_props.probe_sensor_over_weight = 'ਭਾਰ ਵੱਧ ਸੈਂਸਰ';
_fc_props.probe_batteries_low = 'ਬੈਟਰੀ ਘੱਟ';
_fc_props.no_probe_data = 'ਕੋਈ ਪੜਤਾਲ ਡਾਟਾ ਨਹੀਂ:';
_fc_props.check_probe = 'ਪੜਤਾਲ ਪੜਤਾਲ';
_fc_props.service_probe = 'ਸੇਵਾ ਪੜਤਾਲ';
_fc_props.below_x = 'ਹੇਠਾਂ {0}';
_fc_props.above_x = '{0 Ab ਤੋਂ ਉੱਪਰ';
_fc_props.probe_serial_number_invalid_format = 'ਫਿਲਡ ਲਈ ਪਲੇਸਹੋਲਡਰ ਵਿੱਚ ਪ੍ਰਦਰਸ਼ਿਤ ਕੀਤੇ ਅਨੁਸਾਰ ਸੀਰੀਅਲ ਨੰਬਰ ਪੜਤਾਲ ਸੀਰੀਅਲ ਨੰਬਰ ਵਿੱਚ ਨਹੀਂ ਹੈ';
_fc_props.talk_dnd_permission_prompt = 'ਗੱਲ ਬਾਤ ਕਰਨ ਦੀ ਆਗਿਆ ਦੀ ਜ਼ਰੂਰਤ ਨਹੀਂ ਪੈਂਦੀ.';
_fc_props.max = 'ਅਧਿਕਤਮ';
_fc_props.status_changed_quickly = 'ਸਥਿਤੀ ਬਹੁਤ ਜਲਦੀ ਬਦਲ ਦਿੱਤੀ ਗਈ';
_fc_props.is_empty = 'ਖਾਲੀ ਹੈ';
_fc_props.slump_increased_plasticizer_question = 'ਕੀ ਪਲਾਸਟਿਕਾਈਜ਼ਰ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ ਸੀ?';
_fc_props.slump_increased_water_added_question = 'ਕਿੰਨਾ ਕੁ ਮੀਟਰ ਰਹਿਤ ਪਾਣੀ ਜੋੜਿਆ ਗਿਆ ਸੀ?';
_fc_props.probe_and_drs_are_not_support_together_on_the_same_device = 'ਪੜਤਾਲ ਅਤੇ ਡਿਲਿਵਰੀ ਸਾਈਕਲ ਨਿਗਰਾਨੀ ਇਕੋ ਡਿਵਾਈਸ ਤੇ ਇਕੱਠੇ ਸਹਿਯੋਗੀ ਨਹੀਂ ਹਨ.';
_fc_props.no_data_from_sensor = 'ਸੈਂਸਰ ਦਾ ਕੋਈ ਡਾਟਾ ਨਹੀਂ (ਡਾਟਾ ਖਰਾਬ ਹੋਣਾ)';
_fc_props.dvir = 'ਡੀਵੀਆਈਆਰ';
_fc_props.system_type = 'ਸਿਸਟਮ ਕਿਸਮ';
_fc_props.magnet_count = 'ਚੁੰਬਕ ਗਿਣਤੀ';
_fc_props.sensors_setup = 'ਸੈਂਸਰ ਸੈਟਅਪ';
_fc_props.drs_requires_io_mac_sensor_drum_type_magnet_count = 'ਡੀਸੀਐਮ ਨੂੰ I / O ਬਾਕਸ ਮੈਕ ਐਡਰੈੱਸ, ਡਰੱਮ ਦੀ ਕਿਸਮ, ਡੀਸੀਐਮ ਕਿਸਮ, ਅਤੇ ਚੁੰਬਕ ਕਾ Countਂਟ ਨਿਰਧਾਰਤ ਕਰਨ ਦੀ ਲੋੜ ਹੁੰਦੀ ਹੈ.';
_fc_props.drs = 'ਡੀ.ਸੀ.ਐੱਮ';
_fc_props.drs_ticketed_volume = 'ਟਿਕਟ ਵਾਲੀਅਮ';
_fc_props.drs_current_volume = 'ਮੌਜੂਦਾ ਖੰਡ';
_fc_props.drs_current_water_cement_ratio = 'ਮੌਜੂਦਾ ਪਾਣੀ / ਸੀਮੈਂਟ ਅਨੁਪਾਤ';
_fc_props.drs_target_water_cement_ratio = 'ਟੀਚਾ ਪਾਣੀ / ਸੀਮੈਂਟ ਅਨੁਪਾਤ';
_fc_props.drs_total_revolutions = 'ਕੁੱਲ';
_fc_props.drs_current_volume_lp_small = 'ਮੌਜੂਦਾ';
_fc_props.drum_revolutions = 'ਡਰੱਮ ਇਨਕਲਾਬ';
_fc_props.drs_total_revolutions_lp_small = 'ਕੁੱਲ';
_fc_props.drs_charging_revolutions_lp_small = 'ਚਾਰਜਿੰਗ';
_fc_props.drs_discharging_revolutions_lp_small = 'ਡਿਸਚਾਰਜ ਕਰ ਰਿਹਾ ਹੈ';
_fc_props.drs_target_wc_lp_small = 'ਟੀਚਾ ਡਬਲਯੂ / ਸੀ';
_fc_props.drs_current_wc_lp_small = 'ਮੌਜੂਦਾ ਡਬਲਯੂ / ਸੀ';
_fc_props.drs_ticketed_volume_lp_small = 'ਟਿਕਿਆ ਹੋਇਆ';
_fc_props.android_location_disclosure = 'TrackIt ਵਰਤੋਂ ਵਿੱਚ ਨਾ ਹੋਣ ਦੇ ਬਾਵਜੂਦ ਬੈਕਗ੍ਰਾਊਂਡ ਵਿੱਚ ਟਿਕਾਣਾ ਡਾਟਾ ਇਕੱਠਾ ਕਰਨ ਲਈ ਵਧੀਆ ਅਤੇ ਮੋਟੇ ਟਿਕਾਣੇ ਦੀ ਵਰਤੋਂ ਕਰਦਾ ਹੈ, ਡਿਸਪੈਚ ਲਈ ਸਵੈ-ਸਥਿਤੀ ਅਤੇ ਰੀਅਲ-ਟਾਈਮ ਨਕਸ਼ੇ ਦੀ ਦਿੱਖ ਨੂੰ ਸਮਰੱਥ ਬਣਾਉਣ ਲਈ।';
_fc_props.android_location_usage = 'ਸਥਾਨ ਦਾ ਖੁਲਾਸਾ';
_fc_props.end_pour_trigger_threshold = 'ਐਂਡ ਪੌਰ ਟਰਿਗਰ ਥ੍ਰੈਸ਼ੋਲਡ';
_fc_props.drs_wiring_error = 'ਵਾਇਰਿੰਗ ਗਲਤੀ';
_fc_props.drs_wiring_pulse_error = 'ਵਾਇਰਿੰਗ ਪਲਸ ਗਲਤੀ';
_fc_props.drs_wiring_magnet_error = 'ਵਾਇਰਿੰਗ ਮੈਗਨੇਟ ਗਲਤੀ';
_fc_props.drs_magnet_missing_error = 'ਚੁੰਬਕ ਗੁੰਮਣ ਗਲਤੀ';
_fc_props.android_location_disclosure_3p = 'ਟ੍ਰੈਕ ਆਈਟ ਪਿਛੋਕੜ ਵਿੱਚ ਸਥਾਨ ਡਾਟਾ ਇਕੱਤਰ ਕਰਦਾ ਹੈ, ਡਿਸਪੈਚ ਲਈ ਆਟੋ-ਸਟੇਟਸਿੰਗ ਅਤੇ ਰੀਅਲ-ਟਾਈਮ ਮੈਪ ਵਿਜ਼ੀਬਿਲਟੀ ਨੂੰ ਸਮਰੱਥ ਕਰਨ ਲਈ. ਜਦੋਂ ਸ਼ਿਪਰ ਤੋਂ ਬਾਹਰ ਆ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਸਥਾਨ ਸਾਂਝਾ ਨਹੀਂ ਕੀਤਾ ਜਾਂਦਾ.';
_fc_props.please_select_either_fullmdm_or_trackit_remote_support = 'ਕਿਰਪਾ ਕਰਕੇ ਪੂਰਾ ਐਮਡੀਐਮ ਜਾਂ ਟ੍ਰੈਕਿਟ ਰਿਮੋਟ ਸਹਾਇਤਾ ਚੁਣੋ';
_fc_props.move_up = 'ਉੱਪਰ ਚੱਲੋ';
_fc_props.move_down = 'ਹੇਠਾਂ ਭੇਜੋ';
_fc_props.driver_not_found = 'ਡਰਾਈਵਰ ਨਹੀਂ ਮਿਲਿਆ.';
_fc_props.multiple = 'ਬਹੁ';
_fc_props.water_meter_installed = 'ਵਾਟਰ ਮੀਟਰ ਸਥਾਪਤ ਕੀਤਾ';
_fc_props.delete_waypoint = 'ਵੇਅਪੁਆਇੰਟ ਮਿਟਾਓ';
_fc_props.wash_sensor_installed = 'ਸੈਂਸਰ ਲਗਾਓ';
_fc_props.washing_start_time = 'ਧੋਣ ਦਾ ਅਰੰਭ ਦਾ ਸਮਾਂ';
_fc_props.end_washing = 'ਅੰਤ ਧੋਣ';
_fc_props.variable_rpms = 'ਵਾਰ ਆਰਪੀਐਮਜ਼';
_fc_props.no_slump = 'ਕੋਈ ਗੜਬੜ ਨਹੀਂ';
_fc_props.end_washing_duration = 'ਧੋਣ ਦਾ ਅੰਤ';
_fc_props.privacy_policy = 'ਪਰਾਈਵੇਟ ਨੀਤੀ';
_fc_props.privacy_policy_text = 'ਮੈਂ ਪੜ੍ਹ ਲਿਆ ਹੈ ਅਤੇ ਸਵੀਕਾਰ ਕਰ ਲਿਆ ਹੈ';
_fc_props.login_fail_accept_privacy_policy = 'ਜਾਰੀ ਰੱਖਣ ਲਈ ਗੋਪਨੀਯਤਾ ਨੀਤੀ ਨੂੰ ਸਵੀਕਾਰ ਕਰਨਾ ਲਾਜ਼ਮੀ ਹੈ.';
_fc_props.rotation_sensor = 'ਰੋਟੇਸ਼ਨ ਸੈਂਸਰ';
_fc_props.pto_sensor = 'ਪੀਟੀਓ ਸੈਂਸਰ';
_fc_props.pto_sensor_installed = 'ਪੀਟੀਓ ਸੈਂਸਰ ਸਥਾਪਤ ਕੀਤਾ';
_fc_props.polarity_reversed = 'ਉਲਟ ਪੋਲਰਿਟੀ';
_fc_props.pto_sensor_type = 'ਪੀਟੀਓ ਸੈਂਸਰ ਦੀ ਕਿਸਮ';
_fc_props.sensor_type = 'ਸੈਂਸਰ ਦੀ ਕਿਸਮ';
_fc_props.prompt_select_pto_sensor_type = 'ਜੇ ਤੁਸੀਂ ਇੱਕ "ਪੀਟੀਓ ਸੈਂਸਰ" ਆਪਣੀ ਮੁੱਖ ਸੈਂਸਰ ਕਿਸਮ ਦੇ ਤੌਰ ਤੇ ਚੁਣਿਆ ਹੈ ਤਾਂ ਤੁਹਾਨੂੰ ਇੱਕ "ਪੀਟੀਓ ਸੈਂਸਰ ਕਿਸਮ" ਦੀ ਚੋਣ ਕਰਨੀ ਚਾਹੀਦੀ ਹੈ.';
_fc_props.priming_turns_at_full_load = 'ਪ੍ਰਮੁੱਖ ਪੂਰਾ ਲੋਡ \'ਤੇ ਮੋੜਦਾ ਹੈ';
_fc_props.priming_turns_at_ten_percent_load = 'ਪ੍ਰਾਈਮਿੰਗ 10% ਲੋਡ \'ਤੇ ਬਦਲਦੀ ਹੈ';
_fc_props.weight_units = 'ਭਾਰ ਇਕਾਈਆਂ';
_fc_props.settings.send_status = 'ਇਵੈਂਟ ਬ੍ਰਿਜ ਨੂੰ ਸਥਿਤੀ / ਸਥਾਨ ਭੇਜੋ';
_fc_props.volume_difference = 'ਵਾਲੀਅਮ ਅੰਤਰ';
_fc_props.mobile_ticket_iframe_url = 'ਮੋਬਾਈਲ ਟਿਕਟ IFrame URL';
_fc_props.mobile_ticket_user = 'ਮੋਬਾਈਲ ਟਿਕਟ ਉਪਭੋਗਤਾ';
_fc_props.mobile_ticket_password = 'ਮੋਬਾਈਲ ਟਿਕਟ ਪਾਸਵਰਡ';
_fc_props.current_truck_status = 'ਮੌਜੂਦਾ ਟਰੱਕ ਸਥਿਤੀ';
_fc_props.estimated_symbol = '℮';
_fc_props.almost_equal_to_symbol = '≈';
_fc_props.triggered_by_max_revolutions = 'ਅਧਿਕਤਮ ਇਨਕਲਾਬਾਂ ਦੁਆਰਾ ਪ੍ਰੇਰਿਤ';
_fc_props.alerts_by_type = 'ਕਿਸਮ ਦੁਆਰਾ ਸੁਚੇਤਨਾਵਾਂ';
_fc_props.water_flow_meter = 'ਪਾਣੀ ਦਾ ਪ੍ਰਵਾਹ ਮੀਟਰ';
_fc_props.flow_meter_no_pulses_warning = 'ਕੋਈ ਦਾਲ ਨਹੀਂ - ਚੇਤਾਵਨੀ';
_fc_props.flow_meter_no_pulses_critical = 'ਕੋਈ ਦਾਲ ਨਹੀਂ - ਆਲੋਚਨਾਤਮਕ';
_fc_props.flow_meter_excessive_pulses = 'ਬਹੁਤ ਜ਼ਿਆਦਾ ਦਾਲਾਂ';
_fc_props.flow_meter_constantly_flowing = 'ਨਿਰੰਤਰ ਵਹਿ ਰਿਹਾ ਹੈ';
_fc_props.flow_meter_good = 'ਚੰਗਾ';
_fc_props.whats_new = 'ਨਵਾਂ ਕੀ ਹੈ';
_fc_props.whats_new_widget_text = 'ਕੀ ਤੁਸੀਂ ਜਾਣਦੇ ਹੋ ਕਿ ਅਸੀਂ ਟ੍ਰੈਕਇਟ ਨੂੰ ਮਹੀਨੇ ਵਿੱਚ ਘੱਟੋ ਘੱਟ ਇੱਕ ਵਾਰ ਅਪਡੇਟ ਕਰਦੇ ਹਾਂ? ਨਵੀਨਤਮ ਅਪਡੇਟਸ ਅਤੇ ਸੁਝਾਅ ਪ੍ਰਾਪਤ ਕਰੋ.';
_fc_props.get_the_scoop = 'ਸਕੂਪ ਲਵੋ';
_fc_props.trackit_insights = 'ਟ੍ਰੈਕਿਟ ਇਨਸਾਈਟਸ';
_fc_props.business_analytics_blurb = 'ਕਾਰੋਬਾਰੀ ਵਿਸ਼ਲੇਸ਼ਣ ਜੋ ਤੁਹਾਡੀ ਤਲ ਲਾਈਨ ਨੂੰ ਬਿਹਤਰ ਬਣਾਉਂਦੇ ਹਨ, ਹੁਣ ਟ੍ਰੈਕਇਟ ਦੇ ਨਾਲ ਸ਼ਾਮਲ ਕੀਤੇ ਗਏ ਹਨ';
_fc_props.find_out_more = 'ਹੋਰ ਪਤਾ ਕਰੋ';
_fc_props.daily_breakdown = 'ਰੋਜ਼ਾਨਾ ਟੁੱਟਣਾ';
_fc_props.question_summary = 'ਪ੍ਰਸ਼ਨ ਸਾਰਾਂਸ਼';
_fc_props.trip_summary = 'ਯਾਤਰਾ ਦਾ ਸਾਰਾਂਸ਼';
_fc_props.manage_employees = 'ਕਰਮਚਾਰੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.manage_hotspots = 'ਹੌਟਸਪੌਟਸ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.manage_devices = 'ਡਿਵਾਈਸਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.manage_equipment = 'ਉਪਕਰਣਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.manage_users = 'ਉਪਭੋਗਤਾਵਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.manage_statuses = 'ਸਥਿਤੀਆਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.manage_alerts = 'ਸੁਚੇਤਨਾਵਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ';
_fc_props.popular_report_links = 'ਪ੍ਰਸਿੱਧ ਰਿਪੋਰਟ ਲਿੰਕ';
_fc_props.popular_settings_links = 'ਪ੍ਰਸਿੱਧ ਸੈਟਿੰਗ ਲਿੰਕ';
_fc_props.sidemenu_settings = 'ਸੈਟਿੰਗਜ਼';
_fc_props.loads_per_driver = 'ਲੋਡ ਪ੍ਰਤੀ ਡਰਾਈਵਰ';
_fc_props.please_select_application = 'ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ ਘੱਟ ਇੱਕ ਐਪਲੀਕੇਸ਼ਨ ਦੀ ਚੋਣ ਕਰੋ';
_fc_props.external_app_processing = 'ਬਾਹਰੀ ਐਪ ਤੇ ਪ੍ਰਕਿਰਿਆ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ';
_fc_props.external_app_sent = 'ਬਾਹਰੀ ਐਪ ਤੇ ਭੇਜਿਆ ਗਿਆ';
_fc_props.external_app_received = 'ਬਾਹਰੀ ਐਪ ਦੁਆਰਾ ਪ੍ਰਾਪਤ ਕੀਤਾ ਗਿਆ';
_fc_props.wait_time_at_job = 'ਨੌਕਰੀ \'ਤੇ ਸਮੇਂ ਦੀ ਉਡੀਕ ਕਰੋ';
_fc_props.wait_time_at_plant = 'ਪਲਾਂਟ ਵਿਖੇ ਉਡੀਕ ਸਮਾਂ';
_fc_props.loading_time = 'ਲੋਡ ਕਰਨ ਦਾ ਸਮਾਂ';
_fc_props.unloading_time = 'ਅਨਲੋਡਿੰਗ ਸਮਾਂ';
_fc_props.pre_post_trip = 'ਪਰੀ / ਪੋਸਟ ਕਰੋ ਯਾਤਰਾ';
_fc_props.update_thresholds = 'ਥ੍ਰੈਸ਼ਹੋਲਡਸ ਨੂੰ ਅਪਡੇਟ ਕਰੋ';
_fc_props.green = 'ਹਰਾ';
_fc_props.yellow = 'ਪੀਲਾ';
_fc_props.red = 'ਲਾਲ';
_fc_props.between = 'ਵਿਚਕਾਰ';
_fc_props.num_of_loads = '# ਲੋਡਸ ਦਾ';
_fc_props.num_of_drivers = '# ਡਰਾਈਵਰਾਂ ਦੇ';
_fc_props.status_meaning_not_setup = 'ਸਥਿਤੀ ਦਾ ਮਤਲਬ ਸੈਟਅਪ ਨਹੀਂ';
_fc_props.or_less = 'ਜਾਂ ਘੱਟ';
_fc_props.or_greater = 'ਜਾਂ ਗ੍ਰੇਟਰ';
_fc_props.probe_self_diagnostic_state = 'ਸਵੈ ਨਿਦਾਨ ਸਥਿਤੀ ਦੀ ਜਾਂਚ ਕਰੋ';
_fc_props.update_current_truck_status_settings = 'ਮੌਜੂਦਾ ਟਰੱਕ ਸਥਿਤੀ ਸੈਟਿੰਗਾਂ ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ';
_fc_props.default_behavior = 'ਪੂਰਵ-ਨਿਰਧਾਰਤ (ਚੋਟੀ ਦੇ 10 ਸਥਿਤੀਆਂ)';
_fc_props.combine_similar_statuses = 'ਸਾਰੀਆਂ ਕਿਸਮਾਂ (ਲੂਪਸ) ਤੋਂ ਸਮਾਨ ਸਥਿਤੀਆਂ ਨੂੰ ਜੋੜੋ';
_fc_props.use_other_category = '\'ਹੋਰ\' ਸ਼੍ਰੇਣੀ ਦੀ ਵਰਤੋਂ ਕਰੋ';
_fc_props.selected_statuses = 'ਚੁਣੀਆਂ ਗਈਆਂ ਸਥਿਤੀਆਂ';
_fc_props.ideas = 'ਵਿਚਾਰ';
_fc_props.air_content = 'ਹਵਾ ਸਮੱਗਰੀ';
_fc_props.air_measured_time = 'ਹਵਾ ਦਾ ਮਾਪਿਆ ਸਮਾਂ';
_fc_props.ten_max = '10 ਅਧਿਕਤਮ';
_fc_props.loads_per_plant = 'ਪ੍ਰਤੀ ਪੌਦਾ ਲੋਡ';
_fc_props.invalid_flow_meter_pulse_per_volume = 'ਪ੍ਰਤੀ ਵੌਲਯੂਮ ਅਵੈਧ ਫਲੋ ਮੀਟਰ ਪਲਸ।';
_fc_props.coming_soon_title = 'ਹੋਰ ਵਿਜੇਟਸ ਜਲਦੀ ਆ ਰਹੇ ਹਨ';
_fc_props.submit_your_ideas = 'ਆਪਣੇ ਖੱਬੇ ਨੈਵੀਗੇਸ਼ਨ ਮੀਨੂ \'ਤੇ ਲਾਈਟ ਬਲਬ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਸਾਡੇ ਵਿਚਾਰ ਪੋਰਟਲ \'ਤੇ ਆਪਣੇ ਵਿਚਾਰ ਜਮ੍ਹਾਂ ਕਰੋ।';
_fc_props.selected_plants = 'ਚੁਣੇ ਪੌਦੇ';
_fc_props.update_loads_per_plant_settings = 'ਪ੍ਰਤੀ ਪਲਾਂਟ ਸੈਟਿੰਗਾਂ ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ';
_fc_props.default_top_10_plants = 'ਡਿਫੌਲਟ (ਚੋਟੀ ਦੇ 10 ਪੌਦੇ)';
_fc_props.phone_number_will_be_uploaded = 'ਡਿਵਾਈਸ ਆਈਡੀ ਨੂੰ ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਦੇ ਉਦੇਸ਼ਾਂ ਲਈ ਅਪਲੋਡ ਕੀਤਾ ਜਾਵੇਗਾ।';
_fc_props.include_deleted = 'ਮਿਟਾਏ ਗਏ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.primary_status_type = 'ਪ੍ਰਾਇਮਰੀ ਸਥਿਤੀ ਦੀ ਕਿਸਮ';
_fc_props.additional_status_trigger = 'ਵਧੀਕ ਸਥਿਤੀ ਟਰਿਗਰਸ';
_fc_props.update_tickets_destination = 'ਸਾਰੀਆਂ ਸੰਬੰਧਿਤ ਟਿਕਟਾਂ \'ਤੇ ਟਿਕਾਣਾ ਅੱਪਡੇਟ ਕਰੋ';
_fc_props.settings.send_destination_changes_to_connex = 'ਕਨੈਕਸ ਨੂੰ ਮੰਜ਼ਿਲ ਤਬਦੀਲੀਆਂ ਭੇਜੋ';
_fc_props.settings.is_the_ticket_active_when_it_is_received_by_trackit_default_always = 'ਕੀ ਟਿਕਟ ਸਰਗਰਮ ਹੈ ਜਦੋਂ ਇਹ TrackIt ਦੁਆਰਾ ਪ੍ਰਾਪਤ ਕੀਤੀ ਜਾਂਦੀ ਹੈ (ਡਿਫੌਲਟ \'ਹਮੇਸ਼ਾ\')';
_fc_props.settings.autoactivate_next_ticket_on_ticket_completion = 'ਪਿਛਲੀ ਟਿਕਟ ਪੂਰੀ ਹੋਣ \'ਤੇ ਅਗਲੀ ਟਿਕਟ ਨੂੰ ਆਟੋ-ਐਕਟੀਵੇਟ ਕਰੋ';
_fc_props.settings.should_autoactivate_next_ticket_on_ticket_completion_default_true = 'ਟਿਕਟ ਪੂਰਾ ਹੋਣ \'ਤੇ ਅਗਲੀ ਟਿਕਟ ਨੂੰ ਆਟੋ-ਐਕਟੀਵੇਟ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ (ਡਿਫੌਲਟ \'ਸੱਚ\')';
_fc_props.settings.status_to_idle_status_when_no_active_ticket = 'ਕੋਈ ਕਿਰਿਆਸ਼ੀਲ ਟਿਕਟ ਨਾ ਹੋਣ \'ਤੇ ਨਿਸ਼ਕਿਰਿਆ ਸਥਿਤੀ ਤੱਕ ਸਥਿਤੀ';
_fc_props.break_down = 'ਟੁੱਟ ਜਾਣਾ';
_fc_props.idle = 'ਵਿਹਲਾ';
_fc_props.pto_work_maximum_speed_limit = 'ਕੰਮ ਦੀ ਅਧਿਕਤਮ ਗਤੀ ਸੀਮਾ';
_fc_props.settings.associate_ticket_to_status_on_complete = 'ਐਸੋਸੀਏਟ ਸਥਿਤੀ ਤਬਦੀਲੀ ਜੋ ਉਸ ਟਿਕਟ ਨਾਲ ਇੱਕ ਟਿਕਟ ਨੂੰ ਪੂਰਾ ਕਰਦੀ ਹੈ';
_fc_props.approaching = 'ਨੇੜੇ ਆ ਰਿਹਾ ਹੈ';
_fc_props.performing_task = 'ਕੰਮ ਕਰਨਾ';
_fc_props.are_you_sure_you_want_to_delete_geogate = 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ \'ਤੇ ਜਿਓਗੇਟ {0} ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.trackit_destination_changed_to_0 = 'ਟਿਕਟ {0} ਅੱਪਡੇਟ ਕਰੋ। ਮੰਜ਼ਿਲ ਬਦਲ ਗਈ।';
_fc_props.trackit_return_destination_changed_to_0 = 'ਟਿਕਟ {0} ਅੱਪਡੇਟ ਕਰੋ। ਵਾਪਸੀ ਬਦਲ ਗਈ।';
_fc_props.menu_settings_title_geogate = 'ਜਿਓਗੇਟ';
_fc_props.add_geogate = 'ਜੀਓਗੇਟ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.driver_enabled = 'ਡਰਾਈਵਰ ਚਾਲੂ ਹੈ';
_fc_props.dispatcher_enabled = 'ਡਿਸਪੈਚਰ ਚਾਲੂ ਹੈ';
_fc_props.runsheet_number = 'ਰਨਸ਼ੀਟ ਨੰਬਰ';
_fc_props.manual_load = 'ਮੈਨੁਅਲ ਲੋਡ';
_fc_props.edit_geogate = 'ਜੀਓਗੇਟ ਦਾ ਸੰਪਾਦਨ ਕਰੋ';
_fc_props.handling = 'ਸੰਭਾਲਣਾ';
_fc_props.geogate_name = 'ਜਿਓਗੇਟ';
_fc_props.geogate_panel = 'ਜੀਓਗੇਟ ਪੈਨਲ';
_fc_props.vehicle_is_not_logged_in = 'ਸੁਨੇਹਾ ਨਹੀਂ ਭੇਜਿਆ ਗਿਆ (ਵਾਹਨ ਲੌਗਇਨ ਨਹੀਂ ਹੈ)';
_fc_props.air = 'ਹਵਾ';
_fc_props.create_a_geogate = 'ਇੱਕ ਜਿਓਗੇਟ ਬਣਾਓ';
_fc_props.click_the_map_to_set_the_geogate_points = 'ਜਿਓਗੇਟ ਪੁਆਇੰਟ ਸੈੱਟ ਕਰਨ ਲਈ ਨਕਸ਼ੇ \'ਤੇ ਕਲਿੱਕ ਕਰੋ।';
_fc_props.clock_out = 'ਘੜੀ ਬਾਹਰ';
_fc_props.handling_operation = 'ਹੈਂਡਲਿੰਗ ਓਪਰੇਸ਼ਨ';
_fc_props.view_geogates = 'ਜਿਓਗੇਟਸ ਦੇਖੋ';
_fc_props.select_a_geogate = 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਜਿਓਗੇਟ ਚੁਣੋ';
_fc_props.failed_to_find_geogate = 'ਜਿਓਗੇਟ ਨੂੰ ਲੱਭਣ ਵਿੱਚ ਅਸਫਲ';
_fc_props.adjust_geogate = 'ਜੀਓਗੇਟ ਨੂੰ ਵਿਵਸਥਿਤ ਕਰੋ';
_fc_props.geogate_expiration_date_time_extra_days = 'ਜਿਓਗੇਟ ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਸਮਾਂ ਡਿਫੌਲਟ ਵਾਧੂ ਦਿਨ';
_fc_props.menu_settings_title_retention = 'ਡਾਟਾ ਧਾਰਨ';
_fc_props.category = 'ਸ਼੍ਰੇਣੀ';
_fc_props.telemetry = 'ਟੈਲੀਮੈਟਰੀ';
_fc_props.ticket_data = 'ਟਿਕਟ ਡੇਟਾ';
_fc_props._30_days = '30 ਦਿਨ';
_fc_props._6_months = '6 ਮਹੀਨੇ';
_fc_props._1_year = '1 ਸਾਲ';
_fc_props._3_years = '3 ਸਾਲ';
_fc_props.new_group = 'ਨਵਾਂ ਸਮੂਹ';
_fc_props.selected_vehicles = 'ਚੁਣੇ ਗਏ ਵਾਹਨ';
_fc_props.group_name_is_required_field = 'ਗਰੁੱਪ ਦਾ ਨਾਮ ਲੋੜੀਂਦਾ ਖੇਤਰ ਹੈ';
_fc_props.at_least_vehicle_should_be_selected = 'ਘੱਟੋ-ਘੱਟ 1 ਵਾਹਨ ਚੁਣਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.groups = 'ਸਮੂਹ';
_fc_props.trucks = 'ਟਰੱਕ';
_fc_props.geogate_has_been_removed_please_refresh_page = 'ਜੀਓਗੇਟ ਨੂੰ ਹਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਪੰਨੇ ਨੂੰ ਤਾਜ਼ਾ ਕਰੋ।';
_fc_props.air_timestamp = 'ਏਅਰ ਟਾਈਮਸਟੈਂਪ';
_fc_props.fail = 'ਫੇਲ';
_fc_props.stuck = 'ਫਸਿਆ';
_fc_props.dirty = 'ਗੰਦਾ';
_fc_props.seasonal = 'ਮੌਸਮੀ';
_fc_props.category_details = 'ਸ਼੍ਰੇਣੀ ਦੇ ਵੇਰਵੇ';
_fc_props.alert_events = 'ਚੇਤਾਵਨੀ ਇਵੈਂਟਸ';
_fc_props.driver_performance_infractions = 'ਡ੍ਰਾਈਵਰ ਦੀ ਕਾਰਗੁਜ਼ਾਰੀ ਦੀ ਉਲੰਘਣਾ';
_fc_props.telemetry_details = 'ਸਥਾਨ ਡੇਟਾ, ਹੌਟਸਪੌਟਸ, ਹੌਟਸਪੌਟਸ ਵਿੱਚ ਸਮਾਂ, ਖੇਤਰਾਂ ਵਿੱਚ ਸਮਾਂ, DCM ਡੇਟਾ, ਪੜਤਾਲ ਡੇਟਾ, ਇੰਜਣ ਡੇਟਾ';
_fc_props.ticket_data_details = 'ਟਿਕਟ ਡੇਟਾ';
_fc_props.general_details = 'ਸਥਿਤੀ ਬਦਲਾਵ, ਟਾਈਮਕਾਰਡ, ਸਮਾਂ-ਸੂਚੀ';
_fc_props.wash = 'ਧੋਵੋ';
_fc_props.filter_options = 'ਫਿਲਟਰ ਵਿਕਲਪ';
_fc_props.home_plants = 'ਘਰੇਲੂ ਪੌਦੇ';
_fc_props.dcm = 'ਡੀ.ਸੀ.ਐਮ';
_fc_props.pto_requires_pto_sensor_type_value = 'DCM PTO ਨੂੰ PTO ਸੈਂਸਰ ਦੀ ਕਿਸਮ ਸੈੱਟ ਕਰਨ ਦੀ ਲੋੜ ਹੈ।';
_fc_props.invalid_io_box_mac_address = 'ਅਵੈਧ I/O ਬਾਕਸ ਮੈਕ ਐਡਰੈੱਸ';
_fc_props.access_denied = 'ਐਕਸੇਸ ਡਿਨਾਇਡ';
_fc_props.wash_events = 'ਇਵੈਂਟਸ ਧੋਵੋ';
_fc_props.min_speed = 'ਘੱਟੋ-ਘੱਟ ਗਤੀ';
_fc_props.temperature_change = 'ਤਾਪਮਾਨ ਤਬਦੀਲੀ';
_fc_props.degrees_cap = 'ਡਿਗਰੀ';
_fc_props.washout_tracking = 'ਵਾਸ਼ਆਊਟ ਟਰੈਕਿੰਗ';
_fc_props.approaching_wash = 'ਧੋਣ ਦੇ ਨੇੜੇ ਆ ਰਿਹਾ ਹੈ';
_fc_props.arrival_wash = 'ਆਗਮਨ ਵਾਸ਼';
_fc_props.performing_wash = 'ਧੋਣ ਦਾ ਪ੍ਰਦਰਸ਼ਨ';
_fc_props.wash_complete = 'ਪੂਰੀ ਤਰ੍ਹਾਂ ਧੋਵੋ';
_fc_props.must_be_positive_or_zero = 'ਸਕਾਰਾਤਮਕ ਜਾਂ ਜ਼ੀਰੋ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ!';
_fc_props.min_speed_greater_than_max = 'ਘੱਟੋ-ਘੱਟ ਗਤੀ ਅਧਿਕਤਮ ਸਪੀਡ ਤੋਂ ਘੱਟ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ!';
_fc_props.clock_in = 'ਕਲਾਕ ਇਨ';
_fc_props.mix_water_at_x_to_y_for_t = '{2} ਘੁੰਮਣ ਲਈ {0} ਤੋਂ {1} RPM \'ਤੇ ਪਾਣੀ ਮਿਲਾਓ।';
_fc_props.discharge_water_for_x_revolutions = '{0} ਘੁੰਮਣ ਲਈ ਪਾਣੀ ਛੱਡੋ।';
_fc_props.eod_drum_wash_complete = 'ਡਰੱਮ ਵਾਸ਼ ਪੂਰਾ! <br> ਸਿਖਰ \'ਤੇ ਜਾਂਚ ਦੇ ਨਾਲ ਡਰੱਮ ਨੂੰ ਰੋਕੋ।';
_fc_props.washing_complete = 'ਗਾਈਡ ਵਾਸ਼ਆਊਟ ਪੂਰਾ ਹੋਇਆ';
_fc_props.eod_washout_no_data_available = 'ਸੈਂਸਰ ਡਾਟਾ ਉਪਲਬਧ ਨਹੀਂ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਧੋਣ ਦੀ ਪ੍ਰਕਿਰਿਆ ਪੂਰੀ ਕਰੋ।';
_fc_props.eod_washout_no_data_available_title = 'ਗਾਈਡ ਵਾਸ਼ਆਊਟ ਉਪਲਬਧ ਨਹੀਂ ਹੈ';
_fc_props.menu_reports_title_ticket_time_summary = 'ਟਿਕਟ ਸਮਾਂ ਸੰਖੇਪ';
_fc_props.menu_reports_description_ticket_time_summary = 'ਆਰਡਰ ਜਾਂ ਵਾਹਨ ਲਈ ਟਿਕਟ ਸਮੇਂ ਦੀ ਜਾਣਕਾਰੀ ਦੇਖੋ';
_fc_props.menu_reports_title_summary = 'ਸੰਖੇਪ';
_fc_props.menu_reports_title_ticket_summary = 'ਟਿਕਟ ਸੰਖੇਪ';
_fc_props.menu_reports_description_summary1 = 'ਹੌਟਸਪੌਟਸ ਵਿੱਚ ਸਮੇਂ ਦਾ ਸਾਰ ਵੇਖੋ';
_fc_props.menu_dispatch_title_main = 'ਮੁੱਖ';
_fc_props.menu_dispatch_title_login_registry = 'ਲੌਗਇਨ ਰਜਿਸਟਰੀ';
_fc_props.menu_reports_title_main = 'ਮੁੱਖ';
_fc_props.time_management_are_you_sure_you_want_to_delete_export_data_type = 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ \'ਤੇ ਇਸ ਨਿਰਯਾਤ ਡੇਟਾ ਕਿਸਮ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.dvir_status_paper_logs = 'ਪੇਪਰ ਲੌਗਸ';
_fc_props.menu_reports_title_billing_report = 'ਬਿਲਿੰਗ ਰਿਪੋਰਟ';
_fc_props.menu_reports_description_billing_report = 'ਬਿਲਿੰਗ ਰਿਪੋਰਟ';
_fc_props.settings_status_to_ticketed_status_on_activate_work = 'ਐਕਟੀਵੇਟ ਕੰਮ \'ਤੇ ਟਿਕਟ ਦੀ ਸਥਿਤੀ ਦੀ ਸਥਿਤੀ';
_fc_props.settings_status_to_ticketed_status_on_activate_work_default_false = 'ਐਕਟੀਵੇਟ ਕੰਮ \'ਤੇ ਟਿਕਟ ਦੀ ਸਥਿਤੀ ਦੀ ਸਥਿਤੀ (ਡਿਫਾਲਟ \'ਗਲਤ\')';
_fc_props.status_change_type_drum_rotation_sensor = 'ਡੀ.ਸੀ.ਐਮ';
_fc_props.settings_connex_eid = 'ਕਨੈਕਸ ਈ.ਆਈ.ਡੀ';
_fc_props.units_weight_pound_label = 'ਪੌਂਡ';
_fc_props.units_weight_kilogram_label = 'ਕਿਲੋਗ੍ਰਾਮ';
_fc_props.settings_weight = 'ਭਾਰ';
_fc_props.units_pounds = 'ਪੌਂਡ';
_fc_props.units_kilograms = 'ਕਿਲੋਗ੍ਰਾਮ';
_fc_props.geo_gate_edit_modal_description = 'ਵਰਣਨ';
_fc_props.geo_gate_edit_modal_start_point = 'ਸ਼ੁਰੂਆਤੀ ਬਿੰਦੂ';
_fc_props.geo_gate_edit_modal_end_point = 'ਅੰਤ ਬਿੰਦੂ';
_fc_props.geo_gate_edit_modal_vehicle_types = 'ਉਪਕਰਣ ਦੀ ਕਿਸਮ';
_fc_props.geo_gate_edit_modal_heading_south = 'ਦੱਖਣ ਤੋਂ ਉੱਤਰ';
_fc_props.geo_gate_edit_modal_heading_north = 'ਉੱਤਰ ਤੋਂ ਦੱਖਣ ਤੱਕ';
_fc_props.geo_gate_edit_modal_driver_message = 'ਡਰਾਈਵਰ ਸੁਨੇਹਾ';
_fc_props.geo_gate_edit_modal_dispatcher_message = 'ਸੁਨੇਹਾ ਭੇਜੋ';
_fc_props.geo_gate_edit_modal_expiration_date_time = 'ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਦਾ ਸਮਾਂ';
_fc_props.geo_gate_edit_modal_permanent = 'ਸਥਾਈ';
_fc_props.geo_gate_edit_modal_critical = 'ਨਾਜ਼ੁਕ';
_fc_props.geo_gate_edit_modal_error_message_description_empty = 'ਜਿਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਵਰਣਨ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.geo_gate_edit_modal_error_message_start_point_empty = 'ਜੀਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਸਟਾਰਟ ਪੁਆਇੰਟ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.geo_gate_edit_modal_error_message_start_point_invalid_format = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਸਟਾਰਟ ਪੁਆਇੰਟ ਖੇਤਰ ਵਿੱਚ ਅਵੈਧ ਡੇਟਾ ਫਾਰਮੈਟ ਹੈ।';
_fc_props.geo_gate_edit_modal_error_message_end_point_invalid_format = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਅੰਤ ਬਿੰਦੂ ਖੇਤਰ ਵਿੱਚ ਅਵੈਧ ਡੇਟਾ ਫਾਰਮੈਟ ਹੈ।';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_empty = 'ਜਿਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਸਮਾਂ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_invalid_format = 'ਜੀਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਸਮਾਂ ਖੇਤਰ ਵਿੱਚ ਅਵੈਧ ਡੇਟਾ ਫਾਰਮੈਟ ਹੈ।';
_fc_props.geo_gate_edit_modal_error_message_heading_north_dispatcher_message_empty = 'ਜਿਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਉੱਤਰ ਤੋਂ ਦੱਖਣ - ਡਿਸਪੈਚ ਸੁਨੇਹਾ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.geo_gate_edit_modal_error_message_no_changes = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਕੋਈ ਬਦਲਾਅ ਨਹੀਂ ਲੱਭੇ';
_fc_props.geo_gate_edit_modal_error_message_expiration_date_time_expired = 'ਜੀਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਗਲਤੀ: ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਸਮਾਂ ਖੇਤਰ ਵਿੱਚ ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ-ਸਮਾਂ ਸ਼ਾਮਲ ਹੈ';
_fc_props.geo_gate_edit_modal_error_message_description_invalid_format = 'ਜਿਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਗਲਤੀ: ਵਰਣਨ ਖੇਤਰ ਵਿੱਚ ਕੇਵਲ ਅੱਖਰ ਅਤੇ ਸੰਖਿਆਵਾਂ ਹੋਣੀਆਂ ਚਾਹੀਦੀਆਂ ਹਨ';
_fc_props.geo_gate_edit_modal_error_message_vehicle_types_empty = 'ਜਿਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਉਪਕਰਨ ਕਿਸਮ ਖੇਤਰ ਨੂੰ ਭਰਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.geo_gate_table_message_label_north_to_south = 'ਉੱਤਰ ਤੋਂ ਦੱਖਣ ਤੱਕ';
_fc_props.geo_gate_table_message_label_south_to_north = 'ਦੱਖਣ ਤੋਂ ਉੱਤਰ';
_fc_props.geo_gate_table_message_label_both = 'ਦੋਵੇਂ';
_fc_props.geo_gate_edit_modal_field_point_tip = 'ਵਿਥਕਾਰ, ਲੰਬਕਾਰ';
_fc_props.geo_gate_edit_modal_device_movement_message_restriction = 'ਇਸਦੀ ਜਾਂਚ ਕਰਨ ਨਾਲ ਡਿਵਾਈਸ ਮੂਵਮੈਂਟ ਸੁਨੇਹਾ ਪਾਬੰਦੀ ਲਈ ਸੈੱਟਅੱਪ ਓਵਰਰਾਈਡ ਹੋ ਜਾਵੇਗਾ';
_fc_props.geo_gate_edit_modal_seasonal = 'ਮੌਸਮੀ';
_fc_props.geo_gate_edit_modal_error_message_seasonal_empty = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਮੌਸਮੀ ਤਾਰੀਖਾਂ ਨੂੰ ਭਰਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.geo_gate_edit_modal_error_message_seasonal_invalid_date_format = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਮੌਸਮੀ ਮਿਤੀ ਖੇਤਰਾਂ ਵਿੱਚ ਅਵੈਧ ਡੇਟਾ ਫਾਰਮੈਟ ਹੈ';
_fc_props.test_js_test_javascript = 'ਜਾਵਾਸਕ੍ਰਿਪਟ ਦੀ ਜਾਂਚ ਕਰੋ';
_fc_props.units_miles_per_hour_abbr = 'MPH';
_fc_props.units_kilometers_per_hour_abbr = 'KPH';
_fc_props.units_kilometers = 'ਕਿਲੋਮੀਟਰ';
_fc_props.units_miles = 'ਮੀਲ';
_fc_props.units_gallons_abbr = 'GAL';
_fc_props.units_liters_abbr = 'ਐੱਲ';
_fc_props.geo_gate_edit_modal_error_message_heading_south_driver_message_empty = 'ਜੀਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਗਲਤੀ: ਦੱਖਣ ਤੋਂ ਉੱਤਰ - ਡਰਾਈਵਰ ਸੁਨੇਹਾ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.geo_gate_edit_modal_error_message_heading_south_dispatcher_message_empty = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਦੱਖਣ ਤੋਂ ਉੱਤਰ - ਡਿਸਪੈਚ ਸੁਨੇਹਾ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.geo_gate_edit_modal_error_message_heading_north_driver_message_empty = 'ਜੀਓਗੇਟ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਉੱਤਰ ਤੋਂ ਦੱਖਣ - ਡਰਾਈਵਰ ਸੁਨੇਹਾ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.geo_gate_heading = 'ਸਿਰਲੇਖ';
_fc_props.geo_gate_edit_modal_error_message_end_point_empty = 'ਜੀਓਗੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਅੰਤਮ ਬਿੰਦੂ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.units_mile = 'ਮੀਲ';
_fc_props.units_kilometer = 'ਕਿਲੋਮੀਟਰ';
_fc_props.cloud_dvir__exit = 'ਨਿਕਾਸ';
_fc_props.cloud_dvir__signature = 'ਦਸਤਖਤ';
_fc_props.cloud_dvir__inspection = 'ਨਿਰੀਖਣ';
_fc_props.cloud_dvir__add_photos_notes = 'ਫੋਟੋਆਂ/ਨੋਟ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.cloud_dvir__odometer_reading = 'ਓਡੋਮੀਟਰ ਰੀਡਿੰਗ';
_fc_props.cloud_dvir__engine_hours = 'ਇੰਜਣ ਦੇ ਘੰਟੇ';
_fc_props.cloud_dvir__truck_details = 'ਟਰੱਕ ਦੇ ਵੇਰਵੇ';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_vehicle = 'ਵਾਹਨ ਦੀ ਸਮੁੱਚੀ ਸਥਿਤੀ ਦਾ ਪਤਾ ਲਗਾਓ';
_fc_props.cloud_dvir__full_name_and_signature_of_driver = 'ਡਰਾਈਵਰ ਦਾ ਪੂਰਾ ਨਾਮ ਅਤੇ ਦਸਤਖਤ';
_fc_props.cloud_dvir__sign = 'ਸਾਈਨ';
_fc_props.cloud_dvir__driver_note_from = 'ਤੋਂ ਡਰਾਈਵਰ ਨੋਟ';
_fc_props.cloud_dvir__add_notes = 'ਨੋਟਸ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.cloud_dvir__driver_notes = 'ਡਰਾਈਵਰ ਨੋਟਸ';
_fc_props.cloud_dvir__done = 'ਹੋ ਗਿਆ';
_fc_props.cloud_dvir__needs_repair = 'ਮੁਰੰਮਤ ਦੀ ਲੋੜ ਹੈ';
_fc_props.cloud_dvir__not_applicable = 'ਲਾਗੂ ਨਹੀਂ ਹੈ';
_fc_props.cloud_dvir__review_and_sign = 'ਸਮੀਖਿਆ ਅਤੇ ਸਾਈਨ';
_fc_props.cloud_dvir__add_photos = 'ਫੋਟੋ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.cloud_dvir__upload_photo_limit = 'ਤੁਸੀਂ 3 ਤਸਵੀਰਾਂ ਤੱਕ ਅੱਪਲੋਡ ਕਰ ਸਕਦੇ ਹੋ';
_fc_props.cloud_dvir__mismatch_title = 'ਬੇਮੇਲ ਚੇਤਾਵਨੀ';
_fc_props.cloud_dvir__ok = 'ਠੀਕ ਹੈ';
_fc_props.cloud_dvir_template = 'ਕਲਾਉਡ DVIR ਟੈਂਪਲੇਟ';
_fc_props.cloud_dvir__needs_review = 'DVIR ਨੂੰ ਸਮੀਖਿਆ ਦੀ ਲੋੜ ਹੈ';
_fc_props.cloud_dvir__submitted = 'ਪੇਸ਼ ਕੀਤਾ';
_fc_props.cloud_dvir__completed_by = 'ਦੁਆਰਾ ਪੂਰਾ ਕੀਤਾ ਗਿਆ';
_fc_props.cloud_dvir__review_btn = 'ਸਮੀਖਿਆ';
_fc_props.cloud_dvir__truck_number = 'ਟਰੱਕ {0}';
_fc_props.cloud_dvir__pending_review = 'ਵਿਚਾਰ-ਅਧੀਨ ਸਮੀਖਿਆ';
_fc_props.cloud_dvir__start_dvir = 'DVIR ਸ਼ੁਰੂ ਕਰੋ';
_fc_props.cloud_dvir__dvir_history = 'DVIR ਇਤਿਹਾਸ';
_fc_props.cloud_dvir__view_all = 'ਸਭ ਦੇਖੋ';
_fc_props.load_zone = 'ਲੋਡ ਜ਼ੋਨ';
_fc_props.view_load_zones = 'ਲੋਡ ਜ਼ੋਨ ਦੇਖੋ';
_fc_props.edit_load_zone = 'ਲੋਡ ਜ਼ੋਨ ਦਾ ਸੰਪਾਦਨ ਕਰੋ';
_fc_props.create_a_load_zone = 'ਇੱਕ ਲੋਡ ਜ਼ੋਨ ਬਣਾਓ';
_fc_props.load_zone_panel = 'ਲੋਡ ਜ਼ੋਨ ਪੈਨਲ';
_fc_props.click_the_map_to_set_the_load_zone_center = 'ਲੋਡ ਜ਼ੋਨ ਸੈਂਟਰ ਸੈੱਟ ਕਰਨ ਲਈ ਨਕਸ਼ੇ \'ਤੇ ਕਲਿੱਕ ਕਰੋ।';
_fc_props.cloud_dvir__mismatch_title_all_items_ok = 'ਇਸ ਨਿਰੀਖਣ ਵਿੱਚ ਨੁਕਸ ਦਰਸਾਏ ਗਏ ਹਨ ਅਤੇ ਤੁਸੀਂ ਚੁਣਿਆ ਹੈ ਕਿ ਇਹ ਵਾਹਨ ਚਲਾਉਣ ਲਈ ਸੁਰੱਖਿਅਤ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਅੱਗੇ ਵਧਣ ਤੋਂ ਪਹਿਲਾਂ ਪ੍ਰਮਾਣਿਤ ਕਰੋ';
_fc_props.cloud_dvir__mismatch_title_needs_repair = 'ਇਸ ਨਿਰੀਖਣ ਵਿੱਚ ਕੋਈ ਨੁਕਸ ਨਹੀਂ ਦਰਸਾਏ ਗਏ ਹਨ ਅਤੇ ਤੁਸੀਂ ਚੁਣਿਆ ਹੈ ਕਿ ਇਹ ਵਾਹਨ ਚਲਾਉਣ ਲਈ ਅਸੁਰੱਖਿਅਤ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਅੱਗੇ ਵਧਣ ਤੋਂ ਪਹਿਲਾਂ ਪ੍ਰਮਾਣਿਤ ਕਰੋ।';
_fc_props._90_days = '90 ਦਿਨ';
_fc_props.cloud_dvir = 'ਕਲਾਉਡ DVIR';
_fc_props.cloud_dvir_details = 'ਕਲਾਉਡ DVIR ਅਧੀਨਗੀਆਂ';
_fc_props.show_load_zones = 'ਲੋਡ ਜ਼ੋਨ ਦਿਖਾਓ';
_fc_props.hide_load_zones = 'ਲੋਡ ਜ਼ੋਨ ਓਹਲੇ';
_fc_props.no = 'ਕੋਈ';
_fc_props.cloud_dvir__reset = 'ਰੀਸੈਟ ਕਰੋ';
_fc_props.cloud_dvir__clear = 'ਸਾਫ਼';
_fc_props.mark_read = 'ਮਾਰਕ ਰੀਡ';
_fc_props.mark_unread = 'ਨਾ-ਪੜ੍ਹੇ ਦੀ ਨਿਸ਼ਾਨਦੇਹੀ ਕਰੋ';
_fc_props.read = 'ਪੜ੍ਹੋ';
_fc_props.unread = 'ਨਾ ਪੜ੍ਹਿਆ';
_fc_props.air_sensor_serial_number = 'ਏਅਰ ਸੈਂਸਰ ਸੀਰੀਅਲ #';
_fc_props.air_sensor_mac_address = 'ਏਅਰ ਸੈਂਸਰ MAC ਪਤਾ';
_fc_props.air_sensor_serial_number_invalid_format = 'ਏਅਰ ਸੈਂਸਰ ਸੀਰੀਅਲ ਨੰਬਰ ਵੈਧ ਫਾਰਮੈਟ ਵਿੱਚ ਨਹੀਂ ਹੈ: (ਅੱਖਰ ਅੰਕੀ ਅਤੇ ਡੈਸ਼ਾਂ ਦੇ ਨਾਲ ਵੱਧ ਤੋਂ ਵੱਧ 32 ਅੱਖਰ)';
_fc_props.air_sensor_mac_address_invalid_format = 'ਏਅਰ ਸੈਂਸਰ MAC ਪਤਾ ਵੈਧ ਹੈਕਸ ਫਾਰਮੈਟ ਵਿੱਚ ਨਹੀਂ ਹੈ ਜਿਵੇਂ ਕਿ ਖੇਤਰ ਲਈ ਪਲੇਸਹੋਲਡਰ ਵਿੱਚ ਪ੍ਰਦਰਸ਼ਿਤ ਕੀਤਾ ਗਿਆ ਹੈ';
_fc_props.air_sensor_license = 'ਏਅਰ ਸੈਂਸਰ';
_fc_props.adjust_route_point = 'ਰੂਟ ਪੁਆਇੰਟ ਵਿਵਸਥਿਤ ਕਰੋ';
_fc_props.route_point_panel = 'ਰੂਟ ਪੁਆਇੰਟ ਪੈਨਲ';
_fc_props.route_point = 'ਰੂਟ ਪੁਆਇੰਟ';
_fc_props.cloud_dvir__pre_trip = 'ਪੂਰਵ ਯਾਤਰਾ';
_fc_props.cloud_dvir__post_trip = 'ਪੋਸਟ ਟ੍ਰਿਪ';
_fc_props.cloud_dvir__other_insction_type = 'ਹੋਰ';
_fc_props.cloud_dvir__dvir_completed = 'ਪੂਰਾ ਹੋਇਆ';
_fc_props.cloud_dvir__awaiting_mechanic = 'ਮਕੈਨਿਕ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹੈ';
_fc_props.cloud_dvir__awaiting_driver = 'ਡਰਾਈਵਰ ਸਾਈਨ-ਆਫ ਦੀ ਉਡੀਕ ਕਰ ਰਿਹਾ ਹੈ';
_fc_props.unable_to_check_air_sensor_serial_number_please_contact_support_for_assistance = 'ਏਅਰ ਸੈਂਸਰ ਸੀਰੀਅਲ ਨੰਬਰ ਦੀ ਜਾਂਚ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ, ਵਾਧੂ ਸਹਾਇਤਾ ਲਈ ਸਹਾਇਤਾ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।';
_fc_props.this_air_sensor_serial_number_exists_please_use_another_serial_number = 'ਇਹ ਏਅਰ ਸੈਂਸਰ ਸੀਰੀਅਲ ਨੰਬਰ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਕੋਈ ਹੋਰ ਸੀਰੀਅਲ ਨੰਬਰ ਵਰਤੋ';
_fc_props.unable_to_check_air_sensor_mac_address_please_contact_support_for_assistance = 'ਏਅਰ ਸੈਂਸਰ MAC ਪਤੇ ਦੀ ਜਾਂਚ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ, ਕਿਰਪਾ ਕਰਕੇ ਸਹਾਇਤਾ ਲਈ ਸਹਾਇਤਾ ਨਾਲ ਸੰਪਰਕ ਕਰੋ';
_fc_props.cloud_dvir__error_submit_dvir_title = 'DVIR ਸਪੁਰਦ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.cloud_dvir__error_submit_dvir_descr = 'ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ';
_fc_props.ticket_integration_descr = 'ਟਿਕਟ ਏਕੀਕਰਣ ਟਿਕਟ ਏਕੀਕਰਣ ਸੈਟਿੰਗਾਂ ਦੇ ਨਾਲ-ਨਾਲ ਹੋਮ ਸਕ੍ਰੀਨ / ਲੋਡ ਸਕ੍ਰੀਨ / ਹਾਰਡ ਮਾਉਂਟ ਡਿਸਪਲੇਅ ਨੂੰ ਸੈਟ ਕਰਨ ਦੀ ਆਗਿਆ ਦਿੰਦਾ ਹੈ';
_fc_props.cloud_dvir__success_submit_dvir_title = 'DVIR ਸਫਲਤਾਪੂਰਵਕ ਸਪੁਰਦ ਕੀਤਾ ਗਿਆ';
_fc_props.cloud_dvir__inspection_trip_type = 'ਨਿਰੀਖਣ ਯਾਤਰਾ ਦੀ ਕਿਸਮ';
_fc_props.menu_dvir_title_mechanic = 'ਮਕੈਨਿਕ';
_fc_props.sidemenu_dvir = 'ਡੀ.ਵੀ.ਆਈ.ਆਰ';
_fc_props.air_sensor_firmware = 'ਏਅਰ ਸੈਂਸਰ ਫਰਮਵੇਅਰ';
_fc_props.when_no_active_ticket = 'ਜਦੋਂ ਕੋਈ ਕਿਰਿਆਸ਼ੀਲ ਟਿਕਟ ਨਹੀਂ';
_fc_props.used_by = 'ਦੁਆਰਾ ਵਰਤੀ ਜਾਂਦੀ ਹੈ';
_fc_props.fields = 'ਖੇਤਰ';
_fc_props.are_you_sure_you_want_to_delete_ticket_layout = 'ਕੀ ਤੁਸੀਂ ਪੱਕਾ ਟਿਕਟ ਲੇਆਉਟ "{0}" ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.menu_settings_title_ticket_layout = 'ਟਿਕਟ ਲੇਆਉਟ';
_fc_props.add_ticket_layout = 'ਟਿਕਟ ਲੇਆਉਟ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.edit_ticket_layout = 'ਟਿਕਟ ਲੇਆਉਟ ਦਾ ਸੰਪਾਦਨ ਕਰੋ';
_fc_props.ticket_layout_edit_modal_name = 'ਨਾਮ';
_fc_props.ticket_layout_edit_modal_default = 'ਡਿਫਾਲਟ';
_fc_props.ticket_layout_edit_modal_error_message_name_empty = 'ਟਿਕਟ ਲੇਆਉਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ: ਨਾਮ ਖੇਤਰ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.ticket_layout_edit_modal_error_message_name_invalid_format = 'ਟਿਕਟ ਲੇਆਉਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਗਲਤੀ: ਨਾਮ ਖੇਤਰ ਵਿੱਚ ਸਿਰਫ ਅੱਖਰ ਅਤੇ ਨੰਬਰ ਹੋਣੇ ਚਾਹੀਦੇ ਹਨ';
_fc_props.ticket_layout_edit_modal_fields = 'ਖੇਤਰ';
_fc_props.ticket_layout_edit_modal_usage = 'ਵਰਤੋਂ';
_fc_props.ticket_layout_has_been_removed_please_refresh_page = 'ਟਿਕਟ ਖਾਕਾ ਹਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਪੰਨੇ ਨੂੰ ਤਾਜ਼ਾ ਕਰੋ।';
_fc_props.more_lower_case = 'ਹੋਰ';
_fc_props.ticket_layout_edit_modal_error_selected_fields_are_empty = 'ਟਿਕਟ ਲੇਆਉਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਗਲਤੀ: ਇੱਕ ਜਾਂ ਇੱਕ ਤੋਂ ਵੱਧ ਖੇਤਰ ਚੁਣੇ ਜਾਣੇ ਚਾਹੀਦੇ ਹਨ।';
_fc_props.ticket_layout_edit_modal_error_selected_statuses_are_empty = 'ਟਿਕਟ ਲੇਆਉਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਗਲਤੀ: ਇੱਕ ਜਾਂ ਇੱਕ ਤੋਂ ਵੱਧ ਸਥਿਤੀਆਂ ਚੁਣੀਆਂ ਜਾਣੀਆਂ ਚਾਹੀਦੀਆਂ ਹਨ।';
_fc_props.cloud_dvir__ad_hoc = 'ਐਡਹਾਕ';
_fc_props.radius_multiplier_should_be = 'ਰੇਡੀਅਸ ਗੁਣਕ ਇੱਕ ਫਲੋਟਿੰਗ ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.ticket_max_activate_age_err = 'ਟਿਕਟ ਅਧਿਕਤਮ ਐਕਟੀਵੇਟ ਉਮਰ ਮਿੰਟ ਇੱਕ ਪੂਰਨ ਅੰਕ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.max_hotspot_unused_days_errs = 'ਅਧਿਕਤਮ ਹੌਟਸਪੌਟ ਅਣਵਰਤੇ ਦਿਨ ਇੱਕ ਪੂਰਨ ਅੰਕ ਹੋਣੇ ਚਾਹੀਦੇ ਹਨ।';
_fc_props.begin_pour_air_content = 'ਹਵਾ ਸਮੱਗਰੀ ਡੋਲ੍ਹਣਾ ਸ਼ੁਰੂ ਕਰੋ';
_fc_props.arrival_air_content = 'ਆਗਮਨ ਹਵਾਈ ਸਮੱਗਰੀ';
_fc_props.fully_mixed_air_content = 'ਪੂਰੀ ਤਰ੍ਹਾਂ ਮਿਸ਼ਰਤ ਹਵਾ ਸਮੱਗਰੀ';
_fc_props.departure_air_content = 'ਰਵਾਨਗੀ ਏਅਰ ਸਮੱਗਰੀ';
_fc_props.popup_enabled = 'ਪੌਪਅੱਪ ਸਮਰਥਿਤ';
_fc_props.popup_disabled = 'ਪੌਪਅੱਪ ਅਯੋਗ ਹੈ';
_fc_props.cloud_dvir__leave_confirmation_title = 'ਚੇਤਾਵਨੀ';
_fc_props.cloud_dvir__leave_confirmation_description = 'ਹੁਣੇ ਪੰਨੇ ਤੋਂ ਬਾਹਰ ਆਉਣ ਨਾਲ ਸਾਰੀਆਂ ਤਬਦੀਲੀਆਂ ਖਤਮ ਹੋ ਜਾਣਗੀਆਂ।';
_fc_props.cloud_dvir__leave_confirmation_approve = 'ਪੁਸ਼ਟੀ ਕਰੋ';
_fc_props.cloud_dvir__leave_confirmation_abort = 'ਰੱਦ ਕਰੋ';
_fc_props.air_bt_ratio = 'ਏਅਰ ਸੈਂਸਰ ਮਾਪ';
_fc_props.cloud_dvir__view_photos_notes = 'ਫੋਟੋਆਂ/ਨੋਟਸ ਦੇਖੋ';
_fc_props.error_adding_the_status_it_will_create_a_blank_usage_in_another_ticket_layout = 'ਸਥਿਤੀ ਨੂੰ ਜੋੜਨ ਨਾਲ ਕਿਸੇ ਹੋਰ ਟਿਕਟ ਲੇਆਉਟ ਵਿੱਚ ਇੱਕ ਖਾਲੀ ਵਰਤੋਂ ਬਣ ਜਾਵੇਗੀ। ਕੀ ਤੁਸੀਂ ਜੋੜਨਾ ਜਾਰੀ ਰੱਖਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.vehicle_does_not_have_air_sensor_license = 'ਵਾਹਨ ਕੋਲ ਏਅਰ ਸੈਂਸਰ ਲਾਇਸੈਂਸ ਨਹੀਂ ਹੈ।';
_fc_props.air_sensor_installed = 'ਏਅਰ ਸੈਂਸਰ ਲਗਾਇਆ ਗਿਆ';
_fc_props.load_card = 'ਕਾਰਡ ਲੋਡ ਕਰੋ';
_fc_props.cloud_dvir__inspection_profile = 'ਨਿਰੀਖਣ ਪ੍ਰੋਫ਼ਾਈਲ';
_fc_props.cloud_dvir__view_details = 'ਵੇਰਵੇ ਵੇਖੋ';
_fc_props.cloud_dvir__certify_repairs = 'ਮੁਰੰਮਤ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰੋ';
_fc_props.cloud_dvir__repairs_made = 'ਮੁਰੰਮਤ ਕੀਤੀ';
_fc_props.cloud_dvir__repairs_not_necessary = 'ਮੁਰੰਮਤ ਜ਼ਰੂਰੀ ਨਹੀਂ';
_fc_props.cloud_dvir__mechanic_note_from = 'ਤੋਂ ਮਕੈਨਿਕ ਨੋਟ';
_fc_props.cloud_dvir__mechanic_assignment = 'ਮਕੈਨਿਕ ਅਸਾਈਨਮੈਂਟ';
_fc_props.cloud_dvir__mechanic_signature_description = 'ਹੇਠਾਂ ਸਾਰੀਆਂ ਲਾਗੂ ਹੋਣ ਵਾਲੀਆਂ ਵਸਤੂਆਂ \'ਤੇ ਨਿਸ਼ਾਨ ਲਗਾਓ ਅਤੇ ਇਹ ਦਰਸਾਉਂਦੇ ਹੋਏ ਸਾਈਨ ਕਰੋ ਕਿ ਵਾਹਨ ਦੀ ਸਹੀ ਢੰਗ ਨਾਲ ਜਾਂਚ ਕੀਤੀ ਗਈ ਹੈ।';
_fc_props.cloud_dvir__condition_satisfactory_label = 'ਇਸ ਗੱਡੀ ਦੀ ਹਾਲਤ ਤਸੱਲੀਬਖਸ਼ ਹੈ';
_fc_props.cloud_dvir__defects_corrected_label = 'ਨੁਕਸ ਠੀਕ ਕੀਤੇ ਗਏ ਹਨ';
_fc_props.cloud_dvir__repair_not_required_label = 'ਵਾਹਨ ਦੇ ਸੁਰੱਖਿਅਤ ਸੰਚਾਲਨ ਲਈ ਮੁਰੰਮਤ ਦੀ ਲੋੜ ਨਹੀਂ ਹੈ';
_fc_props.safety = 'ਸੁਰੱਖਿਆ';
_fc_props.cloud_dvir__awaiting_sign = 'ਉਡੀਕ ਚਿੰਨ੍ਹ';
_fc_props.cloud_dvir__dvir_review = 'DVIR ਸਮੀਖਿਆ';
_fc_props.settings.mobile_auto_start_navigation = 'ਮੋਬਾਈਲ \'ਤੇ ਆਟੋ-ਸਟਾਰਟ ਨੈਵੀਗੇਸ਼ਨ';
_fc_props.cloud_dvir__edit = 'ਸੰਪਾਦਿਤ ਕਰੋ';
_fc_props.delete_custom_label = 'ਕਸਟਮ ਲੇਬਲ ਮਿਟਾਓ';
_fc_props.cloud_dvir__error_incompatible_aux_version = 'TrackIt ਦਾ ਤੁਹਾਡਾ ਸੰਸਕਰਣ ਪੁਰਾਣਾ ਹੈ ਅਤੇ ਕੁਝ DVIR ਵਿਸ਼ੇਸ਼ਤਾਵਾਂ ਠੀਕ ਤਰ੍ਹਾਂ ਕੰਮ ਨਹੀਂ ਕਰ ਸਕਦੀਆਂ। ਕਿਰਪਾ ਕਰਕੇ ਅੱਗੇ ਵਧਣ ਤੋਂ ਪਹਿਲਾਂ TrackIt ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ।';
_fc_props.cloud_dvir__error_submit_dvir_signature = 'ਹਸਤਾਖਰ ਅੱਪਲੋਡ ਦੌਰਾਨ ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ। ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ';
_fc_props.max_hotspot_unused_days_error = 'ਅਧਿਕਤਮ ਹੌਟਸਪੌਟ ਅਣਵਰਤੇ ਦਿਨਾਂ ਵਿੱਚ ਅਧਿਕਤਮ 1000 ਦਿਨ ਹੁੰਦੇ ਹਨ।';
_fc_props.menu_dvir_title_admin_templates = 'ਟੈਂਪਲੇਟਸ';
_fc_props.menu.dvir = {};
_fc_props.menu.dvir.admin = {};
_fc_props.menu.dvir.admin.title = 'ਐਡਮਿਨ';
_fc_props.cloud_dvir__admin_templates = 'ਟੈਂਪਲੇਟਸ';
_fc_props.form_name = 'ਫਾਰਮ ਦਾ ਨਾਮ';
_fc_props.form_status = 'ਫਾਰਮ ਸਥਿਤੀ';
_fc_props.menu.dvir.title = 'ਡੀ.ਵੀ.ਆਈ.ਆਰ';
_fc_props.cloud_dvir__option_download = 'ਡਾਊਨਲੋਡ ਕਰੋ';
_fc_props.cloud_dvir__option_view = 'ਦੇਖੋ';
_fc_props.cloud_dvir__inspectortype_driver = 'ਡਰਾਈਵਰ';
_fc_props.menu.dvir.description = {};
_fc_props.menu.dvir.description.admin = 'ਕਸਟਮ DVIR ਫਾਰਮ ਅਤੇ ਸਬਮਿਸ਼ਨ ਦੇਖੋ/ਸੋਧੋ/ਬਣਾਓ';
_fc_props.menu.dvir.description.mechanic = 'ਕਾਰਵਾਈਯੋਗ DVIR ਸਬਮਿਸ਼ਨ ਦੇਖੋ/ਸੋਧੋ';
_fc_props.not_in_use = 'ਵਰਤੋਂ ਵਿੱਚ ਨਹੀਂ ਹੈ';
_fc_props.logged_in = 'ਲਾਗਇਨ ਕੀਤਾ';
_fc_props.reviewing_driver = 'ਡਰਾਈਵਰ ਦੀ ਸਮੀਖਿਆ ਕਰ ਰਿਹਾ ਹੈ';
_fc_props.cloud_dvir__report_title = 'ਡਰਾਈਵਰ ਦੀ ਵਾਹਨ ਨਿਰੀਖਣ ਰਿਪੋਰਟ';
_fc_props.cloud_dvir__report_signed = 'ਦਸਤਖਤ ਕੀਤੇ';
_fc_props.cloud_dvir__report_page_counter = 'ਪੰਨਾ {1} ਵਿੱਚੋਂ {0}';
_fc_props.cloud_dvir__report_defects = 'ਨੁਕਸ';
_fc_props.cloud_dvir__report_comments = 'ਟਿੱਪਣੀਆਂ';
_fc_props.cloud_dvir__report_inspection_table_title = 'ਵਾਹਨ ਨਿਰੀਖਣ';
_fc_props.cloud_dvir__report_driver_table_title = 'ਡਰਾਈਵਰ ਜਾਣਕਾਰੀ';
_fc_props.cloud_dvir__report_carrier = 'ਕੈਰੀਅਰ';
_fc_props.cloud_dvir__report_truck = 'ਟਰੱਕ';
_fc_props.cloud_dvir__report_location = 'ਟਿਕਾਣਾ';
_fc_props.cloud_dvir__report_odometer = 'ਓਡੋਮੀਟਰ';
_fc_props.cloud_dvir__report_hours = 'ਘੰਟੇ';
_fc_props.cloud_dvir__report_no_comments = 'ਕੋਈ ਟਿੱਪਣੀ ਨਹੀਂ';
_fc_props.include_unlicensed_vehicles = 'ਬਿਨਾਂ ਲਾਇਸੈਂਸ ਵਾਲੇ ਵਾਹਨ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.cloud_dvir__search_by_form_name = 'ਫਾਰਮ ਨਾਮ ਦੁਆਰਾ ਖੋਜ ਕਰੋ';
_fc_props.cloud_dvir__create_dvir = 'ਇੱਕ DVIR ਬਣਾਓ';
_fc_props.cloud_dvir__update_dvir = 'DVIR ਅੱਪਡੇਟ ਕਰੋ';
_fc_props.cloud_dvir__form_name = 'ਫਾਰਮ ਦਾ ਨਾਮ';
_fc_props.cloud_dvir__form_description = 'ਫਾਰਮ ਵਰਣਨ';
_fc_props.cloud_dvir__form_status = 'ਫਾਰਮ ਸਥਿਤੀ';
_fc_props.information = 'ਜਾਣਕਾਰੀ';
_fc_props.cloud_dvir__search_categories = 'ਸ਼੍ਰੇਣੀਆਂ ਖੋਜੋ';
_fc_props.cloud_dvir__create_new_category = 'ਨਵੀਂ ਸ਼੍ਰੇਣੀ ਬਣਾਓ';
_fc_props.category_name = 'ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ';
_fc_props.sub_category = 'ਉਪ ਸ਼੍ਰੇਣੀ';
_fc_props.sub_category_name = 'ਉਪ ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ';
_fc_props.delete_category = 'ਸ਼੍ਰੇਣੀ ਮਿਟਾਓ';
_fc_props.cloud_dvir__dvir_information = 'DVIR ਜਾਣਕਾਰੀ';
_fc_props.cloud_dvir__dvir_choose_categories = 'ਸ਼੍ਰੇਣੀਆਂ ਚੁਣੋ';
_fc_props.cloud_dvir__create_category = 'ਸ਼੍ਰੇਣੀ ਬਣਾਓ';
_fc_props.cloud_dvir__update_category = 'ਅੱਪਡੇਟ ਸ਼੍ਰੇਣੀ';
_fc_props.delete_route_point = 'ਰੂਟ ਪੁਆਇੰਟ ਮਿਟਾਓ';
_fc_props.view_route_points = 'ਰੂਟ ਪੁਆਇੰਟ ਵੇਖੋ';
_fc_props.ignore_cancel_preload = 'ਰੱਦ ਕਰਨ ਨੂੰ ਅਣਡਿੱਠ ਕਰੋ, ਤੁਸੀਂ ਪਹਿਲਾਂ ਤੋਂ ਲੋਡ ਕੀਤੇ ਹੋ। ਤੁਹਾਡੀ ਹੋਮ ਰਨ ਜਲਦੀ ਹੀ ਦਿਖਾਈ ਜਾਵੇਗੀ';
_fc_props.cloud_dvir__assigned_mechanic = 'ਮਕੈਨਿਕ ਨਿਯੁਕਤ ਕੀਤਾ ਗਿਆ';
_fc_props.cloud_dvir__add_mechanic = 'ਇੱਕ ਮਕੈਨਿਕ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.contact_type = 'ਸੰਪਰਕ ਦੀ ਕਿਸਮ';
_fc_props.cloud_dvir__send_email_notifications = 'ਈਮੇਲ ਸੂਚਨਾਵਾਂ ਭੇਜੋ';
_fc_props.menu_dvir_title_admin_dvir = 'ਡੀ.ਵੀ.ਆਈ.ਆਰ';
_fc_props.cloud_dvir__error_retrieving_dvir_history = 'DVIR ਇਤਿਹਾਸ ਰਿਕਾਰਡਾਂ ਨੂੰ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ।';
_fc_props.cloud_dvir__error_processing_dvir_record = 'DVIR ਰਿਕਾਰਡ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ।';
_fc_props.cloud_dvir__error_saving_submission = 'ਸਪੁਰਦਗੀ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ। ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ.';
_fc_props.cloud_dvir__error_retrieving_mechanics = 'ਮਕੈਨਿਕਸ ਦੀ ਸੂਚੀ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ।';
_fc_props.cloud_dvir__template_saved = 'ਟੈਮਪਲੇਟ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ';
_fc_props.cloud_dvir__error_retrieving_dvir_templates = 'ਟੈਮਪਲੇਟਾਂ ਦੀ ਸੂਚੀ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ।';
_fc_props.cloud_dvir__error_saving_dvir_template = 'ਟੈਮਪਲੇਟ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ। ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ.';
_fc_props.dispatch_group = 'ਡਿਸਪੈਚ ਗਰੁੱਪ';
_fc_props.save_adjust = 'ਰੂਟ ਪੁਆਇੰਟ ਨੂੰ ਸੇਵ ਅਤੇ ਐਡਜਸਟ ਕਰੋ';
_fc_props.reset_route_point = 'ਰੂਟ ਪੁਆਇੰਟ ਰੀਸੈਟ ਕਰੋ';
_fc_props.no_records_stub_adjust_filters = 'ਨਤੀਜਿਆਂ ਦੀ ਗਿਣਤੀ ਵਧਾਉਣ ਲਈ ਆਪਣੀ ਖੋਜ ਜਾਂ ਫਿਲਟਰ ਸੈਟਿੰਗਾਂ ਨੂੰ ਵਿਵਸਥਿਤ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰੋ।';
_fc_props.no_dvir_records = 'ਸਾਰੇ DVIRs \'ਤੇ ਫੜੇ ਗਏ!';
_fc_props.no_dvir_records_sub_title = 'ਤੁਸੀਂ ਬਹੁਤ ਕੁਝ ਪੂਰਾ ਕੀਤਾ ਹੈ';
_fc_props.settings.master_data_connex_x_api_key = 'ਮਾਸਟਰ ਡਾਟਾ ਕਨੈਕਸ x-api-key';
_fc_props.slump_not_obtained_for_x = 'ਇਸ ਲਈ ਕੋਈ ਸਲੰਪ ਰੀਡਿੰਗ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਈ: {0}';
_fc_props.please_select_a_sensor_status_type = 'ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਚੇਤਾਵਨੀ ਨੂੰ ਚਾਲੂ ਕਰਨ ਲਈ ਸੈਂਸਰ ਸਥਿਤੀ ਕਿਸਮਾਂ ਦੀ ਚੋਣ ਕਰੋ';
_fc_props.settings.master_data_connex_token = 'ਮਾਸਟਰ ਡਾਟਾ ਕਨੈਕਸ ਟੋਕਨ';
_fc_props.error_validating_master_data_connex_credentials = 'ਮਾਸਟਰ ਡੇਟਾ ਕਨੈਕਸ ਕ੍ਰੈਡੈਂਸ਼ੀਅਲ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.settings.master_data_connex_base_url = 'ਮਾਸਟਰ ਡਾਟਾ ਕਨੈਕਸ ਬੇਸ URL';
_fc_props.settings.master_data_connex_exp_time = 'ਮਾਸਟਰ ਡਾਟਾ ਕਨੈਕਸ ਮਿਆਦ ਪੁੱਗਣ ਦਾ ਸਮਾਂ';
_fc_props.download_template = 'ਟੈਮਪਲੇਟ ਡਾਊਨਲੋਡ ਕਰੋ';
_fc_props.menu_settings_title_dispatch_group = 'ਡਿਸਪੈਚ ਗਰੁੱਪ';
_fc_props.settings.dispatch_groups = 'ਡਿਸਪੈਚ ਗਰੁੱਪ';
_fc_props.dispatch_group_name = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਦਾ ਨਾਮ';
_fc_props.dispatch_group_plants = 'ਗਰੁੱਪ ਪਲਾਂਟ ਡਿਸਪੈਚ ਕਰੋ';
_fc_props.dispatch_group_plants_mapping_message = 'ਦੱਸੋ ਕਿ ਕਿਹੜੇ ਪੌਦੇ ਇਸ ਡਿਸਪੈਚ ਗਰੁੱਪ ਦਾ ਹਿੱਸਾ ਹੋਣਗੇ।';
_fc_props.dispatch_group_users_mapping_message = 'ਦੱਸੋ ਕਿ ਕਿਹੜੇ ਉਪਭੋਗਤਾ ਇਸ ਡਿਸਪੈਚ ਸਮੂਹ ਦਾ ਹਿੱਸਾ ਹੋਣਗੇ।';
_fc_props.dispatch_group_plant_disassociation_message = 'ਪੌਦਾ ਇੱਕ ਸਮੇਂ ਵਿੱਚ ਇੱਕ ਡਿਸਪੈਚ ਸਮੂਹ ਦੇ ਅਧੀਨ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.dispatch_group_search_by_plant = 'ਪੌਦੇ ਦੇ ਨਾਮ ਦੁਆਰਾ ਖੋਜ ਕਰੋ';
_fc_props.dispatch_group_search_by_username = 'ਉਪਭੋਗਤਾ ਨਾਮ ਦੁਆਰਾ ਖੋਜ ਕਰੋ';
_fc_props.search_by_dispatch_group_name = 'ਡਿਸਪੈਚ ਸਮੂਹ ਦੇ ਨਾਮ ਦੁਆਰਾ ਖੋਜ ਕਰੋ';
_fc_props.create_dispatch_group = 'ਇੱਕ ਡਿਸਪੈਚ ਗਰੁੱਪ ਬਣਾਓ';
_fc_props.update_dispatch_group = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਨੂੰ ਅੱਪਡੇਟ ਕਰੋ';
_fc_props.dispatch_group_saved = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ';
_fc_props.warning_deleting_dispatch_group_unassociate_plants = 'ਇਸ ਡਿਸਪੈਚ ਗਰੁੱਪ ਨੂੰ ਮਿਟਾਉਣ ਨਾਲ ਸੰਬੰਧਿਤ ਪਲਾਂਟਾਂ ਲਈ ਡਿਫੌਲਟ ਡਿਸਪੈਚ ਗਰੁੱਪ ਬਣ ਜਾਣਗੇ।';
_fc_props.error_saving_dispatch_group = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ। ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ.';
_fc_props.please_enter_a_valid_plant_num = 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਪਲਾਂਟ ਨੰਬਰ ਦਾਖਲ ਕਰੋ।';
_fc_props.cloud_dvir__report_no_defects = 'ਕੋਈ ਨੁਕਸ ਨਹੀਂ';
_fc_props.shoud_select_dispatch_group = 'ਤੁਹਾਨੂੰ ਘੱਟੋ-ਘੱਟ ਇੱਕ ਡਿਸਪੈਚ ਗਰੁੱਪ ਚੁਣਨਾ ਪਵੇਗਾ';
_fc_props.one_or_more_users_are_associated_with_this_dispatch_group_only = 'ਮਿਟਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ ਕਿਉਂਕਿ ਇਸ ਡਿਸਪੈਚ ਸਮੂਹ ਵਿੱਚ ਉਹ ਉਪਭੋਗਤਾ ਹਨ ਜੋ ਸਿਰਫ ਇਸ ਡਿਸਪੈਚ ਸਮੂਹ ਨਾਲ ਜੁੜੇ ਹੋਏ ਹਨ।';
_fc_props.cloud_dvir__username = 'ਯੂਜ਼ਰਨੇਮ';
_fc_props.cloud_dvir__password = 'ਪਾਸਵਰਡ';
_fc_props.cloud_dvir__login = 'ਲਾਗਿਨ';
_fc_props.cloud_dvir__mechanic_sign_in = 'ਮਕੈਨਿਕ ਸਾਈਨ ਇਨ';
_fc_props.delete_dispatch_group = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਮਿਟਾਓ';
_fc_props.cloud_dvir__driver = 'ਡਰਾਈਵਰ';
_fc_props.cloud_dvir__equipment_pound = 'ਉਪਕਰਨ #';
_fc_props.cloud_dvir__mechanic = 'ਮਕੈਨਿਕ';
_fc_props.dispatch_group_user_only_unassociate_message = 'ਉਪਭੋਗਤਾ ਨੂੰ ਮਿਟਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ ਕਿਉਂਕਿ ਉਪਭੋਗਤਾ ਘੱਟੋ ਘੱਟ ਇੱਕ ਡਿਸਪੈਚ ਸਮੂਹ ਨਾਲ ਜੁੜੇ ਹੋਣੇ ਚਾਹੀਦੇ ਹਨ।';
_fc_props.cloud_dvir__time_period_filter_today = 'ਅੱਜ';
_fc_props.cloud_dvir__time_period_filter_yesterday = 'ਕੱਲ੍ਹ';
_fc_props.cloud_dvir__time_period_filter_last_7_days = 'ਪਿਛਲੇ 7 ਦਿਨ';
_fc_props.cloud_dvir__time_period_filter_last_30_days = 'ਪਿਛਲੇ 30 ਦਿਨ';
_fc_props.cloud_dvir__date_range_filter = 'ਮਿਤੀ ਅਨੁਸਾਰ ਫਿਲਟਰ ਕਰੋ';
_fc_props.cloud_dvir__download = 'ਡਾਊਨਲੋਡ ਕਰੋ';
_fc_props.dispatch_group_is_empty = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ।';
_fc_props.dispatch_group_with_empty_plants = 'ਘੱਟੋ-ਘੱਟ ਇੱਕ ਪੌਦਾ ਡਿਸਪੈਚ ਗਰੁੱਪ ਨਾਲ ਜੁੜਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.dispatch_group_with_empty_users = 'ਘੱਟੋ-ਘੱਟ ਇੱਕ ਉਪਭੋਗਤਾ ਨੂੰ ਡਿਸਪੈਚ ਸਮੂਹ ਨਾਲ ਜੋੜਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ।';
_fc_props.not_delete_one_or_more_users_are_associated_with_this_dispatch_group_only = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਨੂੰ ਮਿਟਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ ਕਿਉਂਕਿ ਇਸ ਵਿੱਚ ਉਹ ਉਪਭੋਗਤਾ ਹਨ ਜੋ ਸਿਰਫ਼ ਇਸ ਡਿਸਪੈਚ ਗਰੁੱਪ ਨਾਲ ਜੁੜੇ ਹੋਏ ਹਨ।';
_fc_props.dispatch_group_not_exists = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਮੌਜੂਦ ਨਹੀਂ ਹੈ।';
_fc_props.dispatch_group_contains_one_plant = 'ਮਿਟਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ ਕਿਉਂਕਿ ਇਸ ਡਿਸਪੈਚ ਗਰੁੱਪ ਵਿੱਚ ਸਿਰਫ਼ ਇੱਕ ਪੌਦਾ ਹੈ।';
_fc_props.dispatch_group_users = 'ਸਮੂਹ ਉਪਭੋਗਤਾਵਾਂ ਨੂੰ ਡਿਸਪੈਚ ਕਰੋ';
_fc_props.cloud_dvir__error_retrieving_dvir = 'DVIR ਰਿਕਾਰਡ ਨੂੰ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.cloud_dvir__assign_mechanic = 'ਮਕੈਨਿਕ ਨੂੰ ਨਿਯੁਕਤ ਕਰੋ';
_fc_props.cloud_dvir__submit_review = 'ਸਮੀਖਿਆ ਸਪੁਰਦ ਕਰੋ';
_fc_props.error_deleting_dispatch_group = 'ਡਿਸਪੈਚ ਗਰੁੱਪ ਨੂੰ ਮਿਟਾਉਣ ਵਿੱਚ ਤਰੁੱਟੀ।';
_fc_props.error_retrieving_dispatch_groups = 'ਡਿਸਪੈਚ ਸਮੂਹਾਂ ਦੀ ਸੂਚੀ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ।';
_fc_props.cloud_dvir__undo_selection = 'ਚੋਣ ਨੂੰ ਅਣਕੀਤਾ ਕਰੋ';
_fc_props.enable_master_data = 'ਮਾਸਟਰ ਡੇਟਾ ਨੂੰ ਸਮਰੱਥ ਬਣਾਓ';
_fc_props.manually_sync = 'ਹੱਥੀਂ ਸਿੰਕ ਕਰੋ';
_fc_props.master_data_connex_token_expiration = 'ਮਾਸਟਰ ਡਾਟਾ ਕਨੈਕਸ ਟੋਕਨ ਦੀ ਮਿਆਦ ਸਮਾਪਤੀ';
_fc_props.cloud_dvir__time_period_filter_custom = 'ਪ੍ਰਥਾ';
_fc_props.settings.zello_session_timeout_minutes = 'Zello ਸੈਸ਼ਨ ਦਾ ਸਮਾਂ ਸਮਾਪਤ (ਮਿੰਟ)';
_fc_props.menu_settings_title_master_data = 'ਮਾਸਟਰ ਡਾਟਾ';
_fc_props.settings.master_data = 'ਮਾਸਟਰ ਡਾਟਾ';
_fc_props.error_retrieving_master_data = 'ਮਾਸਟਰ ਡਾਟਾ ਮੁੜ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.master_data_sync_completed = 'ਮਾਸਟਰ ਡਾਟਾ ਸਿੰਕ ਪੂਰਾ ਹੋਇਆ';
_fc_props.master_data_successfully_updated = 'ਡਾਟਾ ਸਫਲਤਾਪੂਰਵਕ ਅੱਪਡੇਟ ਕੀਤਾ ਗਿਆ';
_fc_props.master_data_failed_to_update = 'ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਅਸਫਲ।';
_fc_props.master_data_sync_initiated = 'ਮਾਸਟਰ ਡਾਟਾ ਸਿੰਕ ਸ਼ੁਰੂ ਕੀਤਾ ਗਿਆ';
_fc_props.master_data_sync_failed = 'ਮਾਸਟਰ ਡਾਟਾ ਸਿੰਕ ਅਸਫਲ ਰਿਹਾ';
_fc_props.enabled_excluding_origin_on_deliveries = 'ਸਹੀ (ਡਿਲਿਵਰੀ ਟਿਕਟਾਂ \'ਤੇ ਮੂਲ ਨੂੰ ਛੱਡ ਕੇ)';
_fc_props.enabled_including_origin_on_deliveries = 'ਸਹੀ (ਡਿਲੀਵਰੀ ਟਿਕਟਾਂ \'ਤੇ ਮੂਲ ਸਮੇਤ)';
_fc_props.cloud_dvir__i_will_decide_later = 'ਮੈਂ ਬਾਅਦ ਵਿੱਚ ਫੈਸਲਾ ਕਰਾਂਗਾ';
_fc_props.returning = 'ਵਾਪਸ ਆ ਰਿਹਾ ਹੈ';
_fc_props.leftover_concrete = 'ਬਚਿਆ ਹੋਇਆ ਕੰਕਰੀਟ';
_fc_props.leftover_concrete_on_vehicle_x = 'ਵਾਹਨ {0} \'ਤੇ ਬਚਿਆ ਹੋਇਆ ਕੰਕਰੀਟ';
_fc_props.leftover_concrete_alert = 'ਬਚੀ ਹੋਈ ਕੰਕਰੀਟ ਚੇਤਾਵਨੀ';
_fc_props.wc_ratio_exceeded = 'W/C ਅਨੁਪਾਤ ਵੱਧ ਗਿਆ';
_fc_props.search_by_site_name = 'ਸਾਈਟ ਦੇ ਨਾਮ ਦੁਆਰਾ ਖੋਜ ਕਰੋ';
_fc_props.sites = 'ਸਾਈਟਾਂ';
_fc_props.token_expiration = 'ਟੋਕਨ ਦੀ ਮਿਆਦ ਸਮਾਪਤੀ';
_fc_props.menu_settings_title_connex_api_key_management = 'ਕਨੈਕਸ API ਕੁੰਜੀ ਪ੍ਰਬੰਧਨ';
_fc_props.settings.connex_api_key_management = 'ਕਨੈਕਸ API ਕੁੰਜੀ ਪ੍ਰਬੰਧਨ';
_fc_props.error_retrieving_connex_api_key_data = 'ਕਨੈਕਸ ਏਪੀਆਈ ਕੁੰਜੀ ਡੇਟਾ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.qr_code = 'QR ਕੋਡ';
_fc_props.informing_driver_and_interface_when_entering_wrong_plant = 'ਗਲਤ ਪਲਾਂਟ ਵਿੱਚ ਦਾਖਲ ਹੋਣ \'ਤੇ ਡਰਾਈਵਰ ਅਤੇ ਇੰਟਰਫੇਸ ਨੂੰ ਸੂਚਿਤ ਕਰਨਾ';
_fc_props.enable_driver_message = 'ਡਰਾਈਵਰ ਸੁਨੇਹਾ ਚਾਲੂ ਕਰੋ';
_fc_props.enable_dispatch_message = 'ਡਿਸਪੈਚ ਸੁਨੇਹਾ ਚਾਲੂ ਕਰੋ';
_fc_props.enable_sending_message_to_interface = 'ਇੰਟਰਫੇਸ ਨੂੰ ਸੁਨੇਹਾ ਭੇਜਣਾ ਯੋਗ ਕਰੋ';
_fc_props.ticket_qr_code = 'ਟਿਕਟ QR ਕੋਡ';
_fc_props.leftover_concrete_alert_description = 'ਡੋਲ੍ਹਣ ਤੋਂ ਬਾਅਦ ਬਚਿਆ ਹੋਇਆ ਕੰਕਰੀਟ ਮਿਲਣ \'ਤੇ ਚੇਤਾਵਨੀ।';
_fc_props.minimum_leftover_concrete_amount = 'ਘੱਟੋ-ਘੱਟ ਬਚੀ ਹੋਈ ਠੋਸ ਰਕਮ';
_fc_props.error_validating_master_data_connex_credentials_unauthorized = 'ਮਾਸਟਰ ਡਾਟਾ ਕਨੈਕਸ ਕ੍ਰੈਡੈਂਸ਼ੀਅਲ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.error_validating_master_data_connex_credentials_permissions = 'ਮਾਸਟਰ ਡੇਟਾ ਕਨੈਕਸ ਕ੍ਰੈਡੈਂਸ਼ੀਅਲ ਅਨੁਮਤੀਆਂ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.error_validating_master_data_connex_url_not_found = 'ਮਾਸਟਰ ਡੇਟਾ ਕਨੈਕਸ ਯੂਆਰਐਲ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ ਨਹੀਂ ਮਿਲੀ';
_fc_props.error_validating_master_data_connex_unknown_error = 'ਮਾਸਟਰ ਡੇਟਾ ਕਨੈਕਸ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ ਅਣਜਾਣ ਤਰੁੱਟੀ';
_fc_props.master_data_sync_failed_sync_up_vehicle_type = 'ਮਾਸਟਰ ਡਾਟਾ ਸਮਕਾਲੀਕਰਨ ਅਸਫਲ ਰਿਹਾ ਵਾਹਨ ਦੀ ਕਿਸਮ ਨੂੰ ਸਿੰਕ ਕਰੋ।';
_fc_props.master_data_sync_failed_sync_up_vehicle = 'ਮਾਸਟਰ ਡਾਟਾ ਸਮਕਾਲੀਕਰਨ ਅਸਫਲ ਹੋਇਆ ਵਾਹਨ ਨੂੰ ਸਿੰਕ ਕਰੋ।';
_fc_props.master_data_sync_failed_sync_up_plant = 'ਮਾਸਟਰ ਡਾਟਾ ਸਮਕਾਲੀਕਰਨ ਅਸਫਲ ਹੋਇਆ ਪਲਾਂਟ ਸਿੰਕ ਅੱਪ।';
_fc_props.master_data_sync_failed_data_base_connection = 'ਮਾਸਟਰ ਡਾਟਾ ਸਿੰਕ ਡਾਟਾਬੇਸ ਨਾਲ ਕਨੈਕਸ਼ਨ ਅਸਫਲ ਰਿਹਾ।';
_fc_props.error_validating_master_data_connex_permission_is_insufficient = 'ਮਾਸਟਰ ਡੇਟਾ ਕਨੈਕਸ ਕ੍ਰੈਡੈਂਸ਼ੀਅਲਸ ਬੇਅਰਰ ਟੋਕਨ ਅਨੁਮਤੀ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ ਨਾਕਾਫ਼ੀ ਹੈ';
_fc_props.settings.back_date_arrive_job = 'ਨੌਕਰੀ ਪਹੁੰਚਣ ਦੀ ਪਿਛਲੀ ਤਾਰੀਖ';
_fc_props.device_notifications = 'ਸੂਚਨਾਵਾਂ';
_fc_props.loading_number = 'ਨੰਬਰ ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ';
_fc_props.leftover_concrete_volume_temp_age = 'ਬਚੀ ਹੋਈ ਮਾਤਰਾ={0}, ਟੈਂਪ={1}, ਉਮਰ={2}';
_fc_props.total_ordered = 'ਕੁੱਲ ਆਰਡਰ ਕੀਤੀ ਮਾਤਰਾ';
_fc_props.total_ticketed = 'ਡਿਲੀਵਰ ਕੀਤਾ ਆਰਡਰ';
_fc_props.load_number = 'ਲੋਡ ਨੰਬਰ';
_fc_props.min_leftover_concrete_must_be_filled_in_and_gt_x = 'ਘੱਟੋ-ਘੱਟ ਬਚਿਆ ਹੋਇਆ ਕੰਕਰੀਟ ਭਰਿਆ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ ਅਤੇ {0} ਤੋਂ ਵੱਧ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.error_validating_master_data_connex_credentials_are_the_same = 'ਮਾਸਟਰ ਡੇਟਾ ਨੂੰ ਪ੍ਰਮਾਣਿਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ। ਪ੍ਰਮਾਣ ਪੱਤਰ ਇੱਕੋ ਜਿਹੇ ਨਹੀਂ ਹੋ ਸਕਦੇ।';
_fc_props.use_aws_for_talk = 'ਗੱਲਬਾਤ ਲਈ AWS ਦੀ ਵਰਤੋਂ ਕਰੋ';
_fc_props.air_readings_not_supported = 'ਏਅਰ ਰੀਡਿੰਗ ਸਮਰਥਿਤ ਨਹੀਂ ਹੈ';
_fc_props.air_not_supported = 'ਹਵਾ ਸਮਰਥਿਤ ਨਹੀਂ ਹੈ';
_fc_props.mix_not_supported = 'ਮਿਕਸ ਸਮਰਥਿਤ ਨਹੀਂ ਹੈ';
_fc_props.air_default_and_none_not_supported = 'ਪੂਰਵ-ਨਿਰਧਾਰਤ ਨਹੀਂ ਚੁਣ ਸਕਦੇ ਅਤੇ ਇੱਕੋ ਸਮੇਂ ਸਮਰਥਿਤ ਨਹੀਂ ਹਨ!';
_fc_props.notifications_enabled = 'ਸੂਚਨਾਵਾਂ ਚਾਲੂ ਕੀਤੀਆਂ ਗਈਆਂ';
_fc_props.prevent_status_change = 'ਸਥਿਤੀ ਤਬਦੀਲੀ ਨੂੰ ਰੋਕੋ';
_fc_props.message_to_interface = 'ਇੰਟਰਫੇਸ ਨੂੰ ਸੁਨੇਹਾ';
_fc_props.prevent_wrong_plant = 'ਗਲਤ ਪੌਦੇ ਨੂੰ ਰੋਕੋ';
_fc_props.previous_tickets = 'ਪਿਛਲੀਆਂ ਟਿਕਟਾਂ';
_fc_props.audit_ticket_number = 'ਟਿਕਟ ਨੰਬਰ';
_fc_props.audit_date_created = 'ਬਣਾਉਣ ਦੀ ਮਿਤੀ';
_fc_props.audit_vehicle_number = 'ਵਾਹਨ ਨੰਬਰ';
_fc_props.cloud_dvir__table_ok = 'ਚਲਾਉਣ ਲਈ ਸੁਰੱਖਿਅਤ';
_fc_props.cloud_dvir__table_needs_repair = 'ਕੰਮ ਕਰਨ ਲਈ ਅਸੁਰੱਖਿਅਤ';
_fc_props.missing_air_calibration = 'ਕੋਈ ਏਅਰ ਕੈਲੀਬ੍ਰੇਸ਼ਨ ਡੇਟਾ ਨਹੀਂ';
_fc_props.reported_defects = 'ਰਿਪੋਰਟ ਕੀਤੀ ਨੁਕਸ';
_fc_props.address_latitude = 'ਪਤਾ ਵਿਥਕਾਰ';
_fc_props.address_longitude = 'ਪਤਾ ਲੰਬਕਾਰ';
_fc_props.routing_latitude = 'ਰੂਟਿੰਗ ਵਿਥਕਾਰ';
_fc_props.routing_longitude = 'ਰੂਟਿੰਗ ਲੰਬਕਾਰ';
_fc_props.category_name_must_be_unique = 'ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ ਵਿਲੱਖਣ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.subcategory_name_must_be_unique = 'ਉਪ-ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ ਵਿਲੱਖਣ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ';
_fc_props.menu_settings_tracking_device = 'ਟਰੈਕਿੰਗ ਡਿਵਾਈਸ';
_fc_props.menu_settings_title_tracking_device = 'ਟਰੈਕਿੰਗ ਡਿਵਾਈਸ';
_fc_props.menu_settings_title_personal_device_registration = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਰਜਿਸਟ੍ਰੇਸ਼ਨ';
_fc_props.registration_code = 'ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ';
_fc_props.creation_date = 'ਰਚਨਾ';
_fc_props.expiration_date = 'ਮਿਆਦ ਪੁੱਗਣ';
_fc_props.trackitadmin_personal_device_registration = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਰਜਿਸਟ੍ਰੇਸ਼ਨ';
_fc_props.create_registration_code = 'ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.remove_registration_code = 'ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਹਟਾਓ';
_fc_props.add_a_registration_code = 'ਇੱਕ ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.select_number_of_registration_codes_to_generate = 'ਰਜਿਸਟਰੇਸ਼ਨ ਕੋਡਾਂ ਦੀ ਗਿਣਤੀ ਚੁਣੋ ਜੋ ਤੁਸੀਂ ਬਣਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ।';
_fc_props.registration_code_count = 'ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਦੀ ਗਿਣਤੀ';
_fc_props.are_you_sure_you_want_to_delete_this_reg_code = 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ \'ਤੇ ਚੁਣੇ ਹੋਏ ਰਜਿਸਟਰਡ ਕੋਡ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.are_you_sure_you_want_to_delete_selected_reg_codes = 'ਕੀ ਤੁਸੀਂ ਚੁਣੇ ਹੋਏ ਰਜਿਸਟਰਡ ਕੋਡਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.add_personal_device = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.menu_settings_title_personal_device = 'ਨਿੱਜੀ ਡਿਵਾਈਸ';
_fc_props.cloud_dvir__approval_critical_alert = 'ਵਾਹਨ ਵਿੱਚ ਗੰਭੀਰ ਨੁਕਸ ਹੈ। ਚਲਾਉਣ ਲਈ ਸੁਰੱਖਿਅਤ ਵਜੋਂ ਨਿਸ਼ਾਨਦੇਹੀ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕਦੀ।';
_fc_props.export_registration_codes = 'ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਐਕਸਪੋਰਟ ਕਰੋ';
_fc_props.download_format = 'ਫਾਰਮੈਟ ਡਾਊਨਲੋਡ ਕਰੋ';
_fc_props.specify_download_format_x = '{0} ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡਾਂ ਨੂੰ ਨਿਰਯਾਤ ਕਰਨ ਲਈ ਡਾਊਨਲੋਡ ਫਾਰਮੈਟ ਦਿਓ';
_fc_props.personal_device_download = 'ਡਾਊਨਲੋਡ ਕਰੋ';
_fc_props.add_personal_device_input_message = 'ਹੇਠਾਂ ਇੱਕ ਨਿੱਜੀ ਡਿਵਾਈਸ ID ਇਨਪੁਟ ਕਰੋ। ਸੇਵ ਕਰਨ ਤੋਂ ਬਾਅਦ ਮਿਆਦ ਸਮਾਪਤੀ ਆਪਣੇ ਆਪ ਲਾਗੂ ਹੋ ਜਾਵੇਗੀ';
_fc_props.error_creating_personal_device = 'ਇੱਕ ਨਿੱਜੀ ਡਿਵਾਈਸ ਬਣਾਉਣ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.device_already_exists = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ';
_fc_props.export = 'ਨਿਰਯਾਤ';
_fc_props.error_can_not_change_connex_eid = 'ਕਨੈਕਸ ਈਡ ਨੂੰ ਬਦਲਿਆ ਨਹੀਂ ਜਾ ਸਕਦਾ ਕਿਉਂਕਿ ਇਹ ਵਰਤਮਾਨ ਵਿੱਚ ਮਾਸਟਰ ਡੇਟਾ ਲਈ ਵਰਤਿਆ ਜਾ ਰਿਹਾ ਹੈ';
_fc_props.remove_personal_device = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਹਟਾਓ';
_fc_props.are_you_sure_you_want_to_delete_this_personal_device = 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ \'ਤੇ ਚੁਣੀ ਗਈ ਨਿੱਜੀ ਡਿਵਾਈਸ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.are_you_sure_you_want_to_delete_selected_personal_devices = 'ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ \'ਤੇ ਚੁਣੀਆਂ ਗਈਆਂ ਨਿੱਜੀ ਡਿਵਾਈਸਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?';
_fc_props.error_deleting_personal_device = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਨੂੰ ਮਿਟਾਉਣ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.customerid = 'ਗਾਹਕ ਆਈ.ਡੀ';
_fc_props.customername = 'ਗਾਹਕ ਦਾ ਨਾਮ';
_fc_props.ordernumber = 'ਕ੍ਰਮ ਸੰਖਿਆ';
_fc_props.orderdescription = 'ਆਰਡਰ ਦਾ ਵਰਣਨ';
_fc_props.locationid = 'ਟਿਕਾਣਾ ਆਈ.ਡੀ';
_fc_props.locationdescription = 'ਟਿਕਾਣਾ ਵਰਣਨ';
_fc_props.productid = 'ਉਤਪਾਦ ਆਈ.ਡੀ';
_fc_props.productdescription = 'ਉਤਪਾਦ ਵਰਣਨ';
_fc_props.vehicleid = 'ਵਾਹਨ ਆਈ.ਡੀ';
_fc_props.vehicledescription = 'ਵਾਹਨ ਦਾ ਵੇਰਵਾ';
_fc_props.ticketnumber = 'ਟਿਕਟ #';
_fc_props.ticketdate = 'ਟਿਕਟ ਦੀ ਮਿਤੀ/ਸਮਾਂ';
_fc_props.drivername = 'ਡਰਾਈਵਰ ਦਾ ਨਾਮ';
_fc_props.arriveregion = 'ਪਹੁੰਚਣਾ ਖੇਤਰ (TIME)';
_fc_props.leaveregion = 'ਖੇਤਰ ਛੱਡੋ (TIME)';
_fc_props.regionduration = 'ਖੇਤਰ ਦੀ ਮਿਆਦ';
_fc_props.menu_reports_title_prevailing_wage = 'ਪ੍ਰਚਲਿਤ ਤਨਖਾਹ';
_fc_props.slump_calibration_x_not_found_for_y = 'ਟਿਕਟ {1} ਲਈ ਸਲੰਪ ਕੈਲੀਬ੍ਰੇਸ਼ਨ "{0}" ਨਹੀਂ ਮਿਲਿਆ';
_fc_props.slump_calibration_not_found = 'ਸਲੰਪ ਕੈਲੀਬ੍ਰੇਸ਼ਨ ਨਹੀਂ ਮਿਲਿਆ';
_fc_props.slump_calibration_not_found_description = 'ਸਲੰਪ ਕੈਲੀਬ੍ਰੇਸ਼ਨ ਨਾ ਮਿਲਣ \'ਤੇ ਚੇਤਾਵਨੀ';
_fc_props.error_getting_personal_device_list = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਸੂਚੀ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ';
_fc_props.batch_summary_date_range_note = '*ਨੋਟ: ਜੇਕਰ ਕੋਈ ਫਿਲਟਰ ਲਾਗੂ ਨਹੀਂ ਕੀਤੇ ਜਾਂਦੇ ਹਨ ਤਾਂ ਇਹ ਰਿਪੋਰਟ 3 ਦਿਨਾਂ ਤੱਕ ਸੀਮਿਤ ਹੈ। ਉਪਕਰਨ (10 ਅਧਿਕਤਮ), ਪਲਾਂਟ (1 ਅਧਿਕਤਮ), ਉਤਪਾਦ (1 ਅਧਿਕਤਮ), ਜਾਂ ਨੌਕਰੀ (1 ਅਧਿਕਤਮ) ਦੁਆਰਾ ਫਿਲਟਰ ਕੀਤੇ ਜਾਣ \'ਤੇ ਮਿਤੀ ਦੀ ਰੇਂਜ 31 ਦਿਨਾਂ ਤੱਕ ਜਾ ਸਕਦੀ ਹੈ।';
_fc_props.select_registration_code_expiration_time_days = 'ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਦੀ ਮਿਆਦ ਪੁੱਗਣ ਤੱਕ ਦਿਨਾਂ ਦੀ ਗਿਣਤੀ ਚੁਣੋ ਅਤੇ ਫਿਰ ਸੇਵ \'ਤੇ ਕਲਿੱਕ ਕਰੋ।';
_fc_props.add_personal_device_expiration = 'ਮਿਆਦ ਪੁੱਗਣ (ਦਿਨਾਂ ਵਿੱਚ)';
_fc_props.cloud_dvir__vehicle_no = 'ਵਾਹਨ ਨੰ';
_fc_props.cloud_dvir__trailer_no = 'ਟ੍ਰੇਲਰ ਨੰ';
_fc_props.cloud_dvir__vehicle = 'ਵਾਹਨ';
_fc_props.cloud_dvir__trailer = 'ਟ੍ਰੇਲਰ';
_fc_props.cloud_dvir__truck_number_label = 'ਟਰੱਕ ਨੰਬਰ';
_fc_props.cloud_dvir__trailer_number = 'ਟ੍ਰੇਲਰ ਨੰਬਰ';
_fc_props.cloud_dvir__has_trailer = 'ਟ੍ਰੇਲਰ ਨਾਲ ਗੱਡੀ ਨਹੀਂ ਚਲਾ ਰਿਹਾ';
_fc_props.cloud_dvir__trailer_input_field_prompt = 'ਇੱਥੇ ਆਪਣਾ ਟ੍ਰੇਲਰ ਨੰਬਰ ਦਰਜ ਕਰੋ';
_fc_props.work_order = 'ਵਰਕ ਆਰਡਰ';
_fc_props.unable_to_determine_slump = 'ਮੰਦੀ ਦਾ ਪਤਾ ਲਗਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ';
_fc_props.cloud_dvir__determine_the_overall_condition_of_the_trailer = 'ਟ੍ਰੇਲਰ ਦੀ ਸਮੁੱਚੀ ਸਥਿਤੀ ਦਾ ਪਤਾ ਲਗਾਓ';
_fc_props.defaultTrailer = 'ਡਿਫੌਲਟ ਟ੍ਰੇਲਰ';
_fc_props.trailer_type_column_billable = 'ਬਿਲ ਕਰਨ ਯੋਗ';
_fc_props.trailer_type_column_notes = 'ਨੋਟਸ';
_fc_props.trailer_type_column_date = 'ਮਿਤੀ';
_fc_props.trailer_type_column_order = 'ਆਰਡਰ';
_fc_props.trailer_type_column_ticket = 'ਟਿਕਟ';
_fc_props.trailer_type_column_project = 'ਪ੍ਰੋਜੈਕਟ';
_fc_props.trailer_type_column_customer = 'ਗਾਹਕ';
_fc_props.trailer_type_column_location = 'ਟਿਕਾਣਾ';
_fc_props.trailer_type_column_vehicle_type = 'ਵਾਹਨ ਦੀ ਕਿਸਮ';
_fc_props.trailer_type_column_product = 'ਉਤਪਾਦ';
_fc_props.trailer_type_action_rebill = 'ਰੀਬਿਲ';
_fc_props.master_data_sync_failed_no_default_vehicle_type_has_been_set = 'ਮਾਸਟਰ ਡਾਟਾ ਸਮਕਾਲੀਕਰਨ ਅਸਫਲ ਰਿਹਾ ਕੋਈ ਡਿਫੌਲਟ ਉਪਕਰਣ ਕਿਸਮ ਸੈੱਟ ਨਹੀਂ ਕੀਤਾ ਗਿਆ ਹੈ';
_fc_props.trailer_type = 'ਟ੍ਰੇਲਰ ਦੀ ਕਿਸਮ';
_fc_props.trailer_type_is_required = 'ਟ੍ਰੇਲਰ ਕਿਸਮ ਦੀ ਲੋੜ ਹੈ';
_fc_props.trailer_number_already_exists = 'ਟ੍ਰੇਲਰ ਨੰਬਰ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ';
_fc_props.plate_number_already_exists = 'ਪਲੇਟ ਨੰਬਰ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ';
_fc_props.trailer_vin_already_exists = 'ਟ੍ਰੇਲਰ ਵਿਨ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ';
_fc_props.trailer_does_not_exist = 'ਟ੍ਰੇਲਰ ਮੌਜੂਦ ਨਹੀਂ ਹੈ';
_fc_props.trailer_number_is_required = 'ਟ੍ਰੇਲਰ ਨੰਬਰ ਲੋੜੀਂਦਾ ਹੈ';
_fc_props.plate_number_is_required = 'ਪਲੇਟ ਨੰਬਰ ਦੀ ਲੋੜ ਹੈ';
_fc_props.plate_number = 'ਪਲੇਟ ਨੰਬਰ';
_fc_props.menu_settings_title_trailer = 'ਟ੍ਰੇਲਰ';
_fc_props.add_trailer = 'ਟ੍ਰੇਲਰ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.edit_trailer = 'ਟ੍ਰੇਲਰ ਦਾ ਸੰਪਾਦਨ ਕਰੋ';
_fc_props.menu_settings_title_equipment = 'ਉਪਕਰਨ';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes = 'ਕਮਾਂਡ ਕਲਾਉਡ ਤੋਂ X ਮਿੰਟ ਪੁਰਾਣੀਆਂ ਨਵੀਆਂ ਟਿਕਟਾਂ ਨੂੰ ਅਣਡਿੱਠ ਕਰੋ';
_fc_props.settings_ignore_new_tickets_from_command_cloud_older_than_specified_minutes_title = 'X ਮਿੰਟਾਂ ਤੋਂ ਪੁਰਾਣੇ ਡਿਸਪੈਚ ਮਿਤੀ ਸਮੇਂ ਦੇ ਨਾਲ ਕਮਾਂਡ ਕਲਾਉਡ ਤੋਂ ਨਵੀਆਂ ਟਿਕਟਾਂ ਨੂੰ ਅਣਡਿੱਠ ਕਰੋ (ਡਿਫੌਲਟ ਖਾਲੀ ਅਤੇ ਬੰਦ ਹੈ)';
_fc_props.edit_load_card = 'ਲੋਡ ਕਾਰਡ ਦਾ ਸੰਪਾਦਨ ਕਰੋ';
_fc_props.default_layout = 'ਪੂਰਵ-ਨਿਰਧਾਰਤ ਖਾਕਾ';
_fc_props.load_card_empty_fields = 'ਲੋਡ ਕਾਰਡ ਨੂੰ ਸੰਭਾਲਣ ਵਿੱਚ ਤਰੁੱਟੀ: ਇੱਕ ਜਾਂ ਵੱਧ ਖੇਤਰ ਚੁਣੇ ਜਾਣੇ ਚਾਹੀਦੇ ਹਨ।';
_fc_props.trailers = 'ਟ੍ਰੇਲਰ';
_fc_props.error_failed_to_save_data = 'ਗੜਬੜ: ਡਾਟਾ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਅਸਫਲ';
_fc_props.slump_increased_plasticizer_added_question = 'ਕਿੰਨਾ ਪਲਾਸਟਿਕਾਈਜ਼ਰ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ ਸੀ?';
_fc_props.add_trailer_type = 'ਟ੍ਰੇਲਰ ਦੀ ਕਿਸਮ ਸ਼ਾਮਲ ਕਰੋ';
_fc_props.menu_settings_title_trailer_type = 'ਟ੍ਰੇਲਰ ਦੀ ਕਿਸਮ';
_fc_props.edit_trailer_type = 'ਟ੍ਰੇਲਰ ਦੀ ਕਿਸਮ ਦਾ ਸੰਪਾਦਨ ਕਰੋ';
_fc_props.single_use_codes = 'ਸਿੰਗਲ ਯੂਜ਼ ਕੋਡ';
_fc_props.multiple_use_codes = 'ਮਲਟੀਪਲ ਯੂਜ਼ ਕੋਡ';
_fc_props.trailer_type_description_is_required = 'ਟ੍ਰੇਲਰ ਦੀ ਕਿਸਮ ਦਾ ਵਰਣਨ ਲੋੜੀਂਦਾ ਹੈ';
_fc_props.trailer_saved = 'ਟ੍ਰੇਲਰ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ';
_fc_props.cloud_dvir__trailer_only_dvir = 'ਟ੍ਰੇਲਰ ਸਿਰਫ਼ DVIR';
_fc_props.choose_file = 'ਫਾਈਲ ਚੁਣੋ';
_fc_props.no_file_chosen = 'ਕੋਈ ਫ਼ਾਈਲ ਨਹੀਂ ਚੁਣੀ ਗਈ';
_fc_props.please_choose_file = 'ਕਿਰਪਾ ਕਰਕੇ ਫਾਈਲ ਚੁਣੋ';
_fc_props.no_filters = 'ਕੋਈ ਫਿਲਟਰ ਨਹੀਂ ਚੁਣਿਆ ਗਿਆ';
_fc_props.remaining_defects = 'ਬਾਕੀ ਨੁਕਸ';
_fc_props.load_more = 'ਹੋਰ ਲੋਡ ਕਰੋ';
_fc_props.pagination_label = '{2} ਵਿੱਚੋਂ {0}-{1}';
_fc_props.extend = 'ਵਿਸਤਾਰ ਕਰੋ';
_fc_props.cloud_dvir__notes_or_photo_required = 'ਜਾਂ ਤਾਂ ਨੋਟਸ ਜਾਂ ਫੋਟੋ ਦੀ ਲੋੜ ਹੈ।';
_fc_props.no_default_plant_specified_failed_to_save = 'ਕੋਈ ਡਿਫਾਲਟ ਪਲਾਂਟ ਨਹੀਂ ਦਿੱਤਾ ਗਿਆ।';
_fc_props.no_default_employee_type_specified_failed_to_save = 'ਕੋਈ ਪੂਰਵ-ਨਿਰਧਾਰਤ ਕਰਮਚਾਰੀ ਦੀ ਕਿਸਮ ਨਹੀਂ ਦਿੱਤੀ ਗਈ ਹੈ।';
_fc_props.personal_device_registration_code_saved = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ';
_fc_props.error_saving_personal_device_registration_code = 'ਨਿੱਜੀ ਡਿਵਾਈਸ ਰਜਿਸਟ੍ਰੇਸ਼ਨ ਕੋਡ ਨੂੰ ਸੁਰੱਖਿਅਤ ਕਰਨ ਵਿੱਚ ਤਰੁੱਟੀ। ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ.';
_fc_props.settings.master_data_sub_title = 'TrackIt ਅਤੇ Connex ਵਿਚਕਾਰ ਇੱਕ ਮਾਸਟਰ ਡਾਟਾ ਏਕੀਕਰਣ ਸੈਟਅੱਪ ਕਰੋ';
_fc_props.connex_authentication = '{0} ਪ੍ਰਮਾਣੀਕਰਨ';
_fc_props.select_company = 'ਕੰਪਨੀ ਚੁਣੋ';
_fc_props.requirements = 'ਲੋੜਾਂ';
_fc_props.default_plant_type = 'ਡਿਫਾਲਟ ਪਲਾਂਟ';
_fc_props.default_employee_type = 'ਪੂਰਵ-ਨਿਰਧਾਰਤ ਕਰਮਚਾਰੀ ਦੀ ਕਿਸਮ';
_fc_props.default_equipment_type = 'ਪੂਰਵ-ਨਿਰਧਾਰਤ ਉਪਕਰਨ ਦੀ ਕਿਸਮ';
_fc_props.support_alphanumeric_trucks = 'ਅਲਫਾਨਿਊਮੇਰਿਕ ਟਰੱਕਾਂ ਦਾ ਸਮਰਥਨ ਕਰੋ';
_fc_props.no_default_equipment_type_specified_failed_to_save = 'ਕੋਈ ਪੂਰਵ-ਨਿਰਧਾਰਤ ਉਪਕਰਨ ਦੀ ਕਿਸਮ ਨਹੀਂ ਦਿੱਤੀ ਗਈ ਹੈ।';
_fc_props.air_pau_battery_low_message = 'ਏਅਰ PAU ਬੈਟਰੀ ਘੱਟ ਚੇਤਾਵਨੀ';
_fc_props.air_pau_battery_low = 'ਏਅਰ PAU ਬੈਟਰੀ ਘੱਟ';
_fc_props.alert_when_air_pau_battery_low = 'ਏਅਰ PAU ਬੈਟਰੀ ਘੱਟ ਹੋਣ \'ਤੇ ਚੇਤਾਵਨੀ';
_fc_props.air_pau_battery_low_alert = 'ਏਅਰ ਬੈਟਰੀ ਘੱਟ ਸੁਚੇਤਨਾ';
_fc_props.sensor_unable_to_determine_slump = 'ਸੈਂਸਰ ਸਲੰਪ ਦਾ ਪਤਾ ਲਗਾਉਣ ਵਿੱਚ ਅਸਮਰੱਥ ਹੈ।';
_fc_props.menu_settings_title_export = 'ਨਿਰਯਾਤ ਕਰੋ';
_fc_props.compare_company = 'ਕਿਸੇ ਹੋਰ ਕੰਪਨੀ ਨਾਲ ਤੁਲਨਾ ਕਰੋ';
_fc_props.site_settings = 'ਸਾਈਟ ਸੈਟਿੰਗਾਂ';
_fc_props.permission_type = 'ਇਜਾਜ਼ਤ ਦੀ ਕਿਸਮ';
_fc_props.ticket_integration = 'ਟਿਕਟ ਏਕੀਕਰਣ';
_fc_props.select_one_or_more_settings = 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਜਾਂ ਵੱਧ ਸੈਟਿੰਗਾਂ ਚੁਣੋ।';
_fc_props.export_tooltip = 'ਇਹ ਵਿਕਲਪ ਸਿਰਫ਼ ਤਾਂ ਹੀ ਉਪਲਬਧ ਹੈ ਜੇਕਰ ਤੁਸੀਂ ਇੱਕ ਤੋਂ ਵੱਧ ਕੰਪਨੀਆਂ ਨਾਲ ਸਬੰਧਤ ਹੋ।';
_fc_props.select_company_error = 'ਕਿਰਪਾ ਕਰਕੇ ਕੋਈ ਕੰਪਨੀ ਚੁਣੋ।';
_fc_props.audible = 'ਸੁਣਨਯੋਗ';
_fc_props.deleted_master_data_successfully = 'ਮਾਸਟਰ ਡੇਟਾ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾਇਆ ਗਿਆ';
_fc_props.cloud_dvir__units_miles_abbr = 'ਐਮਆਈ';
_fc_props.cloud_dvir__units_kilometers_abbr = 'ਕਿਲੋਮੀਟਰ';

export default _fc_props;
