import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { Log } from '../utils/Log';
import { EVENT_TYPE, DEFAULT_ODOMETER_ISO_UOM } from './constants';
import { InspectionTripType } from '../types/types';
import VersionManager, { FunctionMap } from '../utils/VersionManager';
import { mergeNonUndefined } from '../utils/objects';

export type Location = {
  latitude: number | null;
  longitude: number | null;
}

export type EngineData = {
  odometer: number | string,
  engineHours: number | string,
}

export type DriverData = {
  driverId: string | null;
  driverName: string | null;
}

export type TrailerData = {
  trailerId: string;
  trailerNumber: string;
  cloudDvirTemplateId: string;
}

export type StartUpData = {
  inspectionTripType: InspectionTripType;
  vin: string;
  language: string;
  vehicleNum: string;
  defaultOdometerIsoUom: string;
}

export interface EventsManagerContextValue extends StartUpData {
  location: Location;
  engineData: EngineData;
  driverData: DriverData;
  address: string;
  auxFunctions: FunctionMap;
  isAttemptToCloseDvir: boolean;
  trailerList: TrailerData[];
  setIsAttemptToCloseDvir: (value: boolean) => void;
}

const initLocation: Location = { latitude: null, longitude: null };
const initEngineData: EngineData = { odometer: 0, engineHours: 0 };
const initDriverData: DriverData = { driverId: null, driverName: null };
const initStartUpData: StartUpData = {
  inspectionTripType: null, vin: null, language: 'en', vehicleNum: null, defaultOdometerIsoUom: DEFAULT_ODOMETER_ISO_UOM,
};

export const EventsManagerContext = createContext<EventsManagerContextValue>({
  location: initLocation,
  engineData: initEngineData,
  driverData: initDriverData,
  address: '',
  auxFunctions: {},
  isAttemptToCloseDvir: false,
  trailerList: [],
  setIsAttemptToCloseDvir: () => {},
  ...initStartUpData,
});

export const EventsManagerProvider = ({ children }) => {
  const [location, setLocation] = useState<Location>(initLocation);
  const [address, setAddress] = useState<string>('');
  const [engineData, setEngineData] = useState<EngineData>(initEngineData);
  const [driverData, setDriverData] = useState<DriverData>(initDriverData);
  const [trailerList, setTrailerList] = useState<TrailerData[]>([]);
  const [startUpData, setStartUpData] = useState<StartUpData>(initStartUpData);
  const [isAttemptToCloseDvir, setIsAttemptToCloseDvir] = useState<boolean>(false);
  const auxFunctions = VersionManager.auxFunctionsMap;

  const handleJwtGenerationCompleted = (payloadData) => {
    const eventType = `${EVENT_TYPE.JWT_READY}_${payloadData.eventUUID}`;

    window.dispatchEvent(new CustomEvent(eventType, {
      detail: {
        type: eventType,
        token: payloadData.token,
        eventUUID: payloadData.eventUUID,
      },
    }));
  };

  const handleEventMessage = (event: MessageEvent) => {
    if (!event?.data.payload || !event?.data.type) {
      return;
    }

    const { payload, type } = event.data;

    if (type.startsWith(`${EVENT_TYPE.JWT_GENERATION_COMPLETED}_`)) {
      const payloadData = JSON.parse(payload);

      if (type === `${EVENT_TYPE.JWT_GENERATION_COMPLETED}_${payloadData.eventUUID}`) {
        handleJwtGenerationCompleted(payloadData);
        return;
      }
      return;
    }

    switch (type) {
      case EVENT_TYPE.LOCATION_CHANGED:
      case EVENT_TYPE.LOCATION_UPDATED:
        const { latitude, longitude } = payload as Location;

        setLocation({ latitude, longitude });
        return;

      case EVENT_TYPE.ADDRESS_UPDATED:
        const payloadData = JSON.parse(payload);
        setAddress(payloadData.address);
        return;

      case EVENT_TYPE.ENGINE_DATA:
        const { odometer, engineHours } = payload as EngineData;

        setEngineData({ odometer, engineHours });
        return;

      case EVENT_TYPE.DRIVER_DATA: {
        const { driverId, driverName } = payload as DriverData;

        setDriverData({ driverId, driverName });
        return;
      }

      case EVENT_TYPE.START_UP_DATA:
        const {
          vin, inspectionTripType, language, vehicleNum, defaultOdometerIsoUom,
        } = payload as StartUpData;

        setStartUpData((prevData) => mergeNonUndefined(prevData, {
          vin, inspectionTripType, language, vehicleNum, defaultOdometerIsoUom,
        }));

        const { driverId, driverName } = payload as DriverData;

        if (driverId && driverName) {
          setDriverData({ driverId, driverName });
        }

        window.dispatchEvent(new CustomEvent(EVENT_TYPE.REFRESH_HOME_PAGE_DATA, {
          detail: { type: EVENT_TYPE.REFRESH_HOME_PAGE_DATA },
        }));
        return;

      case EVENT_TYPE.CLOSE_DVIR_UI:
        setIsAttemptToCloseDvir(true);

        return;

      case EVENT_TYPE.TRAILER_LIST: {
        const trailerListData = JSON.parse(payload);

        setTrailerList(trailerListData.trailerList);

        return;
      }

      default:
        Log.i('No registered handler found for the following event type :', event.data.type);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleEventMessage, false);

    return () => {
      window.removeEventListener('message', handleEventMessage);
    };
  }, []);

  const value = useMemo(() => ({
    location,
    engineData,
    driverData,
    address,
    auxFunctions,
    isAttemptToCloseDvir,
    trailerList,
    setIsAttemptToCloseDvir,
    ...startUpData,
  }), [
    location,
    engineData,
    driverData,
    address,
    startUpData,
    isAttemptToCloseDvir,
    auxFunctions,
    trailerList,
    setIsAttemptToCloseDvir,
  ]);

  return <EventsManagerContext.Provider value={value}>{children}</EventsManagerContext.Provider>;
};
